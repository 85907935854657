import algoliasearch from "algoliasearch";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ArrowLeft from "../../assets/icons/arrow-ios-left.svg";
import ArrowRight from "../../assets/icons/arrow-ios-right.svg";
import navigation from "../../assets/icons/navigation.svg";
import MapGeolocation from "../Portal/Common/Map/MapGeolocation";
import "./Adventures.css";
import Mapbox from "./Mapbox";

const Adventures = (props) => {
  // eslint-disable-next-line
  const { target, title, onLocationAllow, searchLocation, redirectButton } = props;
  const history = useHistory();
  const [results, setResults] = useState([]);
  const [input, setInput] = useState();
  const [to, setTO] = useState(0);
  const [userLocation, setUserLocation] = useState()


  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const indexGuideFishing = searchClient.initIndex("GUIDE_FISHER");
  const indexHuntTrip = searchClient.initIndex("HUNTING_TRIP");

  useEffect(() => {
    switch (target) {
      case "fishing":
        indexGuideFishing
          .search("", {
            filters:
              searchLocation && searchLocation !== "()" ? searchLocation : "",
          })
          .then(({ hits }) => {
            setResults(hits);
          })
          .catch((err) => {
            return err.message;
          });
        break;
      default:
        indexHuntTrip
          .search("", {
            filters:
              searchLocation && searchLocation !== "()" ? searchLocation : "",
          })
          .then(({ hits }) => {
            setResults(hits);
          })
          .catch((err) => {
            return err.message
          });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, searchLocation]);
  const handleInputChange = (event) => {
    let value = event.target.value;

    if (value.trim().length > 0) {
      clearTimeout(to);
      setTO(
        setTimeout(() => {
          switch (target) {
            case "fishing":
              indexGuideFishing
                .search()
                //The line above brings the data from algolia
                .then(({ hits }) => {
                  //this will filter the results and redirect the map to the new searched area 
                  const matches = hits.filter(e => e.guideRegionOperating[0].toLowerCase().includes(value.toLowerCase()))
                    matches.length >= 1 && setUserLocation({coords:{
                    longitude:matches[0].geolocation.lng,
                    latitude:matches[0].geolocation.lat
                  }})
                  setResults(matches);
                })
                .catch((err) => {
                  return err.message
                });
              break;
            default:
              indexHuntTrip
                .search()
                .then(({ hits }) => {
                  //this will filter the results and redirect the map to the new searched area 
                  const matches = hits.filter(e => e.location[0].toLowerCase().includes(value.toLowerCase()))
                    matches.length >= 1 && setUserLocation({coords:{
                    longitude:matches[0].geolocation.lng,
                    latitude:matches[0].geolocation.lat
                  }})
                  setResults(matches);
                })
                .catch((err) => {
                  return err.message
                });
          }
          setInput(value);
        }, 1500)
      );
    } else {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (target) {
      case "fishing":
        indexGuideFishing
          .search(input)
          .then(({ hits }) => {
            setResults(hits);
          })
          .catch((err) => {
            return err.message;
          });
        break;
      default:
        indexHuntTrip
          .search(input)
          .then(({ hits }) => {
            setResults(hits);
          })
          .catch((err) => {
            return err.message
          });
    }
    indexGuideFishing
      .search(input)
      .then(({ hits }) => {
        setResults(hits);
      })
      .catch((err) => {
      });
  };
  return (
    <>
      <div
        id={target == "fishing" && redirectButton!== "none"? "imageBackgroundFeaturedExpeditions" : ""}
        className="d-flex justify-content-left background-adventures"
      >
        <Container fluid className="mb-5">
          <div className="mapbox-container">
            <Row className="mx-auto pr-0">
              <Col md="9" className="TDestinations-title pl-3">
                <p className="primary-color adventures-first-title mt-5 mb-0">
                  Adventure is close
                </p>
                <h1 className=" font-weight-bold adventures-second-title mb-0">
                  {title}
                </h1>
              </Col>
              <Col
                md="3"
                className="align-self-end flex-row-reverse d-none d-md-block"
              >
            {redirectButton !== 'none'&&
              <Button
                onClick={() => {
                  history.push(
                    `${
                      target == "hunting"
                        ? "/search?query=&target=hunting&category=all"
                        : "/search?target=fishing"
                    }`
                  );
                }}
                variant="Adventures"
                type="submit"
                className="font-size-16 btn-search-adventure d-md-block ml-auto"
              >
                {target == "hunting"
                  ? "Search All Trips"
                  : "Search All Charters"}
              </Button>}
              </Col>
              <Col xs={12} md="3" className="pt-3 pr-0 pl-0 d-md-none">
                <Button
                  onClick={() => {
                    history.push(
                      `${
                        target == "hunting"
                          ? "/search?query=&target=hunting&category=all"
                          : "/search?target=fishing"
                      }`
                    );
                  }}
                  variant="Adventures"
                  type="submit"
                  className="font-size-16 btn-search-adventure"
                  block
                >
                  {target == "hunting"
                    ? "Search All Trips"
                    : "Search All charters"}
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="adventures-map-container">
              <Container fluid className="adventures-map-container mt-5">
                <div className="mapbox-container">
                  <Row>
                    <Col
                      md="8"
                      className="d-none d-sm-none d-md-block map-search-mapbox"
                    >
                      <Mapbox target={target} results={results} currentLocation={userLocation}/>
                    </Col>
                    <Col
                      md="4"
                      sm="12"
                      className="map-search-expeditions px-0 adventures-map-container"
                    >
                      <Form noValidate onSubmit={handleSubmit}>
                        <div className="searchbar-wrapper">
                          <Form.Control
                            type="text"
                            placeholder="Search by address.."
                            className="search-bar "
                            id="searchBarMap"
                            onChange={handleInputChange}
                          />
                          {/* <Image
                            className="search-navigator"
                            src={navigation}
                            onClick={() => {
                              getLocation();
                            }}
                          /> */}
                        </div>
                      </Form>
                      <div className="map-search-div pt-1 ml-1">
                        <div className="map-trip-cards-container mr-1">
                          {!!results.length &&
                            target === "fishing" &&
                            //this will display the results of the search filtering those that don't have the profile information filled out
                            results.filter(trip => trip.guideAboutUs !== null).map((trip) => {
                              return (
                                <div
                                  key={trip.guideFisherID}
                                  className="map-trip-card text-dark d-flex flex-column justify-content-between font-primary"
                                >
                                  <div>
                                    <p className="primary-color font-weight-bold font-size-10  font-weight-bold pt-3 mb-1">
                                      {trip.guideFullName}
                                    </p>
                                    <p className="font-size-18 font-weight-bold mb-0">
                                      {trip.guideBusinessName}
                                    </p>
                                    <p className="my-0 font-size-12">
                                      <span>Capacity </span>
                                      {trip.guidePartySize}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center pb-3 map-card-price">
                                    <p className="primary-color font-weight-bold mb-0">
                                      <span className="secondary-gray font-weight-light mr-2">
                                        from
                                      </span>
                                      {`$${trip.guidePriceMin} - $${trip.guidePriceMax}`}
                                    </p>
                                    <Button
                                      onClick={() => {
                                        history.push(
                                          `/guide-fisherman/${trip.guideFisherID}`
                                        );
                                      }}
                                      variant="booking mr-2"
                                      className="hide-book-trip-btn"
                                    >
                                      Learn more
                                    </Button>
                                  </div>
                                  <Button
                                    onClick={() => {
                                      history.push(
                                        `/guide-fisherman/${trip.guideFisherID}`
                                      );
                                    }}
                                    variant="booking mb-3 mr-2"
                                    className="show-book-trip-btn"
                                  >
                                    Learn more
                                  </Button>
                                </div>
                              );
                            })}
                          {!!results.length &&
                            target === "hunting" &&
                            results.map((trip) => {
                              return (
                                <div
                                  key={trip.huntingTripID}
                                  className="map-trip-card text-dark d-flex flex-column justify-content-between font-primary"
                                >
                                  <div className="pt-3">
                                    <p className="primary-color font-weight-bold font-size-10  font-weight-bold my-0">
                                      {trip.guideBusinessName}
                                    </p>
                                    <p className="map-card-title pt-2">
                                      {trip.huntingTripName}
                                    </p>
                                  </div>
                                  <div className=" d-flex justify-content-between align-items-center pb-3 map-card-price">
                                    <p className="primary-color font-weight-bold mb-0">
                                      ${trip.pricePerPerson}
                                      {/* <span className="price-original  ml-2 mr-5">
                                        {""}
                                      </span> */}
                                    </p>
                                    <Button
                                      onClick={() => {
                                        history.push(
                                          `/hunting-trip/${trip.huntingTripID}`
                                        );
                                      }}
                                      variant="booking mr-2"
                                      className="hide-book-trip-btn"
                                    >
                                      Learn more
                                    </Button>
                                  </div>
                                  <Button
                                    onClick={() => {
                                      history.push(
                                        `/hunting-trip/${trip.huntingTripID}`
                                      );
                                    }}
                                    variant="booking mb-3 mr-2"
                                    className="show-book-trip-btn"
                                  >
                                    Learn more
                                  </Button>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <Row>
                        <Col className="d-flex justify-content-between map-pagination">
                          <p className="pl-4 pt-3 map-pagination-title">
                            Showing results 1-{results.length}
                          </p>
                          <div className="pl-5 pt-2 ">
                            <Image src={ArrowLeft} className="mr-4" />
                            <Image src={ArrowRight} className="mr-5" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Adventures;
