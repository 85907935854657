import React, { useEffect, useState } from "react";
import { Col, Container, Image, ListGroup, Row } from "react-bootstrap";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import AccountActiveDetailsIcon from "../../../assets/icons/Account Details Active.svg";
import AccountDetailsIcon from "../../../assets/icons/Account Details.svg";
import FavoriteActiveIcon from "../../../assets/icons/Favorites Active.svg";
import FavoriteIcon from "../../../assets/icons/Favorites.svg";
import LogoutActiveIcon from "../../../assets/icons/Logout Active.svg";
import LogoutIcon from "../../../assets/icons/Logout.svg";
import MessagesActiveIcon from "../../../assets/icons/Messages Active.svg";
import MessagesIcon from "../../../assets/icons/Messages.svg";
import SwitchActiveIcon from "../../../assets/icons/Switch Account Active.svg";
import SwitchIcon from "../../../assets/icons/Switch Account.svg";
import { app } from "../../../FirebaseConfig";
import { getInitials } from "../../../utils/Misc";
export default function MenuCustomer({
  fullName,
  selectedRole,
  profilePic,
  selectedTab,
  onChangeItem,
  triggerChangeModal,
}) {
  const [selectedPage, setSelectedPage] = useState(
    selectedTab ?? "favorite-trips"
  );

  const history = useHistory();

  useEffect(() => {
    setSelectedPage(selectedTab);
  }, [selectedTab]);

  const logout = async () => {
    await app
      .auth()
      .signOut()
      .then((result) => {
        history.push("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Router>
        <ListGroup variant="flush" className="lateral-menu">
          <ListGroup.Item className="mt-3 lateral-menu-header">
            <Container fluid>
              <Row>
                <Col>
                  <Row>
                    {profilePic ? (
                      <img
                        className="d-none d-sm-block circle-profile "
                        src={profilePic}
                        alt=""
                      />
                    ) : (
                      <div className="circle-profile mt-1">
                        <span className="initials">
                          {getInitials(fullName)}
                        </span>
                      </div>
                    )}
                    <Col>
                      <p className="card-text font-size-18 font-weight-bold user mt-1">
                        {fullName}
                      </p>
                      <p className="font-size-12 role-name type font-weight-normal">
                        {selectedRole.toLowerCase()} portal
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedPage === "favorite-trips"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("favorite-trips");
              onChangeItem("favorite-trips");
            }}
          >
            <Image className="selected mr-3" src={FavoriteActiveIcon} />
            <Image className="no-selected mr-3 " src={FavoriteIcon} /> Favorite
            Trips
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedPage === "messages"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("messages");
              onChangeItem("messages");
            }}
          >
            <Image className="selected mr-3" src={MessagesActiveIcon} />
            <Image className="no-selected mr-3 " src={MessagesIcon} />
            Messages
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedPage === "settings"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("settings");
              onChangeItem("settings");
            }}
          >
            <Image className="selected mr-3" src={AccountActiveDetailsIcon} />
            <Image className="no-selected mr-3 " src={AccountDetailsIcon} />
            Account Details
          </ListGroup.Item>
          <ListGroup.Item
            className="lateral-menu-item"
            onClick={() => {
              triggerChangeModal();
            }}
          >
            <Image className="selected mr-3" src={SwitchActiveIcon} />
            <Image className="no-selected mr-3 " src={SwitchIcon} />
            Switch Account
          </ListGroup.Item>
          <ListGroup.Item
            className="lateral-menu-item"
            onClick={() => logout()}
          >
            <Image className="selected mr-3" src={LogoutActiveIcon} />
            <Image className="no-selected mr-3 " src={LogoutIcon} />
            Log Out
          </ListGroup.Item>
        </ListGroup>
      </Router>
    </>
  );
}
