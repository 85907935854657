import update from "immutability-helper";
import React from "react";
import { Image, Table as ReactTable } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";
import {
  useColumnOrder,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import keypad from "../assets/icons/keypad-fill.svg";
const Table = ({ columns, data, drag, updateData }) => {
  const [records, setRecords] = React.useState(data);

  const getRowId = React.useCallback((row) => {
    return row.reviewId;
  }, []);

  React.useEffect(() => {
    setRecords(data);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      data: records,
      columns,
      getRowId,
    },
    useResizeColumns,
    useColumnOrder,
    useSortBy
  );

  const moveRow = async (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };
  const updatedata = () => {
    updateData(records);
  };
  return (
    <>
      <ReactTable
        {...getTableProps()}
        responsive
        borderless
        className="reviews-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {drag && <th />}
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, index) =>
              prepareRow(row) || (
                <RowTable
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  isDrag={drag}
                  updatedata={updatedata}
                  height="20px"
                  {...row.getRowProps()}
                />
              )
          )}
        </tbody>
      </ReactTable>
    </>
  );
};

const DND_ROW_TYPE = "row";

const RowTable = ({ row, index, moveRow, isDrag, updatedata }) => {
  const dropRefRow = React.useRef(null);
  const dragRefRow = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ROW_TYPE,
    drop: () => updatedata(),
    hover(item, monitor) {
      if (!dropRefRow.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRefRow.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDraggingRow }, drag, preview] = useDrag({
    item: { type: DND_ROW_TYPE, index },

    collect: (monitor) => ({
      isDraggingRow:
        monitor.isDragging() && monitor.getItemType() === DND_ROW_TYPE,
      isOver: monitor.isOver,
      canDrop: monitor.canDrop,
    }),
  });
  const opacity = isDraggingRow ? 0.5 : 1;

  preview(drop(dropRefRow));
  drag(dragRefRow);

  return (
    <>
      {isDrag ? (
        <tr ref={dropRefRow} style={{ opacity }}>
          <td ref={dragRefRow}>
            <Image src={keypad} />
          </td>
          {row.cells.map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
          })}
        </tr>
      ) : (
        <tr>
          {row.cells.map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
          })}
        </tr>
      )}
    </>
  );
};

export default Table;
