import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Separator from "../utils/Separator";
import "./FloatingCard.css";

const FloatingCard = (props) => {
  return (
    <>
      <Container fluid className={`${props.background} `}>
        <Row noGutters>
          <Col>
            <Row className="mx-0 mb-3 align-items-center">
              <h2 className="font-weight-bold mr-2 font-size-18 font-family-Roboto">
                {props.title}
              </h2>
              <Separator size="300px" color={props.lineColor} />
            </Row>
          </Col>
        </Row>
        <Row noGutters>
          <Col className={`${props.textColor} ${props.fontFamily && "montserrat"}`}>{props.text}</Col>
        </Row>
      </Container>
    </>
  );
};
export default FloatingCard;
