import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import Loader from 'react-loader-spinner'
import NewTrip from "./NewTrip";
import UpdateTrip from "./UpdateTrip";
import TripCard from "../../../../common/TripCard";
import { User } from "../../../../utils/UserProvider";
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";
import { AiFillWarning } from "react-icons/ai";
import { Staff } from "../../../../utils/staffProvider";

/**
 * TODO: Separate table trips by status
 */
export default function GuideHunterTrips() {

  const [page, setPage] = useState("");
  const { signInAs } = useContext(Staff)
  // eslint-disable-next-line
  const [tab, setTab] = useState("new");
  const [tripsList, setTripsList] = useState([]);
  const [currentTripId, setCurrentTripId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const { user } = useContext(User);

  const getHuntTrips = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!user) {
          throw new Error('User is not logged in!');
        }
        let selRole = user.otherRoles.find(
          (r) => r.roleName === "HUNTER"
        );
        let result = await request({
          url: 'guidex-api',
          trailingURL: `/hunt-trips?guide_id=${signInAs ? signInAs : selRole['userId']}`,
          requiresAuth: true
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    })
  }

  const processHuntTrips = async () => {
    setIsLoading(true);
    await getHuntTrips()
      .then(result => {
        const { trips } = result;
        setIsLoading(false);
        setTripsList(trips);
      })
      .catch(err => {
        showToast(
          <>
            <AiFillWarning /> There was a problem trying to fetch the trips data.
          </>,
          "warning"
        );
        setIsLoading(false);
      })
  }

  const handleTripUpload = () => {
    setPage("");
    processHuntTrips();
  }

  const onSelectAction = (action, tripId) => {
    switch (action) {
      default:
        setPage("updateTrip");;
        setCurrentTripId(tripId)
    }
  }

  useEffect(() => {
    processHuntTrips();
    // eslint-disable-next-line
  }, [reload]);

  console.dir(tripsList)
  return (
    <>
      {
        (() => {
          switch (page) {
            case "newTrip":
              return <NewTrip onUploadTrip={handleTripUpload} handlePage={setPage}/>
            case "updateTrip":
              return <UpdateTrip onUploadTrip={handleTripUpload} tripId={currentTripId} handlePage={setPage}/>
            default:
              return (
                isLoading ?
                  <center className="mt-5">
                    <Loader
                      type="TailSpin"
                      color="#ec7228"
                      height={150}
                      width={150}
                    />
                  </center>
                  :
                  <div className="mt-5 mb-5">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="reviews-title d-inline mr-3">Trips</p>
                      </div>
                      <Button variant="request-review" onClick={() => {
                        setPage("newTrip")
                      }}>
                        Add New Trip<span className="font-size-20 ml-4">+</span>
                      </Button>
                    </div>
                    {
                      tripsList.length > 0 ?
                        <>
                          <Row className="mt-5 mb-4">
                            <Col className="trips-tab mb-3">
                              <p
                                className={` reviews-tab-title font-weight-bold d-inline ${tab === "new" ? "active-tab" : ""
                              }`}
                                onClick={() => setTab("new")}
                              >
                                Active Trips
                              </p>
                              <p
                                className={`reviews-tab-title  d-inline font-weight-bold ${tab === "archived" ? "active-tab" : ""
                              }`}
                                onClick={() => setTab("archived")}
                              >
                                Archived
                              </p>
                              {/*<p className=" reviews-tab-title d-inline font-weight-bold">
                                Past Trips
                              </p>*/}
                            </Col>
                          </Row>
                          {tripsList.map((tripItem) => { 
                            const { guide, trip, tripGallery } = tripItem;
                            const tripData = {
                              id: trip.tripId,
                              guideCompany: guide.guideBusiness,
                              title: trip.tripName,
                              priceTotal: trip.tripPricePerPerson - trip.tripDiscountPerPerson,
                              priceOriginal: trip.tripPricePerPerson,
                              timeFrame: trip.tripTimeFrame,
                              location: trip.tripLocation,
                              description: trip.tripDescription,
                              mainImage: tripGallery && tripGallery.images.length > 0 ? tripGallery.images[0] : null,
                              status: trip.tripStatus
                            }
                            return (
                              (tab === 'new' && tripData.status === 'ACTIVE' ? 
                              <>
                                <TripCard
                                  key={tripData.id}
                                  status={"Active"}
                                  align={"left"}
                                  tripData={tripData}
                                  page="trips"
                                  selectAction={onSelectAction}
                                  setReload={setReload}
                                  reload={reload}
                                ></TripCard>
                              </>:
                              tab === 'archived' && tripData.status === 'ARCHIVE' && <>
                              <TripCard
                                key={tripData.id}
                                status={"Archived"}
                                align={"left"}
                                tripData={tripData}
                                page="trips"
                                selectAction={onSelectAction}
                                setReload={setReload}
                                reload={reload}
                              ></TripCard>
                            </>
                            )
                            );
                          })}
                        </>
                        :
                        <Container className="shadow case-0 mt-5" fluid>
                          <Row noGutters className=" ">
                            <Col>
                              <p className="case-0-text pt-5">
                                You haven’t added any trips yet.{" "}
                              </p>
                              <Button variant="guidex mt-3 mb-5" onClick={() => {
                                setPage("newTrip")
                              }}>Add a trip</Button>
                            </Col>
                          </Row>
                        </Container>
                    }
                  </div>
              )
          }
        })()
      }
    </>
  )

}