import React, { useEffect, useState } from "react";
import { app } from "../FirebaseConfig";

export const User = React.createContext();

export const UserContext = ({ children }) => {
  const [user, setuser] = useState(null);
  const [auth, setauth] = useState(null);
  const [menuMobile, setMenuMobile] = useState(false);
  useEffect(() => {
    app.auth().onAuthStateChanged(function (u) {
      if (!u) {
        window.localStorage.removeItem("userRolesData");
      }
      setauth(u);
    });
  }, []);

  useEffect(() => {
    if (user) {
      window.localStorage.setItem("userRolesData", JSON.stringify(user));
    }
  }, [user]);

  return (
    <User.Provider
      value={{
        menuMobile,
        setMenuMobile,
        user,
        setuser,
        auth,
        setauth,
      }}
    >
      {children}
    </User.Provider>
  );
};
