import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { request } from "../../../../utils/HttpHelper";
import Separator from "../../../../utils/Separator";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { showToast } from "../../../../utils/Misc";

function AdvertisingModal(props) {

  const { onClose } = props;
  const [error, setError] = useState(null);

  const requestNew = (body) => {
    return new Promise( async (resolve, reject) => {
      try {
        await request({
          method: "PUT",
          url: "guidex-api",
          trailingURL: "/adv-apply",
          body,
          requiresAuth: true
        })
        resolve();
      } catch (error) {
        reject(error);
      }
    })
  }

  return (
    <Container fluid>
      <Formik
        initialValues={{ newArea: "", newSpecies: "" }}
        validate={values => {
          const errors = {};
          if (!values.newArea && !values.newSpecies) {
            errors.message = "At least one of these fields is required";
          }
          setError(errors.message);
          return errors;
        }}
        onSubmit={ async (values, { setSubmitting }) => {
          try {
            console.dir(values)
            setSubmitting(true);
            if(values.newArea.trim().length > 0){
              let payload = {
                "advType": "REGIONS",
                "advName": values.newArea
              };
              await requestNew(payload);
            }
            if(values.newSpecies.trim().length > 0){
              let payload = {
                "advType": "SPECIES",
                "advName": values.newSpecies
              };
              await requestNew(payload);
            }
            setSubmitting(false);
            showToast(
              <>
                <AiFillCheckCircle /> Request sent succesfully!
              </>,
              "success"
            );
            onClose();
          } catch (error) {
            console.error(error);
            showToast(
              <>
                <AiFillWarning /> There was a problem trying to process your request.
              </>,
              "error"
            );
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mx-0 mb-2 align-items-center">
              <h2 className="primary-color font-size-30 mr-1 font-weight-bolder ">
                Add a New Area &amp; Species
              </h2>
              <Separator size="51px" color="#EC7228" />
              <p className="card-text font-size-15 mt-3">
                This will help us know what areas our guides want to advertise to
                first. New Location will add within the week.
              </p>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label>Enter Area</Form.Label>
                <Form.Control
                  placeholder="Highland Rach"
                  className="background-guidex"
                  name="newArea"
                  value={values.newArea}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.newArea && values.newArea.trim().length > 0}
                ></Form.Control>
                <Form.Label className="mt-4">Enter Species</Form.Label>
                <Form.Control
                  placeholder="Moose"
                  className="background-guidex"
                  name="newSpecies"
                  value={values.newSpecies}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.newSpecies && values.newSpecies.trim().length > 0}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  className={`${
                    error ? "d-block" : "d-none"
                  } invalid-feedback`}
                >
                  {error}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end mt-5 mb-5">
                <Button disabled={isSubmitting} variant="guidex-light" className="mr-4" onClick={onClose}>
                  Cancel
                </Button>
                <Button disabled={isSubmitting} variant="guidex" type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
export default AdvertisingModal;
