/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { RiMailLine, RiPhoneLine } from "react-icons/ri";
import { TbWorld } from "react-icons/tb"
import ImageGallery from "react-image-gallery";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import Favorite from "../common/Favorite";
import { User } from "../utils/UserProvider";
import "./Trips.css";
import CurrencyFormat from "react-currency-format";

const GuideInfo = (props) => {
  const { trip, tags, guide, rating, gallery, page, target } = props;
  const tripId = useParams().id

  const { user, setuser, auth } = useContext(User);
  const history = useHistory();

  const [images, setImages] = useState([]);
  const [timeFrame, setTimeframe] = useState('');
  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 991 });

  useEffect(() => {
    if (gallery) {
      let imgs = [...gallery.images];
      let newImgs = imgs.map((img) => {
        let a = {
          original: img.imageUrl,
          thumbnail: img.imageUrl,
          bulletClass: "oval",
          originalClass: "img-clas",
        };
        return a;
      });
      setImages([...newImgs]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(page)
    if(page !== "guideProfile"){
      setTimeframe(getCleanDates(trip.tripTimeFrame));
    }
  }, []);

  const getCleanDates = (s) => {
    try {
      const dateString = s.toString();
      const startDate = dateString.substring(0, 24);
      const endDate = dateString.substring(27);

      const dt1 = new Date(startDate);
      const dt2 = new Date(endDate);

      if (isNaN(dt2) || isNaN(dt2)) {//to check if the dates are valid dates
        return s;
      }

      return `${dt1.getMonth() + 1}/${dt1.getDate()}/${dt1.getFullYear()} - ${dt2.getMonth() + 1}/${dt2.getDate()}/${dt2.getFullYear()}`;

    } catch (e) {
      //if the date cannot be converted just return the previous string
      console.error(e);
      return s;
    }
  }
  

  return (
    <>
      <Container className="px-3 max-widht-card mb-5" fluid>
        <Row className="pb-3 pt-3">
          <Col sm={12} className="mb-sm-0 mb-md-3">
            {page === "guideProfile" ? (
              <Row noGutters className="mt-2">
                <Col xs="auto">
                  <Image src={guide.profilePictureURL} className="guide-logo" />
                </Col>
                <Col xs="auto" className="pl-3">
                  <div className="d-inline-flex mb-0">
                    <StarRatings
                      rating={rating.reviewsAverage}
                      starDimension="17px"
                      starSpacing="3px"
                      starRatedColor="#EC7228"
                    />
                    <span className="starRatingsText mt-auto ml-1 ">
                      ({rating.reviewsAmount})
                    </span>
                  </div>
                  <p className="trips-pdp-title ">{guide.businessName}</p>
                </Col>
              </Row>
            ) : (
              <div>
                {page === "aboutGuidex" ? (
                  <></>
                ) : (
                  <div className="d-inline-flex">
                    <StarRatings
                      rating={rating?.reviewsAverage}
                      starDimension="17px"
                      starSpacing="3px"
                      starRatedColor="#EC7228"
                    />
                    <span className="starRatingsText mt-auto ml-1 ">
                      ({rating?.reviewsAmount})
                    </span>
                  </div>
                )}
                <p className="guideName mb-0 mt-2">{guide?.guideBusiness}</p>
                <p className="pdpTitle d-flex">
                  {trip?.tripName}{" "}
                  <Favorite entityType="HUNT_TRIP" entityId={trip.id} />
                </p>
              </div>
            )}
          </Col>
          <Col md={page === "pdp" || page === "guideProfile" ? 12 : 6} lg={8}>
            {trip?.tripIsFeatured && (
              <div className="featured-icon">
                <Row noGutters>
                  <p className="mb-0">
                    {" "}
                    <AiFillStar className="" /> Featured Trip
                  </p>
                </Row>
              </div>
            )}

            {!!images.length && (
              <>
                <div className="gallerySlide d-block d-sm-none">
                  <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showNav={true}
                    lazyLoad={true}
                    items={images}
                    showBullets={true}
                    showThumbnails={false}
                  />
                </div>
                <div className="gallerySlide d-none d-sm-block">
                  <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showNav={true}
                    lazyLoad={true}
                    items={images}
                    showThumbnails={true}
                    thumbnailPosition={
                      page === "pdp" || page === "guideProfile"
                        ? isTablet
                          ? "right"
                          : "bottom"
                        : "bottom"
                    }
                  />
                </div>
              </>
            )}
          </Col>
          <Col md={page === "pdp" || page === "guideProfile" ? 12 : 6} lg={4}>
            {page === "guideProfile" ? (
              <div>
                {user && (
                  <div className="mb-5 pjson">
                    <h3 className="contact-info-title">Contact Info</h3>
                    <p>
                      <RiPhoneLine size="20" />{" "}
                      <span className="ml-2">{guide.phoneNumber}</span>
                    </p>
                    <p>
                      <RiMailLine size="20" />{" "}
                      <span className="ml-2">{guide.email}</span>
                    </p>
                    {!!guide.website && (
                      <p>
                        <TbWorld size="20" />{" "}
                        <span className="ml-2"> {guide.website}</span>
                      </p>
                    )}
                  </div>
                )}
                {!user && (
                  <div className="mb-5">
                    <h3 className="contact-info-title">Contact Info</h3>
                    <p className="contact-info-body">
                      Please Login to see guide contact information
                    </p>
                    <p
                      className="contact-info-login"
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Login
                    </p>
                  </div>
                )}
                <h1 className="font-size-30  font-family-Monserrat font-weight-bold secondary-color">
                  About us
                </h1>
                <p className="font-size-13 font-family-Roboto pjson secondary-color description-max-scroll">
                  {guide.aboutUs}
                </p>

                <Link
                  to={`/contact-guide?guide_type=${target === "hunting" ? "guide_hunter" : "guide_fisherman"
                    }&guide_id=${guide.guideHunterId
                      ? guide.guideHunterId
                      : guide.guideFishermanId
                    }&trip_id=${tripId}`}
                  className="justify-content-end d-flex"
                >
                  <Button
                    variant="guidex"
                    className="btn-block d-lg-block d-none"
                  >
                    Contact Guide
                  </Button>
                  <Button
                    variant="guidex"
                    className={`${isTablet ? "w-50" : "w-100"
                      }  d-block d-lg-none `}
                  >
                    Contact Guide
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                <h1 className="font-size-30  font-family-Monserrat font-weight-bold secondary-color">
                  About this Hunt
                </h1>
                <p className="font-size-15 mb-2 mt-3 font-family-Roboto secondary-color">
                  Dates:{" "}
                  <span className="font-size-15 font-family-Roboto font-weight-bold secondary-color">
                    {timeFrame}
                  </span>
                </p>
                <p className="font-size-15 mb-2 font-family-Roboto secondary-color">
                  Location:{" "}
                  <span className="font-size-15 font-family-Roboto font-weight-bold secondary-color">
                    {trip?.tripLocation}
                  </span>
                </p>
                <p className="font-size-15 mb-2 font-family-Roboto secondary-color">
                  Species:{" "}
                  <span className="font-size-15 font-family-Roboto font-weight-bold secondary-color">
                    {" "}
                    {trip?.tripSpecies}
                  </span>
                </p>
                {/* <p className="font-size-15 font-family-Roboto  secondary-color">
                  Method:{" "}
                  <span className="font-size-15 font-family-Roboto font-weight-bold secondary-color">
                    {" "}
                    {!!tags ? getTagMethod(tags) : ""}
                  </span>
                </p> */}
                <p
                  // dangerouslySetInnerHTML={{ __html: data.description }}
                  className="font-size-13 font-family-Roboto pjson secondary-color description-max-scroll"
                >
                  {trip?.tripDescription}
                </p>
                {page === "aboutGuidex" ? (
                  <></>
                ) : (
                  <>
                    <div className="d-md-flex d-none flex-row align-items-center mb-3">
                      <p className="pdp-price-original mb-0 align-text-bottom">
                        <CurrencyFormat
                          value={trip?.tripDiscountPerPerson !== 0
                            ? trip?.tripDiscountPerPerson
                            : trip?.tripPricePerPerson}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => value}
                        />
                      </p>
                      {trip?.tripDiscountPerPerson !== 0 && (
                        <p className="pdp-price-discount ml-3 align-text-bottom font-weight-light mb-0">
                          <CurrencyFormat
                            value={trip?.tripPricePerPerson}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value) => value}
                          />
                        </p>
                      )}
                    </div>

                    <div className="d-flex d-md-none flex-row align-items-center mb-3">
                      <p className="pdp-price-original mb-0 align-text-bottom">
                        <CurrencyFormat
                          value={trip?.tripDiscountPerPerson !== 0
                            ? trip?.tripDiscountPerPerson
                            : trip?.tripPricePerPerson}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => value}
                        />
                      </p>
                      {trip?.tripDiscountPerPerson !== 0 && (
                        <p className="pdp-price-discount mb-1 ml-3 align-text-bottom font-weight-light">
                          <CurrencyFormat
                            value={trip?.tripPricePerPerson}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            renderText={(value) => value}
                          />
                        </p>
                      )}
                    </div>
                  </>
                )}
                <Link
                  to={`/contact-guide?guide_type=guide_hunter&guide_id=${guide.guideId}&trip_id=${tripId}`}
                  className="justify-content-end d-flex"
                >
                  <Button
                    variant="guidex"
                    className="btn-block d-lg-block d-none"
                  >
                    Contact Guide
                  </Button>
                  <Button
                    variant="guidex"
                    className={`${isTablet ? "w-50" : "w-100"
                      }  d-block d-lg-none `}
                  >
                    Contact Guide
                  </Button>
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default GuideInfo;
