import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./InfoCaptain.css";
const InfoCaptain = (props) => {
  const { title, content } = props;
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="primary-color pt-3 font-weight-bold font-size-30">
              {title}
            </h1>
          </Col>
        </Row>
        <p className="max-container-overflow">{content}</p>
      </Container>
    </>
  );
};
export default InfoCaptain;
