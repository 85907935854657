import React, { useState, useEffect } from "react";
import mapboxgl from "mapbox-gl";
  import {
  Col,
  Form,
  Row,
  Button,
  Container,
  Table
} from "react-bootstrap";
import DataListInput from "react-datalist-input";
import { AiFillWarning } from "react-icons/ai";
import Loader from 'react-loader-spinner';
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";
import * as $ from "jquery";
import "./AdvertisingPerArea.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWlrZWZyYW5jbzM5MzkiLCJhIjoiY2tpM3U2emQ4M2VjZTJ5bjN6NjEybnNmcyJ9.Tsm309LXDKV3Mw2MtgJrRw";

const searchPath = "/geocoding/v5/mapbox.places/{search_text}.json";

const AdvertisingPerArea = (props) => {

  const { onApply } = props

  const [cities, setCities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeCities, setActiveCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [enableApply, setEnableApply] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getActiveCities = () => {
    return new Promise( async (resolve, reject) => {
      try {
        let avtiveASResult = await request({
          url: 'guidex-api',
          trailingURL: '/shares-fisherman',
          requiresAuth: true
        });
        resolve(avtiveASResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const initAdvertisignPerArea = async() => {
    try {
      setIsLoading(true);
      let activeC = await getActiveCities();
      console.log(activeC);
      setActiveCities(activeC.shares);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch the advertisement data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  }

  const searchCity = async () => {
    if(searchTerm.trim().length > 0){
      try {
        const searchPathPlace = searchPath.replace("{search_text}", searchTerm) + `?access_token=${mapboxgl.accessToken}&autocomplete=false&limit=10&country=US&types=place`;
        const result = await request({
          url: 'mapbox-api',
          trailingURL: searchPathPlace
        });
        console.log(result.data);
        const { features } = result.data;
        if(features && features.length > 0){
          let citiesAux = features.filter( c=> {
            return c.place_type.includes("place");
          }).map( c => {
            let item = {
              label: c.place_name,
              key: c.id
            };
            return item;
          });
          setCities(citiesAux);
          $(".searchbar").click();
        }
      } catch (error) {
        console.error(error);
      }
      
    }
  }

  const applyForAdvertisement = () => {
    onApply(selectedCity)
  }

  useEffect(() => {
    initAdvertisignPerArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEnableApply(selectedCity != null);
  }, [selectedCity]);

  const match = (currentInput, item) => {
    //return item.label.toLowerCase().includes(currentInput.toLowerCase());
    return true;
  }

  return (
    <Container fluid className="advertising-area-container ">
      <Row>
        <Col className="pl-4">
          <p className="font-weight-bold font-size-22">Advertsing Per Area</p>
          <p className="font-weight-bold mt-4">Add a new location</p>
        </Col>
      </Row>
      <Row>
        <Col className="pl-4 pb-4">
          <Form>
            <Form.Row>
              <Col md="8">
                <DataListInput
                  placeholder="Enter location..."
                  inputClassName="background-guidex form-control searchbar"
                  dropDownLength={10}
                  items={cities}
                  onSelect={(selectedItem) => {
                    setSelectedCity(selectedItem);
                  }}
                  match={match}
                  onInput={(e) => {
                    setSearchTerm(e);
                  }}
                />
                {/* <Form.Control 
                  type="text"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                /> */}
              </Col>
              <Col md="2" className="pl-3">
                <Button variant="secondary-guidex" onClick={searchCity} disabled={!searchTerm.trim().length > 0}> Search</Button>
              </Col>
              <Col md="2" className="pl-3">
                <Button variant="guidex" onClick={applyForAdvertisement} disabled={!enableApply}> Apply</Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <p className="mt-4 mb-5 ml-2   font-weight-bold">Active City/State</p>
          {
            !isLoading ?
              <Table responsive borderless className="active-areas">
                <thead>
                  <tr className="d-flex tr-head">
                    <th className="col-4">City/State</th>
                    <th className="col-4">Shares</th>
                    <th className="col-4"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    activeCities.map( ac => {
                      return (
                        <tr className="d-flex align-items-center" key={"activeC_"+ac.mapboxCityID}>
                          <td className="col-4 align-middle">{ac.cityName}</td>
                          <td className="col-4 align-middle">
                            {ac.aquiredShares}%
                          </td>
                          <td className="col-4">
                            <Button variant="guidex-light" onClick={() => {
                              onApply({
                                label: ac.cityName,
                                key: ac.mapboxCityID
                              });
                            }}>Edit</Button>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </Table>
            :
            <>
              <center>
                <Loader
                  type="ThreeDots"
                  color="#ec7228"
                  height={25}
                  width={50}
                />
              </center>
            </>
          }
          
        </Col>
      </Row>
    </Container>
  );
};
export default AdvertisingPerArea;
