import React, { useState } from "react";
import { Col, Nav, NavDropdown, Row, Tab } from "react-bootstrap";
import InfoCaptain from "./InfoCaptain";
import "./MenuCaptain.css";

export default function MenuCaptain(props) {
  const { trip, guide } = props;
  const [activeTab, setactiveTab] = useState("about_the_guide");
  const getLabel = (selectedRole) => {
    switch (selectedRole) {
      case "about_the_guide":
        return "About the Guide";
      case "hunting_style":
        return "Hunting Style";
      case "price_breakdown":
        return "Price Breakdown";
      case "transportation":
        return "Transportation";
      case "other_info":
        return "Other Information";
      case "terms":
        return "Terms";
      case "about_the_captain":
        return "About the Captain";
      case "types_of_fishing":
        return "Types of Fishing";
      case "fising_techniquies":
        return "Fishing Techniques";
      case "amenities":
        return "Amenities";
      case "trips_include":
        return "Trips Include";

      default:
        return "";
    }
  };
  return (
    <>
      {!!trip ? (
        <Tab.Container defaultActiveKey="about_the_guide">
          <Row>
            <Col sm={3}>
              <Nav
                onSelect={(selectedKey) => setactiveTab(selectedKey)}
                className="flex-column list-group-item"
                expand="lg"
                id="tripCapitan"
              >
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="about_the_guide">
                    About the Guide
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="hunting_style">Hunting Style</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="price_breakdown">
                    Price Breakdown
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="transportation">Transportation</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="other_info">Other Information</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="terms">Terms</Nav.Link>
                </Nav.Item>

                <NavDropdown
                  id="captainDropdown"
                  className="d-block d-md-none"
                  title={getLabel(activeTab)}
                  active
                >
                  <NavDropdown.Item eventKey="about_the_guide" className="background-color-red">
                    About the Guide
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="hunting_style">
                    Hunting Style
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="price_breakdown">
                    Price Breakdown
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="transportation">
                    Transportation
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="other_info">
                    Other Information
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="terms">Terms</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="about_the_guide">
                  <InfoCaptain
                    title="About the Guide"
                    content={trip.tripAboutTheGuide}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="hunting_style">
                  <InfoCaptain
                    title="Hunting Style"
                    content={trip.tripHuntingStyle}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="price_breakdown">
                  <InfoCaptain
                    title="Price Breakdown"
                    content={trip.tripPriceBreakdown}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="transportation">
                  <InfoCaptain
                    title="Transportation"
                    content={trip.tripTransport}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="other_info">
                  <InfoCaptain
                    title="Other Information"
                    content={trip.tripOtherInfo}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="terms">
                  <InfoCaptain title="Terms" content={trip.tripTerms} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      ) : (
        <Tab.Container defaultActiveKey="about_the_captain">
          <Row>
            <Col sm={3}>
              <Nav
                onSelect={(selectedKey) => setactiveTab(selectedKey)}
                className="flex-column list-group-item"
                id="tripCapitan"
              >
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="about_the_captain">
                    About the Captain
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="types_of_fishing">
                    Types of Fishing
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="fising_techniquies">
                    Fishing Techniques
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="amenities">Amenities</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="trips_include">Trips Include</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-md-block">
                  <Nav.Link eventKey="terms">Terms</Nav.Link>
                </Nav.Item>
                <NavDropdown
                  id="captainDropdown"
                  className="d-block d-md-none"
                  title={getLabel(activeTab)}
                  active
                >
                  <NavDropdown.Item eventKey="about_the_captain">
                    About the Captain
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="types_of_fishing">
                    Types of Fishing
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="fising_techniquies">
                    Fishing Techniques
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="amenities">
                    Amenities
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="trips_include">
                    Trips Include
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="terms">Terms</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="about_the_captain">
                  <InfoCaptain
                    title="About the Captain"
                    content={guide.aboutTheCaptain}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="types_of_fishing">
                  <InfoCaptain
                    title="Types of Fishing"
                    content={guide.typesOfFishing}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="fising_techniquies">
                  <InfoCaptain
                    title="Fishing Techniques"
                    content={guide.fishingTechniques}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="amenities">
                  <InfoCaptain title="Amenities" content={guide.amenities} />
                </Tab.Pane>
                <Tab.Pane eventKey="trips_include">
                  <InfoCaptain
                    title="Trips Include"
                    content={guide.tripsInclude}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="terms">
                  <InfoCaptain title="Terms" content={guide.terms} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </>
  );
}
