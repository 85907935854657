import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import Loader from 'react-loader-spinner'
import { showToast } from "../../../utils/Misc";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { request } from "../../../utils/HttpHelper";
import "./ApprovedSpecies.css";

const ReviewsStaff = props => {
  const [pendingApprovalRegions, setPendingApporvalRegions] = useState([]);
  const [pendingApprovalSpecies, setPendingApporvalSpecies] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);

  const getRegionsCatalogue = () => {
    return new Promise( async (resolve, reject) => {
      try {
        let regionsResult = await request({
          url: 'guidex-api',
          trailingURL: '/adv-apply?advType=REGIONS&is_staff=true',
          requiresAuth: true
        });
        resolve(regionsResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const getSpeciesCatalogue = () => {
    return new Promise( async (resolve, reject) => {
      try {
        let speciesResult = await request({
          url: 'guidex-api',
          trailingURL: '/adv-apply?advType=SPECIES&is_staff=true',
          requiresAuth: true
        });
        resolve(speciesResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const initApprovedSpecies = async() => {
    try {
      setIsLoading(true);
      let regionsList = await getRegionsCatalogue();
      let speciesList = await getSpeciesCatalogue();
      let pendingRegions = regionsList.data.filter( r => !r.advIsApproved);
      let pendingSpecies = speciesList.data.filter( r => !r.advIsApproved);
      setPendingApporvalRegions([...pendingRegions]);
      setPendingApporvalSpecies([...pendingSpecies]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to get the catalogue.
          data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    initApprovedSpecies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reject = async (advType, advId) => {
    try {
      setIsSubmitting(true);
      await request({
        method: "DELETE",
        url: "guidex-api",
        trailingURL: `/adv-apply?advType=${advType}&advID=${advId}`,
        requiresAuth: true
      });
      showToast(
        <>
          <AiFillCheckCircle /> The selected term was rejected!
        </>,
        "success"
      );
      setIsSubmitting(false);
      initApprovedSpecies();
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to reject this term.
        </>,
        "warning"
      );
    }
  }

  const approve = async (advType, advId) => {
    try {
      setIsSubmitting(true);
      let body = {
        "advID": advId,    
        "advType": advType,
        "advIsApproved": true
      };
      await request({
        method: "POST",
        url: "guidex-api",
        trailingURL: `/adv-apply`,
        body,
        requiresAuth: true
      });
      showToast(
        <>
          <AiFillCheckCircle /> The selected term was approved!
        </>,
        "success"
      );
      setIsSubmitting(false);
      initApprovedSpecies();
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to approve this term.
        </>,
        "warning"
      );
    }
  }

  return (
    <>
      <>
        <h2 className="mb-5 approve-guides-title portal-section-title mt-5">
          Approve Regions &amp; Species
        </h2>
        <Container fluid className="approve-guides-container mb-5">
          {
            !isLoading ? 
            <>
              <Row>
                <Col>
                  <Table responsive borderless className="table-species">
                    <thead>
                      <tr className="no-border">
                        <th> Region</th>
                        <th className="table-species-thead">Date of Submision</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pendingApprovalRegions.map( region => {
                          return (
                            <tr className="pb-5" key={"REGION_"+region.advID}>
                              <td>{region.advName}</td>
                              <td>{region.advCreatedOn}</td>
                              <td>Pending</td>
                              <td align="right">
                                <Button variant="species-light" className="mr-3" onClick={() => {
                                  reject("REGIONS", region.advID);
                                }} disabled={isSubmitting}>
                                  Reject
                                </Button>
                                <Button variant="species" onClick={() => {
                                  approve("REGIONS", region.advID);
                                }} disabled={isSubmitting}>Approve</Button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table responsive borderless className="table-species">
                    <thead>
                      <tr className="no-border">
                        <th> Species</th>
                        <th className="table-species-thead">Date of Submision</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pendingApprovalSpecies.map( species => {
                          return (
                            <tr className="pb-5" key={"SPECIES_"+species.advID}>
                              <td>{species.advName}</td>
                              <td>{species.advCreatedOn}</td>
                              <td>Pending</td>
                              <td align="right">
                                <Button variant="species-light" className="mr-3" onClick={() => {
                                  reject("SPECIES", species.advID);
                                }} disabled={isSubmitting}>
                                  Reject
                                </Button>
                                <Button variant="species" onClick={() => {
                                  approve("SPECIES", species.advID);
                                }} disabled={isSubmitting}>Approve</Button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
            :
            <center>
              <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
            </center>
          }
        </Container>
      </>
    </>
  );
};
export default ReviewsStaff;
