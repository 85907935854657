import React, { useState, useContext } from "react";
import { Field, Formik } from "formik";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  Row,
  Nav,
  Tab
} from "react-bootstrap";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { BsX } from "react-icons/bs";
import { showToast } from "../../../../utils/Misc";
import editIcon from "../../../../assets/icons/EditOutlineWhite.svg";
import newIcon from "../../../../assets/others/new_icon.png";
import newPhoto from "../../../../assets/others/new_photo.png";
import { User } from "../../../../utils/UserProvider";
import { request } from "../../../../utils/HttpHelper";
import MapGeolocation from "../../Common/Map/MapGeolocation";
import DataListInput from "react-datalist-input";
import * as $ from "jquery";
import { Staff } from "../../../../utils/staffProvider";
import { ReactComponent as PenIcon } from "../../../../assets/icons/pen-edit.svg";


export default function GeneralDetails(props) {
  const { guideData, tagTypesCat, tagTypes, species } = props;

  const { user, setuser } = useContext(User);
  const { loginAs } = useContext(Staff);
  const [editMode, setEditMode] = useState(false);
  // eslint-disable-next-line
  const [error, seterror] = useState("");
  const [triggerImageProfile, setTriggerImageProfile] = useState(false);
  const [triggerImageLogo, setTriggerImageLogo] = useState(false);
  const [, updateState] = useState();
  const [selectedSpecies, setSelectedSpecies] = useState(null);

  const match = (currentInput, item) => {
    return item.label.toLowerCase().includes(currentInput.toLowerCase());
  }

  const processArray = str => {
    let mappedArr = [];
    if (str) {
      let strL = str.split(",");
      // eslint-disable-next-line
      let itemsMapped = strL.map(s => {
        if (s) {
          let mappedI = s.toString().trim();
          return mappedI;
        }
      });
      mappedArr = itemsMapped;
    }
    return mappedArr;
  };

  const changeEditMode = () => {
    setEditMode(!editMode);
  };

  const [specieText, setSpecieText] = useState("");

  const updateGuideData = payload => {
    return new Promise(async (resolve, reject) => {
      try {
        await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: `/guide-fisher${loginAs > 0 ? `?id=${loginAs}` : ""}`,
          body: payload,
          requiresAuth: true
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadImage = formData => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: `/image-upload-type?entityTypeID=2`,
          body: formData,
          config: {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          },
          requiresAuth: true
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const setNewProfilePicture = () => {
    $("#profile-picture").trigger("click");
  };

  const handleProfilePictureChange = e => {
    var input = document.getElementById("profile-picture");
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#profile-picture-slot").attr("src", e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      setTriggerImageProfile(true);
    }
  };

  const setNewLogoPicture = () => {
    $("#logo-picture").trigger("click");
  };

  const handleLogoPictureChange = e => {
    var input = document.getElementById("logo-picture");
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#logo-picture-slot").attr("src", e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      setTriggerImageLogo(true);
    }
  };

  const submitGeneralDetails = async (values, setSubmitting) => {
    setSubmitting(true);

    if (triggerImageProfile || triggerImageLogo) {
      const formData = new FormData();

      if (triggerImageProfile) {
        let input = document.getElementById("profile-picture");
        if (input.files && input.files[0]) {
          formData.append("PROFILE_PICTURE", input.files[0]);
        }
      }

      if (triggerImageLogo) {
        let input = document.getElementById("logo-picture");
        if (input.files && input.files[0]) {
          formData.append("LOGO_PICTURE", input.files[0]);
        }
      }

      try {
        let resultUpload = await uploadImage(formData);

        if (triggerImageProfile) {
          let selectedImage = resultUpload.uploadedImages.find(
            i => i.imageName === "PROFILE_PICTURE"
          );
          values.profilePictureURL = selectedImage.imageUrl;
        }
        if (triggerImageLogo) {
          let selectedImage = resultUpload.uploadedImages.find(
            i => i.imageName === "LOGO_PICTURE"
          );
          values.logoURL = selectedImage.imageUrl;
        }
      } catch (error) {
        throw error;
      }
    }

    const uploadValues = {
      profilePictureURL: values.profilePictureURL,
      profileLogoURL: values.logoURL,
      guideName: values.guideName,
      guideContactName: values.guideContactName,
      guidePhone: values.phone,
      guideWebsite: values.webSite,
      guideAboutUs: values.about,
      guideSpecies: values.species.map(e => e.key),
      guideOperationalMonths: values.monthsOperation.join(","),
      guidePartySize: parseInt(values.partySize),
      guidePriceMin: values.priceMin,
      guidePriceMax: values.priceMax,
      guideTagTypes: values.tagTypes.map(tt => Number(tt)),
      aboutTheCaptain: values.aboutTheCaptain,
      typesOfFishing: values.typesOfFishing,
      fishingTechniques: values.fishingTechniques,
      amenities: values.amenities,
      tripsInclude: values.tripsInclude,
      terms: values.terms,
      lat: values.lat,
      lng: values.lng
    };

    updateGuideData(uploadValues)
      .then(() => {
        /** Updating name in local storage */
        let userD = user;
        userD.fullName = values.guideContactName;
        let selRoleI = userD.otherRoles.findIndex(
          r => r.roleName === "FISHERMAN"
        );
        if (selRoleI >= 0) {
          let selRole = userD.otherRoles[selRoleI];
          selRole.fullName = values.guideContactName;
          userD.otherRoles[selRoleI] = selRole;
        }
        setuser(userD);
        //window.localStorage.setItem("userRolesData", JSON.stringify(userD));
        showToast(
          <>
            <AiFillCheckCircle /> Data saved correctly!
          </>,
          "success"
        );
        setTriggerImageProfile(false);
        setTriggerImageLogo(false);
        setSubmitting(false);
        setEditMode(false);
      })
      .catch(err => {
        setSubmitting(false);
        showToast(
          <>
            <AiFillWarning /> There was a problem trying to update.
          </>,
          "warning"
        );
      });
  };

  const initValues = {
    profilePictureURL: guideData.profilePictureURL,
    logoURL: guideData.logoURL,
    guideName: guideData.businessName,
    guideContactName: guideData.fullName,
    phone: guideData.phoneNumber,
    guidexNumber: guideData.guideFisherUID,
    webSite: guideData.website,
    about: guideData.aboutUs || "",
    monthsOperation: processArray(guideData.monthsOperation),
    species: processArray(guideData.species).map(specie => ({label: specie, key: species[species.findIndex(e => e.label === specie)].key})),
    partySize: parseInt(guideData.partySize),
    priceMin: guideData.priceMin,
    priceMax: guideData.priceMax,
    tagTypes: tagTypes.map(tt => tt.tagTypeID.toString()),
    aboutTheCaptain: guideData.aboutTheCaptain,
    typesOfFishing: guideData.typesOfFishing,
    fishingTechniques: guideData.fishingTechniques,
    amenities: guideData.amenities,
    tripsInclude: guideData.tripsInclude,
    terms: guideData.terms,
    lat: guideData.lat,
    lng: guideData.lng
  };
  
  return (
    <>
      <Container fluid className="general-details-container">
        <Formik
          initialValues={initValues}
          validate={(values) => {
            const errors = {};
            if (!values.guideName) {
              errors.guideName = "Required";
            }
            if (!values.guideContactName) {
              errors.guideContactName = "Required";
            }
            if (!values.phone) {
              errors.phone = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            submitGeneralDetails(values, setSubmitting);
          }}
          onReset={(values) => {
            values = { ...initValues };
            setEditMode(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit} onReset={handleReset}>
              <Row>
                <Col className="d-flex justify-content-between pt-5 ">
                  <h1 className="profile-title">Profile</h1>
                  <div className="general-details-div-button">
                    {!editMode ? (
                      <Button
                        variant="general-details"
                        onClick={changeEditMode}
                      >
                        <Row noGutters>
                          <Col xs="auto">Edit General Info</Col>

                          <Col xs="auto" className="ml-auto">
                            <Image src={editIcon} />
                          </Col>
                        </Row>
                      </Button>
                    ) : (
                      <div>
                        <Button
                          variant="secondary-cancel mr-5"
                          type="reset"
                          disabled={isSubmitting}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="save"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save Changes
                        </Button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4 className="profile-subtitle">General Details</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="general-details-title mt-5 mb-0">
                    Guide Pofile Picture
                  </p>
                </Col>
              </Row>

              <Row className="mt-1 mb-5">
                <Col md="4">
                  {!editMode ? (
                    <Image
                      src={values.profilePictureURL || newPhoto}
                      width="100%"
                    />
                  ) : (
                    <div>
                      <Image
                        id="profile-picture-slot"
                        src={values.profilePictureURL || newPhoto}
                        width="100%"
                      />
                      <Button
                        className="d-flex justify-content-center"
                        onClick={setNewProfilePicture}
                        variant="pen-icon-edit"
                      >
                        <PenIcon />
                      </Button>
                      <input
                        type="file"
                        name="profile-picture"
                        id="profile-picture"
                        className="invisible-trigger"
                        multiple={false}
                        onChange={handleProfilePictureChange}
                      />
                    </div>
                  )}
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <p className="general-details-title ">Guide Logo</p>
                          {!editMode ? (
                            <Image
                              src={values.logoURL || newIcon}
                              className="owner-circle"
                            />
                          ) : (
                            <div className="wrapper-icon">
                              <Image
                                id="logo-picture-slot"
                                src={values.logoURL || newIcon}
                                className="owner-circle"
                              />
                              <Button
                                variant="settings-edit-icon-pen"
                                className=" d-flex justify-content-center"
                                onClick={setNewLogoPicture}
                              >
                                <PenIcon height="30px" width="30px"/>
                              </Button>
                              <input
                                type="file"
                                name="logo-picture"
                                id="logo-picture"
                                className="invisible-trigger"
                                multiple={false}
                                onChange={handleLogoPictureChange}
                              />
                            </div>
                          )}
                          <Row>
                            <Col className="mt-5">
                              <p className="mb-2 general-details-title">
                                Charter's Name
                              </p>
                              {!editMode ? (
                                <p className="general-details-body">
                                  {values.guideName}
                                </p>
                              ) : (
                                <Form.Group controlId="FormGuideName">
                                  <FormControl
                                    value={values.guideName}
                                    name="guideName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.guideName}
                                    isValid={
                                      touched.guideName && !errors.guideName
                                    }
                                  />
                                  <Form.Control.Feedback
                                    className="mt-2"
                                    type="invalid"
                                  >
                                    {errors.guideName}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-4">
                              <p className="mb-2 general-details-title">
                                Phone
                              </p>
                              {!editMode ? (
                                <p className="general-details-body">
                                  {values.phone}
                                </p>
                              ) : (
                                <Form.Group controlId="FormGuidePhone">
                                  <FormControl
                                    value={values.phone}
                                    name="phone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.phone}
                                    isValid={touched.phone && !errors.phone}
                                  />
                                  <Form.Control.Feedback
                                    className="mt-2"
                                    type="invalid"
                                  >
                                    {errors.phone}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Row>
                            <Col className="mt-5">
                              <p className="mb-2 general-details-title">
                                Guidex Number
                              </p>
                              <p className="primary-color font-weight-bold font-size-15">
                                {values.guidexNumber}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-5">
                              <p className="mb-2 general-details-title">
                                Guide Name
                              </p>
                              {!editMode ? (
                                <p className="general-details-body">
                                  {values.guideContactName}
                                </p>
                              ) : (
                                <Form.Group controlId="FormGuideContactName">
                                  <FormControl
                                    value={values.guideContactName}
                                    name="guideContactName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.guideContactName}
                                    isValid={
                                      touched.guideContactName &&
                                      !errors.guideContactName
                                    }
                                  />
                                  <Form.Control.Feedback
                                    className="mt-2"
                                    type="invalid"
                                  >
                                    {errors.guideContactName}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-4">
                              <p className="mb-2 general-details-title">
                                Website
                              </p>
                              {!editMode ? (
                                <p className="general-details-body">
                                  {values.webSite}
                                </p>
                              ) : (
                                <Form.Group controlId="FormGuideWebsite">
                                  <FormControl
                                    value={values.webSite}
                                    name="webSite"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {editMode ? (
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label className="mt-4">
                            Drag the pin on the map to get a more accurate
                            location.
                          </Form.Label>
                          <MapGeolocation
                            storedGeolocation={{
                              lat: values.lat,
                              lng: values.lng,
                            }}
                            onChangeGeoloction={(coords) => {
                              const [lat, lng] = coords;
                              values.lat = lat;
                              values.lng = lng;
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="mt-4 ">
                  <div className="general-details-about">
                    <Form.Label className="d-flex justify-content-between align-items-center font-size-15">
                      About us {editMode && <span className="font-size-12">Max of 1500 characters</span>}
                    </Form.Label>
                    {!editMode ? (
                      <p className="general-details-body">{values.about}</p>
                    ) : (
                      <Form.Group controlId="FormGuideWebsite">
                        <FormControl
                          as="textarea"
                          rows={6}
                          value={values.about}
                          name="about"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={1500}
                        />
                      </Form.Group>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="profile-subtitle mt-5">Filters </h4>
                  {!editMode ? (
                    <>
                      {values.species.map((title) => (
                        <Button
                          variant="hunting"
                          className="mr-2 mt-4"
                          key={`species_${title.label}`}
                        >
                          {title.label}
                        </Button>
                      ))}
                      {
                        // eslint-disable-next-line
                        values.tagTypes.map((id) => {
                          let currentTagType = tagTypesCat.find((ttc) => {
                            return ttc.tagTypeID === Number(id);
                          });
                          if (currentTagType) {
                            return (
                              <Button
                                variant="hunting"
                                className="mr-2 mt-4"
                                key={`tagtype_${currentTagType.tagTypeID}`}
                              >
                                {currentTagType.tagTypeLabel}
                              </Button>
                            );
                          }
                        })
                      }
                    </>
                  ) : (
                    <div>
                      <p className="general-details-body ">
                        Add filters to get better customer contacts
                      </p>
                      <Row>
                        <Col md="4">
                          <p className="general-details-title ">
                            Species Fished for
                          </p>

                          <DataListInput
                            placeholder="Enter Species..."
                            inputClassName="background-guidex form-control"
                            dropDownLength={5}
                            onChange={handleChange}
                            items={species}
                            onSelect={(selectedItem) => {
                              setSelectedSpecies(selectedItem);
                              values.species.push(selectedItem);
                            }}
                            clearInputOnSelect={true}
                            match={match}
                          />

                          {values.species.map((s, i) => (
                            <Button
                              variant="hunting"
                              className="mr-2 mb-1"
                              key={`${s}_${i}`}
                            >
                              {s.label}{" "}
                              <BsX
                                className="clear-filter"
                                onClick={() => {
                                  values.species.splice(i, 1);
                                  updateState({});
                                }}
                              ></BsX>
                            </Button>
                          ))}
                          {values.species.length > 0 && (
                            <p
                              className="ml-3 hunting-clear"
                              onClick={() => {
                                values.species = [];
                                updateState({});
                              }}
                            >
                              Clear all
                            </p>
                          )}
                          <Row>
                            <Col className="my-3">
                              <p className="general-details-title">
                                {" "}
                                Party Size
                              </p>
                              <Row>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    {" "}
                                    1
                                    <Field
                                      type="checkbox"
                                      name="partySize"
                                      value="1"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    2
                                    <Field
                                      type="checkbox"
                                      name="partySize"
                                      value="2"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    {" "}
                                    3
                                    <Field
                                      type="checkbox"
                                      name="partySize"
                                      value="3"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    4
                                    <Field
                                      type="checkbox"
                                      name="partySize"
                                      value="4"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    5
                                    <Field
                                      type="checkbox"
                                      name="partySize"
                                      value="5"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    {" "}
                                    6+
                                    <Field
                                      type="checkbox"
                                      name="partySize"
                                      value="6"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="mb-2 general-details-title">
                                Price range minimum
                              </p>
                              <Form.Group controlId="priceMin">
                                <FormControl
                                  value={values.priceMin}
                                  name="priceMin"
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={!!errors.priceMin}
                                  isValid={touched.priceMin && !errors.priceMin}
                                />
                                <Form.Control.Feedback
                                  className="mt-2"
                                  type="invalid"
                                >
                                  {errors.priceMin}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col>
                              <p className="mb-2 general-details-title">
                                Price range maximum
                              </p>
                              <Form.Group controlId="priceMax">
                                <FormControl
                                  value={values.priceMax}
                                  name="priceMax"
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={!!errors.priceMax}
                                  isValid={touched.priceMax && !errors.priceMax}
                                />
                                <Form.Control.Feedback
                                  className="mt-2"
                                  type="invalid"
                                >
                                  {errors.priceMax}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col>
                              <Form.Group controlId="formBasicMonths">
                                <p className="d-flex general-details-title justify-content-between">
                                  Operational Months{" "}
                                  <span
                                    className="primary-color font-weight-bold d-flex justify-content-end clickable mb-0"
                                    onClick={() => {
                                      values.monthsOperation = [
                                        "JAN",
                                        "FEB",
                                        "MAR",
                                        "APR",
                                        "MAY",
                                        "JUN",
                                        "JUL",
                                        "AUG",
                                        "SEP",
                                        "OCT",
                                        "NOV",
                                        "DEC",
                                      ];
                                      updateState({});
                                    }}
                                  >
                                    + Select All
                                  </span>
                                </p>
                                <Row>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      January
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="JAN"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      February
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="FEB"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      March
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="MAR"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      April
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="APR"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      May
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="MAY"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      June
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="JUN"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      July
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="JUL"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      August
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="AUG"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      September
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="SEP"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      October
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="OCT"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      November
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="NOV"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      December
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="DEC"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="my-3">
                              <p className="general-details-title"> Tags</p>
                              <Row>
                                {tagTypesCat.map((tt) => {
                                  return (
                                    <Col key={"TT_" + tt.tagTypeID} md={3}>
                                      <label className="checkbox-container">
                                        {tt.tagTypeLabel}
                                        <Field
                                          type="checkbox"
                                          name="tagTypes"
                                          value={tt.tagTypeID.toString()}
                                          className="checkbox-guidex"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Tab.Container defaultActiveKey="about_the_captain">
                            <Row>
                              <Col sm={3}>
                                <Nav
                                  className="flex-column list-group-item"
                                  id="tripCapitan"
                                >
                                  <Nav.Item>
                                    <Nav.Link eventKey="about_the_captain">
                                      About the Captain
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="types_of_fishing">
                                      Types of Fishing
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="fising_techniquies">
                                      Fishing Techniques
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="amenities">
                                      Amenities
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="trips_include">
                                      Trips Include
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="terms">Terms</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Col>
                              <Col sm={9}>
                                <Tab.Content>
                                  <Tab.Pane eventKey="about_the_captain">
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      About the captain
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description{" "}
                                      <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about yourself..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.aboutTheCaptain}
                                      name="aboutTheCaptain"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="types_of_fishing">
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Types of Fishing
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description{" "}
                                      <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about the types of fishing that you perform..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.typesOfFishing}
                                      name="typesOfFishing"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="fising_techniquies">
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Fishing Techniques
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description{" "}
                                      <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about your fishing techniques..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.fishingTechniques}
                                      name="fishingTechniques"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="amenities">
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Amenities
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description{" "}
                                      <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about the amenities taht you offer..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.amenities}
                                      name="amenities"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="trips_include">
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Trips Include
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description{" "}
                                      <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about what is included in your fishing trips..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.tripsInclude}
                                      name="tripsInclude"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="terms">
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Terms
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description{" "}
                                      <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about your terms and conditions..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.terms}
                                      name="terms"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-end mb-5 mt-5">
                          <div>
                            <Button
                              variant="secondary-cancel mr-5"
                              type="reset"
                              disabled={isSubmitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="save"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Save Changes
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
