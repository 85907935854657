import React, { useEffect, useState } from "react";
import {Elements, CardElement, useStripe, useElements, PaymentElement, ShippingAddressElement } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Button, Col, Container, Form, FormGroup, Row, Table } from "react-bootstrap";
import { showToast } from "../../../../utils/Misc";
import { AiFillWarning } from "react-icons/ai";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = (props) => {
  const { session, totalValue, confirmCardPayment, advertisementData } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
/*
try{
  const {paymentIntent} = await stripe.confirmCardPayment(
    session, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    } 
  );
} catch (error )
{
  console.error(error);
  showToast(
    <>
      <AiFillWarning /> There was a problem trying to fetch required data. {error}
    </>,
    "warning"
  );
}*/

    confirmCardPayment(advertisementData);
    setIsLoading(false);


    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
 /*   const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await  stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: 'Jenny Rosen',
      },
    });

     // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }*/

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="mb-2 mt-4 label-range" > Shipping Information </p>
          <FormGroup controlId="controlBillingAdress">
            <Form.Label>Email</Form.Label>
              <Form.Control 
                placeholder="Enter your Email" 
                    name="Email"/>
                <Form.Control.Feedback
                  className="mt-2"
                   type="invalid">
              </Form.Control.Feedback>
              <Form.Label>Shipping Adress</Form.Label>
              <Form.Control 
                placeholder="Enter your Adress" 
                    name="Adress"/>
                <Form.Control.Feedback
                  className="mt-2"
                   type="invalid">
              </Form.Control.Feedback>
            </FormGroup>
        <p className="mb-2 mt-4 label-range" > Payment Details </p>
        <CardElement id="card-element" className="m-3 font-size-30"/>          
        
        
      <Button type="submit" disabled={!stripe} variant="guidex" className="m-2" >
        Pay ${ totalValue }
      </Button>
    </form>
  );
};

const AdvertisingCheckout = (props) => {

  const { session , advertisementData , confirmCardPayment } = props;

  const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
  
  const  daysBet = diffDays(new Date(advertisementData.startDate), new Date(advertisementData.endDate));
  
  const totalValue = daysBet * advertisementData.dailyRate;

  const getCleanDates = (dateString) => {
    const startDate = dateString.substring(0, 24);
    const dt1 = new Date(startDate);
    return `${dt1.getMonth() + 1}/${dt1.getDate()}/${dt1.getFullYear()}`;
  }
  
  return (
    <>
      <Container fluid className="advertising-area-container mt-2 pr-5 pl-5">
      <Row >
        <Col className="mr-5">
        <Table responsive borderless className="ranking-table">
            <thead>
              <tr className="d-flex tr-head">
                <th className="col-3">Checkout</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td className="col-3 font-size-14 font-weight-bold"> {advertisementData.tripName}</td>
                <td className="col-2 font-size-14 font-weight-bold"> {daysBet} days </td>
              </tr>
              <tr>
                <td className="col-3 font-size-14"> Ad Value per day </td>
                <td className="col-2 font-size-14"> ${advertisementData.dailyRate} </td>
              </tr>
              <tr>
                <td className="col-3 font-size-14"> From </td>
                <td className="col-2 font-size-14">{getCleanDates(advertisementData.startDate) }</td>
              </tr>
              <tr>
                <td className="col-3 font-size-14"> To </td>
                <td className="col-2 font-size-14">{getCleanDates(advertisementData.endDate) }</td>
              </tr>
              <tr>
                 <td className="col-3 font-size-15 font-weight-bold"> Total  </td>
                 <td className="col-2 font-size-15 font-weight-bold">${  totalValue }</td>
              </tr>
            </tbody> 
          </Table>
        
        </Col>
        <Col className="ml-5">
        <div>
          <h4> Payment </h4>
          <Elements stripe={stripePromise}>
              <CheckoutForm 
              session={session} 
              totalValue={totalValue} 
              confirmCardPayment={confirmCardPayment}
              advertisementData={advertisementData}
              />
          </Elements>                
        </div>
        
        </Col>
        
      </Row>
      </Container>
        
    </>
  );
};
export default AdvertisingCheckout;
