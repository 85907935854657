import React, { useState } from 'react'
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { app } from "../../FirebaseConfig";
import Separator from "../../utils/Separator";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const SetPassword = () => {
    const query = useQuery();
    const code = query.get('oobCode');
    console.log(code);
    const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
    const [error, seterror] = useState("");
    const [success, setSuccess] = useState(false);
  
    const setNewPassword = async (values, setSubmitting) => {
      try {
        await app.auth().confirmPasswordReset(code, values.password)
        setSubmitting(false);
        setSuccess(true);
      } catch (error) {
        console.error(error);
        seterror(error.message);
        setSubmitting(false);
      }
    };
    
    return (
      <div id="imageBackgroundSignin">
        {!success ? (
          <Container className="d-flex justify-content-center">
            <Col md="auto" className="guidex-card">
              <div className="mb-3" >
                <Row className="mx-0 mb-3 align-items-center" style={{width: "450px"}}>
                  <h2 className="primary-color font-weight-bold font-size-30 mr-1 col-7 px-0">
                    Set password?
                  </h2>
                  <Separator size={"183px"} color="#EC7228" className="col px-0"/>
                </Row>
                <p className="mb-0 font-size-15">
                  Please enter your password
                </p>
              </div>
  
              <Formik
                initialValues={{ password: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.password) {
                    errors.password = "Required";
                  } 
                   
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setNewPassword(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        bsCustomPrefix="asdasda"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Enter password"
                        isInvalid={!!errors.password}
                        isValid={touched.password && !errors.password}
                      />
                      <Form.Control.Feedback className="mt-2" type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      <div
                        className={`${
                          error ? "d-block" : "d-none"
                        } invalid-feedback`}
                      >
                        {error}
                      </div>
                    </Form.Group>
  
                    <div className="d-flex justify-content-end mt-4 mb-0">
                      <Button
                        variant="guidex"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Container>
        ) : (
          <Container className="d-flex justify-content-center">
            <Col md="auto" className="guidex-card">
              <div className="mb-3">
                <Row className="mx-0 mb-2 align-items-center">
                  <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                    Password Set!
                  </h2>
                  <Separator size={!isMobile ? "60%" : "114px"} color="#EC7228" />
                </Row>
              </div>
  
              <Row>
                <Col>
                  <p>
                  your password has been successfully saved
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end mt-4">
                  <Link to="/" className="link-btn">
                    <Button variant="guidex" className="d-xs-block">
                      Close
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Container>
        )}
      </div>
    );
  };

export default SetPassword