import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import "./AdvertisingFeaturesTrips.css";
import TripCard from "../../../../common/TripCard";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./calendarStyles.css";
import { request } from "../../../../utils/HttpHelper";
import { User } from "../../../../utils/UserProvider";
import { showToast } from "../../../../utils/Misc";
import { AiFillWarning } from "react-icons/ai";
import { loadStripe } from "@stripe/stripe-js";
import * as moment from "moment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AdvertisingFeaturedTrips = (props) => {
  // eslint-disable-next-line
  const { onReview, onCancel, selectedTripData, confirmCardPayment } = props;
  const { user } = useContext(User);
  const [value, changeInput] = useState(0);
  const [dateRange, setdateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [focus, setFocus] = useState(null);
  const [trips, setTrips] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState();
  const [featuredTrips, setFeaturedTrips] = useState([]);
  const [featuredTripsIds, setFeaturedTripsIds] = useState([]);
  //const { startDate, endDate } = dateRange;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTripId, setSelectedTripId] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  const handleOnDateChange = (startDate, endDate) => {
    console.log(startDate);
    console.log(endDate);
    setdateRange(startDate, endDate);
  };

  // const changeIcon = () => {
  //   if (icon === arrowDownIcon) {
  //     setIcon(arrowUpIcon);
  //   } else {
  //     setIcon(arrowDownIcon);
  //   }
  // };

  const getUserData = () => {
    if (!user) {
      throw new Error("User is not logged in!");
    }
    let selRole = user.otherRoles.find((r) => r.roleName === "HUNTER");
    return selRole.userId;
  };

  const getMyTrips = (guideId) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/hunt-trips?guide_id=${guideId}`,
          requiresAuth: true,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getFeaturedTrips = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/ranking-trip`,
          requiresAuth: true,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const initFeaturedTrips = async () => {
    try {
      setIsLoading(true);
      let guideId = getUserData();
      let tripsResult = await getMyTrips(guideId);
      let rankingResult = await getFeaturedTrips();
      let rankingIds = rankingResult.trips.map((t) => t.trip.tripId);
      //select trips that are not on the ranking list
      // if the user select a trip that is already live, this will cause an error
      let filteredTrips = tripsResult.trips.filter(
        (t) => !rankingIds.includes(t.trip.tripId)
      );
      setTrips(filteredTrips);
      setFeaturedTrips(rankingResult.trips);
      setFeaturedTripsIds(rankingIds);
      setIsLoading(false);
      setHasLoaded(true);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch required data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initFeaturedTrips();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedTripData && hasLoaded) {
      changeInput(selectedTripData.dailyRate);
      setSelectedTripId(selectedTripData.huntTripId);
      selectTrip({
        target: { value: selectedTripData.huntTripId },
      });
      setdateRange({
        startDate: moment(selectedTripData.startDate),
        endDate: moment(selectedTripData.endDate),
      });
    }
    // eslint-disable-next-line
  }, [hasLoaded]);

  const selectTrip = (e) => {
    let selectedIdStr = e.target.value;
    changeInput(0);
    if (selectedIdStr) {
      let selectedId = Number(selectedIdStr);
      let currentTrip = trips.find((t) => t.trip.tripId === selectedId);
      try {
        currentTrip["advertising"] = {
          dailyRate: 0,
        };
      } catch (error) {
        console.dir(error);
      }
      setFeaturedTrips((prevVal) => {
        prevVal = [
          ...prevVal.filter((t) => featuredTripsIds.includes(t.trip.tripId)),
        ];
        return [...prevVal, currentTrip];
      });
      setSelectedTrip(currentTrip);
    } else {
      setFeaturedTrips((prevVal) => {
        prevVal = [
          ...prevVal.filter((t) => featuredTripsIds.includes(t.trip.tripId)),
        ];
        return [...prevVal];
      });
      setSelectedTrip(null);
    }
  };

  const toCheckout = async (event) => {
    try {
      setIsLoadingCheckout(true);

      let featuredTripData = {
        tripId:
          selectedTrip && selectedTrip.trip ? selectedTrip.trip.tripId : 0,
        dailyRate: Number(value),
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
        tripName: selectedTrip.trip.tripName,
        tripImg: selectedTrip.tripGallery[0],
      };

      // at the begining Tim does not wanto to charge hunters,
      //so if the payment is 0.0
      //go to confirmation
      if (featuredTripData.dailyRate == 0.0) {
        confirmCardPayment(featuredTripData);
      } else {
        console.dir(featuredTripData);

        // Get Stripe.js instance
        const stripe = await stripePromise;

        // Call your backend to create the Checkout Session
        const response = await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: "/create-checkout-session",
          body: featuredTripData,
          requiresAuth: true,
        });

        const session = await response.data;

        console.table(session);

        //  onReview(session, featuredTripData);

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        //check stripe from here ========================================================
        // window.sessionStorage.setItem("GUIDEX-STRIPE-RESULT", JSON.stringify(result));
        // setIsLoadingCheckout(false);

        //onReview(session, featuredTripData);
      }
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to start the payment
          process.
        </>,
        "warning"
      );
      setIsLoadingCheckout(false);
    }
  };

  return !isLoading ? (
    <>
      <Container
        fluid
        className="advertising-area-container mt-2 pr-5 pl-5 mb-5"
      >
        <div className="d-flex justify-content-between">
          <div class="row">
            <div class="col">
              <Row className=" mb-3 mt-3">
                <Col>
                  <p className="font-weight-bold font-size-22">
                    Ranking On Featured Trips Page
                  </p>
                </Col>
              </Row>
              <Row className="pb-4 mb-2">
                <Col>
                  <Form.Label>
                    Select what package you want to promote
                  </Form.Label>
                  <Form.Control
                    id="trip-selector"
                    as="select"
                    className="font-weight-bold btn-select-item btn-bigger-cell"
                    onChange={selectTrip}
                    value={selectedTripId}
                  >
                    <option value="" className="font-weight-bold">
                      Select Package
                    </option>
                    {trips.map((t) => {
                      return (
                        <option
                          value={t.trip.tripId}
                          key={"my-trips_" + t.trip.tripId}
                          className="font-weight-bold btn-bigger-cell"
                        >
                          {t.trip.tripName}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col>
                  <Form.Label>Date Range</Form.Label>
                  <div>
                    <DateRangePicker
                      startDatePlaceholderText="Start"
                      startDate={dateRange.startDate}
                      onDatesChange={handleOnDateChange}
                      endDatePlaceholderText="End"
                      endDate={dateRange.endDate}
                      numberOfMonths={1}
                      displayFormat="MMM D"
                      showClearDates={true}
                      focusedInput={focus}
                      onFocusChange={(focus) => setFocus(focus)}
                      startDateId="startDateMookh"
                      endDateId="endDateMookh"
                      minimumNights={0}
                      hideKeyboardShortcutsPanel={true}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div class="mr-5 col" />
          </div>
          <div className="mt-3">
            <Button 
              variant="secondary-guidex" 
              onClick={onCancel}
              className="mr-5"
              >
                Cancel
              </Button>
            <Button 
            variant="guidex" 
            className="mr-3"
            disabled={
              !(
                selectTrip != null &&
                dateRange.startDate != null &&
                dateRange.endDate != null
              ) || isLoadingCheckout
            }
            onClick={toCheckout}
            >Review</Button>
          </div>
        </div>

        <p className="mb-2 mt-4 label-range">Daily Advertising</p>
        <Row>
          <Col md={2} sm={2}>
            <div className="input-box d-flex justify-content-between align-self-center">
              <span className="pl-3 text-gray">$ </span>
              <span className="font-weight-bold pr-4">{value}</span>{" "}
              <span className="pr-3 text-gray">/Daily</span>
            </div>
          </Col>
          <Col className="align-self-center" md="10" sm={2}>
            <Form.Control
              type="range"
              min="0"
              max="200"
              className="adv-featured-slider"
              value={value}
              onChange={(value) => {
                if (selectedTrip) {
                  let featured = [...featuredTrips];
                  let i = featured.findIndex(
                    (t) => t.trip.tripId === selectedTrip.trip.tripId
                  );
                  featured[i].advertising.dailyRate = value.target.value;
                  setFeaturedTrips([...featured]);
                  changeInput(value.target.value);
                }
              }}
            />
          </Col>
        </Row>

        <Row className="mt-5 ">
          <Col>
            <p className="font-weight-bold mb-4 pb-1">Featured Trips</p>
            {featuredTrips
              .sort((a, b) => {
                return b.advertising.dailyRate - a.advertising.dailyRate;
              })
              .map((t) => {
                let { trip, guide, tripGallery } = t;
                let tripData = {
                  id: trip.tripId,
                  guideCompany: guide.guideBusiness,
                  title: trip.tripName,
                  priceTotal:
                    trip.tripPricePerPerson - trip.tripDiscountPerPerson,
                  priceOriginal: trip.tripPricePerPerson,
                  timeFrame: trip.tripTimeFrame,
                  location: trip.tripLocation,
                  description: trip.tripDescription,
                  mainImage: tripGallery.images[0]
                    ? tripGallery.images[0]
                    : null,
                };
                return (
                  <TripCard
                    key={tripData.id}
                    align={"left"}
                    tripData={tripData}
                    page="advertising"
                    isSelected={
                      selectedTrip && tripData.id === selectedTrip.trip.tripId
                    }
                  ></TripCard>
                );
              })}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end mt-3">
            <Row>
              <Col>
                <Button
                  variant="cancel"
                  disabled={isLoadingCheckout}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  variant="guidex" // remove this comment in the future
                  disabled={
                    !(
                      selectTrip != null &&
                      /* Number(value) > 0 && */ dateRange.startDate != null &&
                      dateRange.endDate != null
                    ) || isLoadingCheckout
                  }
                  onClick={
                    toCheckout
                    //onReview(selectedTripData)
                  }
                >
                  Review
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <center>
      <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
    </center>
  );
};
export default AdvertisingFeaturedTrips;
