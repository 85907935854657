import React from "react";
import { AiFillWarning } from "react-icons/ai";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./common/Footer";
import Header from "./common/Header";
import { routes } from "./utils/Routes";
import ScrollToTop from "./utils/ScrollToTop";
import { UserContext } from "./utils/UserProvider";

import { request } from "./utils/HttpHelper";

function App() {
  request({
    url: "guidex-api",
    trailingURL: "/hello"
  }).catch(err => {
    toast(
      <>
        <AiFillWarning /> Service unavailable: {err.message}
      </>,
      {
        type: "error",
        autoClose: false
      }
    );
  });

  return (
    <UserContext>
      <Router>
        <ScrollToTop />
        <Header />
        <ToastContainer />
        <Switch>
          {routes.map((route, index) => (
            // Render more <Route>s with the same paths as
            // above, but different components this time.
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              children={<route.main />}
            />
          ))}
        </Switch>
        <Footer />
      </Router>
    </UserContext>
  );
}

export default App;
