import algoliasearch from "algoliasearch";
import * as $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  Dropdown,
  Form,
  Overlay,
  Popover,
  Row,
} from "react-bootstrap";
import { BsInfoCircle, BsX } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { request } from "../../utils/HttpHelper";
//import algoliaIcon from "../../assets/icons/search-by-algolia-light-background.svg";
import "./SearchBarPLP.css";

const SearchBarPLP = (props) => {
  const [isOpen, setisOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [anotherSearchTemp, setAnotherSearchTemp] = useState("");
  const ref = useRef(null);
  const [huntingMethodTagCatalog, setHuntingMethodTagsCatalogue] = useState([]);
  const [huntingGeneralTagCatalog, setHuntingGeneralTagCatalog] = useState([]);
  const [huntingDurationTagCatalog, setHuntingDurationTagCatalog] = useState(
    []
  );
  const [partySize, setPartySize] = useState()
  const {
    target,
    searchParams,
    searchResultsTrips,
    searchResultsGuides,
    setSearchLocation,
  } = props;
  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const indexTrip = searchClient.initIndex("HUNTING_TRIP");
  const indexGuideHunting = searchClient.initIndex("GUIDE_HUNTER");
  const indexGuideFishing = searchClient.initIndex("GUIDE_FISHER");
  /**
   * @description Loading of data from props
   * @var {String} query - Input query string
   * @var {String} location - Location string
   */
  useEffect(() => {
    const { query, category } = searchParams;
    if (query && query.trim().length > 0) {
      switch (category) {
        case "location":
          valueInputsHunting.location.locationTerm = query;
          valueInputsHunting.location.selectedOptions = [];
          $('[name="location_text_input"]').val(query);
          listButtons.push({
            param: "locationManual",
            value: query,
          });
          setlabelsInputHunting((prevState) => ({
            ...prevState,
            locationTerm: query,
          }));
          break;
        case "guideBusinessName":
        case "all":
          valueInputsHunting.searchTerm = query;
          listButtons.push({
            param: "searchTerm",
            value: [query],
          });
          break;
        case "species":
          valueInputsHunting.species.push(query);
          listButtons.push({
            param: "species",
            value: [query],
          });
          break;
        default:
          console.log();
      }
    }
    setTagsCatalogue();
    processQuery();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    clearAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    if (isOpen && isMobile) {
      $("body").css("overflow-y", "hidden");
      $(".hunting-search-bar").css("position", "absolute");
      $(".hunting-search-bar").css("z-index", "200");
      $(".hunting-search-bar").css("background-color", "white");

      $("#imageBackgroundTopoAlt").hide();
      $(".outfitter-match").hide();
      $("#menuFilters").css("min-height", "90vh");
      $("#menuFilters").show();
    } else {
      $("body").css("overflow-y", "auto");
      $(".hunting-search-bar").css("position", "relative");
      $(".hunting-search-bar").css("z-index", "200");
      $(".hunting-search-bar").css(
        "background-color",
        "rgba(255, 255, 255, 0.8)"
      );
      $("#menuFilters").css("min-height", "auto");
      $("#imageBackgroundTopoAlt").show();
      $(".outfitter-match").show();
      $("#menuFilters").hide();
    }

    if (!isMobile) {
      setisOpen(false);
      $("#menuFilters").show();
      $("#menuFilters").css("min-height", "auto");
      $("body").css("overflow-y", "auto");
      $(".hunting-search-bar").css("position", "relative");
      $(".hunting-search-bar").css("z-index", "200");
      $(".hunting-search-bar").css(
        "background-color",
        "rgba(255, 255, 255, 0.8)"
      );
      $("#imageBackgroundTopoAlt").show();
    } else {
      $(".hunting-search-bar").css("background-color", "white");
    }
  }, [isOpen, isMobile]);

  const handleClick = (event) => {
    setShow(!show);
  };

  /**
   * @name listButtons
   * @description Control to handle the pills bellow the search form
   */
  const [listButtons, setListButtons] = useState([]);

  /**
   * @description Controls to handle collapse forms of each search field
   */
  const [collapseTimeFrame, setCollapseTimeFrame] = useState(false);
  const [collapsePartySize, setCollapsePartySize] = useState(false);
  const [collapsePrice, setCollapsePrice] = useState(false);
  const [collapseLocation, setCollapseLocation] = useState(false);
  const [collapseMore, setCollapseMore] = useState(false);

  /**
   * @description Invisible checkbox to trigger changes on the form
   */
  const [trigger, setTrigger] = useState(false);

  const startValueFilters = {
    species: [],
    searchTerm: "",
    timeFrame: {
      season: "",
      year: "",
    },
    priceRange: {
      min: "",
      max: "",
    },
    location: {
      locationTerm: "",
      selectedOptions: [],
    },
    partySize: "",
    more: {
      huntingDifficulty: 0,
      tagTypes: [],
      huntingMethods: [],
      daysOfDuration: "",
    },
  };

  /**
   * @name valueInputsHunting
   * @description Control to handle the value of each search form after submitting
   */
  const [valueInputsHunting, setvalueInputsHunting] = useState(
    startValueFilters
  );

  /**
   * @name labelsInputHunting
   * @description Control to handle the labels of each main input field
   */
  const [labelsInputHunting, setlabelsInputHunting] = useState({
    timeFrame: "",
    priceRange: "",
    location: "",
    partySize: undefined,
    more: "",
  });

  const processQuery = () => {
    // console.dir(valueInputsHunting);

    /**
     * @name filters
     * @description Array that controls the applied filters
     */
    if (target === "hunting") {
      let searchTerm = valueInputsHunting.searchTerm;

      let filtersTrip = [];
      let filtersGuide = [];

      let speciesFilterTrip = "";
      let speciesFilterGuide = "";

      let timeFrameFilterTrip = "";
      let timeFrameFilterGuide = "";

      let priceFilter = "";

      let locationFilterTrip = "";
      let locationFilterGuide = "";

      let huntingDifficultyFilterGuide = "";
      let huntingDifficultyFilterTrip = "";

      let huntingTagsFilter = "";

      let huntingMethodFilterTrip = "";

      let huntigDurationFilter = "";
      //////////////////////////////////////////

      let species = valueInputsHunting.species;
      // species = species.slice(1);
      if (species.length) {
        // speciesFilterTrip = species.map((a) => `species: ${a}`).join(" OR ");
        // filtersTrip.push(`(${speciesFilterTrip})`);

        speciesFilterTrip = `species: "${species.join(" and ")}"`;
        filtersTrip.push(speciesFilterTrip);

        speciesFilterGuide = `guideSpecies: "${species.join(" and ")}"`;
        filtersGuide.push(speciesFilterGuide);
      }

      let timeFrame = valueInputsHunting.timeFrame;
      if (timeFrame.season.length && timeFrame.year.length) {
        let months = [];
        switch (timeFrame.season) {
          case "Winter":
            months = ["DEC", "JAN", "FEB", "MAR"];
            break;
          case "Spring":
            months = ["MAR", "APR", "MAY", "JUN"];
            break;
          case "Summer":
            months = ["JUN", "JUL", "AUG", "SEP"];
            break;
          case "Autumn":
            months = ["SEP", "OCT", "NOV", "DEC"];
            break;
          default:
            break;
        }
        months.push(timeFrame.season);
        // timeFrameFilterTrip = `timeFrame: "${timeFrame.season}"`;
        // filtersTrip.push(timeFrameFilterTrip);

        timeFrameFilterTrip = months
          .map((a) => `timeFrame: "${a}"`)
          .join(" OR ");
        filtersTrip.push(`(${timeFrameFilterTrip})`);

        timeFrameFilterGuide = months
          .map((a) => `timeFrame: "${a}"`)
          .join(" OR ");
        filtersGuide.push(`(${timeFrameFilterGuide})`);
      }

      let prices = valueInputsHunting.priceRange;
      let minPrice = 0;
      let maxPrice = 0;
      if (prices.max.length) {
        maxPrice = prices.max;
      }
      if (prices.min.length) {
        minPrice = prices.min;
      }
      if (minPrice !== 0 || maxPrice !== 0) {
        priceFilter = `discountPerPerson: ${minPrice} TO ${maxPrice}`;
        filtersTrip.push(priceFilter);
      }

      let locationsAux = [...valueInputsHunting.location.selectedOptions];
      let locationManualAux = valueInputsHunting.location.locationTerm;
      if (locationsAux.length || locationManualAux.length) {
        // let newLocations = [];
        // newLocations = locationsAux;
        // newLocations.push(locationManualAux);
        if (locationManualAux.length) locationsAux.push(locationManualAux);
        locationsAux = [...new Set(locationsAux)];

        locationFilterTrip = locationsAux
          .map((a) => `location: "${a}"`)
          .join(" OR ");
        filtersTrip.push(`(${locationFilterTrip})`);

        locationFilterGuide = locationsAux
          .map((a) => `guideRegionOperating: "${a}"`)
          .join(" OR ");
        filtersGuide.push(`(${locationFilterGuide})`);
      }

      let huntingDifficulty = valueInputsHunting.more.huntingDifficulty;

      let filtersGuideTags = [];
      let filtersTripTags = [];
      if (huntingDifficulty.length) {
        switch (huntingDifficulty) {
          case "1":
          case "2":
            huntingDifficultyFilterGuide = "Easy Physical Level";
            huntingDifficultyFilterTrip = "Easy Physical Level";
            break;
          case "3":
            huntingDifficultyFilterGuide = "Moderate Physical Level";
            huntingDifficultyFilterTrip = "Moderate Physical Level";
            break;
          case "4":
            huntingDifficultyFilterGuide = "Difficult Physical Level";
            huntingDifficultyFilterTrip = "Difficult Physical Level";
            break;
          case "5":
            huntingDifficultyFilterGuide = "Extreme Physical Level";
            huntingDifficultyFilterTrip = "Extreme Physical Level";
            break;
          default:
            break;
        }
        filtersGuideTags.push(huntingDifficultyFilterGuide);
        filtersTripTags.push(huntingDifficultyFilterTrip);
      }

      let huntingTags = valueInputsHunting.more.tagTypes;
      let huntingMethods = valueInputsHunting.more.huntingMethods;
      let huntinDuration = valueInputsHunting.more.daysOfDuration;
      if (huntinDuration.length) {
        huntigDurationFilter = huntinDuration;
        filtersTripTags.push(huntigDurationFilter);
      }
      if (huntingTags.length) {
        if (huntingMethods.length) {
          let newTags = [
            ...huntingTags,
            ...huntingMethods,
            ...filtersGuideTags,
          ];
          huntingTagsFilter = newTags.map((a) => `tags: "${a}"`).join(" OR ");
          filtersGuide.push(`(${huntingTagsFilter})`);
        } else {
          let newTags = [...huntingTags, ...filtersGuideTags];
          huntingTagsFilter = newTags.map((a) => `tags: "${a}"`).join(" OR ");
          filtersGuide.push(`(${huntingTagsFilter})`);
        }
      } else if (huntingDifficulty.length) {
        let newTags = [...filtersGuideTags];
        huntingTagsFilter = newTags.map((a) => `tags: "${a}"`).join(" OR ");
        filtersGuide.push(`(${huntingTagsFilter})`);
      }

      if (huntingMethods.length || huntinDuration.length) {
        let newTags = [...huntingMethods, ...filtersTripTags];
        huntingMethodFilterTrip = newTags
          .map((a) => `tags: "${a}"`)
          .join(" OR ");
        filtersTrip.push(`(${huntingMethodFilterTrip})`);
      }

      filtersTrip = filtersTrip.join(" AND ");

      filtersGuide = filtersGuide.join(" AND ");

      indexTrip
        .search(searchTerm ?? "", {
          filters: filtersTrip,
        })
        .then(({ hits }) => {
          // console.dir(hits);
          searchResultsTrips(hits);
        })
        .catch((err) => {
          console.log(err);
        });
      indexGuideHunting
        .search(searchTerm ?? "", {
          filters: filtersGuide,
        })
        .then(({ hits }) => {
          //console.dir(hits);
          searchResultsGuides(hits);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if ("fishing") {
      let searchTerm = valueInputsHunting.searchTerm;

      let filtersGuide = [];

      let speciesFilterFisherman = "";

      let partySizeFilterFisherman = ""

      let timeFrameFilterFisherman = "";

      let priceFilterFisherman = "";

      let locationFilterFisherman = "";

      let speciesFisherman = valueInputsHunting.species;

      let partySizeFisherman = valueInputsHunting.partySize;

      if (speciesFisherman.length) {
        // This part should have another conditional for algolia because it does not work when you enter more than one specie
        speciesFilterFisherman = `guideSpecies: "${speciesFisherman.join(
          " or "
        )}"`;
        console.log(speciesFilterFisherman)
        filtersGuide.push(speciesFilterFisherman);
      }

      if (partySizeFisherman >= 1) {
        partySizeFilterFisherman = `guidePartySize >= ${partySizeFisherman}`
      }

      let timeFrame = valueInputsHunting.timeFrame;
      if (timeFrame.season.length && timeFrame.year.length) {
        let months = [];
        switch (timeFrame.season) {
          case "Winter":
            months = ["DEC", "JAN", "FEB", "MAR"];
            break;
          case "Spring":
            months = ["MAR", "APR", "MAY", "JUN"];
            break;
          case "Summer":
            months = ["JUN", "JUL", "AUG", "SEP"];
            break;
          case "Autumn":
            months = ["SEP", "OCT", "NOV", "DEC"];
            break;
          default:
            break;
        }
        months.push(timeFrame.season);

        timeFrameFilterFisherman = months
          .map((a) => `timeFrame: "${a}"`)
          .join(" OR ");
        filtersGuide.push(`(${timeFrameFilterFisherman})`);
      }

      let prices = valueInputsHunting.priceRange;
      let minPrice = 0;
      let maxPrice = 0;
      if (prices.max.length) {
        maxPrice = prices.max;
      }
      if (prices.min.length) {
        minPrice = prices.min;
      }
      if (minPrice !== 0 || maxPrice !== 0) {
        priceFilterFisherman = `guidePriceMax: ${minPrice} TO ${maxPrice}`;
        filtersGuide.push(priceFilterFisherman);
      }

      let locationsAux = [...valueInputsHunting.location.selectedOptions];
      let locationManualAux = valueInputsHunting.location.locationTerm;
      if (locationsAux.length || locationManualAux.length) {
        if (locationManualAux.length) locationsAux.push(locationManualAux);
        locationsAux = [...new Set(locationsAux)];

        locationFilterFisherman = locationsAux
          .map((a) => `guideRegionOperating: "${a}"`)
          .join(" OR ");
        filtersGuide.push(`(${locationFilterFisherman})`);
      }

      setSearchLocation(`(${locationFilterFisherman})`);
      filtersGuide = filtersGuide.join(" AND ");

      indexGuideFishing
        .search(searchTerm ?? "", {
          filters: filtersGuide,
          numericFilters: partySizeFilterFisherman
        })
        .then(({ hits }) => {
          console.dir(hits);
          searchResultsGuides(hits);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getTagCatalogue = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/hunt-trip-tags`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getTagTypeCatalogue = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/tagtype`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const setTagsCatalogue = async () => {
    getTagCatalogue().then((tagsCat) => {
      let method = tagsCat["tagsCatalogue"].find((obj) => {
        return obj.tagTypeName === "Method";
      });
      setHuntingMethodTagsCatalogue(method.tags);

      let duration = tagsCat["tagsCatalogue"].find((obj) => {
        return obj.tagTypeName === "Duration";
      });
      setHuntingDurationTagCatalog(duration.tags);
      getTagTypeCatalogue().then((tagsType) => {
        let general = tagsType["tagTypes"];
        setHuntingGeneralTagCatalog(general);
      });
    });
  };

  /**
   * @name handleSubmitSearchTermHunting
   * @param {Object} e
   * @description Processes the input text on the search term field
   */
  const handleSubmitSearchTerm = (e) => {
    if (e.key === "Enter" && anotherSearchTemp.trim().length > 0) {
      valueInputsHunting.species.push(anotherSearchTemp);
      let prevspeciesI = listButtons.findIndex((btn) => {
        return btn.param === "species";
      });
      if (prevspeciesI >= 0) {
        listButtons[prevspeciesI].value.push(anotherSearchTemp);
      } else {
        listButtons.push({
          param: "species",
          value: [anotherSearchTemp],
        });
      }
      setAnotherSearchTemp("");
      e.target.value = "";
      processQuery();
    }
  };

  /**
   * @name handleSubmitTimeFrameHunting
   * @param {Object} e
   * @description Processes the selected time frame
   */
  const handleSubmitTimeFrame = (e) => {
    e.preventDefault();

    valueInputsHunting.timeFrame.season = $('[name="timeFrame.season"]').val();
    valueInputsHunting.timeFrame.year = $('[name="timeFrame.year"]').val();

    setlabelsInputHunting((prevState) => ({
      ...prevState,
      timeFrame:
        valueInputsHunting.timeFrame.season +
        " " +
        valueInputsHunting.timeFrame.year,
    }));
    let prevTimeFrameI = listButtons.findIndex((btn) => {
      return btn.param === "timeFrame";
    });
    if (prevTimeFrameI >= 0) {
      listButtons[prevTimeFrameI] = {
        param: "timeFrame",
        value:
          valueInputsHunting.timeFrame.season +
          " " +
          valueInputsHunting.timeFrame.year,
      };
    } else {
      listButtons.push({
        param: "timeFrame",
        value:
          valueInputsHunting.timeFrame.season +
          " " +
          valueInputsHunting.timeFrame.year,
      });
    }
    setCollapseTimeFrame(false);
    processQuery();
  };

  const handleSubmitPartySize = (e) => {
    e.preventDefault();
    valueInputsHunting.partySize = partySize
    setlabelsInputHunting((prevState) => ({
      ...prevState,
      partySize:
        valueInputsHunting.partySize
    }));
    let prevPartySize = listButtons.findIndex((btn) => {
      return btn.param === "partySize";
    });
    if (prevPartySize >= 0) {
      listButtons[prevPartySize] = {
        param: "partySize",
        value: valueInputsHunting.partySize
      }
      console.log(valueInputsHunting.partySize)
    } else {
      listButtons.push({
        param: "partySize",
        value: valueInputsHunting.partySize,
      });

    }
    setCollapsePartySize(false);
    processQuery();
  }

  const handleSubmitPrice = (e) => {
    e.preventDefault();

    valueInputsHunting.priceRange.min = $('[name="priceRange.min"]').val();
    valueInputsHunting.priceRange.max = $('[name="priceRange.max"]').val();

    let labelPrice = "";

    if (
      valueInputsHunting.priceRange.min &&
      valueInputsHunting.priceRange.max
    ) {
      labelPrice = `$${valueInputsHunting.priceRange.min} - $${valueInputsHunting.priceRange.max}`;
    } else if (valueInputsHunting.priceRange.min) {
      labelPrice = "From $" + valueInputsHunting.priceRange.min;
    } else if (valueInputsHunting.priceRange.max) {
      labelPrice = "Up to $" + valueInputsHunting.priceRange.max;
    }

    setlabelsInputHunting((prevState) => ({
      ...prevState,
      priceRange: labelPrice,
    }));

    let prevPriceRangeI = listButtons.findIndex((btn) => {
      return btn.param === "priceRange";
    });

    if (
      valueInputsHunting.priceRange.min.trim().length === 0 &&
      valueInputsHunting.priceRange.max.trim().length === 0
    ) {
      if (prevPriceRangeI >= 0) {
        listButtons.splice(prevPriceRangeI, 1);
      }
    } else {
      if (prevPriceRangeI >= 0) {
        listButtons[prevPriceRangeI] = {
          param: "priceRange",
          value: labelPrice,
        };
      } else {
        listButtons.push({
          param: "priceRange",
          value: labelPrice,
        });
      }
    }

    setCollapsePrice(false);
    processQuery();
  };

  const handleSubmitLocation = (e) => {
    e.preventDefault();

    let selectedLocationsJquery = $(
      "#popular-locations input:checkbox:checked"
    ).map(function () {
      $(`#${$(this).attr("id")}`).prop("checked", false);
      return $(this).attr("id");
    });
    let selectedLocations = [];
    for (let i = 0; i < selectedLocationsJquery.length; i++) {
      selectedLocations.push(selectedLocationsJquery[i]);
    }
    let manualLocation = $('[name="location_text_input"]').val();

    if (manualLocation.trim().length > 0 || selectedLocations.length > 0) {
      valueInputsHunting.location.locationTerm = manualLocation;
      valueInputsHunting.location.selectedOptions = selectedLocations;
      if (manualLocation.trim().length > 0) {
        setlabelsInputHunting((prevState) => ({
          ...prevState,
          location: manualLocation,
        }));

        $('[name="location_text_input"]').val("");

        let prevLocationMI = listButtons.findIndex((btn) => {
          return btn.param === "locationManual";
        });
        if (prevLocationMI >= 0) {
          listButtons[prevLocationMI].value = manualLocation;
        } else {
          listButtons.push({
            param: "locationManual",
            value: manualLocation,
          });
        }
      }

      let prevLocationCBI = listButtons.findIndex((btn) => {
        return btn.param === "locationCB";
      });
      if (prevLocationCBI >= 0) {
        listButtons[prevLocationCBI].value = selectedLocations;
      } else {
        listButtons.push({
          param: "locationCB",
          value: selectedLocations,
        });
      }
    }

    setCollapseLocation(false);
    processQuery();
  };

  const handleSubmitMoreOptions = (e) => {
    e.preventDefault();

    let huntingDifficulty = $('[name="hunting-difficulty-selector"]').val();
    let tagTypeJquery = $("#tag-type-selector input:checkbox:checked").map(
      function () {
        $(`#${$(this).attr("id")}`).prop("checked", false);
        return $(this).attr("id");
      }
    );
    let huntingMethodJquery = $(
      "#hunting-method-selector input:checkbox:checked"
    ).map(function () {
      $(`#${$(this).attr("id")}`).prop("checked", false);
      return $(this).attr("id");
    });
    let daysOfDuration = $('[name="days-of-duration-selector"]').val();

    let tagTypes = [];
    for (let i = 0; i < tagTypeJquery.length; i++) {
      // let ttRaw = tagTypeJquery[i];
      // let ttArr = ttRaw.split("_")
      // tagTypes.push(ttArr[1]);
      tagTypes.push(tagTypeJquery[i]);
    }
    let huntingMethods = [];
    for (let i = 0; i < huntingMethodJquery.length; i++) {
      // let hmRaw = huntingMethodJquery[i];
      // let hmArr = hmRaw.split("_")
      // huntingMethods.push(hmArr[1]);
      huntingMethods.push(huntingMethodJquery[i]);
    }

    valueInputsHunting.more = {
      huntingDifficulty,
      tagTypes,
      huntingMethods,
      daysOfDuration,
    };

    let prevMoreHDI = listButtons.findIndex((btn) => {
      return btn.param === "huntingDifficulty";
    });
    if (prevMoreHDI >= 0) {
      listButtons[prevMoreHDI].value = `Difficulty: ${huntingDifficulty}`;
    } else {
      listButtons.push({
        param: "huntingDifficulty",
        value: `Difficulty: ${huntingDifficulty}`,
      });
    }

    let prevMoreNoFI = listButtons.findIndex((btn) => {
      return btn.param === "daysOfDuration";
    });

    if (daysOfDuration.trim().length === 0) {
      if (prevMoreNoFI >= 0) {
        listButtons.splice(prevMoreNoFI, 1);
      }
    } else {
      if (prevMoreNoFI >= 0) {
        listButtons[prevMoreNoFI].value = `Hunters: ${daysOfDuration}`;
      } else {
        listButtons.push({
          param: "daysOfDuration",
          value: `Duration: ${daysOfDuration}`,
        });
      }
    }

    let prevMoreTTI = listButtons.findIndex((btn) => {
      return btn.param === "tagType";
    });
    if (tagTypes.length === 0) {
      if (prevMoreTTI >= 0) {
        listButtons.splice(prevMoreTTI, 1);
      }
    } else {
      if (prevMoreTTI >= 0) {
        listButtons[prevMoreTTI].value = tagTypes;
      } else {
        listButtons.push({
          param: "tagType",
          value: tagTypes,
        });
      }
    }

    let prevMoreHMI = listButtons.findIndex((btn) => {
      return btn.param === "huntingMethod";
    });

    if (huntingMethods.length === 0) {
      if (prevMoreHMI >= 0) {
        listButtons.splice(prevMoreHMI, 1);
      }
    } else {
      if (prevMoreHMI >= 0) {
        listButtons[prevMoreHMI].value = huntingMethods;
      } else {
        listButtons.push({
          param: "huntingMethod",
          value: huntingMethods,
        });
      }
    }

    setCollapseMore(false);

    processQuery();
  };

  const selectSuggestedPrice = (price) => {
    $('[name="priceRangeHunting.max"]').val(price);
  };

  /**
   * @name clearSelectedFilter
   * @param {String} filterTitle
   * @description Removes a given element from the listButtons array.
   * @returns {Void}
   */
  const clearSelectedFilter = (param, index) => {
    switch (param) {
      case "species":
        valueInputsHunting.species.splice(index, 1);
        let prevSeciesI = listButtons.findIndex((btn) => {
          return btn.param === "species";
        });
        if (prevSeciesI >= 0) {
          // if (listButtons[prevSeciesI].value.length <= 1) {
          //   listButtons.splice(prevSeciesI, 1);
          // } else {
          //   listButtons[prevSeciesI].value.splice(index, 1);
          // }
          listButtons[prevSeciesI].value.splice(index, 1);
        }

        console.dir(listButtons);
        break;
      case "locationCB":
        valueInputsHunting.location.selectedOptions.splice(index, 1);
        // let prevLocationCBI = listButtons.findIndex((btn) => {
        //   return btn.param === "locationCB";
        // });
        // if (prevLocationCBI >= 0) {
        //   // if (listButtons[prevLocationCBI].value.length <= 1) {
        //   //   listButtons.splice(prevLocationCBI, 1);
        //   // } else {
        //   //   listButtons[prevLocationCBI].value.splice(index, 1);
        //   // }
        //   //listButtons[prevLocationCBI].value.splice(index, 1);
        // }
        break;

      case "partySize":
        setListButtons(listButtons.filter(btn => btn.param !== "partySize"))
        labelsInputHunting.partySize = "";
        break;

      case "tagType":
        valueInputsHunting.more.tagTypes.splice(index, 1);
        // let prevMoreTTI = listButtons.findIndex((btn) => {
        //   return btn.param === "tagType";
        // });
        // if (prevMoreTTI >= 0) {
        //   // if (listButtons[prevMoreTTI].value.length <= 1) {
        //   //   listButtons.splice(prevMoreTTI, 1);
        //   // } else {
        //   //   listButtons[prevMoreTTI].value.splice(index, 1);
        //   // }
        //   // listButtons[prevMoreTTI].value.splice(index, 1);
        // }
        break;
      case "huntingMethod":
        valueInputsHunting.more.huntingMethods.splice(index, 1);
        // console.dir(valueInputsHunting);
        // let prevMoreHMI = listButtons.findIndex((btn) => {
        //   return btn.param === "huntingMethod";
        // });
        // console.log(listButtons);
        // if (prevMoreHMI >= 0) {
        //   // if (listButtons[prevMoreHMI].value.length <= 1) {
        //   //   listButtons.splice(prevMoreHMI, 1);
        //   // } else {
        //   //   listButtons[prevMoreHMI].value.splice(index, 1);
        //   // }
        //   // listButtons[prevMoreHMI].value.splice(index, 1);
        // }
        // console.log(listButtons);
        break;
      case "timeFrame":
        valueInputsHunting.timeFrame.season = "";
        valueInputsHunting.timeFrame.year = "";
        setlabelsInputHunting((prevState) => ({
          ...prevState,
          timeFrame: "",
        }));
        let prevTimeFrameI = listButtons.findIndex((btn) => {
          return btn.param === "timeFrame";
        });
        if (prevTimeFrameI >= 0) {
          listButtons.splice(prevTimeFrameI, 1);
        }
        break;
      case "priceRange":
        valueInputsHunting.priceRange.min = "";
        valueInputsHunting.priceRange.max = "";
        setlabelsInputHunting((prevState) => ({
          ...prevState,
          priceRange: "",
        }));
        let prevPriceRangeI = listButtons.findIndex((btn) => {
          return btn.param === "priceRange";
        });
        if (prevPriceRangeI >= 0) {
          listButtons.splice(prevPriceRangeI, 1);
        }
        break;
      case "locationManual":
        valueInputsHunting.location.locationTerm = "";
        setlabelsInputHunting((prevState) => ({
          ...prevState,
          location: "",
        }));
        let prevLocationMI = listButtons.findIndex((btn) => {
          return btn.param === "locationManual";
        });
        if (prevLocationMI >= 0) {
          listButtons.splice(prevLocationMI, 1);
        }
        break;
      case "huntingDifficulty":
        valueInputsHunting.more.huntingDifficulty = 0;
        let prevLocationHDI = listButtons.findIndex((btn) => {
          return btn.param === "huntingDifficulty";
        });
        if (prevLocationHDI >= 0) {
          listButtons.splice(prevLocationHDI, 1);
        }
        break;
      case "daysOfDuration":
        valueInputsHunting.more.daysOfDuration = "";
        let prevLocationNoHI = listButtons.findIndex((btn) => {
          return btn.param === "daysOfDuration";
        });
        if (prevLocationNoHI >= 0) {
          listButtons.splice(prevLocationNoHI, 1);
        }
        break;
      default:
    }
    setTrigger(!trigger);
    processQuery();
  };

  /**
   * @name clearAllFilters
   * @description Removes all elements from the listButtons array.
   * @returns {Void}
   */
  const clearAllFilters = () => {
    setListButtons([]);
    setvalueInputsHunting(startValueFilters);
    setlabelsInputHunting({
      timeFrame: "",
      priceRange: "",
      location: "",
      more: "",
      partySize:""
    });
    setTrigger(!trigger);
    processQuery();
  };

  $("body").click(function (event) {
    let isInput = $(event.target).is(".search-input-hunter");
    let collapseArray = $(".collapse-form-container").has($(event.target));

    if (!isInput && !(collapseArray.length > 0)) {
      setCollapseTimeFrame(false);
      setCollapsePrice(false);
      setCollapseLocation(false);
      setCollapseMore(false);
    }
  });

  return (
    <>
      <div className="hunting-search-bar-wrapper">
        <Container fluid className="hunting-search-bar p-md-4">
          <Row className="invisible-trigger">
            <Form.Control name="trigger" value={trigger} type="checkbox" />
          </Row>
          <div
            onClick={() => setisOpen(!isOpen)}
            className=" py-2   d-block d-md-none"
          >
            Filter <IoMdArrowDropdown></IoMdArrowDropdown>
          </div>
          <div id="menuFilters">
            {target === "hunting" && (
              <>
                {/* <img width={100} src={algoliaIcon} /> */}
                <Row className="">
                  <Col xs={12} md={6} lg={4} className="p-2">
                    <Form.Control
                      className="font-weight-bold searchbar-another-species-hunting search-input-hunter"
                      type="text"
                      placeholder="Add another species"
                      onFocus={() => {
                        setCollapseTimeFrame(false);
                        setCollapsePrice(false);
                        setCollapseLocation(false);
                        setCollapseMore(false);
                      }}
                      onChange={(e) => {
                        let term = e.target.value;
                        if (term.trim().length > 0) {
                          setAnotherSearchTemp(term);
                        }
                      }}
                      onKeyPress={handleSubmitSearchTerm}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="p-2">
                    <Form.Control
                      className="font-weight-bold searchbar-time-frame-hunting search-input-hunter"
                      type="text"
                      name="timeFrame"
                      value={labelsInputHunting.timeFrame}
                      placeholder="Time Frame"
                      readOnly={true}
                      onFocus={(e) => {
                        setCollapseTimeFrame(true);
                        setCollapsePrice(false);
                        setCollapseLocation(false);
                        setCollapseMore(false);
                      }}
                    />

                    <div className="collapse-form-container collapse-form-time-frame">
                      <Collapse
                        in={collapseTimeFrame}
                        className="collapse-form arrow_box"
                      >
                        <Form
                          className="pl-2 pr-2"
                          onSubmit={handleSubmitTimeFrame}
                          name="timeFrameHunting"
                        >
                          <p className="mt-2 ">Time Frame</p>
                          <Form.Row>
                            <Col md="6">
                              <Form.Control
                                name="timeFrame.season"
                                as="select"
                                className="huntingPLP-select"
                              >
                                <option value="Fall">Fall</option>
                                <option value="Winter">Winter</option>
                                <option value="Summer">Summer</option>
                                <option value="Spring">Spring</option>
                              </Form.Control>
                            </Col>
                            <Col md="6">
                              <Form.Control
                                name="timeFrame.year"
                                as="select"
                                className="huntingPLP-select"
                              >
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>
                  <Col xs={12} md={3} lg={2} className="p-2">
                    <Form.Control
                      type="text"
                      className="font-weight-bold searchbar-price-range search-input-hunter"
                      placeholder="Price Range"
                      value={labelsInputHunting.priceRange}
                      readOnly={true}
                      onFocus={() => {
                        setCollapseTimeFrame(false);
                        setCollapsePrice(true);
                        setCollapseLocation(false);
                        setCollapseMore(false);
                      }}
                    />
                    <div className="collapse-form-container collapse-form-price">
                      <Collapse
                        in={collapsePrice}
                        className="collapse-form arrow_box"
                      >
                        <Form
                          className="pl-2 pr-2"
                          name="priceRange"
                          onSubmit={handleSubmitPrice}
                        >
                          <p className="mt-2 ">Price Range</p>
                          <Form.Row>
                            <Col md={5}>
                              <Form.Control
                                inline="true"
                                type="text"
                                placeholder="Min"
                                className="huntingPLP-input"
                                name="priceRange.min"
                              />
                            </Col>
                            <Col md={1}>
                              <div className="mt-2">
                                <span className="font-weight-bold pt-2 mr-1 ml-1">
                                  -
                                </span>
                              </div>
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                inline="true"
                                type="text"
                                placeholder="Max"
                                className="huntingPLP-input"
                                name="priceRange.max"
                              />
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>
                  <Col xs={12} md={3} lg={2} className="p-2">
                    <Form.Control
                      className="font-weight-bold searchbar-location search-input-hunter"
                      type="text"
                      value={labelsInputHunting.location}
                      placeholder="Location"
                      readOnly={true}
                      onFocus={() => {
                        setCollapseTimeFrame(false);
                        setCollapsePrice(false);
                        setCollapseLocation(true);
                        setCollapseMore(false);
                      }}
                    />

                    <div className="collapse-form-container collapse-form-container-center collapse-form-location">
                      <Collapse
                        in={collapseLocation}
                        className="collapse-form arrow_box-center"
                      >
                        <Form
                          className="pl-3 pr-2"
                          onSubmit={handleSubmitLocation}
                          name="locationHunting"
                        >
                          <p className="mt-2 ">Search Location</p>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder="Zipcode, City, State"
                                className="huntingPLP-search pl-5"
                                name="location_text_input"
                              />
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <p className="mt-3">Popular Locations</p>
                          </Form.Row>
                          <Form.Row id="popular-locations">
                            <Col>
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Colorado"
                                label="Colorado"
                              />
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Florida"
                                label="Florida"
                              />
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Alaska"
                                label="Alaska"
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Wyoming"
                                label="Wyoming"
                              />
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Maine"
                                label="Maine"
                              />
                            </Col>
                          </Form.Row>
                          {/* <Form.Row>
                        <p className="primary-color mt-2">More Locations &gt;</p>
                      </Form.Row> */}
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>
                  <Col xs={12} md={2} lg={1} className="p-2">
                    <Form.Control
                      className="font-weight-bold more-btn search-input-hunter"
                      type="text"
                      value={labelsInputHunting.more}
                      placeholder="More"
                      readOnly={true}
                      onFocus={() => {
                        setCollapseTimeFrame(false);
                        setCollapsePrice(false);
                        setCollapseLocation(false);
                        setCollapseMore(true);
                      }}
                    />

                    <div className="collapse-form-container collapse-form-container-right collapse-form-more">
                      <Collapse
                        in={collapseMore}
                        className="collapse-form arrow_box-right"
                      >
                        <Form
                          className="pl-3 pr-2"
                          name="huntingAdditionalOptions"
                          onSubmit={handleSubmitMoreOptions}
                        >
                          <Form.Label className="font-size-15">
                            Hunting Difficulty
                          </Form.Label>
                          <Form.Row>
                            <Col>
                              <div className="d-flex justify-content-between">
                                <span>1</span>
                                <span>5</span>
                              </div>
                              <Form.Control
                                type="range"
                                min="1"
                                max="5"
                                className="plp-slider"
                                name="hunting-difficulty-selector"
                              />
                            </Col>
                          </Form.Row>
                          <Form.Row className="d-flex justify-content-between mt-2">
                            <p className="font-size-10">
                              1-4 mil hikes each day
                            </p>
                            <p className="font-size-10">
                              8-10 mil hikes each day
                            </p>
                            {/* <BsInfoCircle onClick={handleClick} />
                            <Overlay
                              show={show}
                              // target={target}
                              placement="bottom"
                              container={ref.current}
                            >
                              <Popover id="popover-contained">
                                <Popover.Content className="pop-over">
                                  
                                </Popover.Content>
                              </Popover>
                            </Overlay> */}
                          </Form.Row>
                          <Dropdown.Divider className="mt-4" />
                          <Form.Row>
                            <Form.Label className="font-size-15">
                              Tag Type
                            </Form.Label>
                          </Form.Row>
                          <Form.Row
                            className="more-checkboxes"
                            id="tag-type-selector"
                          >
                            {huntingGeneralTagCatalog.map((e) => {
                              return (
                                <Col xs="6" key={e.tagTypeID}>
                                  <Form.Check
                                    type="checkbox"
                                    id={e.tagTypeLabel.replace(/ /g, "")}
                                    label={e.tagTypeLabel}
                                  />
                                </Col>
                              );
                            })}
                          </Form.Row>
                          <Dropdown.Divider className="mt-4" />
                          <Form.Row>
                            <Form.Label className="font-size-15">
                              Hunting Method
                            </Form.Label>
                          </Form.Row>
                          <Form.Row
                            className="more-checkboxes"
                            id="hunting-method-selector"
                          >
                            {huntingMethodTagCatalog.map((e) => {
                              return (
                                <Col xs="6" key={e.tagId}>
                                  <Form.Check
                                    type="checkbox"
                                    id={e.tagName.replace(/ /g, "")}
                                    label={e.tagName}
                                  />
                                </Col>
                              );
                            })}
                          </Form.Row>
                          <Dropdown.Divider className="mt-4" />
                          <Form.Row className="pt-2 pr-2">
                            <Form.Label className="font-size-15">
                              Duration
                            </Form.Label>
                            <Form.Control
                              as="select"
                              className="huntingPLP-select"
                              placeholder="Select Numer of Hunters"
                              name="days-of-duration-selector"
                            >
                              <option value="">Select Days Of Duration</option>
                              {huntingDurationTagCatalog.map((e) => {
                                return (
                                  <option key={e.tagId} value={e.tagName}>
                                    {e.tagName}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Row>
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-4 mb-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {target === "fishing" && (
              <>
                {/* <img width={100} src={algoliaIcon} /> */}
                <Row>
                  <Col md={4} className="d-none d-lg-block">
                    <Form.Control
                      className="font-weight-bold searchbar-another-species-fishing"
                      type="text"
                      placeholder="Add another species"
                      onFocus={() => {
                        setCollapseTimeFrame(false);
                        setCollapsePrice(false);
                        setCollapseLocation(false);
                        setCollapseMore(false);
                      }}
                      onChange={(e) => {
                        let term = e.target.value;
                        if (term.trim().length > 0) {
                          setAnotherSearchTemp(term);
                        }
                      }}
                      onKeyPress={handleSubmitSearchTerm}
                    />
                  </Col>

                  <Col md={2} className="">
                    <Form.Control
                      className="font-weight-bold searchbar-party-size-fishing"
                      type="number"
                      readOnly={true}
                      name="partySize"
                      maxValue={6}
                      value={labelsInputHunting.partySize}
                      placeholder="Party Size"
                      onFocus={(e) => {
                        setCollapsePartySize(true);
                        setCollapseTimeFrame(false);
                        setCollapsePrice(false);
                        setCollapseLocation(false);
                        setCollapseMore(false);
                      }}
                    />
                    <div className="collapse-form-container collapse-form-party-size">
                      <Collapse
                        in={collapsePartySize}
                        className="collapse-form arrow_box"
                      >
                        <Form
                          className="pl-2 pr-2"
                          type="number"
                          onSubmit={(e) => handleSubmitPartySize(e)}
                          name="partySizeFishing"
                        >
                          <p className="mt-2 ">Party Size</p>
                          <Row className="">
                            {[1, 2, 3].map((e) => {
                              return (
                                <Col key={`${e}--filterParty`}>
                                  <Form.Check
                                    label={e}
                                    name="partySize.quantity"
                                    type="radio"
                                    value={e}
                                    as="input"
                                    onClick={(e) => {
                                      setPartySize(e.target.value);
                                    }}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                          <Row className="">
                            {[4, 5, 6].map((e) => {
                              return (
                                <Col key={`${e}--filterParty`}>
                                  <Form.Check
                                    label={e}
                                    name="partySize.quantity"
                                    type="radio"
                                    value={e}
                                    as="input"
                                    onClick={(e) => {
                                      setPartySize(e.target.value);
                                    }}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>

                  <Col md={2} className="d-none d-lg-block">
                    <Form.Control
                      className="font-weight-bold searchbar-time-frame-fishing"
                      type="text"
                      name="timeFrame"
                      value={labelsInputHunting.timeFrame}
                      placeholder="Time Frame"
                      readOnly={true}
                      onClick={(e) => {
                        setCollapseTimeFrame(true);
                        setCollapsePartySize(false);
                        setCollapsePrice(false);
                        setCollapseLocation(false);
                        setCollapseMore(false);
                      }}
                    />

                    <div className="collapse-form-container collapse-form-time-frame">
                      <Collapse
                        in={collapseTimeFrame}
                        className="collapse-form arrow_box"
                      >
                        <Form
                          className="pl-2 pr-2"
                          onSubmit={handleSubmitTimeFrame}
                          name="timeFrameHunting"
                        >
                          <p className="mt-2 ">Time Frame</p>
                          <Form.Row>
                            <Col md="6">
                              <Form.Control
                                name="timeFrame.season"
                                as="select"
                                className="huntingPLP-select"
                              >
                                <option value="Fall">Fall</option>
                                <option value="Winter">Winter</option>
                                <option value="Summer">Summer</option>
                                <option value="Spring">Spring</option>
                              </Form.Control>
                            </Col>
                            <Col md="6">
                              <Form.Control
                                name="timeFrame.year"
                                as="select"
                                className="huntingPLP-select"
                              >
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                              </Form.Control>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>

                  <Col md={2} className="d-none d-lg-block">
                    <Form.Control
                      type="text"
                      className="font-weight-bold searchbar-price-range search-input-hunter"
                      placeholder="Price Range"
                      value={labelsInputHunting.priceRange}
                      readOnly={true}
                      onFocus={() => {
                        setCollapseTimeFrame(false);
                        setCollapsePrice(true);
                        setCollapseLocation(false);
                        setCollapseMore(false);
                        setCollapsePartySize(false);
                      }}
                    />
                    <div className="collapse-form-container collapse-form-price">
                      <Collapse
                        in={collapsePrice}
                        className="collapse-form arrow_box"
                      >
                        <Form
                          className="pl-2 pr-2"
                          name="priceRange"
                          onSubmit={handleSubmitPrice}
                        >
                          <p className="mt-2 ">Price Range</p>
                          <Form.Row>
                            <Col md={5}>
                              <Form.Control
                                inline="true"
                                type="text"
                                placeholder="Min"
                                className="huntingPLP-input"
                                name="priceRange.min"
                              />
                            </Col>
                            <Col md={1}>
                              <div className="mt-2">
                                <span className="font-weight-bold pt-2 mr-1 ml-1">
                                  -
                                </span>
                              </div>
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                inline="true"
                                type="text"
                                placeholder="Max"
                                className="huntingPLP-input"
                                name="priceRange.max"
                              />
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>

                  <Col md={2} className="d-none d-lg-block">
                    <Form.Control
                      className="font-weight-bold searchbar-location search-input-hunter"
                      type="text"
                      value={labelsInputHunting.location}
                      placeholder="Location"
                      readOnly={true}
                      onFocus={() => {
                        setCollapseTimeFrame(false);
                        setCollapsePrice(false);
                        setCollapseLocation(true);
                        setCollapseMore(false);
                        setCollapsePartySize(false);
                      }}
                    />

                    <div className="collapse-form-container collapse-form-container-center collapse-form-location">
                      <Collapse
                        in={collapseLocation}
                        className="collapse-form arrow_box-center"
                      >
                        <Form
                          className="pl-3 pr-2"
                          onSubmit={handleSubmitLocation}
                          name="locationHunting"
                        >
                          <p className="mt-2 ">Search Location</p>
                          <Form.Row>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder="Zipcode, City, State"
                                className="huntingPLP-search pl-5"
                                name="location_text_input"
                              />
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <p className="mt-3">Popular Locations</p>
                          </Form.Row>
                          <Form.Row id="popular-locations">
                            <Col>
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Colorado"
                                label="Colorado"
                              />
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Florida"
                                label="Florida"
                              />
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Alaska"
                                label="Alaska"
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Wyoming"
                                label="Wyoming"
                              />
                              <Form.Check
                                name="location_hunting_cb"
                                type="checkbox"
                                id="Maine"
                                label="Maine"
                              />
                            </Col>
                          </Form.Row>
                          {/* <Form.Row>
                        <p className="primary-color mt-2">More Locations &gt;</p>
                      </Form.Row> */}
                          <Form.Row>
                            <Col className="d-flex flex-row-reverse mt-3">
                              <Button variant="guidex" type="submit">
                                Done
                              </Button>
                            </Col>
                          </Form.Row>
                        </Form>
                      </Collapse>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {listButtons.length > 0 && (
              <Row noGutters className="mt-2">
                {listButtons.map((btn, i) =>
                  (() => {
                    switch (btn.param) {
                      case "species":
                      case "searchTerm":
                      case "locationCB":
                      case "tagType":
                      case "huntingMethod":
                        let buttons = [];
                        buttons = btn.value.map((v, iV) => {
                          return (
                            <Button
                              variant="hunting"
                              className="mr-2"
                              key={`searchParam_${i}_${iV}`}
                            >
                              {v}{" "}
                              <BsX
                                className="clear-filter"
                                onClick={() => {
                                  clearSelectedFilter(btn.param, iV);
                                }}
                              ></BsX>
                            </Button>
                          );
                        });
                        return buttons;
                      case "timeFrame":
                      case "priceRange":
                      case "partySize":
                      case "locationManual":
                      case "huntingDifficulty":
                      case "daysOfDuration":
                        return (
                          <Button
                            variant="hunting"
                            className="mr-2"
                            key={`searchParam_${i}`}
                          >
                            {btn.value}{" "}
                            <BsX
                              className="clear-filter"
                              onClick={() => {
                                clearSelectedFilter(btn.param, null);
                              }}
                            ></BsX>
                          </Button>
                        );
                      default:
                        return <></>;
                    }
                  })()
                )}
                <p
                  className="ml-3 hunting-clear"
                  onClick={() => {
                    clearAllFilters();
                  }}
                >
                  Clear all
                </p>
              </Row>
            )}
            <div className="d-flex d-md-none flex-row-reverse bd-highlight mt-5">
              <Button onClick={() => setisOpen(false)} id="apply-btn-plp">
                Apply
              </Button>
              <Button
                onClick={() => {
                  setisOpen(false);
                  clearAllFilters();
                }}
                id="cancel-btn-plp"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default SearchBarPLP;
