import axios from "axios";
import { app } from "../FirebaseConfig";

const OK = "OK";
const ERROR = "ERROR";
const UNDEFINED = "UNDEFINED";

const endpointsCollection = {
  "guidex-api": process.env.REACT_APP_GUIDEX_API_ENDPOINT,
  "mapbox-api": process.env.REACT_APP_MAPBOX_API_ENDPOINT
};

const request = async ({
  method = "GET",
  url,
  trailingURL,
  body = {},
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  },
  requiresAuth = false,
}) => {
  const endpoint = endpointsCollection[url] ?? url;
  const targetUrl = endpoint + trailingURL;

  if (requiresAuth) {
    const idToken = await app.auth().currentUser.getIdToken(true);
    config.headers["Authorization"] = `Bearer ${idToken}`;
  }

  switch (method) {
    case "GET":
      const getResponseReq = await axios.get(targetUrl, config);
      return processResponse(getResponseReq);
    case "POST":
      const postResponse = await axios.post(targetUrl, body, config);
      return processResponse(postResponse);
    case "PUT":
      const putResponse = await axios.put(targetUrl, body, config);
      return processResponse(putResponse);
    case "DELETE":
      const deleteResponse = await axios.delete(targetUrl, config);
      return processResponse(deleteResponse);
    case "PATCH":
      const patchResponse = await axios.patch(targetUrl, body, config);
      return processResponse(patchResponse);
    case "HEAD":
      const headResponse = await axios.head(targetUrl, body, config);
      return processResponse(headResponse);
    default:
      const optionsResponse = await axios.options(targetUrl, body, config);
      return processResponse(optionsResponse);
  }
};

const processResponse = (promiseResponse) => {
  try {
    /**
     * Request was succesfully processed and has an
     * status code of 2XX
     */
    const response = {
      status: OK,
      ...promiseResponse,
    };
    return response;
  } catch (error) {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      const { status } = error.response;
      let errors = error.response.data;
      let message = "";
      switch (status) {
        case 400:
          message = "Request sent by the client is not correct.";
          break;
        case 401:
          message = "You require authentication to make this request.";
          break;
        case 403:
          message = "You have no permission to access this resource.";
          break;
        case 404:
          message = "The resource you are searching for does't exists.";
          break;
        case 500:
          message = "Service error.";
          break;
        case 503:
          message = "Service is not currently available.";
          break;
        default:
          message = `Error ${status}. Please contact the system administrator.`;
      }

      const response = {
        status: ERROR,
        errors,
        message,
      };
      return response;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      const response = {
        status: ERROR,
        errors: error.request,
        message: "Request error",
      };
      return response;
    } else {
      // Something happened in setting up the request and triggered an Error
      const response = {
        status: UNDEFINED,
        errors: error,
        message: error.message,
      };
      return response;
    }
  }
};

export { request };
