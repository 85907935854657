import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Separator from "../../../../utils/Separator";

function SettingsChangePassword(props) {

  const { onReturn } = props;

  return (
    <Container fluid>
      <Row className="mx-0 mb-2 align-items-center">
        <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
          Change Password
        </h2>
        <Separator size="201px" color="#EC7228" />

        <p className="card-text font-size-15 mt-2">
        Instructions to reset your password has been sent to your email address.
        </p>
      </Row>
      {/* <Row className="mt-2">
        <Col>
          <Form>
            <Form.Label className="mt-4">Current Password</Form.Label>
            <Form.Control className="background-guidex"></Form.Control>
            <Form.Label className="mt-4">New Pasword</Form.Label>
            <Form.Control className="background-guidex"></Form.Control>
            <Form.Label className="mt-4">Re-enter New Password</Form.Label>
            <Form.Control className="background-guidex"></Form.Control>
          </Form>
        </Col>
      </Row> */}
      <Row>
        <Col className="d-flex justify-content-end mt-5 mb-5">
          <Button variant="guidex" onClick={onReturn}>Go Back</Button>
        </Col>
      </Row>
    </Container>
  );
}
export default SettingsChangePassword;
