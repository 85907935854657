import React, { useMemo, useState } from "react";
import { Button, Image, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useDropzone } from "react-dropzone";
import { showToast } from "../../../../utils/Misc";
import { request } from "../../../../utils/HttpHelper";
import { AiFillWarning } from "react-icons/ai";
import Separator from "../../../../utils/Separator";
import downloadIcon from "../../../../assets/icons/download.svg";
import "./AddImage.css";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "50px",
  borderWidth: 2,
  borderRadius: 2,

  border: "3px",
  borderStyle: " dashed",
  borderColor: "#466660",
  backgroundColor: "white",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};
function EditImage(props) {
  const { galleryId, selectedImage, onCancel, onSubmit } = props;
  const [files, setFiles] = useState([]);
  const [flag, setFlag] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
      setFlag(false);
    }
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const images = files.map(file => (
    <div className="row-images" key={file.name}>
      <img src={file.preview} style={{ width: "150px" }} alt="preview"></img>
    </div>
  ));

  const replaceImage = async () => {
    try {
      if(files.length === 0) return;
      setIsDisabled(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("galleryID", galleryId.toString());
      formData.append("galleryImageID", selectedImage.toString());
      let result = await request({
        method: 'POST',
        url: 'guidex-api',
        trailingURL: "/gallery-transact",
        body: formData,
        config:{
          headers: {
            "Content-Type": 'multipart/form-data'
          }
        },
        requiresAuth: true
      });
      console.log(result.body);
      setIsDisabled(false);
      onSubmit();
    } catch (error) {
      console.error(error);
      setIsDisabled(false);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to upload your images.
        </>,
        "error"
      );
    }
  }

  return (
    <Container fluid>
      <Row className="mx-0 mb-2 align-items-center">
        <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
          Replace the selected image
        </h2>
        <Separator size="331px" color="#EC7228" />

        <p className="card-text font-size-15 mt-2"></p>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end mt-5 mb-5">
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <>
                <Image className="download-icon" src={downloadIcon} />
                <p className="drag-text mt-4 mb-5">Drag and Drop An Image</p>
              </>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            type="button"
            variant="add-image"
            onClick={open}
            className="mb-5 mr-3"
            disabled={isDisabled}
          >
            Browse Files
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {!flag && <div>{images}</div>}
        </Col>
      </Row>
        {!flag && (
          <Row>
            <Col>
              <Button
                type="button"
                variant="cancel"
                onClick={onCancel}
                className="mb-5"
                disabled={isDisabled}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="guidex"
                onClick={replaceImage}
                className="mb-5"
                disabled={isDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        )}
    </Container>
  );
}
export default EditImage;
