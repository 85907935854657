import React, { useState, useContext } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row, Modal, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Separator from "../../utils/Separator";
import { useMediaQuery } from "react-responsive";
import { User } from "../../utils/UserProvider";
import { request } from "../../utils/HttpHelper";
import { app } from "../../FirebaseConfig";

const ContactUsForm = props => {

  window.scrollTo(0, 0);
  const { targetMessage } = props;
  const { user } = useContext(User);
  const [error, setError] = useState();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const database = app.database();

  const startConversation = async (values, setSubmitting) => {
    try {
      setSubmitting(true);
      if (targetMessage) {
        let senderRoleTypeId = 0;
        let recipientRoleTypeId = 0;
        if (user) {
          const { otherRoles, selectedRole } = user;
          let selRoleData = otherRoles.find((r) => r.roleName === selectedRole);

          switch (selectedRole) {
            case "HUNTER":
              senderRoleTypeId = 1;
              break;
            case "FISHERMAN":
              senderRoleTypeId = 2;
              break;
            case "STAFF":
              senderRoleTypeId = 3;
              break;
            default:
              senderRoleTypeId = 4;
          }
          switch (targetMessage.guideType) {
            case "guide_hunter":
              recipientRoleTypeId = 1;
              break;
            case "guide_fisherman":
              recipientRoleTypeId = 2;
              break;
            case "staff":
              recipientRoleTypeId = 3;
              break;
            default:
              recipientRoleTypeId = 4;
          }
          const newConversationData = {
            "sender": {
              "entityTypeID": senderRoleTypeId,
              "entityID": selRoleData.userId
            },
            "recipient": {
              "entityTypeID": recipientRoleTypeId,
              "entityID": targetMessage.guideId
            }
          };
          let newConversationResult = await request({
            method: "POST",
            url: 'guidex-api',
            trailingURL: "/conversation",
            body: newConversationData,
            requiresAuth: true
          });
          const { conversationUUID } = newConversationResult.data;
          const dt = new Date();
          database.ref(conversationUUID).set({
            "lastTimeUpdated": dt.toISOString(),
            "participants": [
              {
                "id": 1,
                "roleType": senderRoleTypeId,
                "roleId": selRoleData.userId,
                "name": selRoleData.fullName,
                "unread": 0,
                "tripId": targetMessage.tripId,
                "guideType": targetMessage.guideType
              },
              {
                "id": 2,
                "roleType": recipientRoleTypeId,
                "roleId": targetMessage.guideId,
                "name": targetMessage.guideName,
                "unread": 1,
                "tripId": targetMessage.tripId,
                "guideType": targetMessage.guideType
              }
            ],
            messages: [
              {
                "from": 1,
                "content": values.message,
                "datetime": dt.toISOString()
              }
            ]
          }).then(() => setTimeout(() => {
            history.push(`/${targetMessage.guideType == 'guide_hunter' ? 'hunting-trip' : 'guide-fisherman'}/${targetMessage.tripId}`)
          }, 2000))
            .catch(e => console.error(e));
        } else { 
          // This is for the case when the user is not logged in
          const contactData ={
            templateID: 7,
            params: `PHONE_NUMBER:${values.phone}/,/MESSAGE:${values.message}/,/CUSTOMER_NAME:${values.fullName}/,/CUSTOMER_EMAIL:${values.email}`
          }
          await request({
            method: "POST",
            url: 'guidex-api',
            trailingURL: `/send-template-email?guide_id=${targetMessage.guideId}&entity_type=${targetMessage.guideType === 'guide_fisherman' ? 'fishing' : 'hunting'}`,
            body: contactData
          })
            .then(() => setTimeout(() => { history.push(`hunting-trip/${targetMessage.tripId}`) }, 2000));
        }
      } else { 
        // This is for the case when the user want to contact the admin
        const contactData ={
          email: "guided-expeditions@gmail.com",// here i have to put the email of the admin
          templateID: 7,
          params: `PHONE_NUMBER:${values.phone}/,/MESSAGE:${values.message}/,/CUSTOMER_NAME:${values.fullName}/,/CUSTOMER_EMAIL:${values.email}`
        }
        await request({
          method: "POST",
          url: 'guidex-api',
          trailingURL: "/send-template-email",
          body: contactData
        });
      }
      setShowModal(true);
      setSubmitting(false);
      setTimeout(() => {
        history.push("/")
      }, 2000)
    } catch (error) {
      console.error(error);
      setError("There is a problem submitting your message.");
      setSubmitting(false);
    }
  }

  return (
    <>
      {!showModal &&
        <>
          <div className="mb-3 d-flex flex-column" style={{width: "450px"}}>
            <IoClose
              className="ml-auto mb-3 font-size-20"
              //Here should make a conditional for fishing charters
              onClick={() => history.push(`/`)}
              cursor="pointer"
            />
            <Row noGutters className="mx-0 mb-3 align-items-center">
              <Col xs="auto" md="auto" className="mr-3">
                <h2 className="primary-color font-size-30 font-weight-bold">
                  Let's Chat
                </h2>
              </Col>
              <Col>
                <Separator color="#EC7228" />
              </Col>
            </Row>
            {
              targetMessage != null ?
                (
                  <>
                    {
                      user != null ?
                        <>
                          <p className="mb-0 font-size-15">
                            Use the following form to contact this guide.
                          </p>
                        </>
                        :
                        <>
                          <p className="mb-0 font-size-15">
                            Please login so you can keep this message for future reference.
                          </p>
                          <p>
                            <Link to="/login" className="guidex-link primary-color">
                              Login
                            </Link>
                          </p>
                        </>
                    }
                  </>
                ) : (
                  <>
                    <p className="mb-0 font-size-15">
                      We'd love to hear from you! Send us a message using the form
                      below, or email us.
                    </p>
                    <p>
                      <a href="mailto:guidedexpeditions@gmail.com" className="primary-color">guidedexpeditions@gmail.com</a>
                    </p>
                  </>
                )}
          </div>

          <Formik
            initialValues={{
              fullName: "",
              email: "",
              phone: "",
              message: ""
            }}
            validate={values => {
              const errors = {};
              if (user == null) {
                if (!values.fullName) {
                  errors.fullName = "Required";
                }
                if (!values.phone) {
                  errors.phone = "Required";
                }
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
              }
              if (!values.message) {
                errors.message = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              startConversation(values, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {
                  user == null ?
                    <>
                      <Form.Group controlId="formBasicFullName">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullName}
                          placeholder="First and last name"
                          isInvalid={!!errors.fullName}
                          isValid={touched.fullName && !errors.fullName}
                        />
                        <Form.Control.Feedback className="mt-2" type="invalid">
                          {errors.fullName}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Email address"
                          isInvalid={!!errors.email}
                          isValid={touched.email && !errors.email}
                        />
                        <Form.Control.Feedback className="mt-2" type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formBasicPhone">
                        <Form.Label className="d-flex justify-content-between">
                          Phone<span>Optional</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          placeholder="Phone Number"
                          isInvalid={!!errors.phone}
                          isValid={touched.phone && !errors.phone}
                        />
                        <Form.Control.Feedback className="mt-2" type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </> : <></>
                }

                <Form.Group controlId="formBasicMessage">
                  <Form.Label className="d-flex justify-content-between">
                    How can we help you? <span>Max. 500 Characters</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    placeholder="Message"
                    isInvalid={!!errors.Message}
                    isValid={touched.Message && !errors.Message}
                  />
                  <Form.Control.Feedback className="mt-2" type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <div
                  className={`${error ? 'd-block' : 'd-none'
                    } invalid-feedback`}
                >
                  {error}
                </div>
                <div className="d-flex flex-row-reverse">
                  <Button
                    variant={!isMobile ? "guidex mt-4" : "submit-mobile mb-4 mt-2"}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>}
      {showModal &&
        // Renders a thank you for contacting
        <div>
          <div className="d-flex justify-content-end">
            <IoClose
              className="font-size-20"
              //Here should make a conditional for fishing charters
              onClick={() => history.push(`/`)}
              cursor="pointer"
            />
          </div>
          <Row noGutters className="mx-0 mb-3 align-items-center" style={{width: '450px'}}>
            <Col xs="auto" md="auto" className="mr-3">
              <h2 className="primary-color font-size-30 font-weight-bold">
                Thank You
              </h2>
            </Col>
            <Col>
              <Separator color="#EC7228" />
            </Col>
          </Row>
          <p className="card-text font-size-15 mt-3">
            We have received your message and will contact you soon.
          </p>
        </div>
      }
    </>
  );
};
export default ContactUsForm;
