import algoliasearch from "algoliasearch";
import React, { useEffect, useState } from "react";
import {
  Collapse,
  Dropdown,
  Image,
  ListGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import { useHistory } from "react-router-dom";
import deer from "../../assets/icons/Icon-Deer.svg";
import fish from "../../assets/icons/Icon-Fish.svg";
import "../../index.css";
import Capitalize from "../../utils/capitalize";
import "./SearchBar.css";
//import algoliaIcon from "../../assets/icons/search-by-algolia-light-background.svg";

export default function SearchBarContainer(props) {
  const [background, setBackground] = useState("imageBackgroundHome");
  // TODO: implement other way to set and get the status
  const [tabStatus, setTabStatus] = useState("fishing");
  const [selectedFilters, setSelectedFilters] = useState("all");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [isSearching, setSearchStatus] = useState(false);
  const [to, setTO] = useState(0);

  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    if (searchTerm.trim().length > 0) {
      history.push(
        `/search?query=${searchTerm}&target=${tabStatus}&category=${selectedFilters}`
      );
    }
  };

  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );
  
  // Depending on the tab selected and the filters selected, we will pull the information from different indexes
  const index = searchClient.initIndex(tabStatus == "fishing" ? "GUIDE_FISHER" : selectedFilters === 'guideBusinessName' ? "GUIDE_HUNTER" : "HUNTING_TRIP"  );
  
  const search = async (e) => {
    let val = e.target.value;
    if (val.trim().length > 2) {
      setSearchStatus(true);
      setTO(
        index
          .search(val)
          .then(({ hits }) => {
            console.log(hits)
            setResults(hits);
          })
          .catch((err) => {
            console.log(err);
          }),
          setSearchTerm(val),
          setSearchStatus(false)
      );
    }
  };
  
  const { onChangeTab, onLocationAllow } = props;

  useEffect(() => {
    if (tabStatus) {
      onChangeTab(tabStatus);
    }
  }, [tabStatus, onChangeTab]);

  const handleTargetTab = (index) => {
    if (index === "hunting") {
      setBackground("imageBackgroundHome");
      setTabStatus("hunting");
    }
    if (index === "fishing") {
      setBackground("imageBackgroundHome");
      setTabStatus("fishing");
    }
  };
  
  const handleFilterTab = (filter) => {
    setSelectedFilters(filter);
    setResults([]);
    
    if (filter !== "all") {
      index.setSettings({
        searchableAttributes: [filter],
      });
    } else {
      index.setSettings({
        searchableAttributes: [],
      });
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      id="dropdownFiltersHome"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  return (
    <>
      <div id={background}>
        <div id="imageBackgroundOpacity">
          <div className="search-bar-wrapper">
            <div className="search-bar-title">
              <p className="main-title">Your adventure starts here.</p>
              <p className="main-subtitle">
                Search for your next guided hunting or fishing expedition.
              </p>
            </div>
            <div className="search-bar-container">
              <div className="search-bar-box">
                <Tabs
                  defaultActiveKey="fishing"
                  id="searchbarTabs"
                  onSelect={handleTargetTab}
                >
                  <Tab
                    value={background}
                    eventKey="fishing"
                    title={
                      <div className="search-bar-tab-title">
                        <div
                          style={{
                            height: "44px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Image src={fish} width="46" height="44" />
                          <b className="ml-2 d-none d-md-block"> Fish</b>
                        </div>
                      </div>
                    }
                    tabClassName="search-bar-tab"
                  ></Tab>
                  <Tab
                    eventKey="hunting"
                    title={
                      <div className="search-bar-tab-title">
                        <div
                          style={{
                            height: "44px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Image src={deer} width="35" height="30" />
                          <b className="ml-2 d-none d-md-block"> Hunt</b>
                        </div>
                      </div>
                    }
                    tabClassName="search-bar-tab"
                  ></Tab>
                </Tabs>
                <div className="search-bar-body-container">
                  <div className="search-bar-body">
                    <Tabs
                      defaultActiveKey="all"
                      id="searchbarFilterTabs"
                      onSelect={handleFilterTab}
                      className="d-none d-md-flex"
                    >
                      <Tab
                        eventKey="all"
                        title="All"
                        tabClassName="search-option-tab"
                      ></Tab>
                      <Tab
                        eventKey={tabStatus === "fishing" ? "guideRegionOperating" : "location"}
                        title="Location"
                        tabClassName="search-option-tab"
                      ></Tab>
                      <Tab
                        eventKey={tabStatus === "fishing" ? "guideSpecies" : "species"}
                        title="Species"
                        tabClassName="search-option-tab"
                      ></Tab>
                      <Tab
                        eventKey="guideBusinessName"
                        title={tabStatus === "fishing" ? "Charter Name" : "Outfitter Name"}
                        tabClassName="search-option-tab"
                      ></Tab>
                    </Tabs>
                    <Dropdown className="d-flex d-md-none ml-2">
                      <Dropdown.Toggle as={CustomToggle}>
                        {selectedFilters === "guideBusinessName"
                          ? "Guide Name"
                          : Capitalize(selectedFilters)}{" "}
                        <IoMdArrowDropdown></IoMdArrowDropdown>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={handleFilterTab}
                          eventKey="all"
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={handleFilterTab}
                          eventKey="location"
                        >
                          Location
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={handleFilterTab}
                          eventKey="species"
                        >
                          Species
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={handleFilterTab}
                          eventKey="guideBusinessName"
                        >
                          Guide Name
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <form onSubmit={handleSubmit}>
                      <div className="searchbar-wrapper">
                        <input
                          className="d-none d-md-block"
                          type="text"
                          placeholder={`Search by location, species, or ${tabStatus === "fishing" ? "charter name" : "outfitter name"}`}
                          onChange={search}
                          onClick={() => setOpen(true)}
                        />
                        <input
                          className="d-block d-md-none"
                          type="text"
                          placeholder="Search..."
                          onChange={search}
                          onClick={() => setOpen(true)}
                        />
                      </div>
                    </form>
                    <Collapse in={open}>
                      <ListGroup className="text-left search-bar-suggestions-list">
                        <p className="search-bar-list-title mt-3 mb-1">
                          {isSearching ? "Searching..." : "Suggested Search"}
                        </p>
                        {results.map((hit, i) => i < 3 && (
                          /*
                          Depending in the case of the selected filter and tab, the hit will be pulled from different Indexes in Algolia. and it will
                          send the user to different pages. 
                          */
                          <ListGroup.Item
                            onClick={() => {
                              history.push(
                                tabStatus == "fishing" ? `/guide-fisherman/${hit.guideFisherID}` : (selectedFilters === "guideBusinessName" ? `/guide-hunter/${hit.guideHunterID}`:
                                  `/hunting-trip/${hit.huntingTripID}`)
                              );
                            }}
                            action
                            variant="light"
                            key={hit.huntingTripID}
                            className="text-capitalize"
                          >
                            {tabStatus == "fishing" ? hit.guideBusinessName : selectedFilters === "guideBusinessName" ? hit.guideBusinessName : hit.huntingTripName }
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
