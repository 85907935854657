import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from "react-bootstrap";
import { Formik } from "formik";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";

const RequestReview = (props) => {
  
  const { entityTypeId, onReturn } = props;

  const requestReview = async (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "POST",
          url: "guidex-api",
          body: body,
          requiresAuth: true,
          trailingURL: `/reviews`,
        });
        resolve(result.status);
      } catch (error) {
        reject(error);
      }
    });
  };
  return (
    <>
      <Container fluid className="approve-guides-container mt-5">
        <Formik
            initialValues={{
              reviewEntityTypeId: entityTypeId,
              reviewAbout: "General",
              reviewRequesteeName: "",
              reviewRequesteePhone: "",
              reviewRequesteeEmail: "",
              reviewRequesterMessage: "",
              reviewTarget: 0,
              wasSuccessful: "no",
              wasAsAdvertised: "no",        
              smsCheck: false,
              emailCheck: true
            }}
            validate={(values) => {
              const errors = {};
              // if (!values.reviewAbout) {
              //   errors.reviewAbout = "Required";
              // }
              if (!values.reviewRequesteeName) {
                errors.reviewRequesteeName = "Required";
              }
              if (!values.reviewRequesteePhone) {
                errors.reviewRequesteePhone = "Required";
              }
              if (!values.reviewRequesteeEmail) {
                errors.reviewRequesteeEmail = "Required";
              }
              if (values.reviewRequesteeEmail){
                const regex = /\S+@\S+\.\S+/;
                if(!regex.test(values.reviewRequesteeEmail)) errors.reviewRequesteeEmail = "Should enter a valid email address" ;
              }
              if (!values.reviewRequesterMessage) {
                errors.reviewRequesterMessage = "Required";
              }
              return errors;
            }}
            onSubmit={ (values, { setSubmitting }) => {
              setSubmitting(true);
              console.dir(values);
              requestReview(values).then((e) => {
                if (e === 201) {
                  setSubmitting(false);
                  showToast(
                    <>
                      <AiFillCheckCircle /> Review created correctly!
                    </>,
                    "success"
                  );
                }
                onReturn();
              }).catch( err => {
                setSubmitting(false);
                showToast(
                  <>
                    <AiFillWarning /> There was a problem trying to send your request
                  </>,
                  "error"
                );
              })
            }}
            onReset={( values ) => {
              onReturn();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
            }) => (
            <Form noValidate onSubmit={handleSubmit} onReset={handleReset}>
              <div>
                <Row>
                  <Col>
                    <p className="font-weight-bold font-size-18">Request Review</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={5}>
                    <Form.Label>Review About</Form.Label>
                    <FormControl
                      readOnly={true}
                      type="text"
                      name="reviewAbout"
                      className="review-forms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reviewAbout}
                      isInvalid={!!errors.reviewAbout}
                      //isValid={touched.reviewAbout && !errors.reviewAbout}
                    />
                    <Form.Control.Feedback
                      className="mt-2"
                      type="invalid"
                    >
                      {errors.reviewAbout}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={1}></Col>
                  <Col xs={5}>
                    <Form.Label>Customer Name</Form.Label>
                    <FormControl
                      type="text"
                      name="reviewRequesteeName"
                      className="review-forms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reviewRequesteeName}
                      isInvalid={!!errors.reviewRequesteeName}
                      isValid={touched.reviewRequesteeName && !errors.reviewRequesteeName}
                    />
                    <Form.Control.Feedback
                      className="mt-2"
                      type="invalid"
                    >
                      {errors.reviewRequesteeName}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={5}>
                    <Form.Label>Phone Number</Form.Label>
                    <FormControl
                      type="text"
                      name="reviewRequesteePhone"
                      className="review-forms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reviewRequesteePhone}
                      isInvalid={!!errors.reviewRequesteePhone}
                      isValid={touched.reviewRequesteePhone && !errors.reviewRequesteePhone}
                    />
                    <Form.Control.Feedback
                      className="mt-2"
                      type="invalid"
                    >
                      {errors.reviewRequesteePhone}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={1}></Col>
                  <Col xs={5}>
                    <Form.Label>Email Address</Form.Label>
                    <FormControl
                      type="text"
                      name="reviewRequesteeEmail"
                      className="review-forms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reviewRequesteeEmail}
                      isInvalid={!!errors.reviewRequesteeEmail}
                      isValid={touched.reviewRequesteeEmail && !errors.reviewRequesteeEmail}
                    />
                    <Form.Control.Feedback
                      className="mt-2"
                      type="invalid"
                    >
                      {errors.reviewRequesteeEmail}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={11}>
                    <div className="d-flex justify-content-between">
                      <Form.Label>Personal Message to Customer</Form.Label>
                      <span className="review-max-characters">
                        Max of 100 characteres(optional)
                      </span>
                    </div>
                    <FormControl
                      type="text"
                      name="reviewRequesterMessage"
                      className="review-form-textarea"
                      placeholder="Enter Personal Message"
                      as="textarea"
                      rows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reviewRequesterMessage}
                      isInvalid={!!errors.reviewRequesterMessage}
                      isValid={touched.reviewRequesterMessage && !errors.reviewRequesterMessage}
                    />
                    <Form.Control.Feedback
                      className="mt-2"
                      type="invalid"
                    >
                      {errors.reviewRequesterMessage}
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
                {/*<Row className="mt-4">
                  <Col>
                    <div className="mb-3">
                      <Form.Check
                        inline
                        label="send by text message"
                        type="checkbox"
                        name="smsCheck"
                        checked={values.smsCheck}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Form.Check
                        inline
                        label="send by email"
                        type="checkbox"
                        name="emailCheck"
                        checked={values.emailCheck}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>
            </Row>*/}
              </div>
              <Row>
                <Col className="d-flex justify-content-end mt-2">
                  <Button variant="cancel" type="reset" className="mr-4" disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="send" className="mr-5" disabled={isSubmitting}>
                    Send
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};
export default RequestReview;
