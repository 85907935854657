import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import mainPoint1 from "../../assets/others/main_point_1.png";
import mainPoint2 from "../../assets/others/main_point_2.png";
import mainPoint3 from "../../assets/others/main_point_3.png";
import FloatingCard from "../../common/FloatingCard";
import "./GuideInfo.css";

export default function GuideInfo() {
  window.scrollTo(0, 0);

  return (
    <>
      <div>
        <div id="imageBackgroundGuideInfo" className="page-title">
          <h1 className="become-guide-title font-family-Montserrat">
            Become a Guide
          </h1>
          <div className="mb-5">
            <Link to="/guide-registration">
              <Button variant="guidex" size="guidex-lg">
                Register here
              </Button>
            </Link>
          </div>
        </div>
        <div className="justify-content-center guide-main-points px-3 pt-4">
          <FloatingCard
            title="Teddy Roosevelt"
            text='"In a civilized and cultivated country, wild animals only continue to exist at all when preserved by sportsmen"'
            background="floating-card"
            textColor="floating-text font-size-30 font-family-Montserrat"
            lineColor="#FFFFFF"
          />
          <Container fluid className="transparen-background">
            <Container className="transparen-background">
              <Row className="justify-content-between d-flex px-0">
                <Col sm={12} md={6} className="mb-3 px-0" style={{ maxWidth: "360px" }}>
                  <Card
                    className="border-0 shadow-card"
                    style={{ minHeight: "33rem", height: "100%" }}
                  >
                    <Card.Img variant="top" src={mainPoint1} />
                    <Card.Body>
                      <Card.Title>Lead Generation vs Booking</Card.Title>
                      <Card.Text>
                        We are not a booking agency. We don't charge our guides
                        a percentage of the hunt price and we don't interfere in
                        how our guides run their business. We provide an online
                        experience where users can find our registered guides,
                        read objective reviews, and see available expeditions
                        all at their fingertips. We are the guided outdoor
                        marketplace, increasing visibility on a nationwide scale
                        and generating consistent leads for our guides.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} className="mb-3 px-0" style={{ maxWidth: "360px" }}>
                  <Card
                    className="border-0 shadow-card"
                    style={{ minHeight: "33rem", height: "100%" }}
                  >
                    <Card.Img variant="top" src={mainPoint2} />
                    <Card.Body>
                      <Card.Title>Review Protection</Card.Title>
                      <Card.Text>
                        We know you can't control the weather, amongst other
                        things, and we don't want to provide another avenue for
                        slanderous reviews. That's why the reviews have to be
                        solicited by you. You send an invitation to clients of
                        your choosing to provide reviews. Any negative review
                        will be sent to you first for a response.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={4} className="mb-3 d-lg-block d-none px-0" style={{ maxWidth: "360px" }}>
                  <Card
                    className="border-0 shadow-card"
                    style={{ minHeight: "33rem", height: "100%" }}
                  >
                    <Card.Img variant="top" src={mainPoint3} />
                    <Card.Body>
                      <Card.Title>Great Customer Service</Card.Title>
                      <Card.Text>
                        We will help you build your profile, manage your marketing share, review your annual return on investment, and make recommendations to increase your lead flow.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="d-flex justify-content-center pt-5 pb-5">
                <Link to="/guide-registration">
                  <Button variant="guide-register" size="guidex-sm">
                    Register here
                  </Button>
                </Link>
              </div>
            </Container>
          </Container>
        </div>
      </div>
    </>
  );
}
