import algoliasearch from "algoliasearch";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import Trips from "../../common/Trips";
import { request } from "../../utils/HttpHelper";
import Carrousel from "../Home/Carrousel";
import FeaturedExpeditions from "../Home/FeaturedExpeditions";
import PopularFeatures from "../HuntingTrip/PopularFeatures";
import "./GuideProfile.css";

const Gallary = lazy(() => import("../Home/Gallary"));
export default function GuideProfileHunting() {
  const history = useHistory();

  const [results, setResults] = useState([]);
  const [guide, setGuide] = useState();
  const [rating, setRating] = useState();
  const [gallery, setGallery] = useState();
  const [tags, setTags] = useState();

  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const index = searchClient.initIndex("HUNTING_TRIP");

  const { id } = useParams();

  const search = () => {
    index
      .search("", {
        filters: `guideId:${id}`,
        hitsPerPage: 3,
      })
      .then(({ hits }) => {
        console.log(hits);
        setResults(hits);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const processGuide = async () => {
    getHuntingTripById().then((res) => {
      console.dir(res);
      setGuide({ ...res.guidePublic });
      setRating({ ...res.rating });
      setGallery({...res.guideGallery});
      setTags([...res.tagTypes]);
      console.dir(guide);
    });
  };

  const getHuntingTripById = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "GET",
          url: "guidex-api",
          trailingURL: `/guide-hunter?id=${id}`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (id) processGuide();
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(tags)
  const hardcodedTags = [
    {
      "tagID": 1,
      "tagName": "Rifle",
      "tagType": "Method",
      "tagMultiple": true
    },
    {
      "tagID": 5,
      "tagName": "License",
      "tagType": "General",
      "tagMultiple": true
    },
    {
      "tagID": 6,
      "tagName": "Lodging",
      "tagType": "General",
      "tagMultiple": true
    },
    {
      "tagID": 7,
      "tagName": "Food",
      "tagType": "General",
      "tagMultiple": true
    },
    {
      "tagID": 12,
      "tagName": "3 - day Hunt",
      "tagType": "Duration",
      "tagMultiple": false
    },
    {
      "tagID": 15,
      "tagName": "Easy Physical Level",
      "tagType": "Exertion",
      "tagMultiple": false
    }
  ]
  console.log(results)
  return (
    <>
      <Container fluid className="pdp px-0" id="imageBackgroundTopo">
        <div className="bg-green-light">
          <Container>
            <Row className="">
              <Col>
                <h5
                  onClick={() => {
                    history.goBack();
                  }}
                  className="back d-inline-block"
                >
                  <MdKeyboardArrowLeft size="20" /> Back to Results
                </h5>
                {!!guide && !!rating && !!gallery && (
                  <Trips
                    guide={guide}
                    rating={rating}
                    gallery={gallery}
                    page="guideProfile"
                    target="hunting"
                  ></Trips>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {!!tags && (
          <div className="bg-green-light py-5">
            <PopularFeatures tags={hardcodedTags}></PopularFeatures>
          </div>
        )}
        <Row className="noBackground">
          <Col>
            <FeaturedExpeditions
              results={results}
              background="noBackground"
              page="Guide"
            ></FeaturedExpeditions>
          </Col>
        </Row>
        {!!guide && !!rating && (
          <Row className="noBackground">
            <Col>
              <Carrousel guide={guide} rating={rating} page="guide"></Carrousel>
            </Col>
          </Row>
        )}

        <Suspense fallback={<>loading...</>}>
          <Row className="noBackground">
            <Col>
                <Gallary/>
            </Col>
          </Row>
        </Suspense>
      </Container>
    </>
  );
}
