import React from "react";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

export default function Separator({ color, size }) {
  const style = {
    width: size,
    backgroundColor: color,
    height: "1px",
  };
  return (
    <>
      <div style={style}></div>
    </>
  );
}
