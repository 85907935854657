import React from "react";
import About from "../components/About/About";
import {
  default as Contact,
  default as ContactUs
} from "../components/ContactUs/ContactUs";
import ContactGuide from "../components/Guide/ContacGuide";
import GuideInfo from "../components/Pages/GuideInfo";
import GuideProfileFisherman from "../components/Guide/GuideProfileFisherman";
import GuideProfileHunting from "../components/Guide/GuideProfileHunting";
import GuideRegistration from "../components/Register/GuideRegistration";
import Home from "../components/Home/Home";
//import NoMatch from "../components/Errors/NoMatch";
import HuntingTrip from "../components/HuntingTrip/HuntingTrip";
import Login from "../components/Login/Login";
import MultipleAccounts from "../components/Login/MultipleAccounts";
import FeaturedExpeditionsPage from "../components/Pages/FeaturedExpeditionsPage";
import TermsAndConditions from "../components/Pages/TermsAndConditions";
import PLP from "../components/PLP/PLP";
import Portal from "../components/Portal/Portal";
import RecoveryPassword from "../components/RecoveryPassword/RecoveryPassword";
import Register from "../components/Register/Register";
import MakeAReview from "../components/Pages/MakeAReview";
import SetPassword from "../components/RecoveryPassword/SetPassword";
export const routes = [
  {
    path: "/",
    exact: true,
    main: () => <Home />
  },
  {
    path: "/about",
    exact: true,
    main: () => <About />
  },
  {
    path: "/contact",
    exact: true,
    main: () => <Contact />
  },
  {
    path: "/login",
    exact: true,
    main: () => <Login />
  },
  {
    path: "/register",
    exact: true,
    main: () => <Register />
  },
  {
    path: "/forgot-password",
    exact: true,
    main: () => <RecoveryPassword />
  },
  {
    path: "/set-password",
    exact: true,
    main: () => <SetPassword/>
  },
  {
    path: "/guide-info",
    exact: true,
    main: () => <GuideInfo />
  },
  {
    path: "/guide-fisherman/:id",
    exact: true,
    main: () => <GuideProfileFisherman />
  },
  {
    path: "/guide-hunter/:id",
    exact: true,
    main: () => <GuideProfileHunting />
  },
  {
    path: "/contact-us",
    exact: true,
    main: () => <ContactUs />
  },
  {
    path: "/contact-guide",
    exact: true,
    main: () => <ContactGuide />
  },
  {
    path: "/multiple-accounts",
    exact: true,
    main: () => (
      <MultipleAccounts
        background="imageBackgroundSignin"
        margin="guidex-card"
      />
    )
  },
  {
    path: "/guide-registration",
    exact: true,
    main: () => <GuideRegistration />
  },
  {
    path: "/hunting-trip/:id",
    exact: true,
    main: () => <HuntingTrip />
  },
  {
    path: "/search",
    exact: true,
    main: () => <PLP />
  },
  {
    path: "/my-portal",
    exact: true,
    main: () => <Portal />
  },
  {
    path: "/terms-conditions",
    exact: true,
    main: () => <TermsAndConditions />
  },
  {
    path: "/featured-expeditions",
    exact: true,
    main: () => <FeaturedExpeditionsPage />
  },
  {
    path: "/make-a-review",
    exact: true,
    main: () => <MakeAReview />
  }
  // {
  //   path: "*",
  //   main: () => <NoMatch />,
  // }
];
