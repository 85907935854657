import React from "react";
import { Col, Row, Container, Table } from "react-bootstrap";

const ChangeReportStaff = () => {
  return (
    <>
      <h2 className="mb-5 approve-guides-title portal-section-title mt-5">
        Change Report
      </h2>

      <Container fluid className="approve-guides-container">
        <Row>
          <Col>
            <Table responsive borderless className="change-report-table">
              <thead>
                <tr>
                  <th> User</th>
                  <th>Date/Time</th>
                  <th>Change</th>
                  <th>Logged In As</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tm Clark</td>
                  <td>10/20/20 08:00:32AM</td>
                  <td>Change the email address</td>
                  <td>Brent´s Fishing Charters</td>
                </tr>
                <tr>
                  <td>Tm Clark</td>
                  <td>10/20/20 08:00:32AM</td>
                  <td>Change the email address</td>
                  <td>Brent´s Fishing Charters</td>
                </tr>
                <tr>
                  <td>Tm Clark</td>
                  <td>10/20/20 08:00:32AM</td>
                  <td>Change the email address</td>
                  <td>Brent´s Fishing Charters</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ChangeReportStaff;
