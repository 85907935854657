import React, { useEffect, useState, useContext } from "react";
import Loader from 'react-loader-spinner'
import GeneralDetails from "./GeneralDetails";
import Adresses from "../../Common/Profile/Addresses";
import "../../Common/Profile/Profile.css";
//import ContactList from "./ContactList";
import { showToast } from "../../../../utils/Misc";
import { AiFillWarning } from "react-icons/ai";
import { request } from "../../../../utils/HttpHelper";
import { Staff } from "../../../../utils/staffProvider";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const ProfileFisherman = ({ user, idUser }) => {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false);
  const [species, setSpecies] = useState([]);
  const [fisherGuideData, setFisherGuideData] = useState({
    billingAddress: {},
    businessAddress: {},
    guide: {
      useBusinessAsBilling: false,
    },
    tagTypes: [],
    licenses: []
  });
  const [tagTypeCat, setTagTypeCat] = useState([]);
  const { signInAs, guideType } = useContext(Staff)

  const queryParams = new URLSearchParams("?" + location.hash.split('?')[1]);
  const idLoginAs = queryParams.get("id");

  const getGuideFishermanData = (guideId) => {
    return new  Promise(async (resolve, reject) => {
      await request({
        url: 'guidex-api',
        trailingURL: `/guide-fisher${idLoginAs > 0 ? `?id=${idLoginAs}` : ""}`,
        requiresAuth: true
      })
        .then(result => {
          setFisherGuideData(result.data);
          resolve();
        })
        .catch(err => {
          reject(err)
        });
    })
  }

  const getTagTypeCatalog = () => {
    return new Promise((resolve, reject) => {
      request({
        url: 'guidex-api',
        trailingURL: `/tagtype?guide_type=Guide%20Fisherman`
      })
        .then(result => {
          setTagTypeCat(result.data['tagTypes'] || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
    });
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const fishermanRoleData = user.otherRoles.find(r => {
        return r.roleName === "FISHERMAN"
      });
      await getGuideFishermanData();
      await getTagTypeCatalog();
      await initSpiciesList();
    } catch (error) {
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch the data.
        </>,
        "warning"
      );
    }
    setIsLoading(false);
  }

  const initSpiciesList = async () => {
    let speciesList = await getSpeciesCatalogue();

    setSpecies(speciesList.data.map(region => {
      let item = {
        label: region.advName,
        key: region.advID
      };
      return item;
    }));
  }

  const getSpeciesCatalogue = () => {

    return new Promise(async (resolve, reject) => {
      try {
        let speciesResult = await request({
          url: 'guidex-api',
          trailingURL: `/adv-apply?advType=SPECIES&entity_type=fishing`,
          requiresAuth: true
        });
        resolve(speciesResult.data)
      } catch (error) {
        console.error(error);
      }
    })
  }
  
  useMemo(() => {
    fetchData();
  },[])
  // useEffect(() => {
    //fetchData();
    // eslint-disable-next-line
  // }, [])

  return (

    <div className="mb-5">
      {
        isLoading ?
          <center className="mt-5">
            <Loader
              type="TailSpin"
              color="#ec7228"
              height={150}
              width={150}
            />
          </center>
          :
          <>
            <GeneralDetails
              guideData={fisherGuideData.guide}
              tagTypesCat={tagTypeCat}
              tagTypes={fisherGuideData.tagTypes}
              species={species}
            />
            <Adresses
              businessAddress={fisherGuideData.businessAddress}
              billingAddress={fisherGuideData.billingAddress}
              guideType="FISHERMAN"
            //useBuAdasBiAd={hunterGuideData.guide.useBusinessAsBilling}
            />
            {/* <ContactList></ContactList> */}
          </>
      }
    </div>
  );
};
export default ProfileFisherman;
