import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import statesList from "../../common/StatesList";
import { Formik, FieldArray, Field } from "formik";
import Separator from "../../utils/Separator";
import DataListInput from "react-datalist-input";
import { request } from "../../utils/HttpHelper";
import "./GuideRegistration.css";

const GuideRegistration = () => {
  const [contactMethods, setContactMethods] = useState([]);
  const [error, seterror] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [, updateState] = useState();
  // const [species, setSpecies] = useState([]);
  //  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const [selectedRadioOption, setSelectedRadioOption] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const history = useHistory();

  const initSpiciesList = async (guide) => {
    let regionsList = await getRegionsCatalogue(guide);
    setRegions(
      regionsList.map((region, i) => {
        let item = {
          label: region.name,
          key: region.id,
        };
        return item;
      })
    );
  };
  const handleRegions = (e) => {
    initSpiciesList(e.target.value);
  };

  const getSpeciesCatalogue = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let speciesResult = await request({
          url: "guidex-api",
          trailingURL: "/adv-apply?advType=SPECIES",
          requiresAuth: false,
        });
        resolve(speciesResult.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getRegionsCatalogue = (guide) => {
    return new Promise(async (resolve, reject) => {
      try {
        let regionsResult = await request({
          url: "guidex-api",
          trailingURL: `/regions?entity_type=${
            guide == "HUNTER" ? "hunting" : "fishing"
          }` /* This endpoint should be changed*/,
          requiresAuth: false,
        });
        resolve(regionsResult.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const match = (currentInput, item) => {
    return item.label.toLowerCase().includes(currentInput.toLowerCase());
  };

  const getContactMethods = async () => {
    request({
      url: "guidex-api",
      trailingURL: "/contactmethod",
    }).then((result) => {
      let methods = result.data["contactMethods"];
      let methodsList = [];
      methods.forEach((cm) => {
        methodsList.push(
          <option
            value={cm["contactMethodID"]}
            key={"contact_method_" + cm["contactMethodID"]}
          >
            {cm["contactMethodLabel"]}
          </option>
        );
      });
      setContactMethods(methodsList);
    });
  };

  useState(() => {
    window.scrollTo(0, 0);
    const initialState = getContactMethods();
    return initialState;
  });

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/");
  };

  const submitGuideRegistration = (formRequest, setSubmitting) => {
    console.dir(formRequest);
    request({
      method: "POST",
      url: "guidex-api",
      trailingURL: `/register-guide`,
      body: formRequest,
    })
      .then(() => {
        setSubmitting(false);
        setShowModal(true);
      })
      .catch((err) => {
        if (err.request) {
          switch (err.request.status) {
            case 409:
              seterror(
                "The email address you are trying to use is already registred as a Guide, please use another one."
              );
              break;
            default:
              seterror(
                "There is a problem with the service, please try again later."
              );
          }
        }
        setSubmitting(false);
      });
  };

  return (
    <>
      <div
        id="imageBackgroundGuideRegistration"
        className="guide-registration-title"
      >
        <h1 className="guide-registration-title-h1 pb-5 pt-5">
          Guide Registration
        </h1>
      </div>
      <Container fluid className="guide-registration-form">
        <Row className="mb-5">
          <Col className="pt-5">
            <Row className="mx-0 mb-3 align-items-center">
              <h2 className="primary-color font-size-20 font-weight-bold mr-1">
                Register to be a Guide
              </h2>
              <span className="d-none d-md-block">
                <Separator size="351px" color="#EC7228" />
              </span>
              <span className="d-md-none">
                <Separator size="35px" color="#EC7228" />
              </span>
            </Row>
            <p className="mb-0 font-size-15">
              We take the process of vetting and adding new guides very
              seriously.
            </p>
            <p>
              Please fill out the following information and we will reach out to
              you within 2 business days.
            </p>
          </Col>
        </Row>

        <Formik
          initialValues={{
            contactName: "",
            email: "",
            mailingAddress: "",
            phone: "",
            preferredContactMethod: "",
            guideCategory: "",
            businessGuideName: "",
            licenseNumber: "",
            stateLicense: "",
            additionalLicenses: [],
            website: "",
            //species: "",
            location: "",
            industryMemberships: "",
            bookingAgencies: "",
            monthsOperation: [],
            comments: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.contactName) {
              errors.contactName = "Required";
            }
            if (!values.mailingAddress) {
              errors.mailingAddress = "Required";
            }
            if (!values.phone) {
              errors.phone = "Required";
            }
            if (!values.preferredContactMethod) {
              errors.preferredContactMethod = "Required";
            }
            if (!values.businessGuideName) {
              errors.businessGuideName = "Required";
            }
            if (!values.licenseNumber) {
              errors.licenseNumber = "Required";
            }
            if (!values.stateLicense) {
              errors.stateLicense = "Required";
            }
            //  if (!selectedSpecies) {
            //    errors.species = "Required";
            //  }
            if (!values.location) {
            errors.location = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.guideCategory) {
              errors.guideCategory = "Required";
            }
            if (values.additionalLicenses.length > 0) {
              let additionalLicensesErrors = [];
              let hasErrors = false;
              values.additionalLicenses.forEach((al, i) => {
                additionalLicensesErrors.push({
                  licenseNumber: "",
                  licenseState: "",
                });
                if (!al.licenseNumber) {
                  additionalLicensesErrors[i].licenseNumber = "Required";
                  hasErrors = true;
                }
                if (!al.licenseState) {
                  additionalLicensesErrors[i].licenseState = "Required";
                  hasErrors = true;
                }
              });
              if (hasErrors) {
                errors.additionalLicenses = additionalLicensesErrors;
              }
            }
            return errors;
          }}
          onSubmit={(
            {
              contactName,
              email,
              mailingAddress,
              phone,
              preferredContactMethod,
              guideCategory,
              businessGuideName,
              licenseNumber,
              stateLicense,
              additionalLicenses,
              website,
              //species,
              location,
              industryMemberships,
              bookingAgencies,
              monthsOperation,
              comments,
            },
            { setSubmitting }
          ) => {
            let licenses = [
              {
                licenseNumber: licenseNumber,
                licenseState: stateLicense,
              },
            ];
            //species = selectedSpecies.label;
            if (selectedRegion != null) {
              location = selectedRegion;
            } else {
              location = "Not Defined";
            }

            licenses = licenses.concat(additionalLicenses);
            const preferredContactMethodId = Number(preferredContactMethod);
            const registerRequest = {
              contactName,
              email,
              mailingAddress,
              phone,
              preferredContactMethod: preferredContactMethodId,
              businessGuideName,
              guideCategory,
              licenses,
              website,
              //species,
              location,
              industryMemberships,
              bookingAgencies,
              monthsOperation,
              comments,
            };
            seterror("");
            submitGuideRegistration(registerRequest, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={6}>
                  <div>
                    <h2 className="primary-color font-size-13 font-weight-bold mr-1">
                      Contact info
                    </h2>
                  </div>
                  <Form.Group controlId="formBasicContactName">
                    <Form.Label>Contact Name</Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="text"
                      name="contactName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactName}
                      placeholder="Full name"
                      isInvalid={!!errors.contactName}
                      isValid={touched.contactName && !errors.contactName}
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.contactName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Email address"
                      isInvalid={!!errors.email}
                      isValid={touched.email && !errors.email}
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Mailing Address</Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="text"
                      name="mailingAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mailingAddress}
                      placeholder="Mailing Address"
                      isInvalid={!!errors.mailingAddress}
                      isValid={touched.mailingAddress && !errors.mailingAddress}
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.mailingAddress}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="text"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      placeholder="Phone Number"
                      isInvalid={!!errors.phone}
                      isValid={touched.phone && !errors.phone}
                    />

                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicPreferred">
                    <Form.Label>Preferred Contact Method</Form.Label>
                    <Form.Control
                      as="select"
                      name="preferredContactMethod"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.preferredContactMethod}
                      placeholder="Select One"
                      isInvalid={!!errors.preferredContactMethod}
                      isValid={
                        touched.preferredContactMethod &&
                        !errors.preferredContactMethod
                      }
                    >
                      <option value="" key="NV">
                        Select One
                      </option>
                      {contactMethods}
                    </Form.Control>
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.preferredContactMethod}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6}>
                  <div>
                    <h2 className="primary-color font-size-13 font-weight-bold mr-1">
                      Guide Info
                    </h2>
                  </div>
                  <Form.Group controlId="formBasicCategory">
                    <Form.Label>Guide Category</Form.Label>
                    <Form.Control
                      as="select"
                      name="guideCategory"
                      onChange={(value) => {
                        handleRegions(value);
                        handleChange(value);
                      }}
                      onBlur={handleBlur}
                      value={values.guideCategory}
                      placeholder="Select One"
                      isInvalid={!!errors.guideCategory}
                      isValid={touched.guideCategory && !errors.guideCategory}
                    >
                      <option value="" key="NV">
                        Select One
                      </option>
                      <option value="HUNTER" key="g-hunt">
                        Hunting guide
                      </option>
                      <option value="FISHERMAN" key="g-fish">
                        Fishing guide
                      </option>
                    </Form.Control>
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.guideCategory}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicGuideName">
                    <Form.Label>Registered Business Name</Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="text"
                      name="businessGuideName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.businessGuideName}
                      placeholder="Registered Business Name"
                      isInvalid={!!errors.businessGuideName}
                      isValid={
                        touched.businessGuideName && !errors.businessGuideName
                      }
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.businessGuideName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicLicenseNumber">
                    <Form.Label className="d-flex justify-content-between">
                      License Number
                      <span>Optional</span>
                    </Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="text"
                      name="licenseNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.licenseNumber}
                      placeholder="License Number"
                      isValid={touched.licenseNumber && !errors.licenseNumber}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicStateLicense">
                    <Form.Label>State Licensed in</Form.Label>
                    <Form.Control
                      as="select"
                      name="stateLicense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.stateLicense}
                      placeholder="Select One"
                      isInvalid={!!errors.stateLicense}
                      isValid={touched.stateLicense && !errors.stateLicense}
                    >
                      {statesList()}
                    </Form.Control>
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.stateLicense}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <FieldArray
                    name="additionalLicenses"
                    render={({ remove, push }) => {
                      return (
                        <>
                          {values.additionalLicenses.length > 0 &&
                            values.additionalLicenses.map((license, index) => (
                              <div key={index}>
                                <Form.Group
                                  controlId={`formBasicLicenseNumber_${index}`}
                                >
                                  <Form.Label className="d-flex justify-content-between">
                                    License Number{" "}
                                    <span
                                      className="primary-color font-weight-bold d-flex justify-content-end clickable"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      - Remove
                                    </span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={`additionalLicenses.${index}.licenseNumber`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                      values.additionalLicenses[index]
                                        .licenseNumber
                                    }
                                    placeholder="License Number"
                                    isInvalid={
                                      errors.additionalLicenses &&
                                      errors.additionalLicenses[index] &&
                                      !!errors.additionalLicenses[index]
                                        .licenseNumber
                                    }
                                    isValid={
                                      touched.additionalLicenses &&
                                      touched.additionalLicenses[index] &&
                                      errors.additionalLicenses &&
                                      errors.additionalLicenses[index] &&
                                      touched.additionalLicenses[index]
                                        .licenseNumber &&
                                      !errors.additionalLicenses[index]
                                        .licenseNumber
                                    }
                                  />
                                  <Form.Control.Feedback
                                    className="mt-2"
                                    type="invalid"
                                  >
                                    {errors.additionalLicenses &&
                                      errors.additionalLicenses[index] &&
                                      errors.additionalLicenses[index]
                                        .licenseNumber}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  controlId={`formBasicStateLicense_${index}`}
                                >
                                  <Form.Label>State Licensed in</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name={`additionalLicenses[${index}].licenseState`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                      values.additionalLicenses[index]
                                        .licenseState
                                    }
                                    placeholder="Select One"
                                    isInvalid={
                                      errors.additionalLicenses &&
                                      errors.additionalLicenses[index] &&
                                      !!errors.additionalLicenses[index]
                                        .licenseState
                                    }
                                    isValid={
                                      touched.additionalLicenses &&
                                      touched.additionalLicenses[index] &&
                                      errors.additionalLicenses &&
                                      errors.additionalLicenses[index] &&
                                      touched.additionalLicenses[index]
                                        .licenseState &&
                                      !errors.additionalLicenses[index]
                                        .licenseState
                                    }
                                  >
                                    {statesList()}
                                  </Form.Control>
                                  <Form.Control.Feedback
                                    className="mt-2"
                                    type="invalid"
                                  >
                                    {errors.additionalLicenses &&
                                      errors.additionalLicenses[index] &&
                                      errors.additionalLicenses[index]
                                        .licenseState}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                            ))}
                          <p
                            id="addLicenseLink"
                            className="primary-color font-weight-bold d-flex justify-content-end clickable"
                            onClick={() => {
                              if (!touched.additionalLicenses) {
                                touched.additionalLicenses = [];
                              }
                              touched.additionalLicenses.push({
                                licenseNumber: false,
                                licenseState: false,
                              });
                              push({ licenseNumber: "", licenseState: "" });
                            }}
                          >
                            + Add another License and State
                          </p>
                        </>
                      );
                    }}
                  />
                  <Form.Group controlId="formBasicWebSite">
                    <Form.Label className="d-flex justify-content-between">
                      website<span>Optional</span>
                    </Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="text"
                      name="website"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.website}
                      placeholder="Website"
                      isInvalid={!!errors.website}
                      isValid={touched.website && !errors.website}
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.website}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicOperationLocation">
                    <Form.Label>
                      Primary Location/Region Operating In
                    </Form.Label>

                    <DataListInput
                      placeholder="Select a Primary Region..."
                      inputClassName="background-guidex form-control"
                      dropDownLength={5}
                      isInvalid={!!errors.location}
                      isValid={touched.location && !errors.location}
                      items={regions}
                      onSelect={(selectedItem) => {
                        setSelectedRegion(selectedItem.key);
                      }}
                      match={match}
                    />

                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.location}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicIndustryMemberships">
                    <Form.Label className="d-flex justify-content-between">
                      Industry Memberships?<span>Optional</span>
                    </Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="text"
                      name="industryMemberships"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.industryMemberships}
                      placeholder="(example: Outfitter Associations, SCI)"
                      isInvalid={!!errors.industryMemberships}
                      isValid={
                        touched.industryMemberships &&
                        !errors.industryMemberships
                      }
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.industryMemberships}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicAgencies">
                    <Form.Label className="d-flex justify-content-between">
                      Any Booking Agencies
                    </Form.Label>

                    <Row>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          Yes
                          <Field
                            type="radio"
                            name="bookingAgency"
                            value="YES"
                            checked={selectedRadioOption === "YES"}
                            className="checkbox-guidex"
                            onChange={() => {
                              setSelectedRadioOption("YES");
                            }}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          No
                          <Field
                            type="radio"
                            name="bookingAgency"
                            value="NO"
                            checked={selectedRadioOption === "NO"}
                            className="checkbox-guidex"
                            onChange={() => {
                              setSelectedRadioOption("NO");
                            }}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                    </Row>

                    {selectedRadioOption === "YES" ? (
                      <div>
                        <Form.Label className="d-flex justify-content-between">
                          How much do they charge<span>Optional</span>
                        </Form.Label>
                        <Form.Control
                          bsCustomPrefix="asdasda"
                          type="text"
                          name="bookingAgencies"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bookingAgencies}
                          placeholder="What percentage do they charge?"
                          isInvalid={!!errors.bookingAgencies}
                          isValid={
                            touched.industryMemberships &&
                            !errors.industryMemberships
                          }
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicMonths">
                    <Form.Label className="d-flex justify-content-between">
                      Operational Months{" "}
                      <span
                        className="primary-color font-weight-bold d-flex justify-content-end clickable"
                        onClick={() => {
                          values.monthsOperation = [
                            "JAN",
                            "FEB",
                            "MAR",
                            "APR",
                            "MAY",
                            "JUN",
                            "JUL",
                            "AUG",
                            "SEP",
                            "OCT",
                            "NOV",
                            "DEC",
                          ];
                          updateState({});
                        }}
                      >
                        + Select All
                      </span>
                    </Form.Label>
                    <Row>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          January
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="JAN"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          February
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="FEB"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          March
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="MAR"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          April
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="APR"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          May
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="MAY"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          June
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="JUN"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          July
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="JUL"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          August
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="AUG"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          September
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="SEP"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          October
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="OCT"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          November
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="NOV"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <label className="checkbox-container">
                          {" "}
                          December
                          <Field
                            type="checkbox"
                            name="monthsOperation"
                            value="DEC"
                            className="checkbox-guidex"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group controlId="formBasicComments">
                    <Form.Label className="d-flex justify-content-between">
                      Comments <span>Optional</span>
                    </Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      as="textarea"
                      rows={4}
                      name="comments"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comments}
                      placeholder="Add comments"
                      isInvalid={!!errors.comments}
                      isValid={touched.comments && !errors.comments}
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.comments}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex flex-row-reverse pb-2">
                    <Button
                      variant="guide-registration"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                  <div
                    className={`${
                      error ? "d-block" : "d-none"
                    } invalid-feedback`}
                  >
                    {error}
                  </div>
                  <div className="pb-5"></div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container className="d-flex justify-content-center">
            <Col md="auto">
              <Row className="mx-0 mb-2 align-items-center">
                <Col md="auto" className="px-0">
                  <h2 className="primary-color font-size-30 mr-1 font-weight-bold">
                    Thank You
                  </h2>
                </Col>
                <Col className="px-0">
                  <Separator size="251px" color="#EC7228" />
                </Col>
              </Row>
              <Row className="mx-0">
                <p className="card-text font-size-15">
                  Thank you for your application! We will reach out soon!
                </p>
              </Row>
              <Row className="mx-0 mb-5 mt-4 float-right">
                <Button
                  variant="guidex"
                  type="button"
                  onClick={() => handleCloseModal()}
                >
                  Back to home
                </Button>
              </Row>
            </Col>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default GuideRegistration;
