import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { app } from "../../FirebaseConfig";
import { getInitials } from "../../utils/Misc";
import Separator from "../../utils/Separator";
import { User } from "../../utils/UserProvider";
import FetchFavorites from "./FetchFavorites";
import "./Login.css";

function MultipleAccounts(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [background] = useState(props.background);
  const [noMargin] = useState(props.margin);

  const { onSwitchAccount } = props;
  const { user, setuser } = useContext(User);

  const history = useHistory();

  const getRolesFromStorage = () => {
    if (user) {
      return {
        userId: user.userId,
        email: user.email,
        roles: user.otherRoles,
      };
    } else {
      return {
        email: null,
        roles: [],
      };
    }
  };

  const { email, userId, roles } = history.location.state
    ? history.location.state
    : getRolesFromStorage();
  // eslint-disable-next-line no-unused-vars

  const logout = async () => {
    await app
      .auth()
      .signOut()
      .then((result) => {
        history.push("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const selectRole = async (roleName) => {
    let selRole = roles.find((r) => r.roleName === roleName);
    let userData = {
      email: email,
      userId: userId,
      fullName: selRole.fullName,
      selectedRole: selRole.roleName,
      profilePic: selRole.profilePic || null,
      otherRoles: roles,
    };
    if (roleName === "CUSTOMER") {
      let favorites = await FetchFavorites();
      userData = { ...userData, favorites };
    }
    setuser(userData);
    //window.localStorage.setItem("userRolesData", JSON.stringify(userData));
    let nextPath = "";
    switch (selRole.roleName) {
      // case "CUSTOMER":
      //   nextPath = "/my-portal#favorite-trips";
      //   break;
      case "HUNTER":
      case "FISHERMAN":
        nextPath = "/my-portal#profile";
        break;
      case "STAFF":
        nextPath = "/my-portal#login-as";
        break;
      default:
        nextPath = "/";
    }
    if (onSwitchAccount) {
      onSwitchAccount();
    }
    history.push(nextPath);
  };

  return (
    <div id={background}>
      <Container className="d-flex justify-content-center">
        <Col md="auto" className={noMargin}>
          {props.modal === "true" ? (
            <div className="mb-3">
              <Row className="mx-0 mb-2 align-items-center">
                <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                  Switch Account
                </h2>
                <Separator size="251px" color="#EC7228" />
              </Row>
              <p className="card-text font-size-15">
                Choose which account you want to switch to.
              </p>
            </div>
          ) : (
            <div className="mb-3">
              <Row className="mx-0 mb-2 align-items-center">
                <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                  Select an account
                </h2>
                <Separator
                  size={!isMobile ? "251px" : "10px"}
                  color="#EC7228"
                />
              </Row>
              <p className="card-text font-size-15">
                You're signed in as{" "}
                <span className="font-weight-bold">{email}</span>.&nbsp;
                {/*eslint-disable-next-line */}
                <a href="#" className="primary-color " onClick={() => logout()}>
                  Switch
                </a>
              </p>
            </div>
          )}

          <ul className="list-group list-group-flush mt-4 mb-4">
            {roles.map(function (role, i) {
              return (
                <li
                  className={
                    (i < roles.length - 1 ? "separator" : "") +
                    " list-group-item" +
                    (!isMobile ? "" : " pl-0")
                  }
                  key={i}
                >
                  <Container className={!isMobile ? "" : "pl-0"}>
                    <Row>
                      <Col>
                        <Row>
                          {
                            role.profilePic ?
                              <img className="d-none d-sm-block circle-profile " src={role.profilePic} alt="" />
                              :
                              <div className="d-none d-sm-block circle-profile ">
                                <span className="initials">
                                  {getInitials(role.fullName)}
                                </span>
                              </div>
                          }
                          <Col>
                            <p
                              className={
                                !isMobile
                                  ? "card-text font-size-18 font-weight-bold user mt-1"
                                  : "card-text user font-size-16 font-weight-bold  mt-1"
                              }
                            >
                              {role.fullName}
                            </p>
                            <p className="font-size-12 role-name type">
                              { (role.roleName.toLowerCase() === "hunter" || role.roleName.toLowerCase() === "fisherman" ) ? "Guide Portal" : role.roleName.toLowerCase()} 
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="d-flex align-items-center justify-content-end">
                        <Button
                          variant="guidex select-account"
                          type="button"
                          onClick={() => selectRole(role.roleName)}
                        >
                          Select
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </li>
              );
            })}
          </ul>
        </Col>
      </Container>
    </div>
  );
}
export default MultipleAccounts;
