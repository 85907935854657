import React, { useState, useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { FaFacebookF, FaGoogle, FaTwitter } from "react-icons/fa";
import * as firebase from "firebase/app";
import { app } from "../../FirebaseConfig";
import { User } from "../../utils/UserProvider";
import { Formik } from "formik";
import Separator from "../../utils/Separator";
import { Link, useHistory } from "react-router-dom";
import "./Register.css";
import { request } from "../../utils/HttpHelper.jsx";
import { useMediaQuery } from "react-responsive";
import FetchFavorites from "../Login/FetchFavorites";

export default function Register() {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  window.scrollTo(0, 0);

  const history = useHistory();
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
  const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

  // eslint-disable-next-line no-unused-vars
  const { user, setuser } = useContext(User);
  const [error, seterror] = useState("");

  // const { usuario } = useContext(Auth);

  // useEffect(() => {
  //   if (usuario) {
  //     history.push("/");
  //   }
  // }, [history, usuario]);

  const registerInWithEmailAndPasswordHandler = async (
    values,
    setSubmitting
  ) => {
    try {
      let dt = new Date();
      await app
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);
      const body = {
        fullName: values.fullName,
        email: values.email,
        acceptedTACOn: dt.toISOString()
      };
      await request({
        method: "POST",
        url: "guidex-api",
        trailingURL: "/register",
        body,
        requiresAuth: true
      });
      let resultUserData = await request({
        method: "POST",
        url: "guidex-api",
        trailingURL: "/login",
        requiresAuth: true
      });
      setSubmitting(false);
      console.log(resultUserData);
      let roles = resultUserData.data["roles"];
      if (roles.length > 1) {
        history.push("/multiple-accounts", resultUserData.data);
      } else {
        let userData = {
          email: resultUserData.data.email,
          userId: resultUserData.data.userId,
          fullName: roles[0].fullName,
          selectedRole: roles[0].roleName,
          profilePic: roles[0].profilePic || null,
          otherRoles: roles
        };
        setuser(userData);
        //window.localStorage.setItem("userRolesData", JSON.stringify(userData));
        history.push("/");
      }
    } catch (error) {
      console.log(error);
      seterror(error.message);
      setSubmitting(false);
    }
  };

  const socialLogin = async provider => {
    try {
      let dt = new Date();
      let resultSocialLogin = await app.auth().signInWithPopup(provider);
      let body = {
        fullName:
          resultSocialLogin.user.displayName ??
          resultSocialLogin.additionalUserInfo.username,
        email: resultSocialLogin.user.email,
        phone: resultSocialLogin.user.phoneNumber,
        acceptedTACOn: dt.toISOString()
      };
      if (resultSocialLogin.additionalUserInfo.isNewUser) {
        await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: "/register",
          body,
          requiresAuth: true
        });
        let resultUserData = await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: "/login",
          requiresAuth: true
        });
        let roles = resultUserData.data["roles"];
        if (roles.length > 1) {
          history.push("/multiple-accounts", resultUserData.data);
        } else {
          let userData = {
            email: resultUserData.data.email,
            userId: resultUserData.data.userId,
            fullName: roles[0].fullName,
            selectedRole: roles[0].roleName,
            profilePic: roles[0].profilePic || null,
            otherRoles: roles
          };
          let favorites = await FetchFavorites();
          userData = { ...userData, favorites };
          setuser(userData);
          // window.localStorage.setItem(
          //   "userRolesData",
          //   JSON.stringify(userData)
          // );
          history.push("/");
        }
      } else {
        let resultUserData = await await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: "/login",
          requiresAuth: true
        });
        let roles = resultUserData.data["roles"];
        if (roles.length > 1) {
          history.push("/multiple-accounts", resultUserData.data);
        } else {
          let userData = {
            email: resultUserData.data.email,
            userId: resultUserData.data.userId,
            fullName: roles[0].fullName,
            selectedRole: roles[0].roleName,
            profilePic: roles[0].profilePic || null,
            otherRoles: roles
          };
          let favorites = await FetchFavorites();
          userData = { ...userData, favorites };
          setuser(userData);
          // window.localStorage.setItem(
          //   "userRolesData",
          //   JSON.stringify(userData)
          // );
          history.push("/");
        }
      }
    } catch (error) {
      seterror(error.message);
    }
  };

  return (
    <div id="imageBackgroundSignup">
      <Container className="d-flex justify-content-center">
        <Col md="auto" className="guidex-card">
          <div className="mb-3">
            <Row className="mx-0 mb-3 align-items-center">
              <h2 className="primary-color font-weight-bold font-size-30 mr-1">
                Register
              </h2>
              <Separator size={!isMobile ? "351px" : "163px"} color="#EC7228" />
            </Row>
            <p className="mb-0 font-size-15">
              To register please fill in the form bellow. Already a member?
              <Link to="/login" className="ml-1 guidex-link primary-color">
                Log in
              </Link>
            </p>
            <p className="mb-0 font-size-15">
              Looking to become a guide with us?
              <Link
                to="/guide-registration"
                className="ml-1 guidex-link primary-color"
              >
                Register Here
              </Link>
            </p>
          </div>

          <Formik
            initialValues={{
              fullName: "",
              email: "",
              password: "",
              acceptToS: false
            }}
            validate={values => {
              const errors = {};
              if (!values.fullName) {
                errors.fullName = "Required";
              }
              if (!values.password) {
                errors.password = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.acceptToS) {
                errors.acceptToS = "You need to agree the Terms and Conditions";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              registerInWithEmailAndPasswordHandler(values, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicFullName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fullName}
                    placeholder="First and last name"
                    isInvalid={!!errors.fullName}
                    isValid={touched.fullName && !errors.fullName}
                  />
                  <Form.Control.Feedback className="mt-2" type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Email address"
                    isInvalid={!!errors.email}
                    isValid={touched.email && !errors.email}
                  />
                  <Form.Control.Feedback className="mt-2" type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={!!errors.password}
                    isValid={touched.password && !errors.password}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <div className="mx-0 mt-2 d-flex align-items-end justify-content-between">
                    <div>
                      <div
                        className={`${
                          errors.password ? "d-block" : "d-none"
                        } invalid-feedback`}
                      >
                        {errors.password}
                      </div>
                      <div
                        className={`${
                          error ? "d-block" : "d-none"
                        } invalid-feedback`}
                      >
                        {error}
                      </div>
                    </div>
                  </div>
                </Form.Group>

                <div className="mb-5 mt-4">
                  <Row>
                    <Col md="8">
                      <Form.Group controlId="formBasicAcceptToS">
                        <Col md="12" className="pl-0">
                          <div className="checkbox">
                            <Form.Check
                              label={
                                <label className="labelTerms">
                                  I agree with the
                                  <a
                                    href="/terms-conditions"
                                    target="_blank"
                                    className="ml-1 guidex-link primary-color"
                                  >
                                    Terms and Conditions
                                  </a>
                                </label>
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.acceptToS}
                              isInvalid={!!errors.acceptToS}
                              isValid={touched.acceptToS && !errors.acceptToS}
                              type="checkbox"
                              name="acceptToS"
                              placeholder="I agree with the Terms and Conditions"
                              className="register-checkbox"
                            />
                          </div>
                        </Col>
                        {/* <Form.Control.Feedback
                          className="mt-2 font-size-11 guidex-link primary-color"
                          type="Invalid"
                        >
                          {errors.acceptToS}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                    <Col md="4" className="d-flex justify-content-end">
                      <Button
                        variant="guidex "
                        type="submit"
                        disabled={isSubmitting || !values.acceptToS}
                      >
                        Register
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
          <p className="font-weight-bold font-size-13 text-center">
            Sign up or log in with a social network
          </p>
          <Row className="justify-content-center">
            <Button
              onClick={() => socialLogin(googleAuthProvider)}
              variant="guidex-social-media"
              className="mr-3"
            >
              <FaGoogle />
            </Button>
            <Button
              onClick={() => socialLogin(facebookAuthProvider)}
              variant="guidex-social-media"
              className="mr-3"
            >
              <FaFacebookF />
            </Button>

            <Button
              onClick={() => socialLogin(twitterAuthProvider)}
              variant="guidex-social-media"
              className="mr-3"
            >
              <FaTwitter />
            </Button>
          </Row>
        </Col>
      </Container>
    </div>
  );
}
