import React, { useState } from "react";
import { Button, Col, Container, Image, Row, Dropdown } from "react-bootstrap";
import StarIcon from "../assets/icons/StarWhiteFill.svg";
import "./TripCard.css";
import StarRatings from "react-rating-stars-component";
import starFullIcon from "../assets/icons/Star-Full-orange.svg";
import starEmptyIcon from "../assets/icons/Star-Empty-orange.svg";
import starHalfIcon from "../assets/icons/Star-Half-orange.svg";
import copyIcon from "../assets/icons/copy.svg";
import trashIcon from "../assets/icons/trash-2.svg";
import checkmark from "../assets/icons/checkmark-circle-2.svg";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import newPhoto from "../assets/others/new_photo.png";
import Favorite from "./Favorite";
import CurrencyFormat from "react-currency-format";
import { useMediaQuery } from "react-responsive";
import { request } from "../utils/HttpHelper";
import { showToast } from "../utils/Misc";
import { AiFillCheckCircle } from "react-icons/ai";

const TripCard = props => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { tripData, page, selectAction, isSelected, status, setReload, reload } = props;
  const [border, setborder] = useState("");
  const customStars = {
    size: 0,
    count: 5,
    value: tripData.ratting,
    edit: false,
    isHalf: true,
    emptyIcon: (
      <Image src={starEmptyIcon} width="13px" className="mr-1"></Image>
    ),
    halfIcon: <Image src={starHalfIcon} width="15px" className="mr-1"></Image>,
    filledIcon: <Image src={starFullIcon} width="15px" className="mr-1"></Image>
  };
  const customStars2 = {
    size: 0,
    count: 5,
    value: tripData.ratting,
    edit: false,
    isHalf: true,
    emptyIcon: (
      <Image src={starEmptyIcon} width="20px" className="mr-1"></Image>
    ),
    halfIcon: <Image src={starHalfIcon} width="22px" className="mr-1"></Image>,
    filledIcon: <Image src={starFullIcon} width="22px" className="mr-1"></Image>
  };
  useState(() => {
    if (isSelected) setborder("favorite-border");
    // eslint-disable-next-line
  }, []);

  const getCleanDates = (dateString) => {
    const startDate = dateString.substring(0, 24);
    const endDate = dateString.substring(27);

    const dt1 = new Date(startDate);
    const dt2 = new Date(endDate); 
      
    return `${dt1.getMonth() + 1}/${dt1.getDate()}/${dt1.getFullYear()} - ${dt2.getMonth() + 1}/${dt2.getDate()}/${dt2.getFullYear()}`;
  }

  /**
   * tripStatus
   * @param {Number} tripId the id of the hunt trip to be archived
   * @returns If the request was successful, the function will return a success toast and the trip will be archived
   * TODO: make this function receive the status so it can change between ACTIVE and ARCHIVED
   */
  const tripStatus = (tripId, status) =>{
    return new Promise( async (resolve, reject) =>{
      if (status === "Active") status = "ARCHIVE";
      if (status === "Archived") status = "ACTIVE";
      try {
        let body = {
          huntingTripId: tripId,
          status: status
        };
        await request({
          method: "PUT",
          url: "guidex-api",
          trailingURL: "/hunt-trip/status",
          body,
          requiresAuth: true
        });
        resolve(
          showToast(
          <>
            <AiFillCheckCircle /> The trip status has been successfully changed
          </>,
          "success"
        ), setReload(!reload));
      }catch (error) {
        reject(error);
      }
    })
  }

  return (
    <>
      {isTabletOrMobile ? (
        <Container
          className={"shadow bg-white  favorite-card pl-0 p-0 mb-4" + border}
          fluid
        >
          <Row noGutters className="card-fav-trips ">
            <Col sm={12} md={4} lg={3} xl={3}></Col>
            <Col
              sm="auto"
              md={8}
              lg={9}
              xl={9}
              className="outfitter-main-content"
            >
              {border === "favorite-border" && page === "advertising" && (
                <div className="square-border d-flex justify-content-center p-2">
                  <Image src={StarIcon}></Image>
                </div>
              )}
              <div className="d-flex justify-content-between mb-0 mt-3">
                <StarRatings {...customStars2} classNames="mb-0" />
                {/* (19) aqui va un contador */}

                {page === "trips" ? (
                  <Dropdown alignRight className="pb-0 mb-0">
                    <Dropdown.Toggle id="dropdown-basic" variant="no-border">
                      <BiDotsHorizontalRounded color="orange" size="30" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="favorite-trips-menu-width">
                      <Dropdown.Item
                        eventKey="option-1 "
                        className="favorite-trips-bigger-cell"
                        onClick={() => {
                          if (selectAction) {
                            selectAction("edit", tripData.id);
                          }
                        }}
                      >
                        <span className="list-center-element">
                          <Image src={copyIcon} className="mr-4" />
                          Edit
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider className="my-0"/>
                      <Dropdown.Item
                        className="favorite-trips-bigger-cell"
                        eventKey="option-2"
                      >
                        <span className="list-center-element">
                          <Image src={trashIcon} className="mr-4" />
                          Archive
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span className=" mr-3">
                    <Favorite
                      entityType="HUNT_TRIP"
                      entityId={tripData.id}
                      size={30}
                    />
                  </span>
                )}
              </div>

              {page === "trips" ? (
                <p className="font-size-12 primary-color title-padding mb-1">
                  {tripData.guideCompany}
                </p>
              ) : (
                <p className="font-size-20 primary-color font-weight-bold mb-1">
                  {tripData.guideCompany}
                </p>
              )}
              <Row noGutters className="justify-content-between">
                <p className="font-size-30 font-weight-bold mb-1 d-flex justify-content-between">
                  {tripData.title}
                </p>
                <div></div>
              </Row>

              <p className="mb-1 font-size-20">
                Dates:{" "}
                <span className="font-weight-bold font-size-20">
                  {tripData.timeFrame}
                </span>
              </p>
              <p className="mb-2 font-size-20">
                Location:{" "}
                <span className="font-weight-bold">{tripData.location}</span>
              </p>
              <Row>
                <Col>
                  <p className="favorite-trip-card-text-responsive">
                    {tripData.description}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="primary-color font-size-30 font-weight-bold">
                    <CurrencyFormat
                      value={tripData.priceTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value) => value}
                    />
                    <span className="font-size-30 price-original text-right mb-0 ml-4">
                      <CurrencyFormat
                        value={tripData.priceOriginal}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => value}
                      />
                    </span>
                  </p>
                </Col>
              </Row>
              {page === "trips" ? (
                <Col className="col-auto">
                  <Button variant="match"> Schedule Pay</Button>
                </Col>
              ) : (
                <Row className="col-auto mt-3">
                  <Col>
                    <Button variant="responsive"> Learn more</Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          className={"shadow bg-white  favorite-card pl-0 p-0 mb-4 " + border}
          fluid
        >
          <Row noGutters className="card-fav-trips ">
            <Col sm={12} md={4} lg={3} xl={3}>
              <Image
                src={
                  tripData.mainImage ? tripData.mainImage.imageUrl : newPhoto
                }
                fluid
                className="image-size"
              />
            </Col>
            <Col
              sm="auto"
              md={8}
              lg={9}
              xl={9}
              className="outfitter-main-content"
            >
              {border === "favorite-border" && page === "advertising" && (
                <div className="square-border d-flex justify-content-center p-2">
                  <Image src={StarIcon}></Image>
                </div>
              )}
              <div className="d-flex justify-content-between mb-0">
                <StarRatings {...customStars} classNames="mb-0" />
                {/* (19) aqui va un contador */}
                {page === "trips" ? (
                  <Dropdown alignRight className="pb-0 mb-0">
                    <Dropdown.Toggle id="dropdown-basic" variant="no-border">
                      <BiDotsHorizontalRounded color="orange" size="30" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="favorite-trips-menu-width">
                      <Dropdown.Item
                        eventKey="option-1 "
                        className="favorite-trips-bigger-cell"
                        onClick={() => {
                          if (selectAction) {
                            selectAction("edit", tripData.id);
                          }
                        }}
                      >
                        <span className="list-center-element">
                          <Image src={copyIcon} className="mr-4" />
                          Edit
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {status === "Active" && 
                      <Dropdown.Item
                        className="favorite-trips-bigger-cell"
                        eventKey="option-2"
                        onClick={() => {tripStatus(tripData.id, status)}}
                      >
                        <span className="list-center-element">
                          <Image src={trashIcon} className="mr-4" />
                          Archive
                        </span>
                      </Dropdown.Item>}
                      {status === "Archived" && 
                      <Dropdown.Item
                        className="favorite-trips-bigger-cell"
                        eventKey="option-2"
                        onClick={() => {tripStatus(tripData.id, status)}}
                      >
                        <span className="list-center-element">
                          <Image src={checkmark} className="mr-4" />
                          Active
                        </span>
                      </Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span>
                    <Favorite entityType="HUNT_TRIP" entityId={tripData.id} />
                  </span>
                )}
              </div>

              {page === "trips" ? (
                <p className="font-size-12 primary-color title-padding mb-1">
                  {tripData.guideCompany}
                </p>
              ) : (
                <p className="font-size-12 font-weight-bold primary-color  mb-1">
                  {tripData.guideCompany}
                </p>
              )}
              <Row noGutters className="justify-content-between">
                <p className="font-size-18 font-weight-bold mb-1 d-flex justify-content-between">
                  {tripData.title}
                </p>
                <div>
                  <span className="primary-color font-size-18 font-weight-bold">
                    <CurrencyFormat
                      value={tripData.priceTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value) => value}
                    />
                  </span>
                  <p className="font-size-15 price-original text-right mb-0">
                    <CurrencyFormat
                      value={tripData.priceOriginal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value) => value}
                    />
                  </p>
                </div>
              </Row>

              <p className="mb-1 font-size-14">
                Dates:{" "}
                <span className="font-weight-bold font-size-14">
                  {getCleanDates(tripData.timeFrame)}
                </span>
              </p>
              <p className="mb-2 font-size-14">
                Location:{" "}
                <span className="font-weight-bold font-size-14">
                  {tripData.location}
                </span>
              </p>
              <Row className="justify-content-between">
                <Col>
                  <p className="favorite-trip-card-text font-size-14">
                    {" "}
                    {tripData.description}
                  </p>
                </Col>
                {/* page === "trips" ? (
                  <Col className="col-auto">
                    <Button variant="match"> Schedule Pay</Button>
                  </Col>
                ) 
                : (
                  <Col className="col-auto">
                    <Button variant="match"> Learn more</Button>
                  </Col>
                )
                */}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
export default TripCard;
