import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Table,
  FormControl
} from "react-bootstrap";
import Loader from "react-loader-spinner";
import { AiFillWarning } from "react-icons/ai";
import { request } from "../../../utils/HttpHelper";
import { showToast } from "../../../utils/Misc";
import { useHistory } from "react-router-dom";
import { Staff } from "../../../utils/staffProvider";
import algoliasearch from "algoliasearch";

const LoginAs = () => {

  const { setSignInAs, setGuideType } = useContext(Staff);
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false);
  const [guideList, setGuideList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [refresh, setRefresh] = useState(0)
  const [activeGuideList, setActiveGuideList] = useState([])
  
 
  useEffect(() => {
    getPendingGuides();
    search()
  }, [refresh])

  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const hunters = searchClient.initIndex("GUIDE_HUNTER");
  const fishers = searchClient.initIndex("GUIDE_FISHER")
  
  
  const search = async (e) => {
    
    const huntersDb = hunters.search()
    .then(({ hits })=>{
      console.log(hits)
      const huntersList = hits.map((e)=>{
        let item = {
          guideType:"HUNTER",
          guideStatus:"Active",
          guideId: e.guideHunterID,
          guideUID:e.guideHunterUID,
          guideName: e.guideFullName,
        }
        return item
      })
      return huntersList      
    })

    const fishersDb = fishers.search()
    .then(({ hits })=>{
      const fishersList = hits.map((e)=>{
        let item = {
          guideType:"FISHER",
          guideStatus:"Active",
          guideId: e.guideFisherID,
          guideUID:e.guideFisherUID,
          guideName: e.guideFullName,
        }
        return item
      })
      return fishersList
    })
    
    const mixDb = (await fishersDb).concat(await huntersDb)
    return setActiveGuideList(mixDb)
  }

  


  // This function get all the users with status pending or rejected from db
  const getPendingGuides = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let getPendingGuidesResult = await request({
          url: "guidex-api",
          trailingURL: "/approve-guide",
          requiresAuth: true
        });
        const { pendingGuideList } = getPendingGuidesResult.data;
        console.dir(pendingGuideList)
        setGuideList(pendingGuideList);
        setIsLoading(false);
        resolve();
      } catch (error) {
        showToast(
          <>
            <AiFillWarning /> There is an error trying to get the guides list.
          </>,
          "error"
        );
        setIsLoading(false);
        reject(error);
      }
    });
  };


  const approveGuide = async (guideId) => {
    //This function redirects to #approve guides, so the user can check the details and decide if approving the user as a guide
    await setSignInAs(guideId);
    history.push("/my-portal#approve-guides");
  };

  const handleSignInAs = (guideId, guideType) => {
    setGuideType(guideType)
    setSignInAs(guideId)
    history.push(`/my-portal#profile?id=${guideId}&guide-type=${guideType}`)
    window.scrollTo(0, 0)
  }

  const searchUser = async (param) => {
    setIsLoading(true)
    const result = activeGuideList.filter(e => e.guideName.toLowerCase().includes(param))
    setGuideList(result)
    setIsLoading(false)
  }
  //console.log(staff)
  console.dir(guideList)
  return (
    <>
      <h2 className="mb-5 approve-guides-title portal-section-title mt-5">
        Log In As
      </h2>
      <Container fluid className="approve-guides-container mb-5">
        <Row>
          <Col>
            <Row>
              <Col>
                <FormControl
                  className="login-search"
                  onChange={(e) => searchUser(e.target.value)}
                />
              </Col>
            </Row>

            <Table responsive borderless className="approve-guides-table  mt-5">
              <thead>
                <tr>
                  <th> Name</th>
                  <th>Email</th>
                  <th>ID</th>
                  <th>Status</th>
                  <th className="ml-5"></th>
                </tr>
              </thead>
              {!isLoading &&
                <tbody>
                  {guideList.map((e) => /*e.guideStatus === "Pending" &&*/
                    <tr key={e.guideType + "__" + e.guideUID}>
                      <td>{e.guideName}</td>
                      <td>{e.email}</td>
                      <td>{e.guideId}</td>
                      <td>{e.guideStatus}</td>

                      { //This block is in chage of rendering the button only when is necesary to approve a guide 
                        e.guideStatus === "Pending" && <td>
                          <Button
                            className={e.guideStatus === "Approved" && "disabled"}
                            variant="details"
                            onClick={(() => approveGuide(e.guideId))}>
                            Approve
                          </Button>
                        </td>
                      }
                      <td>
                        <Button
                          className=""
                          variant="details"
                          onClick={(() => handleSignInAs(e.guideId, e.guideType))}>
                          Login As
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>}
            </Table>
            {isLoading &&
              <center>
                <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
              </center>}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default LoginAs;
