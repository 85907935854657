import React, {useState, useEffect} from "react";
import { Col, Row, Container, Table, Image } from "react-bootstrap";
import "./AdvertisingConfirmation.css";
import printer from "../../../../assets/icons/printer.svg";
import email from "../../../../assets/icons/email.svg";
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";
import Loader from 'react-loader-spinner'
import { AiFillWarning } from "react-icons/ai";

const AdvertisingConfirmation = (props) => {

  const { sessionId, advertisementData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [orderDate, setOrderDate] = useState(new Date().toUTCString())
  const [orderItemName, setOrderItemName] = useState("")
  const [orderDailyRate, setOrderDailyRate] = useState("")
  const [orderBillingInfo, setOrderBillingInfo] = useState({
    city: "",
    country: "",
    line1: "",
    postalCode: "",
    state: ""
  })
  const [orderCardInfo, setOrderCardInfo] = useState({
    brand: "",
    country: "",
    funding: "",
    last4: ""
  })

  const getConformationData = () => {
    return new Promise( async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/stripe-order?session_id=${sessionId}`,
          requiresAuth: true
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  const initAdvConfirmation = async ()  => {
    try {
      setIsLoading(true);
      let confirmationResult = await getConformationData();
      let { payment } = confirmationResult;
      console.dir(payment)
      setOrderId(payment.paymentIntentID);
      setOrderDate(new Date().toUTCString());
      setOrderItemName(payment.tripName);
      setOrderDailyRate(payment.dailyRate);
      setOrderSubtotal(payment.amountSubtotal);
      setOrderTotal(payment.amountTotal);
      setOrderBillingInfo(payment.billingAddress);
      setOrderCardInfo(payment.card)
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch required data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if(sessionId){
      initAdvConfirmation();
    }
  // eslint-disable-next-line
  }, []);

  return (
    !isLoading ?
    <>
      <Container fluid className="advertising-area-container mt-2 pr-5 pl-5">
        <Row className=" mb-4 mt-3">
          <Col className="pl-4 d-flex justify-content-between align-items-center">
            <p className="confirmation-order">
              Thank you for you purchase. Your order Id number is {orderId}, placed on {orderDate}
            </p>
            <div className="confirmation-icons">
              <Image src={printer} className="mr-1" />
              <span>Print</span>
              <Image src={email} className="ml-5 mr-1" /> <span>Email</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Table responsive borderless className="table-confirmation">
              <thead>
                <tr className="d-flex align-items-center tr-head-confirmation pb-1">
                  <th className="col-6">Order item</th>
                  <th className="col-6 text-right">Daily rate</th>
                </tr>
              </thead>
              <tbody>
                <tr className="d-flex align-items-center ">
                  <td className="col-6">Advertising to {orderItemName}</td>
                  <td className="col-6 text-right">${orderDailyRate}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="confirmation-title">Billing Information</p>
            <p className=" pl-3">{orderBillingInfo.line1}</p>
            <p className=" pl-3">{orderBillingInfo.city}, {orderBillingInfo.state}</p>
            <p className=" pl-3">{orderBillingInfo.postalCode}</p>
          </Col>
          <Col>
            <p className="confirmation-title border-complete">Order Summary</p>
            <div className="d-flex justify-content-between pl-3">
              <p className="font-weight-bold font-size-15">Subtotal</p>
              <span>${orderSubtotal/100}</span>
            </div>
            <div className="d-flex justify-content-between pl-3">
              <p className="font-weight-bold font-size-15">Tax</p>
              <span>N/A</span>
            </div>
            <div className="d-flex justify-content-between pl-3">
              <p className="font-weight-bold font-size-18">Total</p>
              <span>${orderTotal/100}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="confirmation-title ">Payment Information</p>
            <p className=" pl-3">
              ({orderCardInfo.brand} {orderCardInfo.country}, {orderCardInfo.funding}) **** **** **** {orderCardInfo.last4}
            </p>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
    :
    <center>
      <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
    </center>
  );
};
export default AdvertisingConfirmation;
