import React, { useState } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Container,
  Image,
  Modal
} from "react-bootstrap";
import "./Settings.css";

import editIcon from "../../../../assets/icons/EditOutlineWhite.svg";
import trashIcon from "../../../../assets/icons/TrashOrange.svg";
import newPhoto from "../../../../assets/others/new_photo.png";
import SettingsChangePassword from "./SettingsChangePassword";
import SettingsDeleteAccount from "./SettingsDeleteAccount";
import {ReactComponent as PenIcon} from "../../../../assets/icons/pen-edit.svg";
import { he } from "date-fns/locale";

const Settings = (props) => {
  const { user } = props;
  console.dir(user)
  const [showSwitch, setShowSwitch] = useState(false);
  const [showSwitch2, setShowSwitch2] = useState(false);
  const handleClose = () => {
    setShowSwitch(false);
  };
  const handleClose2 = () => {
    setShowSwitch2(false);
  };
  const changeModal2 = () => {
    setShowSwitch2(true);
  };

  const changeModal = () => {
    setShowSwitch(true);
  };

  return (
    <Container fluid className="advertising-area-container ">
      <Row>
        <Col className="pl-4">
          <p className="font-weight-bold font-size-22 ">Your Information</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="wrapper">
            <Image src={user.profilePic || newPhoto} className="owner-circle" />
            <Button
              variant=""
              className="d-flex justify-content-center"
            >
              <PenIcon style={{position: "absolute", bottom: "-10px", left:"80px", height: "30px"}}/>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="pl-4 pb-4 mt-5">
          <Form>
            <Form.Row>
              <Col>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  value={user.fullName}
                  className="font-weight-bold background-guidex settings-input-text"
                  readOnly
                  disabled
                />
              </Col>
              <Col>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  value={user.email}
                  className="background-guidex font-weight-bold settings-input-text"
                  readOnly
                  disabled
                />
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 d-flex self-align-center">
              <Col>
                <p className="mb-2 settings-label mt-2">Password</p>
                <Button variant="guidex" onClick={changeModal}>
                  Change Password
                </Button>
              </Col>
            </Form.Row>
          </Form>
          <Row className="mt-5 mb-4">
            <Col className="d-flex justify-content-between">
              <Button variant="guidex-light" onClick={changeModal2}>
                Delete Account <Image src={trashIcon} className="ml-2" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={showSwitch} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="ml-5 mr-5">
          <SettingsChangePassword onReturn={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal show={showSwitch2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="ml-5 mr-5">
          <SettingsDeleteAccount onCancel={handleClose2} onSubmit={handleClose2} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default Settings;
