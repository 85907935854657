import React, { useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Image,
  Row
} from "react-bootstrap";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { showToast } from "../../../../utils/Misc";
import EditIcon from "../../../../assets/icons/EditOutlineWhite.svg";
import { request } from "../../../../utils/HttpHelper";
import { useMediaQuery } from "react-responsive";
export default function Addresses(props) {
  const { businessAddress, billingAddress, guideType } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const [editMode, setEditMode] = useState(false);

  const changeEditMode = () => {
    setEditMode(!editMode);
  };

  const updateGuideAddresses = payload => {
    return new Promise(async (resolve, reject) => {
      try {
        await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: `/guide-${guideType === "FISHERMAN"? "fisher" : "hunter"}?addresses=true`,
          body: payload,
          requiresAuth: true
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const initValues = {
    locationAddress: businessAddress.addressLine1,
    locationCity: businessAddress.addressCity,
    locationState: businessAddress.addressState,
    locationZipCode: businessAddress.addressZipCode,
    billingAddress: billingAddress.addressLine1,
    billingCity: billingAddress.addressCity,
    billingState: billingAddress.addressState,
    billingZipCode: billingAddress.addressZipCode
  };

  return (
    <>
      <Container fluid className="general-details-container">
        <Formik
          initialValues={initValues}
          validate={values => {
            const errors = {};
            if (!values.locationAddress) {
              errors.locationAddress = "Required";
            }
            if (!values.locationCity) {
              errors.locationCity = "Required";
            }
            if (!values.locationState) {
              errors.locationState = "Required";
            }
            if (!values.locationZipCode) {
              errors.locationZipCode = "Required";
            }
            if (!values.billingAddress) {
              errors.billingAddress = "Required";
            }
            if (!values.billingCity) {
              errors.billingCity = "Required";
            }
            if (!values.billingState) {
              errors.billingState = "Required";
            }
            if (!values.billingZipCode) {
              errors.billingZipCode = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.dir(values);
            setSubmitting(true);
            const uploadValues = {
              guideBusinessAddress: {
                addressLine1: values.locationAddress,
                addressLine2: "",
                addressCity: values.locationCity,
                addressState: values.locationState,
                addressZipCode: values.locationZipCode
              },
              guideBillingAddress: {
                addressLine1: values.billingAddress,
                addressLine2: "",
                addressCity: values.billingCity,
                addressState: values.billingState,
                addressZipCode: values.billingZipCode
              }
            };
            console.dir(uploadValues);
            updateGuideAddresses(uploadValues)
              .then(() => {
                showToast(
                  <>
                    <AiFillCheckCircle /> Data saved correctly!
                  </>,
                  "success"
                );
                setSubmitting(false);
                setEditMode(false);
              })
              .catch(err => {
                setSubmitting(false);
                showToast(
                  <>
                    <AiFillWarning /> There was a problem trying to update.
                  </>,
                  "warning"
                );
              });
          }}
          onReset={values => {
            values = { ...initValues };
            setEditMode(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting
          }) => (
            <Form noValidate onSubmit={handleSubmit} onReset={handleReset}>
              {isTabletOrMobile ? (
                <>
                  <div>
                    <h5 className="primary-color font-weight-bold mt-5">
                      Addresses
                    </h5>
                  </div>
                  <div>
                    {" "}
                    <p className="font-weight-bold">Location of Business</p>
                    {!editMode ? (
                      <div>
                        <p className="mt-3 general-details-title">Address</p>
                        <p>
                          {values.locationAddress} <br />
                          {values.locationCity} <br />
                          {values.locationState} <br />
                          {values.locationZipCode}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <FormGroup controlId="businessAddress">
                          <Form.Label className="mt-3">Address</Form.Label>
                          <FormControl
                            name="locationAddress"
                            value={values.locationAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.locationAddress}
                            isValid={
                              touched.locationAddress && !errors.locationAddress
                            }
                          />
                          <Form.Control.Feedback
                            className="mt-2"
                            type="invalid"
                          >
                            {errors.locationAddress}
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup controlId="businessCity">
                          <Form.Label className="mt-3">City</Form.Label>
                          <FormControl
                            name="locationCity"
                            value={values.locationCity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.locationCity}
                            isValid={
                              touched.locationCity && !errors.locationCity
                            }
                          />
                          <Form.Control.Feedback
                            className="mt-2"
                            type="invalid"
                          >
                            {errors.locationCity}
                          </Form.Control.Feedback>
                        </FormGroup>
                        <Form.Row className="mt-3">
                          <Col>
                            <FormGroup controlId="businessState">
                              <Form.Label>State</Form.Label>
                              <FormControl
                                name="locationState"
                                value={values.locationState}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength={2}
                                inline="true"
                                isInvalid={!!errors.locationState}
                                isValid={
                                  touched.locationState && !errors.locationState
                                }
                              />
                              <Form.Control.Feedback
                                className="mt-2"
                                type="invalid"
                              >
                                {errors.locationState}
                              </Form.Control.Feedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup controlId="businessZipCode">
                              <Form.Label>ZipCode</Form.Label>
                              <FormControl
                                name="locationZipCode"
                                value={values.locationZipCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength={5}
                                inline="true"
                                isInvalid={!!errors.locationZipCode}
                                isValid={
                                  touched.locationZipCode &&
                                  !errors.locationZipCode
                                }
                              />
                              <Form.Control.Feedback
                                className="mt-2"
                                type="invalid"
                              >
                                {errors.locationZipCode}
                              </Form.Control.Feedback>
                            </FormGroup>
                          </Col>
                        </Form.Row>
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="font-weight-bold">Billing of Business</p>
                    {!editMode ? (
                      <div>
                        <p className="mt-3 general-details-title">Addresss</p>
                        <p>
                          {values.billingAddress} <br />
                          {values.billingCity} <br />
                          {values.billingState} <br />
                          {values.billingZipCode}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <FormGroup controlId="billingAddress">
                          <Form.Label className="mt-3">Address</Form.Label>
                          <FormControl
                            name="billingAddress"
                            value={values.billingAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.billingAddress}
                            isValid={
                              touched.billingAddress && !errors.billingAddress
                            }
                          />
                          <Form.Control.Feedback
                            className="mt-2"
                            type="invalid"
                          >
                            {errors.billingAddress}
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup controlId="billingCity">
                          <Form.Label className="mt-3">City</Form.Label>
                          <FormControl
                            name="billingCity"
                            value={values.billingCity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.billingCity}
                            isValid={touched.billingCity && !errors.billingCity}
                          />
                          <Form.Control.Feedback
                            className="mt-2"
                            type="invalid"
                          >
                            {errors.billingCity}
                          </Form.Control.Feedback>
                        </FormGroup>
                        <Form.Row className="mt-3">
                          <Col>
                            <FormGroup controlId="billingState">
                              <Form.Label>State</Form.Label>
                              <FormControl
                                name="billingState"
                                value={values.billingState}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength={2}
                                inline="true"
                                isInvalid={!!errors.billingState}
                                isValid={
                                  touched.billingState && !errors.billingState
                                }
                              />
                              <Form.Control.Feedback
                                className="mt-2"
                                type="invalid"
                              >
                                {errors.billingState}
                              </Form.Control.Feedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup controlId="billingZipCode">
                              <Form.Label>ZipCode</Form.Label>
                              <FormControl
                                name="billingZipCode"
                                value={values.billingZipCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength={5}
                                inline="true"
                                isInvalid={!!errors.billingZipCode}
                                isValid={
                                  touched.billingZipCode &&
                                  !errors.billingZipCode
                                }
                              />
                              <Form.Control.Feedback
                                className="mt-2"
                                type="invalid"
                              >
                                {errors.billingZipCode}
                              </Form.Control.Feedback>
                            </FormGroup>
                          </Col>
                        </Form.Row>
                        <Row className="mt-3">
                          <Col className="d-flex justify-content-end mt-5">
                            <div>
                              <Button
                                variant="secondary-cancel mr-5"
                                type="reset"
                                disabled={isSubmitting}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="save"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Save Changes
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                  <div>
                    {!editMode && (
                      <Button
                        variant="general-details"
                        className="btn-general-adresses"
                        onClick={changeEditMode}
                      >
                        <Row noGutters>
                          <Col xs="auto">Edit Adresses</Col>

                          <Col xs="auto" className="ml-auto">
                            <Image src={EditIcon} />
                          </Col>
                        </Row>
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Row>
                    <Col className="d-flex justify-content-between pt-5">
                      <h5 className="primary-color font-weight-bold">
                        Addresses
                      </h5>
                      {!editMode && (
                        <Button
                          variant="general-details"
                          className="btn-general-adresses"
                          onClick={changeEditMode}
                        >
                          <Row noGutters>
                            <Col xs="auto">Edit Adresses</Col>

                            <Col xs="auto" className="ml-auto">
                              <Image src={EditIcon} />
                            </Col>
                          </Row>
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="font-weight-bold">Location of Business</p>
                      {!editMode ? (
                        <div>
                          <p className="mt-3 general-details-title">Address</p>
                          <p>
                            {values.locationAddress} <br />
                            {values.locationCity} <br />
                            {values.locationState} <br />
                            {values.locationZipCode}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <FormGroup controlId="businessAddress">
                            <Form.Label className="mt-3">Address</Form.Label>
                            <FormControl
                              name="locationAddress"
                              value={values.locationAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.locationAddress}
                              isValid={
                                touched.locationAddress &&
                                !errors.locationAddress
                              }
                            />
                            <Form.Control.Feedback
                              className="mt-2"
                              type="invalid"
                            >
                              {errors.locationAddress}
                            </Form.Control.Feedback>
                          </FormGroup>
                          <FormGroup controlId="businessCity">
                            <Form.Label className="mt-3">City</Form.Label>
                            <FormControl
                              name="locationCity"
                              value={values.locationCity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.locationCity}
                              isValid={
                                touched.locationCity && !errors.locationCity
                              }
                            />
                            <Form.Control.Feedback
                              className="mt-2"
                              type="invalid"
                            >
                              {errors.locationCity}
                            </Form.Control.Feedback>
                          </FormGroup>
                          <Form.Row className="mt-3">
                            <Col>
                              <FormGroup controlId="businessState">
                                <Form.Label>State</Form.Label>
                                <FormControl
                                  name="locationState"
                                  value={values.locationState}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={2}
                                  inline="true"
                                  isInvalid={!!errors.locationState}
                                  isValid={
                                    touched.locationState &&
                                    !errors.locationState
                                  }
                                />
                                <Form.Control.Feedback
                                  className="mt-2"
                                  type="invalid"
                                >
                                  {errors.locationState}
                                </Form.Control.Feedback>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup controlId="businessZipCode">
                                <Form.Label>ZipCode</Form.Label>
                                <FormControl
                                  name="locationZipCode"
                                  value={values.locationZipCode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={5}
                                  inline="true"
                                  isInvalid={!!errors.locationZipCode}
                                  isValid={
                                    touched.locationZipCode &&
                                    !errors.locationZipCode
                                  }
                                />
                                <Form.Control.Feedback
                                  className="mt-2"
                                  type="invalid"
                                >
                                  {errors.locationZipCode}
                                </Form.Control.Feedback>
                              </FormGroup>
                            </Col>
                          </Form.Row>
                        </div>
                      )}
                    </Col>
                    <Col>
                      <p className="font-weight-bold">Billing of Business</p>
                      {!editMode ? (
                        <div>
                          <p className="mt-3 general-details-title">Addresss</p>
                          <p>
                            {values.billingAddress} <br />
                            {values.billingCity} <br />
                            {values.billingState} <br />
                            {values.billingZipCode}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <FormGroup controlId="billingAddress">
                            <Form.Label className="mt-3">Address</Form.Label>
                            <FormControl
                              name="billingAddress"
                              value={values.billingAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.billingAddress}
                              isValid={
                                touched.billingAddress && !errors.billingAddress
                              }
                            />
                            <Form.Control.Feedback
                              className="mt-2"
                              type="invalid"
                            >
                              {errors.billingAddress}
                            </Form.Control.Feedback>
                          </FormGroup>
                          <FormGroup controlId="billingCity">
                            <Form.Label className="mt-3">City</Form.Label>
                            <FormControl
                              name="billingCity"
                              value={values.billingCity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.billingCity}
                              isValid={
                                touched.billingCity && !errors.billingCity
                              }
                            />
                            <Form.Control.Feedback
                              className="mt-2"
                              type="invalid"
                            >
                              {errors.billingCity}
                            </Form.Control.Feedback>
                          </FormGroup>
                          <Form.Row className="mt-3">
                            <Col>
                              <FormGroup controlId="billingState">
                                <Form.Label>State</Form.Label>
                                <FormControl
                                  name="billingState"
                                  value={values.billingState}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={2}
                                  inline="true"
                                  isInvalid={!!errors.billingState}
                                  isValid={
                                    touched.billingState && !errors.billingState
                                  }
                                />
                                <Form.Control.Feedback
                                  className="mt-2"
                                  type="invalid"
                                >
                                  {errors.billingState}
                                </Form.Control.Feedback>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup controlId="billingZipCode">
                                <Form.Label>ZipCode</Form.Label>
                                <FormControl
                                  name="billingZipCode"
                                  value={values.billingZipCode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={5}
                                  inline="true"
                                  isInvalid={!!errors.billingZipCode}
                                  isValid={
                                    touched.billingZipCode &&
                                    !errors.billingZipCode
                                  }
                                />
                                <Form.Control.Feedback
                                  className="mt-2"
                                  type="invalid"
                                >
                                  {errors.billingZipCode}
                                </Form.Control.Feedback>
                              </FormGroup>
                            </Col>
                          </Form.Row>
                          <Row className="mt-3">
                            <Col className="d-flex justify-content-end mt-5">
                              <div>
                                <Button
                                  variant="secondary-cancel mr-5"
                                  type="reset"
                                  disabled={isSubmitting}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="save"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  Save Changes
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
