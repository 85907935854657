import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Container,
  Table,
  Modal,
  Image,
} from "react-bootstrap";
import { showToast } from "../../../../utils/Misc";
import { request } from "../../../../utils/HttpHelper";
import { AiFillCheckCircle } from "react-icons/ai";
import DataListInput from "react-datalist-input";
import AdvertisingModal from "./AdvertisingModal";
import editIcon from "../../../../assets/icons/edit-2.svg";
import Separator from "../../../../utils/Separator";

import "./AdvertisingPerArea.css";

const AdvertisingPerArea = (props) => {
  const { onApply, regions, species, activeRegionSpecies } = props;
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const [enableApply, setEnableApply] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [cancelId, setCancelId] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    setShowSwitch(false);
  };

  const changeModal = () => {
    setShowSwitch(true);
  };

  const initAdvertisignPerArea = async () => {};

  const applyForAdvertisement = () => {
    onApply(selectedRegion.key, selectedSpecies.key);
  };

  const stopAdvertisement = (regionSpeciesId) => {
    setDisabled(true) // Disable the buttons on the advertisement modal
    return new Promise(async (resolve, reject) => {
      try {
        let body = {
          regionSpeciesId: regionSpeciesId,
          status: "CANCELLING",
        };
        await request({
          method: "PUT",
          url: "guidex-api",
          trailingURL: "/subscription",
          body,
          requiresAuth: true,
        });
        resolve(
          setShowCancel(false),
          showToast(
            <>
              <AiFillCheckCircle /> The advertisement has been successfully
              cancelled.
            </>,
            "success"
          ),
          setDisabled(false)
        );
      } catch (error) {
        reject(error);
        setShowCancel(false);
        showToast(
          <>
            There was an error trying to stop the advertisement.
          </>,
          "error"
        )
        setDisabled(false)
      }
    });
  };

  useEffect(() => {
    initAdvertisignPerArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const match = (currentInput, item) => {
    return item.label.toLowerCase().includes(currentInput.toLowerCase());
  };

  useEffect(() => {
    setEnableApply(selectedRegion != null && selectedSpecies != null);
  }, [selectedRegion, selectedSpecies]);

  return (
    <Container fluid className="advertising-area-container ">
      <Row>
        <Col className="pl-4">
          <p className="font-weight-bold font-size-22">Advertsing Per Area</p>
          <p className="font-weight-bold font-size-13">
            Advertise to customers that are searching for specific species in
            your area and get seen first.{" "}
          </p>
          <p className="font-weight-bold mt-4">Add a new area and species</p>
        </Col>
      </Row>
      <Row>
        <Col className="pl-4 pb-4">
          <Form>
            <Form.Row>
              <Col md="3">
                <DataListInput
                  placeholder="Enter Area..."
                  inputClassName="background-guidex form-control"
                  dropDownLength={20}
                  items={regions}
                  onSelect={(selectedItem) => {
                    setSelectedRegion(selectedItem);
                  }}
                  match={match}
                />
              </Col>
              <Col md="3">
                <DataListInput
                  placeholder="Enter Species..."
                  inputClassName="background-guidex form-control"
                  dropDownLength={20}
                  items={species}
                  onSelect={(selectedItem) => {
                    setSelectedSpecies(selectedItem);
                  }}
                  match={match}
                />
              </Col>
              <Col md="1" className="pl-3">
                <Button
                  variant="guidex"
                  onClick={applyForAdvertisement}
                  disabled={!enableApply}
                >
                  {" "}
                  Apply
                </Button>
              </Col>
              <Col md="5" className="d-flex justify-content-end">
                <Button variant="guidex-light" onClick={changeModal}>
                  Request Area/Species
                </Button>{" "}
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <p className="mt-4 mb-5 ml-2  font-size-24 font-weight-bold">
            Active areas
          </p>
          <Table responsive borderless className="ranking-table font-size-15">
            <thead>
              <tr className="d-flex tr-head">
                <th className="col-3">Area</th>
                <th className="col-2">Species</th>
                <th className="col-1">Shares</th>
                <th className="col-2">Monthly Budget</th>
                <th className="col-1">Status</th>
              </tr>
            </thead>
            <tbody>
              {activeRegionSpecies.map((ars) => {
                console.log(ars);
                return (
                  <tr
                    className="d-flex align-items-center"
                    key={"activeARS_" + ars.regionID + "_" + ars.speciesID}
                  >
                    <td className="col-3 align-middle">{ars.regionLabel}</td>
                    <td className="col-2 align-middle">{ars.speciesLabel}</td>
                    <td className="col-1 align-middle">
                      {" "}
                      {ars.aquiredShares}%{" "}
                    </td>
                    <td className="col-2 align-middle"> $ {ars.dailyRate}</td>
                    <td className="col-1 role-name font-size-14">
                      <span
                        className={
                          ars.status === "live"
                            ? "dot-advertising-live"
                            : "dot-advertising-inactive"
                        }
                      ></span>
                      {ars.status}
                    </td>
                    <td className="col-1 align-middle">
                      <Image
                        src={editIcon}
                        onClick={() => {
                          onApply(ars.regionID, ars.speciesID);
                        }}
                      ></Image>
                    </td>
                    <td className="col-2">
                      <Button
                        variant="guidex-light"
                        onClick={() => {
                          setShowCancel(true);
                          setCancelId(ars.regionSpeciesID)
                        }}
                      >
                        Stop Ad
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showSwitch} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="ml-5 mr-5">
          <AdvertisingModal onClose={handleClose}></AdvertisingModal>
        </Modal.Body>
      </Modal>
      <Modal show={showCancel} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container className="d-flex justify-content-center">
            <Col md="auto">
              <Row className="mx-0 mb-2 align-items-center">
                <Col md="auto" className="px-0">
                  <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                    Are you sure? 
                  </h2>
                </Col>
                <Col className="px-0">
                  <Separator color="#EC7228" />
                </Col>
              </Row>
              <Row className="mx-0">
                <p className="card-text font-size-15">
                  Are you sure you want to cancel your subscription? The live status will show until the end of this month. 
                </p>
              </Row>
              <Row className="mx-0 mb-5 mt-4 float-right">
                <Button
                  variant="guidex"
                  type="button"
                  className="mr-3"
                  onClick={() => setShowCancel(false)}
                  disabled={disabled}
                >
                  Cancel
                </Button>
                <Button
                  variant="secondary-guidex"
                  type="button"
                  onClick={() => stopAdvertisement(cancelId)}
                  disabled={disabled}
                >
                  Stop Ad
                </Button>
              </Row>
            </Col>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default AdvertisingPerArea;
