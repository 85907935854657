import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Bear from "../../assets/pngs/Bear.png";
import Deer from "../../assets/pngs/Deer.png";
import Field from "../../assets/pngs/Field.png";
import GoingHunting from "../../assets/pngs/GoingHunting.png";
import HelicopterView from "../../assets/pngs/HelicopterView.png";
import Landscape from "../../assets/pngs/Landscape.png";
import RainbowHunting from "../../assets/pngs/RainbowHunting.png";
import Rocks from "../../assets/pngs/Rocks.png";
import gallery1 from "../../assets/pngs/fishing-gallery-1.png";
import gallery2 from "../../assets/pngs/fishing-gallery-2.png";
import gallery3 from "../../assets/pngs/fishing-gallery-3.png";
import gallery4 from "../../assets/pngs/fishing-gallery-4.png";
import gallery5 from "../../assets/pngs/fishing-gallery-5.png";
import gallery6 from "../../assets/pngs/fishing-gallery-6.png";
import gallery7 from "../../assets/pngs/fishing-gallery-7.png";
import gallery8 from "../../assets/pngs/fishing-gallery-8.png";
import "./Gallary.css";
const Gallary = (props) => {
  const history = useHistory();
  const { site } = props;
  return (
    <>
      <div id="noBackground">
        <Container fluid className="d-none d-sm-block">
          <div className="gallery">
            <figure className="gallery__item gallery__item--1">
              <img
                src={site !== "fishing" ? Rocks : gallery1}
                alt="Gallery 1"
                className="gallery__img "
              />
            </figure>
            <figure className="gallery__item gallery__item--2">
              <img
                src={site !== "fishing" ? Landscape : gallery2}
                alt="Gallery 2"
                className="gallery__img"
              />
            </figure>
            <figure className="gallery__item gallery__item--3">
              <img
                src={site !== "fishing" ? Bear : gallery3}
                alt="Gallery 3"
                className="gallery__img"
              />
            </figure>
            <figure className="gallery__item gallery__item--4">
              <img
                src={site !== "fishing" ? Deer : gallery4}
                alt="Gallery 4"
                className="gallery__img"
              />
            </figure>
            <figure className="gallery__item gallery__item--5">
              <img
                src={site !== "fishing" ? GoingHunting : gallery5}
                alt="Gallery 5"
                className="gallery__img"
              />
            </figure>
            <figure className="gallery__item gallery__item--6">
              <img
                src={site !== "fishing" ? HelicopterView : gallery6}
                alt="Gallery 6"
                className="gallery__img"
              />
            </figure>
            <figure className="gallery__item gallery__item--7">
              <img
                src={site !== "fishing" ? RainbowHunting : gallery7}
                alt="Gallery 7"
                className="gallery__img"
              />
            </figure>
            <figure className="gallery__item gallery__item--8">
              <img
                src={site !== "fishing" ? Field : gallery8}
                alt="Gallery 8"
                className="gallery__img"
              />
            </figure>
          </div>
        </Container>
        <Container fluid className="d-sm-none gallery-container">
          <Row noGutters>
            <Col xs={10}>
              <img
                onClick={() => {
                  history.push("/search?query=&target=hunting&category=all");
                }}
                src={Rocks}
                alt="Gallery 1"
                className="img-full-width"
              />
            </Col>
            <Col xs={12}>
              <img
                onClick={() => {
                  history.push("/search?query=&target=hunting&category=all");
                }}
                src={Landscape}
                alt="Gallery 2"
                className="pt-2 img-full-width"
              />
            </Col>
            <Col xs={12}>
              <img
                onClick={() => {
                  history.push("/search?query=&target=hunting&category=all");
                }}
                src={Deer}
                alt="Gallery 3"
                className="pt-2 img-full-width"
              />
            </Col>
          </Row>
        </Container>
        {props.page === "home" && (
          <Row className="mt-5 mb-5 text-center pl-auto justify-content-center">
            <Col>
              <h4 className="font-weight-bold d-inline text-dark">
                Adventure awaits.
              </h4>
              <Button
                variant="guidex"
                onClick={() => {
                  history.push(
                    `${
                      site != "fishing"
                        ? "/search?query=&target=hunting&category=all"
                        : "/search?target=fishing"
                    }`
                  );
                }}
                type="submit"
                className="font-size-16 btn-gallary btn-gallery-margin d-inline-block"
              >
                Search For Your Adventure
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default Gallary;
