import qs from "query-string";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Matches from "../../common/Matches";
import Adventures from "../Home/Adventures";
import FeaturedExpeditions from "../Home/FeaturedExpeditions";
import "./PLP.css";
import SearchBarPLP from "./SearchBarPLP";

export default function PLP() {
  const [trips, searchResultsTrips] = useState([]);
  const [guides, searchResultsGuides] = useState([]);
  const [typePLP, setTypePLP] = useState("hunting");
  const [searchParams, setSearchParams] = useState({
    target: "",
    query: "",
    category: "",
    location: "",
  });
  const [searchLocation, setSearchLocation] = useState();
  const location = useLocation();

  const setParamsByLocation = () => {
    window.scrollTo(0, 0);
    const params = qs.parse(location.search);
    if (params["target"]) {
      setTypePLP(params["target"]);
    }
    setSearchParams({
      ...params,
    });
  };

  useState(() => {
    const initialState = setParamsByLocation();
    return initialState;
  });

  useEffect(() => {
    setParamsByLocation();
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <>
      {typePLP === "hunting" ? (
        <div>
          <div id="imageBackgroundTopo">
            <SearchBarPLP
              target={typePLP}
              searchParams={searchParams}
              searchResultsTrips={searchResultsTrips}
              searchResultsGuides={searchResultsGuides}
            ></SearchBarPLP>
            <FeaturedExpeditions
              results={trips}
              background="imageBackgroundTopoAlt"
            ></FeaturedExpeditions>
          </div>

          <div className="outfitter-match">
            <Container className="mb-4 mt-4 px-0 outfitter-match-title font-family-Roboto">
              Outfitter Matches
            </Container>

            {guides.map((match, i) => {
              return (
                <Matches key={i} charterData={match} target={typePLP}></Matches>
              );
            })}

            {/* <Button
              variant="guidex-outline-primary"
              className="see-all-guide-btn font-weight-bold font-size-16"
            >
              See All Guide Results
            </Button> */}
          </div>
        </div>
      ) : (
        <div>
          <div id="imageBackgroundTopo">
            <SearchBarPLP
              target={typePLP}
              searchParams={searchParams}
              searchResultsTrips={searchResultsTrips}
              searchResultsGuides={searchResultsGuides}
              setSearchLocation={setSearchLocation}
            ></SearchBarPLP>
            <div id="imageBackgroundTopoAlt" className="matches pt-5 pb-5">
              <h3 className="charter-match-title px-0 container">
                Charter Matches
              </h3>
              {/*This will render the matches filtering those profiles that havent been filled out*/}
              {guides.filter(trip => trip.guideAboutUs !== null).map((match, i) => {
                return (
                  <div key={i} className="match-background">
                    <Matches charterData={match} target={typePLP}></Matches>
                  </div>
                );
              })}
            </div>
          </div>
          <Adventures
            target="fishing"
            searchLocation={searchLocation}
            title="Let's go fishing"
            redirectButton={'none'}
          />
        </div>
      )}
    </>
  );
}
