import React from "react";
import { Col, Row, Button, Container, Table, Image } from "react-bootstrap";
import "./AdvertisingRanking.css";
import editIcon from "../../../../assets/icons/edit-2.svg";
// import infoIcon from "../../../../assets/icons/alert-circle.svg";
// import closeIcon from "../../../../assets/icons/close.svg";
import newPhoto from "../../../../assets/others/new_photo.png";

const AdvertisingRanking = (props) => {

  const { onCheckout, onAddNew, onSelect, featuredTrips } = props;

  const getCleanDates = (dateString) => {
    const dt = new Date(dateString);
    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`;
  }

  return (
    <Container fluid className="advertising-area-container mt-5 ">
      <Row className=" mb-4 ">
        <Col className="pl-4 d-flex justify-content-between align-items-start">
          <Col>
            <p className="font-weight-bold font-size-22">
              Ranking on Featured Trips Page
            </p>
            <p className="font-weight-bold font-size-13">
              Advertise your specific trip(s) on our designated featured expeditions page.
              This is great for last minute reservation needs.
            </p>
          </Col>
          <Button variant="guidex" onClick={
            () => {
              onAddNew();
            }
          }>+ Add New</Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table responsive borderless className="ranking-table font-size-15">
            <thead>
              <tr className="d-flex tr-head">
                <th className="col-2">Image</th>
                <th className="col-3">Name of Package</th>
                <th className="col-3">Dates</th>
                <th className="col-2">Daily Budget</th>
                <th className="col-1">Status</th>
               
                <th className="col-1"></th> 
                
              </tr>
            </thead>
            <tbody>
              {
                featuredTrips.map(ft => {
                  return (
                    <tr className="d-flex align-items-center" key={"featured_" + ft.advertising.advertisingTripId}>
                      <td className="ranking-row col-2">
                        <Image src={ft.tripGallery.images.length > 0 ? ft.tripGallery.images[0].imageUrl : newPhoto} className="ranking-image"></Image>
                      </td>
                      <td className="col-3 font-size-14">{ft.trip.tripName}</td>
                      <td className="col-3 font-size-14">{getCleanDates(ft.advertising.startDate)} - {getCleanDates(ft.advertising.endDate)} </td>                    
                      <td className="col-2 font-size-14">${ft.advertising.dailyRate}</td>                    
                      <td className="col-1 role-name font-size-14">
                        <span className={
                          ft.advertising.status === 'live' ?
                            "dot-advertising-live" :
                            "dot-advertising-inactive" 
                        }></span>
                        {
                          ft.advertising.status
                        }
                      </td>
                      <td className="col-1">
                        {
                          ft.advertising.status !== 'live' ?
                            <Image src={editIcon} onClick={() => {
                              onSelect(ft.advertising);
                            }}></Image> : <p></p>
                          /*  :
                            <Button variant="guidex-light" onClick={() => {
                              onSelect(ft.advertising);
                            }}>Advertise</Button>*/
                        }
                      </td>
                    </tr>
                  )
                })
              }
              {/* <tr className="ranking-table-message">
                <td colSpan="5">
                  <div className="ranking-message font-weight-bold d-flex justify-content-between">
                    <div>
                      <Image src={infoIcon} className="mr-3 ml-4" />
                      This package has been out byt by someone else.{" "}
                      <u className="font-weight-light ml-2">Edit My Budget</u>
                    </div>
                    <Image src={closeIcon} className="mr-3"></Image>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
export default AdvertisingRanking;
