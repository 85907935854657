import React, { useEffect, useState, useContext } from "react";
import Loader from 'react-loader-spinner'
import GeneralDetails from "./GeneralDetails";
import Adresses from "../../Common/Profile/Addresses";
import "../../Common/Profile/Profile.css";
import { showToast } from "../../../../utils/Misc";
import { AiFillWarning } from "react-icons/ai";
import { request } from "../../../../utils/HttpHelper";
import { Staff } from "../../../../utils/staffProvider";
import { useMemo } from "react";

const ProfileHunter = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hunterGuideData, setHunterGuideData] = useState({
    billingAddress: {},
    businessAddress: {},
    guide: {
      useBusinessAsBilling: false,
    },
    huntingMethods: [],
    tagTypes: [],
    licenses: []
  });
  const [huntingMethodCat, setHuntingMethodCat] = useState([]);
  const [tagTypeCat, setTagTypeCat] = useState([]);

  const { signInAs, guideType } = useContext(Staff)

  const [species, setSpecies] = useState([]);

  const initSpiciesList = async () => {
    let speciesList = await getSpeciesCatalogue();

    setSpecies(speciesList.data.map(region => {
      let item = {
        label: region.advName,
        key: region.advID
      };
      return item;
    }));

  }

  const getSpeciesCatalogue = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let speciesResult = await request({
          url: 'guidex-api',
          trailingURL: `/adv-apply?advType=SPECIES`, //${signInAs != "FALSE" && "&is_staff=TRUE"}
          requiresAuth: true
        });
        resolve(speciesResult.data)
      } catch (error) {
        console.error(error);
      }
    })
  }

  const getGuideHunterData = async (guideId) => {
    //if (guideType !== "HUNTER") return;
    return new Promise((resolve, reject) => {
      request({
        url: 'guidex-api',
        trailingURL: `/guide-hunter${signInAs ? `?id=${signInAs}` : ""}`,
        requiresAuth: true
      })
        .then(result => {
          setHunterGuideData(result.data);
          resolve();
        })
        .catch(err => {
          reject(err)
        });
    })
  }

  const getHuntingMethodCatalog = () => {
    return new Promise((resolve, reject) => {
      request({
        url: 'guidex-api',
        trailingURL: `/huntingmethod`
      })
        .then(result => {
          setHuntingMethodCat(result.data['huntingMethods'] || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
    });
  }

  const getTagTypeCatalog = () => {
    return new Promise((resolve, reject) => {
      request({
        url: 'guidex-api',
        trailingURL: `/tagtype`
      })
        .then(result => {
          setTagTypeCat(result.data['tagTypes'] || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
    });
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const hunterRoleData = user.otherRoles.find(r => {
        return r.roleName === "HUNTER"
      });
      await getGuideHunterData(hunterRoleData.userId);
      await getHuntingMethodCatalog();
      await getTagTypeCatalog();
      await initSpiciesList();
    } catch (error) {
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch the data.
        </>,
        "warning"
      );
    }
    setIsLoading(false);
  }

  useMemo(() => {
    fetchData();
  }, [])
  
  return (

    <div className="mb-5">
      {
        isLoading ?
          <center className="mt-5">
            <Loader
              type="TailSpin"
              color="#ec7228"
              height={150}
              width={150}
            />
          </center>
          :
          <>
            <GeneralDetails
              guideData={hunterGuideData.guide}
              huntingMethodsCat={huntingMethodCat}
              tagTypesCat={tagTypeCat}
              huntingMethods={hunterGuideData.huntingMethods}
              tagTypes={hunterGuideData.tagTypes}
              species={species}
              loginAsSpecies={hunterGuideData.guide.species}
            />
            <Adresses
              businessAddress={hunterGuideData.businessAddress}
              billingAddress={hunterGuideData.billingAddress}
            // useBuAdasBiAd={hunterGuideData.guide.useBusinessAsBilling}
            />
            {/* <ContactList></ContactList> */}
          </>
      }
    </div>
  );
};
export default ProfileHunter;
