import algoliasearch from "algoliasearch";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import Trips from "../../common/Trips";
import { request } from "../../utils/HttpHelper";
import Carrousel from "../Home/Carrousel";
import FeaturedExpeditions from "../Home/FeaturedExpeditions";
import Captain from "./Capitan/Captain";
import "./HuntingTrip.css";
import PopularFeatures from "./PopularFeatures";
const HuntingTrip = () => {
  const history = useHistory();
  const [results, setResults] = useState([]);
  const [trip, setTrip] = useState();
  const [tags, setTags] = useState();
  const [guide, setGuide] = useState();
  const [rating, setRating] = useState();
  const [gallery, setGallery] = useState();
  const { id } = useParams();

  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const index = searchClient.initIndex("HUNTING_TRIP");

  const search = () => {
    index
      .search("", {
        filters: "featured:true",
        hitsPerPage: 3,
      })
      .then(({ hits }) => {
        setResults(hits);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    search();
    if (id) processTrip();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processTrip = async () => {
    getHuntingTripById().then((res) => {
      console.log(res)
      setTrip({ ...res.trip });
      setTags([...res.tags]);
      setGuide({ ...res.guide });
      setRating({ ...res.rating });
      setGallery({...res.tripGallery})
    });
  };
  const getHuntingTripById = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "GET",
          url: "guidex-api",
          trailingURL: `/hunt-trip?id=${id}`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      <Container fluid className="pdp px-0" id="imageBackgroundTopo">
        {!!trip && !!tags && !!guide && !!rating && !!gallery && (
          <>
            <div className="bg-green-light">
              <Container>
                <Row className="">
                  <Col>
                    <Container fluid className="px-3 max-widht-card">
                      <h5
                        onClick={() => {
                          history.goBack();
                        }}
                        className="back d-inline-block"
                      >
                        <MdKeyboardArrowLeft size="20" /> Back to Results
                      </h5>
                    </Container>
                    <Trips
                      trip={trip}
                      tags={tags}
                      guide={guide}
                      rating={rating}
                      gallery={gallery}
                      page="pdp"
                    ></Trips>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="bg-green-light py-5">
              <PopularFeatures tags={tags}></PopularFeatures>
            </div>
            <div className="bg-grey py-5">
              <Captain trip={trip} guide={guide}></Captain>
            </div>
            <Row className="noBackground">
              <Col>
                <Carrousel
                  trip={trip}
                  tags={tags}
                  guide={guide}
                  rating={rating}
                  page="pdp"
                ></Carrousel>
              </Col>
            </Row>
            <Row className="noBackground">
              <Col>
                <FeaturedExpeditions
                  results={results}
                  background="noBackground"
                  page="PDP"
                ></FeaturedExpeditions>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};
export default HuntingTrip;
