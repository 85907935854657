import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { Form, Image } from "react-bootstrap"
import { request } from "../../../../utils/HttpHelper";
import navigation from "../../../../assets/icons/navigation.svg";
import ZoomIn from "../../../../assets/icons/Zoom In.svg";
import ZoomOut from "../../../../assets/icons/Zoom Out.svg";
import  "./MapGeolocation.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWlrZWZyYW5jbzM5MzkiLCJhIjoiY2tpM3U2emQ4M2VjZTJ5bjN6NjEybnNmcyJ9.Tsm309LXDKV3Mw2MtgJrRw";

const searchPath = "/geocoding/v5/mapbox.places/{search_text}.json";

const MapGeolocation = (props) => {

  const { storedGeolocation, onChangeGeoloction } = props;
  const [ defaultLat, defaultLng ] = storedGeolocation && storedGeolocation.lat && storedGeolocation.lng ?
   [ storedGeolocation.lat, storedGeolocation.lng ] : [ 44.460938, -93.185195 ];
  const defaultZoom = storedGeolocation && storedGeolocation.lat && storedGeolocation.lng ? 9 : 2.5;

  const mapContainerRef = useRef(null);
  // eslint-disable-next-line
  const [lng, setLng] = useState(defaultLng);
  // eslint-disable-next-line
  const [lat, setLat] = useState(defaultLat);
  // eslint-disable-next-line
  const [zoom, setZoom] = useState(defaultZoom);

  const [searchTerm, setSearchTerm] = useState("");

  const [map, setMap] = useState();
  const [marker, setMarker] = useState();

  const searchPlace = (placeStr) => {
    return new Promise( async(resolve, reject) => {
      try {
        const placeParsed = placeStr;
        const searchPathPlace = searchPath.replace("{search_text}", placeParsed) + `?access_token=${mapboxgl.accessToken}&autocomplete=true&limit=10&country=US`;
        const result = await request({
          url: 'mapbox-api',
          trailingURL: searchPathPlace
        });
        resolve(result.data);
        console.log(result.data)
      } catch (error) {
        reject(error);
      }
    });
  }

  const locationSearch = (e) => {
    setSearchTerm(e.target.value);
  }

  const submitSearch = (e) => {
    if(e.key === "Enter" && searchTerm.trim().length > 0){
      e.preventDefault();
      searchPlace(searchTerm)
      .then( result => {
        if(result){
          const { features } = result;
          if(features && features.length > 0){
            let firstResult = features[0];
            let [rLng, rLat] = firstResult.center;
            setLng(rLng);
            setLat(rLat);
            map.setZoom(9);
            map.setCenter([rLng, rLat]);
            marker.setLngLat([rLng, rLat]);
          }
        }
        setSearchTerm("");
      })
    }
  }

  const getUserCoords = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude, longitude } = pos.coords;
        setLng(longitude);
        setLat(latitude);
        map.setZoom(9);
        map.setCenter([longitude, latitude]);
        marker.setLngLat([longitude, latitude]);
      });
    }
  }
  
  useEffect(() => {
    const tempMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [lng, lat],
      zoom: zoom,
      pitch: 0,
      doubleClickZoom: false,
    });

    tempMap.on("load", (e) => {
      console.dir(e);
    });

    let markerEl = document.createElement("div");
    markerEl.className = "marker-geolocation";

    let tempMarker = new mapboxgl.Marker(markerEl)
      .setLngLat([lng, lat])
      .setDraggable(true)
      .addTo(tempMap);

    tempMarker.on("dragend", (e) => {
      setLng(e.target._lngLat.lng);
      setLat(e.target._lngLat.lat);
    });

    setMap(tempMap);
    setMarker(tempMarker);

    //return () => map.remove();
  }, [mapContainerRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChangeGeoloction([lat, lng]);
  // eslint-disable-next-line
  }, [lat, lng])

  return (
    <div className="map-geolocation-wrapper">
      <Form.Control
          type="text"
          placeholder="Search a city or zipcode..."
          onChange={locationSearch}
          onKeyPress={submitSearch}
        />
        <Image
          className="my-location-button"
          src={navigation}
          onClick={() => {
            getUserCoords();
          }}
        />
      <Image
        className="zoom-in-geolocation"
        src={ZoomIn}
        onClick={() => {
          setZoom( lastZoom => lastZoom + 1 );
          //map.setZoom(zoom);
          map.zoomIn({duration: 500});
        }}
      />
      <Image
        className="zoom-out-geolocation"
        src={ZoomOut}
        onClick={() => {
          setZoom( lastZoom => lastZoom - 1 );
          //map.setZoom(zoom);
          map.zoomOut({duration: 500});
        }}
      />
      <div className="map-geolocation-container" ref={mapContainerRef} />
    </div>
  );

}

export default MapGeolocation;