import React from "react";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import ContactUsForm from "./ContactUsForm";

const ContactUs = () => {
  return (
    <div id="imageBackgroundContacUs">
      <Container className="d-flex justify-content-center">
        <Col md="auto" className="guidex-card">
          <ContactUsForm />
        </Col>
      </Container>
    </div>
  );
};
export default ContactUs;
