import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./TDestinations.css";

const TDestinations = ({ site }) => {
  const history = useHistory();
  return (
    <>
      <div id="noBackground">
        <Container fluid className=" mb-5">
          <Row className="featured-cards">
            <Col md="8" className="TDestinations-title">
              <p className="primary-color font-weight-bold mt-5 mb-1">
                Explore the great outdoors
              </p>
              <h1 className="font-weight-bold text-dark">Trending Destinations</h1>
            </Col>
            <Col
              md="4"
              className="align-self-end d-none d-md-block flex-row-reverse "
            >
              <Button
                variant="TDestinations"
                type="submit"
                className="font-size-16 ml-auto  d-none d-md-block btn-search-adventure"
                onClick={() => {
                  history.push(site === "fishing" ? "/search?target=fishing" :
                    "/search?target=hunting"
                  );
                }}
              >
                Search for Adventure
              </Button>
            </Col>
          </Row>

          <Row className="container-td-cards featured-cards mt-3">
            <Col sm={12} md={4} xl={4} className="px-2 mt-3 zoom-img">
              <div
                id="imageBacgroundColorado"
                className="rounded cursor-pointer"
                onClick={() => {
                  history.push(
                    `/search?query=Colorado&target=${site === "fishing"? "fishing" : "hunting"}&category=location`
                  );
                }}
              >
                <div className="text-image">Colorado</div>
              </div>
            </Col>
            <Col sm={12} md={4} xl={4} className="px-2 mt-3 zoom-img">
              <div
                id="imageBacgroundFlorida"
                className="rounded cursor-pointer"
                onClick={() => {
                  history.push(
                    `/search?query=Florida&target=${site === "fishing"? "fishing" : "hunting"}&category=location`
                  );
                }}
              >
                <div className="text-image">Florida</div>
              </div>
            </Col>
            <Col sm={12} md={4} xl={4} className="px-2 mt-3 zoom-img">
              <div
                id="imageBacgroundAlaska"
                className="rounded cursor-pointer"
                onClick={() => {
                  history.push(
                    `/search?query=Alaska&target=${site === "fishing"? "fishing" : "hunting"}&category=location`
                  );
                }}
              >
                <div className="text-image">Alaska</div>
              </div>
            </Col>
          </Row>
          <Row className=" featured-cards">
            <Col sm={12} md={6} xl={6} className="px-2 mt-3 zoom-img">
              <div
                id="imageBacgroundWyoming"
                className="rounded cursor-pointer"
                onClick={() => {
                  history.push(
                    `/search?query=Wyoming&target=${site === "fishing"? "fishing" : "hunting"}&category=location`
                  );
                }}
              >
                <div className="text-image">Wyoming</div>
              </div>
            </Col>
            <Col sm={12} md={6} xl={6} className="px-2 mt-3 zoom-img">
              <div
                id="imageBacgroundMaine"
                className="rounded cursor-pointer"
                onClick={() => {
                  history.push(
                    `/search?query=Maine&target=${site === "fishing"? "fishing" : "hunting"}&category=location`
                  );
                }}
              >
                <div className="text-image">Maine</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default TDestinations;
