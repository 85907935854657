import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { Alert, Button, Col, Container, Dropdown, Image, Row, Modal, Form } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import "react-dropdown/style.css";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Loader from "react-loader-spinner";
import StarRatings from "react-rating-stars-component";
import archive from "../../../assets/icons/archive.svg";
import checkCircle from "../../../assets/icons/checkmark-circle.svg";
import email from "../../../assets/icons/email.svg";
import starEmptyIcon from "../../../assets/icons/Star-Empty-orange.svg";
import starFullIcon from "../../../assets/icons/Star-Full-orange.svg";
import starHalfIcon from "../../../assets/icons/Star-Half-orange.svg";
import { request } from "../../../utils/HttpHelper";
import Table from "../../../utils/TableDAD";
import RequestReview from "../GuideHunter/Reviews/RequestReview";

const Reviews = (props) => {
  const { user } = props;
  const [tab, setTab] = useState("live");
  const [step, setStep] = useState(null);
  const [reviewsLive, setReviewsLive] = useState([]);
  const [reviewsArchive, setReviewsArchive] = useState([]);
  const [reviewsNew, setReviewsNew] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newOrder, setNewOrder] = useState([]);
  const [bodyNewOrder, setBodyNewOrder] = useState(null);
  const [entityTypeId, setEntityTypeId] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [reviewRequesteeEmail, setReviewRequesteeEmail] = useState("");

  const [rating, setRating] = useState({
    reviewsAmount: 0,
    reviewsAverage: 0
  })
  const columns = useMemo(
    () => [
      {
        Header: "Rating",
        accessor: "reviewRating",
        className: "rating-cell",

        Cell: ({ cell: { value } }) => (
          <StarRatings
            size={0}
            count={5}
            value={value}
            edit={false}
            isHalf={true}
            emptyIcon={
              <Image src={starEmptyIcon} width="13px" className="mr-1"></Image>
            }
            halfIcon={
              <Image src={starHalfIcon} width="15px" className="mr-1"></Image>
            }
            filledIcon={
              <Image src={starFullIcon} width="15px" className="mr-1"></Image>
            }
            classNames="mb-0 d-inline"
          />
        ),
        // Cell: ({ cell: { value } }) => (
        //   <img src={value} alt="asdas" width={60} />
        // ),
      },
      {
        Header: "Author",
        accessor: "reviewRequesteeName",
        className: "rating-cell",
      },
      {
        Header: "Was your trip successful?",
        accessor: "wasSuccessful",
        className: "rating-cell",
      },
      {
        Header: "Was your experience as advertised?",
        accessor: "wasAsAdvertised",
        className: "rating-cell",
      },
      
      {
        Header: "Message",
        accessor: "reviewRequesteeMessage",
      },
      {
        id: "checkbox-table-column",
        accessor: "reviewId",

        Cell: (props) => {
          const dropDownButton = (data) => {
            switch (data.reviewStatus) {
              case "live":
                return (
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="dropdown-table"
                      onClick={() => setStatusReview(data, "archived")}
                    >
                      <Image src={archive} /> Archive
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-table"
                      onClick={() => {
                        contactAuthorById(data);
                      }}
                    >
                      <Image src={email} /> Contact Author
                    </Dropdown.Item>
                  </Dropdown.Menu>
                );
              case "new":
              case "archived":
                return (
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="dropdown-table"
                      onClick={() => setStatusReview(data, "live")}
                    >
                      <Image src={checkCircle} /> Approve Review
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-table"
                      onClick={() => {
                        contactAuthorById(data);
                        setModalShow(true);
                      }}
                    >
                      <Image src={email} /> Contact Author
                    </Dropdown.Item>
                  </Dropdown.Menu>
                );

              default:
                break;
            }
          };

          return (
            <>
              <Dropdown drop="down" id="dropdowmTable">
                <Dropdown.Toggle>
                  <BiDotsHorizontalRounded size={35} />
                </Dropdown.Toggle>
                {dropDownButton(props.row.original)}
              </Dropdown>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getEntityData = () => {
    return new Promise((resolve, reject) => {
      try {
        const { selectedRole } = user;
        const selRoleData = user.otherRoles.find(
          (r) => r.roleName === selectedRole
        );
        const { userId } = selRoleData;
        let roleTypeId = 0;
        switch (selectedRole) {
          case "HUNTER":
            roleTypeId = 1;
            break;
          case "FISHERMAN":
            roleTypeId = 2;
            break;
          default:
        }
        resolve({
          roleTypeId,
          userId
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  const getReviews = async () => {
    setIsLoading(true);

    const { roleTypeId } = await getEntityData();

    setEntityTypeId(roleTypeId);

    const result = await getGuideReviews(roleTypeId);

    let rating = result.rating;
    let reviewsNewFilter = result.newReviews;
    let reviewsLiveFilter = result.liveReviews;
    let reviewsArchivedFilter = result.archivedReviews;

    reviewsNewFilter = reviewsNewFilter.map((row, index) => {
      row.id = index;

      return row;
    });

    reviewsLiveFilter = reviewsLiveFilter.map((row, index) => {
      row.id = index;
      return row;
    });

    reviewsArchivedFilter = reviewsArchivedFilter.map((row, index) => {
      row.id = index;

      return row;
    });

    setRating({ ...rating });
    setReviewsLive(reviewsLiveFilter);
    setReviewsArchive(reviewsArchivedFilter);
    setReviewsNew(reviewsNewFilter);
    setIsLoading(false);
  };

  useEffect(() => {
    getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestReviewForm = () => {
    setStep("requestReview");
  };

  // const approveReviewById = (id) => {
  //   console.log(id);
  // };

  const setStatusReview = async (review, status) => {
    let body = {
      reviewId: review.reviewId,
      reviewEntityTypeId: 1,
      state: status,
    };
    try {
      let result = await request({
        method: "PUT",
        body,
        url: "guidex-api",
        trailingURL: `/reviews`,
        requiresAuth: true,
      });
      if (result.status === 200) {
        getReviews();
      }
    } catch (error) {
      console.log(error); 
     }
  };

  const contactAuthorById = (review) => {
    setReviewRequesteeEmail(review.reviewRequesteeEmail); 
    setModalShow(true);
  };

  const getGuideReviews = async (roleTypeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/reviews?entityTypeID=${roleTypeId}`,
          requiresAuth: true,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const setNewRows = (data) => {
    setNewOrder(data);
    let body = data.map((item, i) => {
      let container = {};
      container.reviewId = item.reviewId;
      container.currentIndex = item.reviewOrder;
      container.newIndex = i + 1;
      return container;
    });
    let fullBody = {
      entityTypeId: entityTypeId,
      reviewArrange: body,
    };
    console.dir(data);
    console.dir(fullBody);
    setBodyNewOrder(fullBody);
  };
  const updateOrderReviewsLive = async () => {
    // console.log(reviewsLive);
    // console.log(bodyNewOrder);
    bodyNewOrder.reviewArrange.forEach((newItem, i) => {
      // console.log(i);
      // console.log(newItem.currentIndex);
      // console.log(reviewsLive[i].reviewOrder);
      newItem.newIndex = reviewsLive[i].reviewOrder
    })
    console.table(bodyNewOrder.reviewArrange);
    try {
      let result = await request({
        method: "POST",
        body: bodyNewOrder,
        url: "guidex-api",
        trailingURL: `/rearrange-reviews`,
        requiresAuth: true,
      });
      console.log(result);
      if (result.status === 200) {
        getReviews();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isLoading ? (
        <center>
          <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
        </center>
      ) : (
        <>
          <MyVerticallyCenteredModal
                show={modalShow}
                email={reviewRequesteeEmail}
                user={user}
                onHide={() => setModalShow(false)}
          />
          {~step && props.page === "staff" && (
            <>
              <h2 className="mb-5 approve-guides-title portal-section-title mt-5">
                Reviews
              </h2>
              <Container fluid className="approve-guides-container mb-5">
                <Row>
                  <Col>
                    <DndProvider backend={HTML5Backend}>
                      {reviewsNew.length ? (
                        <Table columns={columns} data={reviewsNew} />
                      ) : (
                        <></>
                      )}
                    </DndProvider>
                  </Col>
                </Row>
              </Container>
            </>
          )}

          {!step && props.page === "guide" && (
            <div className="mt-5 mb-5">
              <div className="d-flex justify-content-between">
                <div>
                  <p className="reviews-title d-inline mr-3">Reviews</p>
                  <div className="d-inline-block">
                    <StarRatings
                      size={0}
                      count={5}
                      value={rating.reviewsAverage}
                      edit={false}
                      isHalf={true}
                      emptyIcon={
                        <Image
                          src={starEmptyIcon}
                          width="13px"
                          className="mr-1"
                        ></Image>
                      }
                      halfIcon={
                        <Image
                          src={starHalfIcon}
                          width="15px"
                          className="mr-1"
                        ></Image>
                      }
                      filledIcon={
                        <Image
                          src={starFullIcon}
                          width="15px"
                          className="mr-1"
                        ></Image>
                      }
                      classNames="mb-0 "
                    />
                  </div>
                  <p className=" primary-color font-weight-bold font-size-20 d-inline ml-3">
                    {rating.reviewsAverage.toFixed(1)} stars <span style={{ color: "black" }}>({rating.reviewsAmount})</span>
                  </p>
                </div>
                <Button
                  variant="request-review"
                  onClick={() => {
                    requestReviewForm();
                  }}
                >
                  Request a Review <span className="font-size-20">+</span>
                </Button>
              </div>
              {(tab === "new" || tab === "archive") && (
                <p className="mb-5" style={{ color: "transparent" }}>
                  Order will be reflected on the guide profile page
                </p>
              )}
              {tab === "live" && (
                <p className="mb-5">
                  Order will be reflected on the guide profile page
                </p>
              )}
              <Row className="mb-3">
                <Col className="  reviews-tab-guide mb-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      {/* <p
                        className={`font-weight-bold mr-5 d-inline ${
                          tab === "new" ? "active" : ""
                        }`}
                        onClick={() => setTab("new")}
                      >
                        New
                      </p> */}
                      <p
                        className={`ml-5 mr-5 d-inline font-weight-bold ${tab === "live" ? "active" : ""
                          }`}
                        onClick={() => setTab("live")}
                      >
                        Live
                      </p>
                      <p
                        className={`ml-5 mr-5 d-inline font-weight-bold ${tab === "archive" ? "active" : ""
                          }`}
                        onClick={() => setTab("archive")}
                      >
                        Archive
                      </p>
                    </div>
                    {tab === "live" ? (
                      <Button
                        variant="guidex"
                        disabled={newOrder.length ? false : true}
                        onClick={() => {
                          updateOrderReviewsLive();
                        }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        variant="guidex"
                        style={{ opacity: "0" }}
                        disabled
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <Container fluid className="reviews-guide-container">
                <Row>
                  <Col>
                    <DndProvider backend={HTML5Backend}>
                      <div
                        className={`${tab === "new" ? "d-block" : "d-none"}`}
                      >
                        {reviewsNew.length ? (
                          <Table
                            columns={columns}
                            updateData={setNewRows}
                            drag={false}
                            data={reviewsNew}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className={`${tab === "live" ? "d-block" : "d-none"}`}
                      >
                        {reviewsLive.length ? (
                          <Table
                            columns={columns}
                            drag={true}
                            updateData={setNewRows}
                            data={reviewsLive}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className={`${tab === "archive" ? "d-block" : "d-none"
                          }`}
                      >
                        {reviewsArchive.length ? (
                          <Table
                            columns={columns}
                            drag={false}
                            updateData={setNewRows}
                            data={reviewsArchive}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </DndProvider>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {step && (
            <div className="mt-5 mb-5">
              <p className="reviews-title d-inline mr-3 mb-5">
                Request a Review
              </p>              
              <RequestReview entityTypeId={entityTypeId} onReturn={() => {
                setStep(null);
              }} />
            </div>
          )}
        </>
      )}
    </>
  );
};

function MyVerticallyCenteredModal(props) {

  const sendEmail = async (values) => {
    
     try{
      const emailForm = {
        emailTo: props.email,
        name: props.user.fullName,
        phone: "Guide",
        entityType: "guide_hunter",
        email: props.user.email,
        message: values.message
      }
      const response = await request({
        method: "POST",
        url: 'guidex-api',
        trailingURL: "/send-email",
        body: emailForm
      });

      if(response.status == 200)
      props.onHide();

    }catch (error){
      console.log(error);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Send Email to {props.email}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Formik
            initialValues={{
              message: ""
            }}
            validate={values => {
              const errors = {};
              if (!values.message) {
                errors.message = "Required";
              }
              return errors;
            }} onSubmit={(values, { setSubmitting }) => {
                sendEmail(values);
                setSubmitting(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                
              <Form.Group controlId="formBasicMessage">
              <Form.Label className="d-flex justify-content-between">
                Type your message to the customer <span>Max. 500 Characters</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                placeholder="Message"
                isInvalid={!!errors.Message}
                isValid={touched.Message && !errors.Message}
              />
              <Form.Control.Feedback className="mt-2" type="invalid">
                {errors.message}
              </Form.Control.Feedback>              
            </Form.Group>
            <Button disabled={isSubmitting} variant="guidex" type="submit">Send</Button>
            </Form>
           )} 
           </Formik>
             
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}



export default Reviews;
