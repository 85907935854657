import algoliasearch from "algoliasearch";
import React, { Suspense, useEffect, useState } from "react";
import Adventures from "./Adventures";
import FeaturedExpeditions from "./FeaturedExpeditions";
import SearchBar from "./SearchBar";
import TDestinations from "./TDestinations";

/**
 * Todo: Implement React Lazy to improve loafing performance
 */

const Gallary = React.lazy(() => import('./Gallary'))
const AboutGuidex = React.lazy(() => import('./AboutGuidex'))
const Carrousel = React.lazy(() => import('./Carrousel'))

export default function Home() {
  window.scrollTo(0, 0);

  const [tabStatus, setTabStatus] = useState("fishing");
  const [results, setResults] = useState([]);

  const changeTabHandler = (tab) => {
    switch (tab) {
      case "fishing":
        setTabStatus("fishing");
        break;
      default:
        setTabStatus("hunting");
    }
  };

  const locationHandler = (position) => {
  };

  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const index = searchClient.initIndex("HUNTING_TRIP");

  const search = () => {
    index
      .search("", {
        filters: "featured:true",
        hitsPerPage: 3,
      })
      .then(({ hits }) => {
        setResults(hits);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStatus]);
  return (
    <>
      <SearchBar
        onChangeTab={changeTabHandler}
        onLocationAllow={locationHandler}
      />
      {tabStatus === "fishing" ? (
        <>
          <div style={{ color: "white" }}>
            <Adventures
              className="adventureText"
              target={tabStatus}
              title="Let's go fishing"
              onLocationAllow={locationHandler}
            />
            <TDestinations site={tabStatus} />
            <Suspense fallback={<div>Loading...</div>}>
              <AboutGuidex />
              <Carrousel page="home" site={tabStatus} />
              <Gallary page="home" site={tabStatus} />
            </Suspense>
          </div>
        </>
      ) : (
        <>
          <FeaturedExpeditions
            results={results}
            page="featured"
            background="imageBackgroundFeaturedExpeditions"
          />
          <Adventures
            target={tabStatus}
            title="Expeditions Near You"
            onLocationAllow={locationHandler}
          />
          <TDestinations />
          <AboutGuidex />
          <Carrousel page="home" />
          <Gallary page="home" />
        </>
      )}
    </>
  );
}
