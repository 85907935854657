import { request } from "../../utils/HttpHelper";

export default () => {
  return new Promise(async(resolve, reject) => {
    try {
      let favoritesResult = await request({
        url: "guidex-api",
        trailingURL: "/favorites",
        requiresAuth: true
      });
      resolve(favoritesResult.data);
    } catch (error) {
      reject(error);
    }
  })
}