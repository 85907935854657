import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import StarRatings from "react-rating-stars-component";
import { Link, useHistory } from "react-router-dom";
import starEmptyIcon from "../assets/icons/Star-Empty-orange.svg";
import starFullIcon from "../assets/icons/Star-Full-orange.svg";
import starHalfIcon from "../assets/icons/Star-Half-orange.svg";
//import Favorite from "./Favorite";
import "./Matches.css";
const Matches = (props) => {
  const { charterData, target } = props;
  const history = useHistory();

  return (
    <>
      <Container
        className={`${
          props.align === "left" ? "ml-0" : null
        }  "shadow-sm bg-white p-0 mb-4 max-width-content"`}
      >
        <Row noGutters className="outfitter-card">
          <Col xs={1} md={3} className="d-none d-md-block">
            <Image
              src={charterData.guideProfilePictureURL}
              className="img-matches"
            />
          </Col>
          <Col xs={12} md={9} className="outfitter-main-content">
            <Row noGutters className="justify-content-between">
              <div className="mb-0 d-flex align-items-center">
                <StarRatings
                  size={0}
                  count={5}
                  value={charterData?.rating?.reviewsAverage ?? 5}
                  edit={false}
                  isHalf={true}
                  emptyIcon={
                    <Image
                      src={starEmptyIcon}
                      width="13px"
                      className="mr-1"
                    ></Image>
                  }
                  halfIcon={
                    <Image
                      src={starHalfIcon}
                      width="15px"
                      className="mr-1"
                    ></Image>
                  }
                  filledIcon={
                    <Image
                      src={starFullIcon}
                      width="15px"
                      className="mr-1"
                    ></Image>
                  }
                  classNames="mb-0 d-inline"
                />
                <span className="ml-1 font-size-10">
                  ({charterData?.rating?.reviewsAmount ?? 0})
                </span>
              </div>
              {/* <div>
                <Favorite />
              </div> */}
            </Row>
            <Row noGutters className="justify-content-between">
              <p className="font-size-18 font-weight-bold mb-1 mt-1 d-flex justify-content-between text-capitalize">
                {charterData.guideBusinessName}
              </p>
            </Row>

            <p className="mb-1 matches-info">
              Species:{" "}
              <span className="font-weight-500">
                {charterData.guideSpecies.join(", ")}
              </span>
            </p>
            {target !== "fishing" && (
              <>
                <p className="mb-1 matches-info">
                  Seasons:{" "}
                  <span className="font-weight-500">
                    {charterData.timeFrame.join(", ")}
                  </span>
                </p>
                <p className="mb-2 matches-info">
                  Location:{" "}
                  <span className="font-weight-500">
                    {charterData.guideRegionOperating.join(", ")}
                  </span>
                </p>
              </>
            )}
            {target === 'fishing' &&
            <>
              <p className="mb-1 matches-info">
              Price Range:{" "}
              <span className="font-weight-500">
                ${charterData.guidePriceMin}-${charterData.guidePriceMax}
              </span>
              </p>
              <p className="mb-1 matches-info">
              Group Size:{" "}
              <span className="font-weight-500">
                Up to {charterData.guidePartySize} people
              </span>
              </p>
            </>
            }
            <Row className="justify-content-between">
              <Col className="font-size-12 limited-cards-description">{charterData.guideAboutUs}</Col>
              <Col className="col-auto">
                <Link to={() => {}}>
                  <Button
                    onClick={() => {
                      if (target === "hunting") {
                        history.push(
                          `/guide-hunter/${charterData.guideHunterID}`
                        );
                      } else if (target === "fishing") {
                        history.push(
                          `/guide-fisherman/${charterData.guideFisherID}`
                        );
                      }
                    }}
                    variant="match"
                  >
                    {" "}
                    Learn more
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Matches;
