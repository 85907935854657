import React from "react";
import { Formik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Separator from "../../../utils/Separator";

const RejectionModal = (props) => {

  const { submitRejection, cancelRejection } = props

  return (
    <Container fluid className="rejection-modal">
      <Formik
        enableReinitialize={true}
        initialValues={{
          reason: "",
          message: ""
        }}
        validate={(values) => {
          const errors = {};
          if (!values.reason) {
            errors.reason = "Required";
          }
          return errors;
        }}
        onSubmit={(values ) => {
          const { reason, message } = values;
          submitRejection(reason, message);
        }}
        onReset={() => {
          cancelRejection();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} onReset={handleReset}>
            <Row>
              <Col>
                <div className="mb-3">
                  <Row className="mx-0 mb-2 align-items-center">
                    <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                      Reason for Rejection
                    </h2>
                    <Separator size="181px" color="#EC7228" />
                  </Row>
                  <p className="card-text font-size-18">
                    Please select a reaso for rejecting the guides application
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label>Reason</Form.Label>
                <Form.Control 
                  as="select" 
                  className="font-weight-bold"
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.reason}
                  isValid={ touched.reason && !errors.reason }
                >
                  <option 
                    className="font-weight-bold"
                  >
                    [Select a reason for the rejection]
                  </option>
                  <option 
                    className="font-weight-bold"
                    value="Could not find license number in registration"
                  >
                    Could not find license number in registration
                  </option>
                  <option 
                    className="font-weight-bold"
                    value="Reason 2"
                  >
                    Reason 2
                  </option>
                  <option 
                    className="font-weight-bold"
                    value="Reason 3"
                  >
                    Reason 3
                  </option>
                  <option 
                    className="font-weight-bold"
                    value="Reason 4"
                  >
                    Reason 4
                  </option>
                  <option 
                    className="font-weight-bold"
                    value="Reason 5"
                  >
                    Reason 5
                  </option>
                </Form.Control>
                <Form.Control.Feedback
                  className="mt-2"
                  type="invalid"
                >
                  {errors.reason}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  className="font-weight-bold"
                  rows={4}
                  placeholder="Enter personal message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={500}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end mt-5 mb-5">
                <Button 
                  variant="cancel" 
                  className="mr-4"
                  type="reset"
                >
                  Cancel
                </Button>
                <Button 
                  variant="approved"
                  type="submit"
                >Submit</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
export default RejectionModal;
