import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row, Form } from "react-bootstrap";
import { Formik, FieldArray, Field } from "formik";
import { AiFillCheckCircle } from "react-icons/ai";
import StarRatings from "react-rating-stars-component";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/icons/Logo.svg";
import starEmptyIcon from "../../assets/icons/Star-Empty-orange.svg";
import starFullIcon from "../../assets/icons/Star-Full-orange.svg";
import starHalfIcon from "../../assets/icons/Star-Half-orange.svg";
import appleBadge from "../../assets/others/Download_on_the_Mac_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import googleBadge from "../../assets/others/google-play-badge.png";
import { request } from "../../utils/HttpHelper";
import { showToast } from "../../utils/Misc";
import "./MakeAReview.css";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function MakeAReview() {

  const [selectedWasSuccessful, setSelectedWasSuccessful] = useState(null);
  const [selectedWasAsAdvertised, setSelectedWasAsAdvertised] = useState(null);
  

  let query = useQuery();
  const history = useHistory();
  const customStars = {
    size: 0,
    count: 5,
    value: 0,
    edit: true,
    isHalf: true,
    emptyIcon: (
      <Image src={starEmptyIcon} width="42px" className="mr-1"></Image>
    ),
    halfIcon: <Image src={starHalfIcon} width="42px" className="mr-1"></Image>,
    filledIcon: (
      <Image src={starFullIcon} width="42px" className="mr-1"></Image>
    ),
  };
  const [reviewDetails, setReviewDetails] = useState({});
  const [token, setToken] = useState();
  const [rating, setRating] = useState();
  const [message, setMessage] = useState();
  useEffect(() => {
    let token = query.get("token");
    setToken(token);
    getReviewDetailsByToken(token).then((e) => {
      setReviewDetails(e);
      console.dir(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReviewDetailsByToken = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "GET",
          url: "guidex-api",
          trailingURL: `/rate-review?token=${token}`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const setReviewDetailsByToken = (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "POST",
          url: "guidex-api",
          body: body,
          trailingURL: `/rate-review`,
        });
        resolve(result.status);
      } catch (error) {
        reject(error);
      }
    });
  };

  const submitForm = () => {
    let body = {
      token: token,
      rating: rating,
      message: message,
      wasSuccessful: selectedWasSuccessful,
      wasAsAdvertised: selectedWasAsAdvertised,      
    };
    setReviewDetailsByToken(body).then((e) => {
      console.dir(e);
      if (e === 200) {
        showToast(
          <>
            <AiFillCheckCircle /> Review created correctly!
          </>,
          "success"
        );
      }
    });
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  return (
    <div>
      <nav className="navbar email-header">
        <a className="navbar-brand" href="/">
          <Image src={logo} width="180" height="50" alt="logo" />
        </a>
      </nav>

      <Container fluid className="pr-5 pl-5">
        <Row className="mt-5">
          <Col className="imageBackgroundHome d-flex justify-content-center ">
            <div className="align-self-center">
              <p className="email-main-title text-center  ">
                {" "}
                Your adventure starts here.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 ">
          <Col>
            <p className="application-title "> Review your Guide</p>
            <p className="mt-4 application-messagge">
              Hi {reviewDetails.reviewRequesteeName},
            </p>

            <p className="mt-3 application-messagge">
              Our Guide {reviewDetails.reviewRequesterName} is looking for a
              review of your experience with them
            </p>

            <p className="mt-3 application-messagge font-weight-bold mb-5">
              Message from Guide: {reviewDetails.reviewRequesterMessage}
            </p>

            <p className="review-label">How would you rate your experience?</p>
            <StarRatings
              onChange={ratingChanged}
              {...customStars}
              classNames="mb-0 d-inline"
            />

            <p className="review-label mt-2"> Was your trip successful?</p>
            <div className="mb-3">
              <Form.Check
                inline
                label="NO"
                type="checkbox"
                checked={selectedWasSuccessful === "no"}
                onChange={() => {
                  setSelectedWasSuccessful("no");
                }}
                name="wasSuccessfulNO"
                 />
              <Form.Check
                inline
                label="YES"
                type="checkbox"
                name="wasSuccessfulYES"
                checked={selectedWasSuccessful === "yes"}
                onChange={() => {
                  setSelectedWasSuccessful("yes");
                }}/>
            </div>
            <p className="review-label mt-1"> Was your experience as advertised?</p>
            <div className="mb-3">
              <Form.Check
                inline
                label="NO"
                type="checkbox"
                name="wasAsAdvertisedNO" 
                checked={selectedWasAsAdvertised === "no"}
                onChange={() => {
                  setSelectedWasAsAdvertised("no");
                }} />
              <Form.Check
                inline
                label="YES"
                type="checkbox"
                name="wasAsAdvertisedYES"
                checked={selectedWasAsAdvertised === "yes"}
                onChange={() => {
                  setSelectedWasAsAdvertised("yes");
                  console.log(selectedWasAsAdvertised);
                }} />
            </div>

            <div className="form-group mt-5">
              <div className="d-flex justify-content-between">
                <label htmlFor="experience" className="review-label">
                  How was your experience?
                </label>
                <label htmlFor="experience" className="review-label-2">
                  Max of 200 characters(optional)
                </label>
              </div>
              <textarea
                className="form-control"
                id="experience"
                rows="6"
                maxLength="200"
                onChange={handleChange}
              ></textarea>
            </div>
            <p className="application-messagge mt-4">
              Helpful hints to a great review:
            </p>

            <p className="application-messagge">
              1. Your guide can not control the weather, so if you rate them low
              due to weather, this is not helpful to future customers.{" "}
            </p>
            <p className="application-messagge">
              2. Please keep your review to a few thoughts. 
            </p>
            <p className="application-messagge mb-5">
              3. Add in any details that other customers might find helpful.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={() => {
                submitForm();
              }}
              className="btn guidex vision"
            >
              Submit
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="imageBackgroundVision d-flex justify-content-center mb-4">
            <div className="align-self-center">
              <p className="application-vision ">
                {" "}
                "We are dedicated to Empowering clients with transparent
                objective information to help fuel their passionate pursuit of
                the outdoors"
              </p>
              <div className="d-flex justify-content-center mt-5 vision-padding-bottom">
                <Button
                  onClick={() => {
                    history.push("/about");
                  }}
                  className="btn guidex vision"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-4">
            <p className="application-messagge-app">
              GuideX at the touch of a button Download our app from:
            </p>
            <Image src={appleBadge} width="125px" />
            <Image src={googleBadge} width="125px" />
            <p className="mb-0 mt-3 application-messagge-app">
              Questions or concerns? Get in touch with us at xxx@email.com or
              555-555-5555.
            </p>
            <p className=" application-messagge-app">
              Never miss a beat! Follow us on Twitter, Facebook and Instagram
            </p>
            <p className=" application-messagge-app">
              Don’t want any more emails from [Company Name]?{" "}
              <a href="www.guidex.com" className="email-unsubcribe">
                Unsubscribe
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
