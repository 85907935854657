import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { app } from "../../FirebaseConfig";
import Separator from "../../utils/Separator";
const RecoveryPassword = () => {
  window.scrollTo(0, 0);
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [error, seterror] = useState("");
  const [emailStep, setEmailSteps] = useState(false);

  const sendRecoverPasswordEmail = async (values, setSubmitting) => {
    try {
      await app.auth().sendPasswordResetEmail(values.email, setSubmitting);
      setSubmitting(false);
      setEmailSteps(true);
    } catch (error) {
      console.error(error);
      seterror(error.message);
      setSubmitting(false);
    }
  };

  return (
    <div id="imageBackgroundSignin">
      {!emailStep ? (
        <Container className="d-flex justify-content-center">
          <Col md="auto" className="guidex-card">
            <div className="mb-3">
              <Row className="mx-0 mb-3 align-items-center">
                <h2 className="primary-color font-weight-bold font-size-30 mr-1">
                  Forgot your password?
                </h2>
                <Separator size={!isMobile ? "87px" : "0"} color="#EC7228" />
              </Row>
              <p className="mb-0 font-size-15">
                Please enter the e-mail you use when creating your account,
              </p>
              <p>We'll send you instructions to rest your password</p>
            </div>

            <Formik
              initialValues={{ email: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                sendRecoverPasswordEmail(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      bsCustomPrefix="asdasda"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Enter email"
                      isInvalid={!!errors.email}
                      isValid={touched.email && !errors.email}
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                    <div
                      className={`${
                        error ? "d-block" : "d-none"
                      } invalid-feedback`}
                    >
                      {error}
                    </div>
                  </Form.Group>

                  <div className="d-flex justify-content-end mt-4 mb-0">
                    <Button
                      variant="guidex"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Send
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Container>
      ) : (
        <Container className="d-flex justify-content-center">
          <Col md="auto" className="guidex-card">
            <div className="mb-3">
              <Row className="mx-0 mb-2 align-items-center">
                <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                  Email Sent!
                </h2>
                <Separator size={!isMobile ? "60%" : "114px"} color="#EC7228" />
              </Row>
            </div>

            <Row>
              <Col>
                <p>
                  We have sent you instructions to rest your passsword to your
                  email
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end mt-4">
                <Link to="/" className="link-btn">
                  <Button variant="guidex" className="d-xs-block">
                    Close
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Container>
      )}
    </div>
  );
};
export default RecoveryPassword;
