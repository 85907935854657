import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import Separator from "../../../../utils/Separator";

function DeleteImage(props) {
  const { handleAction, onCancel, imageId } = props;

  return (
    <Container fluid>
      <Row className="mx-0 mb-2 align-items-center">
        <Col>
          <Row className="d-flex align-items-center">
            <Col md="auto" className="px-0">
              <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                Delete
              </h2>
            </Col>
            <Col>
              <Separator color="#EC7228"/>
            </Col>
          </Row>
          <Row>
            <p className="card-text font-size-15 mt-2">
              Are you sure you want to delete this image? This is a permanent
              action and cannot be reversed.
            </p>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end mt-5 mb-5">
          <Button variant="guidex-light" className="mr-4" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="guidex"
            onClick={() => handleAction("delete", imageId)}
          >
            Delete Image
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
export default DeleteImage;
