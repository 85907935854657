import React, { useState, useContext } from "react";
import { Field, Formik } from "formik";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  Row
} from "react-bootstrap";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { BsX } from "react-icons/bs";
import { showToast } from "../../../../utils/Misc";
import editIcon from "../../../../assets/icons/EditOutlineWhite.svg";
import newIcon from "../../../../assets/others/new_icon.png";
import newPhoto from "../../../../assets/others/new_photo.png";
import { User } from "../../../../utils/UserProvider";
import { request } from "../../../../utils/HttpHelper";
import MapGeolocation from "../../Common/Map/MapGeolocation";
import DataListInput from "react-datalist-input";
import * as $ from "jquery";
import { useMediaQuery } from "react-responsive";
import { Staff } from "../../../../utils/staffProvider";
import { ReactComponent as PenIcon } from "../../../../assets/icons/pen-edit.svg";
// import useParams from "react-router-dom";
export default function GeneralDetails(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const {
    guideData,
    huntingMethodsCat,
    tagTypesCat,
    huntingMethods,
    tagTypes,
    species
  } = props;
  console.dir(guideData)
  // const {} = useParams()
  const { signInAs } = useContext(Staff)
  const { user, setuser } = useContext(User);
  const [editMode, setEditMode] = useState(false);
  // eslint-disable-next-line
  const [error, seterror] = useState("");
  const [triggerImageProfile, setTriggerImageProfile] = useState(false);
  const [triggerImageLogo, setTriggerImageLogo] = useState(false);
  const [, updateState] = useState();

  const [selectedSpecies, setSelectedSpecies] = useState(null);
  
  const match = (currentInput, item) => {
    return item.label.toLowerCase().includes(currentInput.toLowerCase());
  }

  // useEffect(() => {
  //   initSpiciesList()
  // }, []);

  const processArray = str => {
    let mappedArr = [];
    if (str) {
      let strL = str.split(",");
      // eslint-disable-next-line
      let itemsMapped = strL.map(s => {
        if (s) {
          let mappedI = s.toString().trim();
          return mappedI;
        }
      });
      mappedArr = itemsMapped;
    }
    return mappedArr;
  };

  const changeEditMode = () => {
    setEditMode(!editMode);
  };

  const [specieText, setSpecieText] = useState("");

  const updateGuideData = payload => {
    return new Promise(async (resolve, reject) => {
      try {
        await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: `/guide-hunter${signInAs ? `?id=${signInAs}` : ""}`,
          body: payload,
          requiresAuth: true
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadImage = formData => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: `/image-upload-type?entityTypeID=1`,
          body: formData,
          config: {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          },
          requiresAuth: true
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const setNewProfilePicture = () => {
    $("#profile-picture").trigger("click");
  };

  const handleProfilePictureChange = e => {
    var input = document.getElementById("profile-picture");
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#profile-picture-slot").attr("src", e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      setTriggerImageProfile(true);
    }
  };

  const setNewLogoPicture = () => {
    $("#logo-picture").trigger("click");
  };

  const handleLogoPictureChange = e => {
    var input = document.getElementById("logo-picture");
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#logo-picture-slot").attr("src", e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      setTriggerImageLogo(true);
    }
  };

  const submitGeneralDetails = async (values, setSubmitting) => {
    setSubmitting(true);

    if (triggerImageProfile || triggerImageLogo) {
      const formData = new FormData();

      if (triggerImageProfile) {
        let input = document.getElementById("profile-picture");
        if (input.files && input.files[0]) {
          formData.append("PROFILE_PICTURE", input.files[0]);
        }
      }

      if (triggerImageLogo) {
        let input = document.getElementById("logo-picture");
        if (input.files && input.files[0]) {
          formData.append("LOGO_PICTURE", input.files[0]);
        }
      }

      try {
        let resultUpload = await uploadImage(formData);

        if (triggerImageProfile) {
          let selectedImage = resultUpload.uploadedImages.find(
            i => i.imageName === "PROFILE_PICTURE"
          );
          values.profilePictureURL = selectedImage.imageUrl;
        }
        if (triggerImageLogo) {
          let selectedImage = resultUpload.uploadedImages.find(
            i => i.imageName === "LOGO_PICTURE"
          );
          values.logoURL = selectedImage.imageUrl;
        }
      } catch (error) {
        throw error;
      }
    }

    const uploadValues = {
      profilePictureURL: values.profilePictureURL,
      profileLogoURL: values.logoURL,
      guideName: values.guideName,
      guideContactName: values.guideContactName,
      guidePhone: values.phone,
      guideWebsite: values.webSite,
      guideAboutUs: values.about,
      guideSpecies: values.species.map(e => e.key),
      guideOperationalMonths: values.monthsOperation.join(","),
      guidePhysicalDifficulty: values.physicalDifficulty
        .map(pd => Number(pd))
        .join(","),
      guideHuntingMethods: values.huntingMethods.map(hm => Number(hm)),
      guideTagTypes: values.tagTypes.map(tt => Number(tt)),
      lat: values.lat,
      lng: values.lng
    };
    updateGuideData(uploadValues)
      .then(() => {
        /** Updating name in local storage */
        let userD = user;
        userD.fullName = values.guideContactName;
        let selRoleI = userD.otherRoles.findIndex(r => r.roleName === "HUNTER");
        if (selRoleI >= 0) {
          let selRole = userD.otherRoles[selRoleI];
          selRole.fullName = values.guideContactName;
          userD.otherRoles[selRoleI] = selRole;
        }
        setuser(userD);
        //window.localStorage.setItem("userRolesData", JSON.stringify(userD));
        showToast(
          <>
            <AiFillCheckCircle /> Data saved correctly!
          </>,
          "success"
        );
        setTriggerImageProfile(false);
        setTriggerImageLogo(false);
        setSubmitting(false);
        setEditMode(false);
      })
      .catch(err => {
        setSubmitting(false);
        showToast(
          <>
            <AiFillWarning /> There was a problem trying to update.
          </>,
          "warning"
        );
      });
  };

  const initValues = {
    profilePictureURL: guideData.profilePictureURL,
    logoURL: guideData.logoURL,
    guideName: guideData.businessName,
    guideContactName: guideData.fullName,
    phone: guideData.phoneNumber,
    guidexNumber: guideData.guideHunterUID,
    webSite: guideData.website,
    about: guideData.aboutUs || "",
    monthsOperation: processArray(guideData.monthsOperation),
    species: processArray(guideData.species).map(specie => ({label: specie, key: species[species.findIndex(e => e.label === specie)].key})),
    huntingMethods: huntingMethods.map(hm => hm.huntingMethodID.toString()),
    tagTypes: tagTypes.map(tt => tt.tagTypeID.toString()),
    physicalDifficulty: processArray(guideData.physicalDifficulty),
    lat: guideData.lat,
    lng: guideData.lng
  };

  console.log("This is the init information")
  console.dir(species)
  console.dir(initValues.species)

  return (
    <>
      <Container
        fluid
        className={isTabletOrMobile ? "" : "general-details-container"}
      >
        <Formik
          initialValues={initValues}
          validate={(values) => {
            const errors = {};
            if (!values.guideName) {
              errors.guideName = "Required";
            }
            if (!values.guideContactName) {
              errors.guideContactName = "Required";
            }
            if (!values.phone) {
              errors.phone = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            submitGeneralDetails(values, setSubmitting);
          }}
          onReset={(values) => {
            values = { ...initValues };
            setEditMode(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit} onReset={handleReset}>
              <Row noGutters>
                <Col className="d-flex justify-content-between pt-5 ">
                  <h1 className="profile-title">Profile</h1>
                  <div className="general-details-div-button d-flex flex-row-reverse">
                    {!editMode ? (
                      <Button
                        variant={
                          isTabletOrMobile
                            ? "general-details-responsive "
                            : "general-details"
                        }
                        onClick={changeEditMode}
                      >
                        {isTabletOrMobile ? (
                          <Row noGutters>
                            <Col xs="auto" className="font-size-18">
                              Edit
                            </Col>

                            <Col xs="auto" className="ml-auto">
                              <Image src={editIcon} width="20px" />
                            </Col>
                          </Row>
                        ) : (
                          <Row noGutters>
                            <Col xs="auto">Edit General Info</Col>

                            <Col xs="auto" className="ml-auto">
                              <Image src={editIcon} />
                            </Col>
                          </Row>
                        )}
                      </Button>
                    ) : (
                      <>
                        {isTabletOrMobile ? (
                          <></>
                        ) : (
                          <div>
                            <Button
                              variant="secondary-cancel mr-5"
                              type="reset"
                              disabled={isSubmitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="save"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Save Changes
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4 className="profile-subtitle mt-4">General Details</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="general-details-title mt-2 mb-0">
                    Guide Profile Picture
                  </p>
                </Col>
              </Row>

              <Row className="mt-1 mb-5">
                <Col md="4">
                  {!editMode ? (
                    <Image
                      src={values.profilePictureURL || newPhoto}
                      width="100%"
                    />
                  ) : (
                    <div>
                      <Image
                        id="profile-picture-slot"
                        src={values.profilePictureURL || newPhoto}
                        width="100%"
                      />
                      <Button
                        variant="pen-icon-edit"
                        className="d-flex justify-content-center"
                        onClick={setNewProfilePicture}
                      >
                        <PenIcon />
                      </Button>
                      <input
                        type="file"
                        name="profile-picture"
                        id="profile-picture"
                        className="invisible-trigger"
                        multiple={false}
                        onChange={handleProfilePictureChange}
                      />
                    </div>
                  )}
                </Col>
                <Col>
                  {isTabletOrMobile ? (
                    <>
                      <div className="mt-3">
                        <p className="general-details-title ">Guide Logo</p>
                        {!editMode ? (
                          <Image
                            src={values.logoURL || newIcon}
                            className="owner-circle"
                          />
                        ) : (
                          <div className="wrapper-icon">
                            <Image
                              id="logo-picture-slot"
                              src={values.logoURL || newIcon}
                              className="owner-circle"
                            />
                            <Button
                              variant="settings-edit-icon-pen"
                              className="d-flex justify-content-center"
                              onClick={setNewLogoPicture}
                            >
                              <PenIcon height="30px" width="30px" />
                            </Button>
                            <input
                              type="file"
                              name="logo-picture"
                              id="logo-picture"
                              className="invisible-trigger"
                              multiple={false}
                              onChange={handleLogoPictureChange}
                            />
                          </div>
                        )}
                      </div>
                      <div className="mt-4">
                        {" "}
                        <p className="mb-2 general-details-title">
                          Guidex Number
                        </p>
                        <p className="primary-color font-weight-bold font-size-15">
                          {values.guidexNumber}
                        </p>
                      </div>
                      <div className="mt-4">
                        {" "}
                        <p className="mb-2 general-details-title">Guide Name</p>
                        {!editMode ? (
                          <p className="general-details-body">
                            {values.guideName}
                          </p>
                        ) : (
                          <Form.Group controlId="FormGuideName">
                            <FormControl
                              value={values.guideName}
                              name="guideName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.guideName}
                              isValid={touched.guideName && !errors.guideName}
                              className="input-responsive"
                            />
                            <Form.Control.Feedback
                              className="mt-2"
                              type="invalid"
                            >
                              {errors.guideName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </div>
                      <div className="mt-4">
                        <p className="mb-2 general-details-title">
                          Guide Contact Name
                        </p>
                        {!editMode ? (
                          <p className="general-details-body">
                            {values.guideContactName}
                          </p>
                        ) : (
                          <Form.Group controlId="FormGuideContactName">
                            <FormControl
                              className="input-responsive"
                              value={values.guideContactName}
                              name="guideContactName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.guideContactName}
                              isValid={
                                touched.guideContactName &&
                                !errors.guideContactName
                              }
                            />
                            <Form.Control.Feedback
                              className="mt-2"
                              type="invalid"
                            >
                              {errors.guideContactName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </div>
                      <div className="mt-4">
                        {" "}
                        <p className="mb-2 general-details-title">Phone</p>
                        {!editMode ? (
                          <p className="general-details-body">{values.phone}</p>
                        ) : (
                          <Form.Group controlId="FormGuidePhone">
                            <FormControl
                              className="input-responsive"
                              value={values.phone}
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.phone}
                              isValid={touched.phone && !errors.phone}
                            />
                            <Form.Control.Feedback
                              className="mt-2"
                              type="invalid"
                            >
                              {errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </div>
                      <div className="mt-4 mb-5">
                        {" "}
                        <p className="mb-2 general-details-title">Website</p>
                        {!editMode ? (
                          <p className="general-details-body">
                            {values.webSite}
                          </p>
                        ) : (
                          <Form.Group controlId="FormGuideWebsite">
                            <FormControl
                              className="input-responsive"
                              value={values.webSite}
                              name="webSite"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Group>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col className="px-0">
                          <p className="general-details-title ">Guide Logo</p>
                          {!editMode ? (
                            <Image
                              src={values.logoURL || newIcon}
                              className="owner-circle"
                            />
                          ) : (
                            <div className="wrapper-icon">
                              <Image
                                id="logo-picture-slot"
                                src={values.logoURL || newIcon}
                                className="owner-circle"
                              />
                              <Button
                                variant="settings-edit-icon-pen"
                                className="d-flex justify-content-center"
                                onClick={setNewLogoPicture}
                              >
                                <PenIcon height="30px" width="30px" />
                              </Button>
                              <input
                                type="file"
                                name="logo-picture"
                                id="logo-picture"
                                className="invisible-trigger"
                                multiple={false}
                                onChange={handleLogoPictureChange}
                              />
                            </div>
                          )}
                        </Col>
                        <Col className="px-0">
                          <p className="mb-2 general-details-title">
                            Guidex Number
                          </p>
                          <p className="primary-color font-weight-bold font-size-15">
                            {values.guidexNumber}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Col className="mt-5">
                              <p className="mb-2 general-details-title">
                                Guide Name
                              </p>
                              {!editMode ? (
                                <p className="general-details-body">
                                  {values.guideName}
                                </p>
                              ) : (
                                <Form.Group controlId="FormGuideName">
                                  <FormControl
                                    value={values.guideName}
                                    name="guideName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.guideName}
                                    isValid={
                                      touched.guideName && !errors.guideName
                                    }
                                  />
                                  <Form.Control.Feedback
                                    className="mt-2"
                                    type="invalid"
                                  >
                                    {errors.guideName}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="mb-2 general-details-title">
                                Phone
                              </p>
                              {!editMode ? (
                                <p className="general-details-body">
                                  {values.phone}
                                </p>
                              ) : (
                                <Form.Group controlId="FormGuidePhone">
                                  <FormControl
                                    value={values.phone}
                                    name="phone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.phone}
                                    isValid={touched.phone && !errors.phone}
                                  />
                                  <Form.Control.Feedback
                                    className="mt-2"
                                    type="invalid"
                                  >
                                    {errors.phone}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col className="mt-5">
                          <Row className="d-block">
                            <p className="mb-2 general-details-title">
                              Guide Contact Name
                            </p>
                            {!editMode ? (
                              <p className="general-details-body">
                                {values.guideContactName}
                              </p>
                            ) : (
                              <Form.Group controlId="FormGuideContactName">
                                <FormControl
                                  value={values.guideContactName}
                                  name="guideContactName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={!!errors.guideContactName}
                                  isValid={
                                    touched.guideContactName &&
                                    !errors.guideContactName
                                  }
                                />
                                <Form.Control.Feedback
                                  className="mt-2"
                                  type="invalid"
                                >
                                  {errors.guideContactName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            )}
                          </Row>
                          <Row className="d-block">
                            <p className="mb-2 general-details-title">
                              Website
                            </p>
                            {!editMode ? (
                              <p className="general-details-body">
                                {values.webSite}
                              </p>
                            ) : (
                              <Form.Group controlId="FormGuideWebsite">
                                <FormControl
                                  value={values.webSite}
                                  name="webSite"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Form.Group>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}

                  {editMode ? (
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label className="mt-4">
                            Drag the pin on the map to get a more accurate
                            location.
                          </Form.Label>
                          <MapGeolocation
                            storedGeolocation={{
                              lat: values.lat,
                              lng: values.lng,
                            }}
                            onChangeGeoloction={(coords) => {
                              const [lat, lng] = coords;
                              values.lat = lat;
                              values.lng = lng;
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="mt-4 ">
                  <div className="general-details-about">
                    <Form.Label className="d-flex justify-content-between align-items-center font-size-15">
                      About us{" "}
                      {editMode && (
                        <span className="font-size-12">
                          Max of 1500 characters
                        </span>
                      )}
                    </Form.Label>
                    {!editMode ? (
                      <p className="general-details-body">{values.about}</p>
                    ) : (
                      <Form.Group controlId="FormGuideWebsite">
                        <FormControl
                          as="textarea"
                          rows={6}
                          value={values.about}
                          name="about"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={1500}
                        />
                      </Form.Group>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="profile-subtitle mt-4">Filters </h4>
                  {!editMode ? (
                    <>
                      {values.species.map((title) => (
                        <Button
                          variant="hunting"
                          className="mr-2 mt-4"
                          key={`species_${title.label}`}
                        >
                          {title.label}
                        </Button>
                      ))}
                      {
                        // eslint-disable-next-line
                        values.huntingMethods.map((id) => {
                          let currentHuntingMethod = huntingMethodsCat.find(
                            (hmc) => {
                              return hmc.huntingMethodID === Number(id);
                            }
                          );
                          if (currentHuntingMethod) {
                            return (
                              <Button
                                variant="hunting"
                                className="mr-2 mt-4"
                                key={`huntingmethod_${currentHuntingMethod.huntingMethodID}`}
                              >
                                {currentHuntingMethod.huntingMethodLabel}
                              </Button>
                            );
                          }
                        })
                      }
                      {
                        // eslint-disable-next-line
                        values.tagTypes.map((id) => {
                          let currentTagType = tagTypesCat.find((ttc) => {
                            return ttc.tagTypeID === Number(id);
                          });
                          if (currentTagType) {
                            return (
                              <Button
                                variant="hunting"
                                className="mr-2 mt-4"
                                key={`huntingmethod_${currentTagType.tagTypeID}`}
                              >
                                {currentTagType.tagTypeLabel}
                              </Button>
                            );
                          }
                        })
                      }
                    </>
                  ) : (
                    <div>
                      <p className="general-details-body ">
                        Add filters to get better customer contacts
                      </p>
                      <Row>
                        <Col md="4">
                          <p className="general-details-title ">
                            Species Hunted for
                          </p>

                          <DataListInput
                            placeholder="Enter Species..."
                            inputClassName="background-guidex form-control"
                            dropDownLength={7}
                            onChange={handleChange}
                            items={species}
                            onSelect={(selectedItem) => {
                              setSelectedSpecies(selectedItem.key);
                              values.species.push(selectedItem);
                            }}
                            clearInputOnSelect={true}
                            match={match}
                          />

                          {values.species.map((s, i) => (
                            <Button
                              variant="hunting"
                              className="mt-2 mr-2 mb-1"
                              key={`${s.key}_${i}`}
                            >
                              {s.label}{" "}
                              <BsX
                                className="clear-filter"
                                onClick={() => {
                                  values.species.splice(i, 1);
                                  updateState({});
                                }}
                              ></BsX>
                            </Button>
                          ))}
                          {values.species.length > 0 && (
                            <p
                              className="ml-3 hunting-clear"
                              onClick={() => {
                                values.species = [];
                                updateState({});
                              }}
                            >
                              Clear all
                            </p>
                          )}
                          <Row>
                            <Col className="my-3">
                              <p className="general-details-title">
                                {" "}
                                Physical Difficulty
                              </p>
                              <p className="font-size-10">
                                1-4 mil hikes each day (Easy)
                              </p>
                              <p className="font-size-10">
                                8-10 mil hikes each day (Hard)
                              </p>
                              <Row>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    {" "}
                                    1 - Very easy
                                    <Field
                                      type="checkbox"
                                      name="physicalDifficulty"
                                      value="1"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    2 - Somewhat easy
                                    <Field
                                      type="checkbox"
                                      name="physicalDifficulty"
                                      value="2"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    {" "}
                                    3 - Not easy, not hard
                                    <Field
                                      type="checkbox"
                                      name="physicalDifficulty"
                                      value="3"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    4 - Somewhat Hard
                                    <Field
                                      type="checkbox"
                                      name="physicalDifficulty"
                                      value="4"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                                <Col xs="6" md="4" className="my-1">
                                  <label className="checkbox-container">
                                    5 - Very hard
                                    <Field
                                      type="checkbox"
                                      name="physicalDifficulty"
                                      value="5"
                                      className="checkbox-guidex"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col>
                              <Form.Group controlId="formBasicMonths">
                                <p className="d-flex general-details-title justify-content-between">
                                  Operational Months{" "}
                                  <span
                                    className="primary-color font-weight-bold d-flex justify-content-end clickable mb-0"
                                    onClick={() => {
                                      values.monthsOperation = [
                                        "JAN",
                                        "FEB",
                                        "MAR",
                                        "APR",
                                        "MAY",
                                        "JUN",
                                        "JUL",
                                        "AUG",
                                        "SEP",
                                        "OCT",
                                        "NOV",
                                        "DEC",
                                      ];
                                      updateState({});
                                    }}
                                  >
                                    + Select All
                                  </span>
                                </p>
                                <Row>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      January
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="JAN"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      February
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="FEB"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      March
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="MAR"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      April
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="APR"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      May
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="MAY"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      June
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="JUN"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      July
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="JUL"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      August
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="AUG"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      September
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="SEP"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      October
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="OCT"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      November
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="NOV"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                  <Col xs={6} md={3} className="my-1">
                                    <label className="checkbox-container">
                                      {" "}
                                      December
                                      <Field
                                        type="checkbox"
                                        name="monthsOperation"
                                        value="DEC"
                                        className="checkbox-guidex"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="my-3">
                              <p className="general-details-title">
                                {" "}
                                Hunting Method
                              </p>
                              <Form.Group controlId="formBasicHM">
                                <Row>
                                  {huntingMethodsCat.map((hm) => {
                                    return (
                                      <Col
                                        key={"HM_" + hm.huntingMethodID}
                                        md={3}
                                      >
                                        <label className="checkbox-container">
                                          {hm.huntingMethodLabel}
                                          <Field
                                            type="checkbox"
                                            name="huntingMethods"
                                            value={hm.huntingMethodID.toString()}
                                            className="checkbox-guidex"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="my-3">
                              <p className="general-details-title"> Tag Type</p>
                              <Row>
                                {tagTypesCat.map((tt) => {
                                  return (
                                    <Col key={"TT_" + tt.tagTypeID} md={3}>
                                      <label className="checkbox-container">
                                        {tt.tagTypeLabel}
                                        <Field
                                          type="checkbox"
                                          name="tagTypes"
                                          value={tt.tagTypeID.toString()}
                                          className="checkbox-guidex"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-flex justify-content-end mb-5 mt-5">
                              <div>
                                <Button
                                  variant="secondary-cancel mr-5"
                                  type="reset"
                                  disabled={isSubmitting}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="save"
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="mr-3"
                                >
                                  Save Changes
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}