import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import ModalWarning from "../../components/Portal/Common/ModalWarning";
import { User } from "../../utils/UserProvider";
import MultipleAccounts from "../Login/MultipleAccounts";
import Gallery from "./Common/Gallery/Gallery";
import Messages from "./Common/Messages";
import Reviews from "./Common/Reviews";
import Settings from "./Common/Settings/Settings";
import AccoutDetailsCustomer from "./Customer/AccoutDetailsCustomer";
import FavoriteItemsCustomer from "./Customer/FavoriteItemsCustomer";
import MenuCustomer from "./Customer/MenuCustomer";
import AdvertisementFisherman from "./GuideFisherman/Advertisement/AdvertisementFisherman";
import MenuGuideFisherman from "./GuideFisherman/MenuGuideFisherman";
import ProfileFisherman from "./GuideFisherman/Profile/ProfileFisherman";
import Advertisement from "./GuideHunter/Advertisement/Advertisement";
import MenuGuideHunter from "./GuideHunter/MenuGuideHunter";
import Orders from "./GuideHunter/Orders/Orders";
import ProfileHunter from "./GuideHunter/Profile/ProfileHunter";
import GuideHunterTrips from "./GuideHunter/Trips/GuideHunterTrips";
import "./Portal.css";
import ApprovedSpecies from "./Staff/ApprovedSpecies";
import ApproveGuides from "./Staff/ApproveGuides";
import ChangeReportStaff from "./Staff/ChangeReportStaff";
import LoginAs from "./Staff/LoginAs";
import MenuStaff from "../Portal/Staff/MenuStaff";
import { StaffProvider } from "../../utils/staffProvider";
import ApproveReviews from "./Staff/ApproveReviews";
// import { useParams } from "react-router-dom";
const Portal = () => {
  const location = useLocation();

  const { user, auth, menuMobile, setMenuMobile } = useContext(User);

  const [page, setPage] = useState();

  const [showSwitch, setShowSwitch] = useState(false);
  const [showWarning, setShowWarning] = useState(true);

  const closeWarning = () => {
    setShowWarning(false);
  };

  const handleClose = () => {
    setShowSwitch(false);
  };

  const changeItem = (item) => {
    window.location.hash = `#${item}`;
    setMenuMobile(false);
  };

  const changeModal = () => {
    setShowSwitch(true);
  };

  useEffect(() => {
    const locHash = location.hash;
    if (locHash.startsWith("#")) {
      let uri = locHash.substr(1);
      if (uri.includes("?")) {
        setPage(uri.split("?")[0]);
      } else {
        setPage(uri);
      }
    }
  }, [location.hash]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  const queryParams = new URLSearchParams("?" + location.hash.split('?')[1]);
  const guideType = queryParams.get("guide-type");
  const loginAsId = queryParams.get("id");

  return (
    <>
      <StaffProvider>
        <Container fluid className="customer" id="imageBackgroundTopo">
          {auth && user && user.selectedRole === "CUSTOMER" && (
            <Row className="noBackground">
              {isTabletOrMobile ? (
                <>
                  {menuMobile && (
                    <Col md={2} lg={3} xl={2} className="portal-menu">
                      <MenuCustomer
                        onChangeItem={changeItem}
                        triggerChangeModal={changeModal}
                        selectedTab={page}
                        fullName={user.fullName}
                        selectedRole={user.selectedRole}
                        profilePic={user.profilePic}
                      />
                    </Col>
                  )}
                </>
              ) : (
                !menuMobile && (
                  <Col md={2} lg={3} xl={2} className="portal-menu">
                    <MenuCustomer
                      onChangeItem={changeItem}
                      triggerChangeModal={changeModal}
                      selectedTab={page}
                      fullName={user.fullName}
                      selectedRole={user.selectedRole}
                      profilePic={user.profilePic}
                    />
                  </Col>
                )
              )}
              {isTabletOrMobile ? (
                <>
                  {!menuMobile && (
                    <Col
                      md={10}
                      lg={9}
                      xl={10}
                      className="pl-4 pr-4 portal-section"
                    >
                      {page === "favorite-trips" && (
                        <>
                          {isTabletOrMobile ? (
                            <h2 className="mb-5 portal-section-title-responsive  mt-5">
                              Favorite Trips
                            </h2>
                          ) : (
                            <h2 className="mb-5 portal-section-title mt-5">
                              Favorite Trips
                            </h2>
                          )}

                          <FavoriteItemsCustomer
                            userId={user.userId}
                            email={user.email}
                          />
                        </>
                      )}
                      {page === "messages" && (
                        <>
                          <Messages user={user} />
                        </>
                      )}
                      {page === "settings" && (
                        <>
                          <h2 className="mb-5 portal-section-title mt-5">
                            Account Details
                          </h2>
                          <AccoutDetailsCustomer
                            userId={user.userId}
                            email={user.email}
                          />
                        </>
                      )}
                    </Col>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <Col
                    md={10}
                    lg={9}
                    xl={10}
                    className="pl-4 pr-4 portal-section"
                  >
                    {page === "favorite-trips" && (
                      <>
                        {isTabletOrMobile ? (
                          <h2 className="mb-5 portal-section-title-responsive  mt-5">
                            Favorite Trips
                          </h2>
                        ) : (
                          <h2 className="mb-5 portal-section-title mt-5">
                            Favorite Trips
                          </h2>
                        )}

                        <FavoriteItemsCustomer
                          userId={user.userId}
                          email={user.email}
                        />
                      </>
                    )}
                    {page === "messages" && (
                      <>
                        <h2 className="mb-5 portal-section-title mt-5">
                          Messages
                        </h2>
                        <Messages user={user} />
                      </>
                    )}
                    {page === "settings" && (
                      <>
                        <h2 className="mb-5 portal-section-title mt-5">
                          Account Details
                        </h2>
                        <AccoutDetailsCustomer
                          userId={user.userId}
                          email={user.email}
                        />
                      </>
                    )}
                  </Col>
                </>
              )}
            </Row>
          )}
          {auth && user && user.selectedRole === "HUNTER" && (
            <Row className="noBackground">
              {isTabletOrMobile ? (
                <>
                  {menuMobile && (
                    <Col md={2} lg={3} xl={2} className="portal-menu">
                      <MenuGuideHunter
                        onChangeItem={changeItem}
                        triggerChangeModal={changeModal}
                        selectedTab={page}
                        fullName={user.fullName}
                        selectedRole={user.selectedRole}
                      />
                    </Col>
                  )}
                </>
              ) : (
                <Col md={2} lg={3} xl={2} className="portal-menu">
                  <MenuGuideHunter
                    onChangeItem={changeItem}
                    triggerChangeModal={changeModal}
                    selectedTab={page}
                    fullName={user.fullName}
                    selectedRole={user.selectedRole}
                  />
                </Col>
              )}
              {isTabletOrMobile ? (
                <>
                  {!menuMobile && (
                    <div className=" portal-section-responsive">
                      {page === "profile" && (
                        <>
                          <ProfileHunter user={user} />
                        </>
                      )}

                      {page === "messages" && (
                        <>
                          <Messages user={user} />
                        </>
                      )}
                      {page === "advertisement" && (
                        <>
                          <h2 className="mb-5 portal-section-title mt-5 ">
                            Advertisement
                          </h2>
                          <Advertisement />
                        </>
                      )}
                      {page === "orders" && (
                        <div className="mt-5 mb-5">
                          <p className="trips-title orders-title"> Orders</p>
                          <Orders />
                        </div>
                      )}
                      {page === "settings" && (
                        <>
                          <h2 className="mb-5 portal-section-title mt-5">
                            Settings
                          </h2>
                          <Settings user={user} />
                        </>
                      )}
                      {page === "trips" && (
                        <>
                          <GuideHunterTrips />
                        </>
                      )}
                      {page === "gallery" && (
                        <>
                          <Gallery user={user} />
                        </>
                      )}
                      {page === "reviews" && (
                        <>
                          <Reviews user={user} page="guide" tab="new" />
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <Col
                  md={10}
                  lg={9}
                  xl={10}
                  className="pl-4 pr-4 portal-section"
                >
                  {page === "profile" && (
                    <>
                      <ProfileHunter user={user} />
                    </>
                  )}
                  {page === "messages" && (
                    <>
                      <h2 className="mb-5 portal-section-title mt-5">
                        Messages
                      </h2>
                      <Messages user={user} />
                    </>
                  )}
                  {page === "advertisement" && (
                    <>
                      <h2 className="mb-5 portal-section-title mt-5 ">
                        Advertisement
                      </h2>
                      <Advertisement />
                    </>
                  )}
                  {page === "orders" && (
                    <div className="mt-5 mb-5">
                      <p className="trips-title orders-title"> Orders</p>
                      <Orders />
                    </div>
                  )}
                  {page === "settings" && (
                    <>
                      <h2 className="mb-5 portal-section-title mt-5">
                        Settings
                      </h2>
                      <Settings user={user} />
                    </>
                  )}
                  {page === "trips" && (
                    <>
                      <GuideHunterTrips />
                    </>
                  )}
                  {page === "gallery" && (
                    <>
                      <Gallery user={user} />
                    </>
                  )}
                  {page === "reviews" && (
                    <>
                      <Reviews user={user} page="guide" tab="new" />
                    </>
                  )}
                </Col>
              )}
            </Row>
          )}
          {auth &&
            user &&
            user.selectedRole === "STAFF" &&
            page !== "approve-guides" &&
            page !== "login-as" &&
            page !== "approve-species" &&
            page !== "approve-reviews" && (
              <Row className="noBackground">
                {isTabletOrMobile ? (
                  <>
                    {menuMobile && (
                      <Col md={2} lg={3} xl={2} className="portal-menu">
                        <MenuGuideHunter
                          onChangeItem={changeItem}
                          triggerChangeModal={changeModal}
                          selectedTab={page}
                          fullName={user.fullName}
                          selectedRole={user.selectedRole}
                        />
                      </Col>
                    )}
                  </>
                ) : guideType === "HUNTER" ? (
                  <Col md={2} lg={3} xl={2} className="portal-menu">
                    <MenuGuideHunter
                      onChangeItem={changeItem}
                      triggerChangeModal={changeModal}
                      selectedTab={page}
                      fullName={user.fullName}
                      selectedRole={user.selectedRole}
                      staffLoged={guideType ? "TRUE" : "FALSE"}
                    />
                  </Col>
                ) : (
                  <Col md={2} lg={3} xl={2} className="portal-menu">
                    <MenuGuideFisherman
                      onChangeItem={changeItem}
                      triggerChangeModal={changeModal}
                      selectedTab={page}
                      fullName={user.fullName}
                      selectedRole={user.selectedRole}
                      profilePic={user.profilePic}
                      loginAsId={loginAsId}
                      guideType={guideType}
                      staffLoged={guideType ? "TRUE" : "FALSE"}
                    />
                  </Col>
                )}
                {isTabletOrMobile ? (
                  <>
                    {!menuMobile && (
                      <div className=" portal-section-responsive">
                        {page === "profile" && (
                          <>
                            <ProfileHunter user={user} />
                          </>
                        )}
                        {page === "messages" && (
                          <>
                            <Messages user={user} />
                          </>
                        )}
                        {page === "advertisement" && (
                          <>
                            <h2 className="mb-5 portal-section-title mt-5 ">
                              Advertisement
                            </h2>
                            <Advertisement />
                          </>
                        )}
                        {page === "orders" && (
                          <div className="mt-5 mb-5">
                            <p className="trips-title orders-title"> Orders</p>
                            <Orders />
                          </div>
                        )}
                        {page === "settings" && (
                          <>
                            <h2 className="mb-5 portal-section-title mt-5">
                              Settings
                            </h2>
                            <Settings user={user} />
                          </>
                        )}
                        {page === "trips" && (
                          <>
                            <GuideHunterTrips />
                          </>
                        )}
                        {page === "gallery" && (
                          <>
                            <Gallery user={user} />
                          </>
                        )}
                        {page === "reviews" && (
                          <>
                            <Reviews user={user} page="guide" tab="new" />
                          </>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <Col
                    md={10}
                    lg={9}
                    xl={10}
                    className="pl-4 pr-4 portal-section"
                  >
                    {page === "profile" && 
                    guideType === "HUNTER" && (
                      <>
                        <ProfileHunter user={user} staffLoged={guideType ? "TRUE" : "FALSE"}/>
                      </>
                    )}
                    {page === "profile" && 
                    guideType === "FISHER" && (
                      <>
                        <ProfileFisherman user={user} staffLoged={guideType ? "TRUE" : "FALSE"}/>
                      </>
                    )}
                    {page === "advertisement" && (
                      <>
                        <h2 className="mb-5 portal-section-title mt-5 ">
                          Advertisement
                        </h2>
                        <Advertisement />
                      </>
                    )}
                    {page === "orders" && (
                      <div className="mt-5 mb-5">
                        <p className="trips-title orders-title"> Orders</p>
                        <Orders />
                      </div>
                    )}
                    {page === "settings" && (
                      <>
                        <h2 className="mb-5 portal-section-title mt-5">
                          Settings
                        </h2>
                        <Settings user={user} />
                      </>
                    )}
                    {page === "trips" && (
                      <>
                        <GuideHunterTrips />
                      </>
                    )}
                    {page === "gallery" && (
                      <>
                        <Gallery user={user} />
                      </>
                    )}
                    {page === "reviews" && (
                      <>
                        <Reviews user={user} page="guide" tab="new" />
                      </>
                    )}
                  </Col>
                )}
              </Row>
            )}
          {auth && user && user.selectedRole === "FISHERMAN" && (
            <Row className="noBackground">
              {isTabletOrMobile ? (
                <>
                  {menuMobile && (
                    <Col md={2} lg={3} xl={2} className="portal-menu">
                      <MenuGuideFisherman
                        onChangeItem={changeItem}
                        triggerChangeModal={changeModal}
                        selectedTab={page}
                        fullName={user.fullName}
                        selectedRole={user.selectedRole}
                        profilePic={user.profilePic}
                      />
                    </Col>
                  )}
                </>
              ) : (
                <Col md={2} lg={3} xl={2} className="portal-menu">
                  <MenuGuideFisherman
                    onChangeItem={changeItem}
                    triggerChangeModal={changeModal}
                    selectedTab={page}
                    fullName={user.fullName}
                    selectedRole={user.selectedRole}
                    profilePic={user.profilePic}
                  />
                </Col>
              )}
              {isTabletOrMobile ? (
                <>
                  {!menuMobile && (
                    <Col
                      md={10}
                      lg={9}
                      xl={10}
                      className="pl-4 pr-4 portal-section"
                    >
                      {page === "profile" && (
                        <>
                          <ProfileFisherman user={user} />
                        </>
                      )}
                      {page === "messages" && (
                        <>
                          <Messages user={user} />
                        </>
                      )}
                      {page === "advertisement" && (
                        <>
                          <h2 className="mb-5 portal-section-title mt-5 ">
                            Advertisement
                          </h2>
                          <AdvertisementFisherman />
                        </>
                      )}
                      {/* {page === "orders" && (
                      <div className="mt-5 mb-5">
                        <p className="trips-title orders-title"> Orders</p>
                        <Orders />
                      </div>
                    )} */}
                      {page === "settings" && (
                        <>
                          <h2 className="mb-5 portal-section-title mt-5">
                            Settings
                          </h2>
                          <Settings user={user} />
                        </>
                      )}
                      {page === "gallery" && (
                        <>
                          <Gallery user={user} />
                        </>
                      )}
                      {page === "reviews" && (
                        <>
                          <Reviews page="guide" user={user} tab="new" />
                        </>
                      )}
                    </Col>
                  )}
                </>
              ) : (
                <Col
                  md={10}
                  lg={9}
                  xl={10}
                  className="pl-4 pr-4 portal-section"
                >
                  {page === "profile" && (
                    <>
                      <ProfileFisherman user={user} />
                    </>
                  )}
                  {page === "messages" && (
                    <>
                      <h2 className="mb-5 portal-section-title mt-5">
                        Messages
                      </h2>
                      <Messages user={user} />
                    </>
                  )}
                  {page === "advertisement" && (
                    <>
                      <h2 className="mb-5 portal-section-title mt-5 ">
                        Advertisement
                      </h2>
                      <AdvertisementFisherman />
                    </>
                  )}
                  {/* {page === "orders" && (
                  <div className="mt-5 mb-5">
                    <p className="trips-title orders-title"> Orders</p>
                    <Orders />
                  </div>
                )} */}
                  {page === "settings" && (
                    <>
                      <h2 className="mb-5 portal-section-title mt-5">
                        Settings
                      </h2>
                      <Settings user={user} />
                    </>
                  )}
                  {page === "gallery" && (
                    <>
                      <Gallery user={user} />
                    </>
                  )}
                  {page === "reviews" && (
                    <>
                      <Reviews page="guide" user={user} tab="new" />
                    </>
                  )}
                </Col>
              )}
            </Row>
          )}
          {auth && user && user.selectedRole === "STAFF" && (
            <Row className="noBackground">
              {isTabletOrMobile ? (
                <>
                  {menuMobile && (
                    <Col md={2} lg={3} xl={2} className="portal-menu">
                      <MenuStaff
                        onChangeItem={changeItem}
                        triggerChangeModal={changeModal}
                        selectedTab={page}
                        fullName={user.fullName}
                        selectedRole={user.selectedRole}
                      />
                    </Col>
                  )}
                </>
              ) : (
                <></>
              )}
              {isTabletOrMobile ? (
                <>
                  {!menuMobile && (
                    <Col md={12} className="pl-4 pr-4 portal-section">
                      {page === "login-as" && (
                        <>
                          <LoginAs />
                        </>
                      )}
                      {page === "approve-guides" && (
                        <>
                          <ApproveGuides />
                        </>
                      )}
                      {page === "approve-species" && (
                        <>
                          <ApprovedSpecies />
                        </>
                      )}
                      {page === "change-report" && (
                        <>
                          <ChangeReportStaff />
                        </>
                      )}
                      {page === "approve-reviews" && (
                        <>
                          <ApproveReviews />
                        </>
                      )}
                    </Col>
                  )}
                </>
              ) : (
                <Col md={12} className="pl-4 pr-4 portal-section">
                  {page === "login-as" && (
                    <>
                      <LoginAs />
                    </>
                  )}
                  {page === "approve-guides" && (
                    <>
                      <ApproveGuides />
                    </>
                  )}
                  {page === "approve-species" && (
                    <>
                      <ApprovedSpecies />
                    </>
                  )}
                  {page === "change-report" && (
                    <>
                      <ChangeReportStaff />
                    </>
                  )}
                  {page === "approve-reviews" && (
                    <>
                      <ApproveReviews />
                    </>
                  )}
                </Col>
              )}
            </Row>
          )}
        </Container>
        {isTabletOrMobile && (
          <Modal
            show={showWarning}
            onHide={closeWarning}
            size="lg"
            className="modal-principal"
            backdropClassName="warning-modal"
          >
            {" "}
            <Modal.Header></Modal.Header>
            <Modal.Body>
              <ModalWarning closeModal={closeWarning}></ModalWarning>
            </Modal.Body>
          </Modal>
        )}
        <Modal show={showSwitch} onHide={handleClose} size="lg">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <MultipleAccounts
              background="noBackground"
              margin="noMargin"
              modal="true"
              onSwitchAccount={() => {
                setShowSwitch(false);
              }}
            ></MultipleAccounts>
          </Modal.Body>
        </Modal>
      </StaffProvider>
    </>
  );
};

export default Portal;
