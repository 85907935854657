import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Carousel, Col, Container, Row, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import StarRatings from "react-star-ratings";
import { request } from "../../utils/HttpHelper";
import "./carrousel.css";
const Carrousel = (props) => {
  const history = useHistory();
  const [publicReviews, setPublicReviews] = useState([]);
  const { page, rating, site } = props;

  useEffect(() => {
    if (page && page === "home") {
      getPublicReviews().then((result) => {
        setPublicReviews(result.reviews);
      });
    } else if (page && (page === "pdp" || page === "guide")) {
      setPublicReviews(rating.reviewsList);
    }
    // eslint-disable-next-line
  }, []);

  const getPublicReviews = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/public-reviews`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  console.log(publicReviews)
  const Desktop = ({ data }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const filterCondition = (site == "fishing" ? 2 : 1);
    let newData = [...data.filter(e => e.reviewEntityType === filterCondition)];
    let chunks = [];
    let size = 3;
    while (newData.length > 0) chunks.push(newData.splice(0, size));
    return isDesktop ? (
      <Carousel
        id="ratingsHome"
        interval={null}
        className="featured-cards "
        indicators={false}
      >
        {chunks.map((reviews, i) => {
          return (
            <Carousel.Item key={i}>
              <Row className="py-5">
                {reviews.map((review) => {
                  return (
                    <Col lg={4} mb="3" key={review.reviewId} className="p-3">
                      <div className="d-flex p-4 flex-column justify-content-between mx-0 carrousel-card bg-white rounded">
                        <div>
                          <StarRatings
                            rating={review.reviewRating}
                            starDimension="17px"
                            starSpacing="3px"
                            starRatedColor="#EC7228"
                          />
                          <p className="text-carrousel-card mt-4">
                            {review.reviewRequesteeMessage}
                          </p>
                        </div>
                        <div>
                          <p className="mt-auto font-weight-bold font-size-15 mb-0 text-dark">
                            {review.reviewRequesteeName.split(' ')[0]}
                          </p>
                          <p className="font-size-13 mt-0 mb-1 text-dark">
                            {review.reviewRequesterBusinessName}
                          </p>
                          {/* <Button
                            onClick={() => {
                              history.push(
                                `${site == "fishing" ? "/guide-fisherman/" : "/hunting-trip/"}
                                ${review.reviewEntityId}`
                              );
                            }}
                            variant="carrousel"
                            type="submit"
                            className="mt-auto"
                            style={{ minWidth: "fit-content" }}
                          >
                            Contact the guide
                          </Button> */}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Carousel.Item>
          );
        })}
      </Carousel>
    ) : null;
  };
  const Tablet = ({ data }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    let newData = [...data];
    let chunks = [];
    let size = 2;

    while (newData.length > 0) chunks.push(newData.splice(0, size));
    return isTablet ? (
      <Carousel
        id="ratingsHome"
        interval={null}
        className="featured-cards "
        indicators={false}
      >
        {chunks.map((reviews, i) => {
          return (
            <Carousel.Item key={i}>
              <Row className="py-5">
                {reviews.map((review) => {
                  return (
                    <Col xs={6} mb="3" key={review.reviewId} className="p-3">
                      <div className="d-flex p-4 flex-column mx-0 carrousel-card bg-white rounded">
                        <StarRatings
                          rating={review.reviewRating}
                          starDimension="17px"
                          starSpacing="3px"
                          starRatedColor="#EC7228"
                        />
                        <p className="text-carrousel-card mt-auto">
                          {review.reviewRequesteeMessage}
                        </p>
                        <p className="mt-auto font-weight-bold font-size-15 mb-0">
                          {review.reviewRequesteeName}
                        </p>
                        <p className="font-size-13 mt-0 mb-1">
                          {review.reviewRequesterName}
                        </p>
                        {/* <Button
                      onClick={() => {
                        history.push(
                          `/hunting-trip/${review.reviewEntityId}`
                        );
                      }}
                      variant="carrousel"
                      type="submit"
                      className="mt-auto"
                    >
                      Learn more
                    </Button> */}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Carousel.Item>
          );
        })}
      </Carousel>
    ) : null;
  };
  const Mobile = ({ data }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    let newData = [...data];
    let chunks = [];
    let size = 1;

    while (newData.length > 0) chunks.push(newData.splice(0, size));
    return isMobile ? (
      <Carousel
        id="ratingsHome"
        interval={null}
        className="featured-cards "
        indicators={false}
      >
        {chunks.map((reviews, i) => {
          return (
            <Carousel.Item key={i}>
              <Row className="py-5">
                {reviews.map((review) => {
                  return (
                    <Col lg={4} mb="3" key={review.reviewId} className="p-3">
                      <div className="d-flex p-4 flex-column mx-0 carrousel-card bg-white rounded">
                        <StarRatings
                          rating={review.reviewRating}
                          starDimension="17px"
                          starSpacing="3px"
                          starRatedColor="#EC7228"
                        />
                        <p className="text-carrousel-card mt-auto">
                          {review.reviewRequesteeMessage}
                        </p>
                        <p className="mt-auto font-weight-bold font-size-15 mb-0">
                          {review.reviewRequesteeName}
                        </p>
                        <p className="font-size-13 mt-0 mb-1">
                          {review.reviewRequesterName}
                        </p>
                        {/* <Button
                      onClick={() => {
                        history.push(
                          `/hunting-trip/${review.reviewEntityId}`
                        );
                      }}
                      variant="carrousel"
                      type="submit"
                      className="mt-auto"
                    >
                      Learn more
                    </Button> */}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Carousel.Item>
          );
        })}
      </Carousel>
    ) : null;
  };

  return (
    <>
      {!!publicReviews.length && (
        <div
          id="noBackground"
          className={props.page === "home" ? null : "bg-green-light"}
        >
          <Container fluid className=" pb-5 pt-5">
            <Desktop data={publicReviews} />
            <Tablet data={publicReviews} />
            <Mobile data={publicReviews} />
          </Container>
        </div>
      )}
    </>
  );
};
export default Carrousel;
