import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { User } from "../../../../utils/UserProvider";
import "./AdvertisingFeaturesTrips.css";
import "./AdvertisingShares.css";
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";
import { loadStripe } from "@stripe/stripe-js";

const AdvertisingShares = (props) => {
  const { onReturn, aquireSharesData } = props;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const { user } = useContext(User);

  const [guideId, setGuideId] = useState(0);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minimunShares, setMinimunShares] = useState(0);
  const [sharesData, setSharesData] = useState({
    shareHolders: [],
  });
  const [maxShares, setMaxShares] = useState(100);

  /**
   * handleChange
   * @param e is the event
   ** This function is called when the user changes the value in the bar range and set the new value accordingly
   */
  const handleChange = (e) => {
    let newValue = e.target.value;
    const currentUser = sharesData.shareHolders.findIndex(
      (u) => u.guideHunterID === guideId
    );
    if (currentUser >= 0 && newValue <= maxShares) {
      if (newValue < minimunShares) {
        sharesData.shareHolders[currentUser].guideHunterAquiredShares =
          Number(minimunShares);
      } else {
        sharesData.shareHolders[currentUser].guideHunterAquiredShares =
          Number(newValue);
      }
      setSharesData({ ...sharesData });
      e.target.value < minimunShares
        ? setValue(minimunShares)
        : setValue(newValue);
    }
  };

  const getSharesByRegionAndSpecies = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { regionId, speciesId } = aquireSharesData;
        let sharesResult = await request({
          url: "guidex-api",
          trailingURL: `/ranking-hunter?region_id=${regionId}&species_id=${speciesId}`,
          requiresAuth: true,
        });
        resolve(sharesResult.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  const initShares = async () => {
    try {
      if (user) {
        setIsLoading(true);
        let selRoleI = user.otherRoles.find((r) => r.roleName === "HUNTER");
        setGuideId(selRoleI.userId);
        let shares = await getSharesByRegionAndSpecies();
        let shareHolderData = shares.shareHolders.find((s) => {
          return s.guideHunterID === selRoleI.userId;
        });
        let max = 0;
        if (shareHolderData !== undefined) {
          max =
            shareHolderData.guideHunterAquiredShares +
            shares.availableSharesPerGuide;
          setMinimunShares(shareHolderData.guideHunterAquiredShares);
          setValue(shareHolderData.guideHunterAquiredShares);
        } else {
          max = shares.availableSharesPerGuide;
        }
        setMaxShares(max);
        setValue(
          shareHolderData === undefined
            ? 0
            : shareHolderData.guideHunterAquiredShares
        );
        setSharesData({ ...shares });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch the
          advertisement data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initShares();
    // eslint-disable-next-line
  }, []);

  const setAdvertisement = async () => {
    try {
      setIsSubmitting(true);
      let shareHolderData = sharesData.shareHolders.find((s) => {
        return s.guideHunterID === guideId;
      });
      let body = {
        regionSpeciesId: sharesData.regionSpeciesID,
        amountShares: minimunShares
          ? value - minimunShares
          : shareHolderData.guideHunterAquiredShares,
      };
      const response = await request({
        method: "POST",
        url: "guidex-api",
        trailingURL: "/create-subscription-session",
        body,
        requiresAuth: true,
      });
      // Get Stripe.js instance
      const stripe = await stripePromise;

      const session = await response.data;

      console.table(session);

      //  onReview(session, featuredTripData);

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      showToast(
        <>
          <AiFillCheckCircle /> Shares acquired correctly!
        </>,
        "success"
      );
      setIsSubmitting(false);
      onReturn(sharesData);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to update your shares.
        </>,
        "warning"
      );
      setIsSubmitting(false);
    }
  };

  var shareDailyRateValue = 0.0;
  return (
    <>
      {!isLoading ? (
        <Container
          fluid
          className="advertising-area-container mt-2 pr-5 pl-5 mb-5"
        >
          <Row className="mb-3 mt-3 d-flex justify-content-between">
            <div>
              <p className="font-weight-bold font-size-22 mb-1">
                Advertising for{" "}
                <span className="primary-color">
                  {sharesData.regionLabel} {sharesData.speciesLabel}
                </span>
              </p>
              <p className="font-weight-bold">
                Available Shares: {sharesData.availableSharesPerGuide}%
              </p>
            </div>
            <div>
              <Button
                variant="secondary-guidex"
                disabled={isSubmitting}
                className="mr-5"
                onClick={() => {
                  onReturn();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="save"
                disabled={isSubmitting}
                onClick={() => {
                  setAdvertisement();
                }}
              >
                Review
              </Button>
            </div>
          </Row>

          <p className="font-weight-bold font-size-20">Your Budget</p>
          <Row>
            <Col md={2} sm={2}>
              <div className="input-box d-flex justify-content-between align-self-center">
                <span className="pl-3 text-gray">$ </span>
                <span className="font-weight-bold pr-4">
                  {parseFloat(shareDailyRateValue)}
                </span>{" "}
                <span className="pr-3 text-gray">/month </span>
                {/* <span className="pr-3 text-gray">/month</span> */}
              </div>
            </Col>
            <Col className="align-self-end" md="10" sm={2}>
              <Form.Control
                type="range"
                min={0}
                max={100}
                className="adv-featured-slider mb-2 "
                value={value}
                //   onChange={value => changeInput(value.target.value)}
                onChange={handleChange}
                style={{
                  background:
                    "linear-gradient(to right, #ec7228 0%,  #ec7228 " +
                    value +
                    "%,  #E9EAEB " +
                    value +
                    "%,  #E9EAEB 100%)",
                }}
              />
              <div className="d-flex justify-content-between  font-weight-bold">
                <p className="mb-0">0%</p>
                <p className="mb-0">100%</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="font-weight-bold mt-5 ml-5">
                Advertising in this area
              </p>

              {sharesData.shareHolders
                .sort((a, b) => {
                  return (
                    b.guideHunterAquiredShares - a.guideHunterAquiredShares
                  );
                })
                .map((user, i) => {
                  return (
                    <Row
                      className="mt-5 d-flex align-items-center"
                      key={user.guideHunterID}
                    >
                      <Col className="first-col" md={1}>
                        <span
                          key={user.guideHunterBusinessName}
                          className={
                            user.guideHunterID === guideId
                              ? "font-weight-bold"
                              : "none"
                          }
                        >
                          {user.guideHunterAquiredShares + "%"}
                        </span>
                      </Col>
                      <Col md={1} className="second-col">
                        <div className="wrapper d-inline"></div>
                        <img
                          src={user.guideHunterLogoURL}
                          className="advertising-circle"
                          alt="profile-img"
                        />
                      </Col>
                      <Col md={5} className="third-col">
                        <span
                          key={i}
                          className={
                            user.guideHunterID === guideId
                              ? "font-weight-bold primary-color"
                              : "none"
                          }
                        >
                          {user.guideHunterBusinessName}
                        </span>
                        <div className="progress mt-2">
                          <div
                            className={
                              user.guideHunterID === guideId
                                ? "progress-bar-active"
                                : "progress-bar"
                            }
                            role="progressbar"
                            style={{
                              width: user.guideHunterAquiredShares + "%",
                            }}
                            aria-valuenow="25"
                            aria-valuemin={user.guideHunterAquiredShares}
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              <Row>
                <Col className="d-flex justify-content-end mt-3 px-0">
                  <Row className="mx-0">
                    <Button
                      className= "mr-5"
                      variant="secondary-guidex"
                      disabled={isSubmitting}
                      onClick={() => {
                        onReturn();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="save"
                      disabled={isSubmitting}
                      onClick={() => {
                        setAdvertisement();
                      }}
                    >
                      Review
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        <center>
          <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
        </center>
      )}
    </>
  );
};
export default AdvertisingShares;
