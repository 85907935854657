import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./TermsAndConditions.css";
export default function TermsAndConditions() {
  window.scrollTo(0, 0);

  return (
    <>
      <Container fluid className="customer px-0" id="">
        <Row className="background-color-page ">
          <Col className="mt-5 title-terms">Terms and Conditions </Col>
        </Row>
        <div className="background-color-page px-0">
          <Container>
            <Col className="pt-5 terms-text">
              <p className="mb-1 font-weight-bold terms-text-left ">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="terms-text-left">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                sint itaque in? Assumenda saepe minus quisquam amet incidunt quo
                ad dolore temporibus ut itaque, deserunt ducimus error earum,
                obcaecati qui!. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Unde sint itaque in? Assumenda saepe minus
                quisquam amet incidunt quo ad dolore temporibus ut itaque,
                deserunt ducimus error earum, obcaecati qui! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Unde sint itaque in?
                Assumenda saepe minus quisquam amet incidunt quo ad dolore
                temporibus ut itaque, deserunt ducimus error earum, obcaecati
                qui! Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Unde sint itaque in? Assumenda saepe minus quisquam amet
                incidunt quo ad dolore temporibus ut itaque, deserunt ducimus
                error earum, obcaecati qui! Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Unde sint itaque in? Assumenda
                saepe minus quisquam amet incidunt quo ad dolore temporibus ut
                itaque, deserunt ducimus error earum, obcaecati qui!
              </p>
              <p className="mb-1 font-weight-bold terms-text-left mt-4">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="terms-text-left">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                sint itaque in? Assumenda saepe minus quisquam amet incidunt quo
                ad dolore temporibus ut itaque, deserunt ducimus error earum,
                obcaecati qui!. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Unde sint itaque in? Assumenda saepe minus
                quisquam amet incidunt quo ad dolore temporibus ut itaque,
                deserunt ducimus error earum, obcaecati qui! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Unde sint itaque in?
                Assumenda saepe minus quisquam amet incidunt quo ad dolore
                temporibus ut itaque, deserunt ducimus error earum, obcaecati
                qui! Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Unde sint itaque in? Assumenda saepe minus quisquam amet
                incidunt quo ad dolore temporibus ut itaque, deserunt ducimus
                error earum, obcaecati qui! Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Unde sint itaque in? Assumenda
                saepe minus quisquam amet incidunt quo ad dolore temporibus ut
                itaque, deserunt ducimus error earum, obcaecati qui!
              </p>
              <p className="mb-1 font-weight-bold terms-text-left mt-4">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <p className="terms-text-left  terms-padding-bottom">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                sint itaque in? Assumenda saepe minus quisquam amet incidunt quo
                ad dolore temporibus ut itaque, deserunt ducimus error earum,
                obcaecati qui!. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Unde sint itaque in? Assumenda saepe minus
                quisquam amet incidunt quo ad dolore temporibus ut itaque,
                deserunt ducimus error earum, obcaecati qui! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Unde sint itaque in?
                Assumenda saepe minus quisquam amet incidunt quo ad dolore
                temporibus ut itaque, deserunt ducimus error earum, obcaecati
                qui! Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Unde sint itaque in? Assumenda saepe minus quisquam amet
                incidunt quo ad dolore temporibus ut itaque, deserunt ducimus
                error earum, obcaecati qui! Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Unde sint itaque in? Assumenda
                saepe minus quisquam amet incidunt quo ad dolore temporibus ut
                itaque, deserunt ducimus error earum, obcaecati qui!
              </p>
            </Col>
          </Container>
        </div>
      </Container>
    </>
  );
}
