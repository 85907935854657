import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BsCheckCircle, BsFillPersonFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useLocation } from "react-router-dom";
import AccountDetailsIcon from "../assets/icons/Account Details.svg";
import AdsIcon from "../assets/icons/Ads.svg";
import ApproveGuidesIcon from "../assets/icons/Approved Guides.svg";
//import ApproveReviewsIcon from "../assets/icons/Approved Reviews.svg";
//import ChangeReportIcon from "../assets/icons/Change Report.svg";
import FavoriteIcon from "../assets/icons/Favorites.svg";
import GallaryIcon from "../assets/icons/Gallary.svg";
import LogInAsIcon from "../assets/icons/Log In As.svg";
import logo from "../assets/icons/Logo.svg";
import LogoutIcon from "../assets/icons/Logout.svg";
import MessagesIcon from "../assets/icons/Messages.svg";
import OrdersIcon from "../assets/icons/Orders.svg";
import profileIcon from "../assets/icons/Profile.svg";
import ReviewsIcon from "../assets/icons/Reviews.svg";
import SettingsIcon from "../assets/icons/settings.svg";
import SwitchIcon from "../assets/icons/Switch Account.svg";
import TripsIcon from "../assets/icons/Trips.svg";
import MultipleAccounts from "../components/Login/MultipleAccounts";
import { app } from "../FirebaseConfig";
import { getInitials } from "../utils/Misc";
import { User } from "../utils/UserProvider";
import "./Header.css";

export default function Header() {
  const { user, setuser, auth, menuMobile, setMenuMobile } = useContext(User);
  const [userName, setuserName] = useState("");
  const [userProfilePicture, setUserProfilePicture] = useState();
  const [role, setrole] = useState(null);
  const [showSwitch, setShowSwitch] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [headerClass, setClass] = useState("");
  useEffect(() => {
    if (
      location.pathname.includes("/featured-expeditions") ||
      location.pathname.includes("/terms-conditions") ||
      location.pathname.includes("/hunting-trip") ||
      location.pathname.includes("/guide-fisherman") ||
      location.pathname.includes("/guide-hunter") ||
      location.pathname.includes("/customer") ||
      location.pathname.includes("/profile-hunter") ||
      location.pathname.includes("/Staff") ||
      location.pathname.includes("/my-portal")
    ) {
      setClass("guidex-navbar");
    } else {
      setClass("guidex-navbar-transparet");
    }
    if (auth && user) {
      setuserName(user.fullName);
      setUserProfilePicture(user.profilePic);
      setrole(user.selectedRole);
    } else if (auth && !user) {
      let jsonStr = window.localStorage.getItem("userRolesData");
      if (jsonStr) {
        let userD = JSON.parse(jsonStr);
        // eslint-disable-next-line
        setuser(userD);
        setuserName(userD.fullName);
        setUserProfilePicture(userD.profilePic);
        setrole(userD.selectedRole);
      }
    }
  }, [auth, user, setuser, location]);

  const changeModal = () => {
    setShowSwitch(true);
  };

  const handleClose = () => {
    setShowSwitch(false);
  };

  const logout = async () => {
    await app
      .auth()
      .signOut()
      .then((result) => {
        history.push("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toPortal = (target) => {
    if (target) {
      history.push(`/my-portal#${target}`);
    } else {
      history.push("/my-portal");
    }
  };

  const userDataDropdown = (
    <>
      <Dropdown.Item
        className="dropdown-item-header"
        onClick={() => {
          toPortal((user.selectedRole === "CUSTOMER") ? "favorite-trips" : "profile");
        }}
      >
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <div className="circle-profile mt-1">
                  {userProfilePicture ? (
                    <img
                      className="d-none d-sm-block circle-profile "
                      src={userProfilePicture}
                      alt=""
                    />
                  ) : (
                    <div className="d-none d-sm-block circle-profile ">
                      <span className="initials">{getInitials(userName)}</span>
                    </div>
                  )}
                </div>
                <Col>
                  <p className="card-text font-size-18 font-weight-bold user mt-1">
                    {userName}
                  </p>
                  <p className="font-size-12 role-name type">
                    {role && role.toLowerCase()} portal
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Dropdown.Item>
      <Dropdown.Divider />
      {user && user.selectedRole === "CUSTOMER" && (
        <>
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("favorite-trips");
            }}
          >
            <Image className="no-selected mr-3 " src={FavoriteIcon} />
            Favorite Trips
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("messages");
            }}
          >
            <Image className="no-selected mr-3 " src={MessagesIcon} />
            Messages
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("settings");
            }}
          >
            <Image className="no-selected mr-3 " src={AccountDetailsIcon} />
            Account Details
          </Dropdown.Item>
        </>
      )}
      {user && user.selectedRole === "HUNTER" && (
        <>
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("profile");
            }}
          >
            <Image className="no-selected mr-3 " src={profileIcon} /> Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("messages");
            }}
          >
            <Image className="no-selected mr-3 " src={MessagesIcon} />
            Messages
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("advertisement");
            }}
          >
            <Image className="no-selected mr-3 " src={AdsIcon} />
            Advertisement
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("trips");
            }}
          >
            <Image className="no-selected mr-3 " src={TripsIcon} />
            Trips
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("gallery");
            }}
          >
            <Image className="no-selected mr-3 " src={GallaryIcon} />
            Gallery
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("orders");
            }}
          >
            <Image className="no-selected mr-3 " src={OrdersIcon} />
            Orders
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("reviews");
            }}
          >
            <Image className="no-selected mr-3 " src={ReviewsIcon} />
            Reviews
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("settings");
            }}
          >
            <Image className="no-selected mr-3 " src={SettingsIcon} />
            Settings
          </Dropdown.Item>
        </>
      )}
      {user && user.selectedRole === "FISHERMAN" && (
        <>
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("profile");
            }}
          >
            <Image className="no-selected mr-3 " src={profileIcon} /> Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("messages");
            }}
          >
            <Image className="no-selected mr-3 " src={MessagesIcon} />
            Messages
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("advertisement");
            }}
          >
            <Image className="no-selected mr-3 " src={AdsIcon} />
            Advertisement
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("gallery");
            }}
          >
            <Image className="no-selected mr-3 " src={GallaryIcon} />
            Gallery
          </Dropdown.Item>
          <Dropdown.Divider />
          {/* <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("orders");
            }}
          >
            <Image className="no-selected mr-3 " src={OrdersIcon} />
            Orders
          </Dropdown.Item>
          <Dropdown.Divider /> */}
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("reviews");
            }}
          >
            <Image className="no-selected mr-3 " src={ReviewsIcon} />
            Reviews
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("settings");
            }}
          >
            <Image className="no-selected mr-3 " src={SettingsIcon} />
            Settings
          </Dropdown.Item>
        </>
      )}
      {(user && role) === "STAFF" && (
        <>
          <Dropdown.Item
            className="dropdown-item-header"
            onClick={() => {
              toPortal("login-as");
            }}
          >
            <Image className="no-selected mr-3 " src={LogInAsIcon} /> Log In As
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header d-flex justify-content-between"
            onClick={() => {
              toPortal("approve-reviews");
            }}
          >
            <div>
              <Image className="no-selected mr-3 " src={ApproveGuidesIcon} />
              Reviews
            </div>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header d-flex justify-content-between"
            onClick={() => {
              toPortal("approve-guides");
            }}
          >
            <div>
              <Image className="no-selected mr-3 " src={ApproveGuidesIcon} />
              Approve Guides
            </div>
            {/* <span className="square">1</span> */}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="dropdown-item-header d-flex justify-content-between"
            onClick={() => {
              toPortal("approve-species");
            }}
          >
            <div>
              <BsCheckCircle className="mr-2" />
              Approve Species
            </div>
            {/* <span className="square">1</span> */}
          </Dropdown.Item>
          {/* <Dropdown.Divider /> */}
          {/* <Dropdown.Item
              className="dropdown-item-header  d-flex justify-content-between"
              onClick={() => {
                toPortal("approve-reviews");
              }}
            >
              <div>
                <Image
                  className="no-selected mr-3 "
                  src={ApproveReviewsIcon}
                />
                Approve Reviews
              </div>
              <span className="square">2</span>
            </Dropdown.Item>
            <Dropdown.Divider /> */}
          {/* <Dropdown.Item
              className="dropdown-item-header"
              onClick={() => {
                toPortal("change-report");
              }}
            >
              <Image
                className="no-selected mr-3 "
                src={ChangeReportIcon}
              />
              Change Report
            </Dropdown.Item> */}
        </>
      )}
      <Dropdown.Divider />
      <Dropdown.Item
        className="dropdown-item-header"
        onClick={() => {
          changeModal();
        }}
      >
        <Image className="no-selected mr-3 " src={SwitchIcon} />
        Switch Account
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item className="dropdown-item-header" onClick={() => logout()}>
        <Image className="no-selected mr-3 " src={LogoutIcon} />
        Log Out
      </Dropdown.Item>
    </>
  );

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="sm"
        className={`${headerClass} d-flex d-flex-row justify-content-between`}
      >
        <Navbar.Brand>
          <Link to="/">
            <Image
              src={logo}
              width="180"
              height="50"
              className="d-inline-block align-top"
            />
          </Link>
        </Navbar.Brand>
        <Nav className="ml-auto">
          {isDesktop && (
            <>
              <Nav.Item>
                <Link to="/search?target=fishing">
                  <Button variant="guidex-link-header mx-5">Fish</Button>
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="/search?target=hunting">
                  <Button variant="guidex-link-header mx-5 ">Hunt</Button>
                </Link>
              </Nav.Item>
            </>
          )}
          {(isDesktop || isTablet) && (
            <Nav.Item>
              <Link to="/featured-expeditions">
                <Button variant="guidex-link-header mx-5">
                  Featured Expeditions
                </Button>
              </Link>
            </Nav.Item>
          )}
        </Nav>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={(e) => {
            if (isMobile) {
              setMenuMobile(!menuMobile);
            }
          }}
        />
        {!isMobile ? (
          <Navbar.Collapse id="responsive-navbar-nav">
            {!auth || !user ? (
              <Nav className="ml-auto">
                <Nav.Item className="mr-3">
                  <Link to="/login">
                    <Button variant="guidex-outline" size="guidex-sm">
                      Login
                    </Button>
                  </Link>
                </Nav.Item>
                <Nav.Item className="mr-3">
                  <Link to="/register">
                    <Button variant="guidex" size="guidex-sm">
                      Register
                    </Button>
                  </Link>
                </Nav.Item>
              </Nav>
            ) : (
              <Nav className="ml-auto">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="guidex-link mx-5"
                    id="dropdown-basic"
                  >
                    <BsFillPersonFill /> Hello, {userName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-header">
                    {userDataDropdown}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            )}
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse id="responsive-navbar-nav">
            {(!auth || !user) && (
              <Container fluid className="justify-content-end">
                <Row>
                  <Col>
                    <Link to="/login">
                      <Button variant="guidex-outline" size="guidex-sm">
                        Login
                      </Button>
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/register">
                      <Button variant="guidex" size="guidex-sm">
                        Register
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            )}
          </Navbar.Collapse>
        )}
      </Navbar>
      <Modal show={showSwitch} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <MultipleAccounts
            background="noBackground"
            margin="noMargin"
            modal="true"
            onSwitchAccount={() => {
              setShowSwitch(false);
            }}
          ></MultipleAccounts>
        </Modal.Body>
      </Modal>
    </>
  );
}
