import React, { useEffect, useState } from "react";
import { Col, Container, Image, ListGroup, Row } from "react-bootstrap";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import LogoutActiveIcon from "../../../assets/icons/Logout Active.svg";
import LogoutIcon from "../../../assets/icons/Logout.svg";
import SwitchActiveIcon from "../../../assets/icons/Switch Account Active.svg";
import SwitchIcon from "../../../assets/icons/Switch Account.svg";
import ApproveGuidesIcon from "../../../assets/icons/Approved Guides.svg";
import { app } from "../../../FirebaseConfig";
import { getInitials } from "../../../utils/Misc";
//import LogInAsIcon from "../../../assets/icons/Log In As.svg";

export default function MenuStaff({
  fullName,
  selectedRole,
  profilePic,
  selectedTab,
  onChangeItem,
  triggerChangeModal
}) {
  const [selectedPage, setSelectedPage] = useState(selectedTab ?? "profile");

  const history = useHistory();

  useEffect(() => {
    setSelectedPage(selectedTab);
  }, [selectedTab]);

  const logout = async () => {
    await app
      .auth()
      .signOut()
      .then(result => {
        history.push("/");
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <Router>
        <ListGroup variant="flush" className="lateral-menu">
          <ListGroup.Item className="mt-3 lateral-menu-header">
            <Container fluid>
              <Row>
                <Col>
                  <Row>
                    {profilePic ? (
                      <img
                        className="d-none d-sm-block circle-profile "
                        src={profilePic}
                        alt=""
                      />
                    ) : (
                      <div className="circle-profile mt-1">
                        <span className="initials">
                          {getInitials(fullName)}
                        </span>
                      </div>
                    )}
                    <Col>
                      <p className="card-text font-size-18 font-weight-bold user mt-1">
                        {fullName}
                      </p>
                      <p className="font-size-12 role-name type">
                        {selectedRole.toLowerCase()} portal
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
          {/* <ListGroup.Item
            className={
              selectedPage === "profile"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("login-as");
              onChangeItem("login-as");
            }}
          >
            <Image className="no-selected mr-3" src={LogInAsIcon} />
            <Image className="selected mr-3" src={LogInAsIcon} />
            Log In As
          </ListGroup.Item> */}
          <ListGroup.Item
            className={
              selectedPage === "messages"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("approve-guides");
              onChangeItem("approve-guides");
            }}
          >
            <Image className="selected mr-3" src={ApproveGuidesIcon} />
            <Image className="no-selected mr-3 " src={ApproveGuidesIcon} />
            Approve Guides
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedPage === "advertisement"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("approve-species");
              onChangeItem("approve-species");
            }}
          >
            <Image className="selected mr-3" src={ApproveGuidesIcon} />
            <Image className="no-selected mr-3 " src={ApproveGuidesIcon} />
            Approve Species
          </ListGroup.Item>

          <ListGroup.Item
            className="lateral-menu-item"
            onClick={() => {
              triggerChangeModal();
            }}
          >
            <Image className="selected mr-3" src={SwitchActiveIcon} />
            <Image className="no-selected mr-3 " src={SwitchIcon} />
            Switch Account
          </ListGroup.Item>
          <ListGroup.Item
            className="lateral-menu-item"
            onClick={() => logout()}
          >
            <Image className="selected mr-3" src={LogoutActiveIcon} />
            <Image className="no-selected mr-3 " src={LogoutIcon} />
            Log Out
          </ListGroup.Item>
        </ListGroup>
      </Router>
    </>
  );
}
