import React, { useEffect, useState } from "react";
import FloatingCard from "../../common/FloatingCard";
import Trips from "../../common/Trips";
import { request } from "../../utils/HttpHelper";
import "./About.css";
import OwnerInfo from "./OwnerInfo";
import Quote from "./Quote";

const About = () => {
  window.scrollTo(0, 0);
  const [trip, setTrip] = useState();
  const [tags, setTags] = useState();
  const [guide, setGuide] = useState();
  const [gallery, setGallery] = useState();
  const [rating, setRating] = useState();
  const [id] = useState(52);

  useEffect(() => {
    if (id) processTrip();
    console.dir(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processTrip = async () => {
    getHuntingTripById()
      .then((res) => {
        console.dir(res);
        setTrip({ ...res.trip });
        setTags([...res.tags]);
        setGuide({ ...res.guide });
        setGallery({ ...res.tripGallery });
        setRating({ ...res.rating });
      })
      .catch((error) => console.dir(error));
  };

  const getHuntingTripById = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "GET",
          url: "guidex-api",
          trailingURL: `/hunt-trip?id=${id}`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  return (
    <>
      <div id="imageBackgroundAbout" className="about-title">
        <div id="imageBackgroundOpacity" className="d-flex">
          <h1 className="about-title-h1 pb-4">About Guidex</h1>
        </div>
      </div>

      <div className="justify-content-center bg-green px-3 mb-5 pb-5">
        <FloatingCard
          title="Our Vision Statement"
          text="GuideX is the leading marketplace for outdoor expeditions, empowering clients with detailed information and connecting them with objectively reviewed professionals to maximize their outdoor experience."
          background="floating-card-2 max-widht-card"
          lineColor="#ec7228"
          textColor="floating-text-2"
          fontFamily="montserrat"
        />
      </div>
      <OwnerInfo />
      <Quote />
      {/* {trip && tags && guide && rating && gallery && (
        <Trips
          trip={trip}
          tags={tags}
          guide={guide}
          rating={rating}
          gallery={gallery}
          page="aboutGuidex"
        ></Trips>
      )} */}
    </>
  );
};
export default About;
