import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as Difficulty } from "../../assets/icons/alert-triangle.svg";
import { ReactComponent as Trophy } from "../../assets/icons/award.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Food } from "../../assets/icons/food.svg";
import { ReactComponent as Lodging } from "../../assets/icons/lodging.svg";
import { ReactComponent as People } from "../../assets/icons/people.svg";
import { ReactComponent as Rifle } from "../../assets/icons/rifle.svg";
import { ReactComponent as Bow } from "../../assets/icons/bow.svg";
import { ReactComponent as Muzzleloader } from "../../assets/icons/move.svg";
import { ReactComponent as Transportation } from "../../assets/icons/car.svg";
import { ReactComponent as Bait } from "../../assets/icons/icons-fishing/bait.svg";
import { ReactComponent as Drinks } from "../../assets/icons/icons-fishing/drinks.svg";
import { ReactComponent as IceBox } from "../../assets/icons/icons-fishing/archive.svg";
import { ReactComponent as RodReels } from "../../assets/icons/icons-fishing/rod-reels-tackle.svg";
import { ReactComponent as Razor } from "../../assets/icons/icons-fishing/razor.svg";
import { ReactComponent as KeepCatch } from "../../assets/icons/icons-fishing/keep-catch.svg";
import { ReactComponent as Person } from "../../assets/icons/icons-fishing/person.svg";
import { ReactComponent as GroupCharter } from "../../assets/icons/icons-fishing/group-charter.svg";
import { ReactComponent as GuideLicensed } from "../../assets/icons/icons-fishing/file.svg";
import { TbToiletPaper } from "react-icons/tb";
import { TbApple as Apple } from "react-icons/tb";
import "./HuntingTrip.css";
import "./PopularFeatures..css";

const PopularFeatures = (props) => {
  const { tags } = props;
  const [chunkTags, setChunkTags] = useState([]);
  useEffect(() => {
    if (tags && tags.length) {
      if (tags.length % 5 !== 0) {
        console.dir(tags);
        let res = 5 - (tags.length % 5);
        while (res > 0) {
          res = res - 1;
          tags.push({});
        }
        let chunks = [];
        let size = 5;

        while (tags.length > 0) chunks.push(tags.splice(0, size));
        setChunkTags(chunks);
      } else {
        let chunks = [];
        let size = 5;

        while (tags.length > 0) chunks.push(tags.splice(0, size));
        setChunkTags(chunks);
      }
    }
  }, [tags]);
  return (
    <>
      <Container className="popular-padding">
        <Row className="popular-color">
          <Col md="3">
            <h2 className="font-weight-bold popular-text mb-5 ml-3">
              What’s Included
            </h2>
          </Col>
          <Col md="9">
            {!!chunkTags.length &&
              chunkTags.map((chunk, i) => {
                return (
                  <Row className={i >= 1 ? "mt-4" : "mt-0"} key={i}>
                    {chunk.map((tag, i) => {
                      console.dir(tag);
                      if (tag.tagType) {
                        switch (tag.tagType) {
                          case "Method":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                {/* <SvgContainer route="../icons/rifle.svg" /> */}
                                {tag.tagName === "Rifle" && (
                                  <>
                                    <Rifle className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center mt-4">
                                      {tag.tagName}
                                    </p>
                                  </>
                                )}
                                {tag.tagName === "Bow" && (
                                  <>
                                    <Bow
                                      className="icon-Included"
                                      fill="#fff"
                                    />
                                    <p className="font-size-15 font-weight-bold text-center mt-4">
                                      {tag.tagName}
                                    </p>
                                  </>
                                )}
                                {tag.tagName === "Muzzleloader" && (
                                  <>
                                    <Muzzleloader className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center mt-4">
                                      {tag.tagName}
                                    </p>
                                  </>
                                )}
                              </Col>
                            );
                          case "General":
                            switch (tag.tagName) {
                              case "License":
                                return (
                                  <Col
                                    className="text-center"
                                    key={i}
                                    xs={6}
                                    md={4}
                                    lg={2}
                                  >
                                    <GuideLicensed className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center  mt-4">
                                      {tag.tagName}
                                    </p>
                                  </Col>
                                );
                              case "Food":
                                return (
                                  <Col
                                    className="text-center"
                                    key={i}
                                    xs={6}
                                    md={4}
                                    lg={2}
                                  >
                                    <Food className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center  mt-4">
                                      {tag.tagName}
                                    </p>
                                  </Col>
                                );
                              case "Transportation":
                                return (
                                  <Col
                                    className="text-center"
                                    key={i}
                                    xs={6}
                                    md={4}
                                    lg={2}
                                  >
                                    <Transportation className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center  mt-4">
                                      {tag.tagName}
                                    </p>
                                  </Col>
                                );
                              case "Trophy Care":
                                return (
                                  <Col
                                    className="text-center"
                                    key={i}
                                    xs={6}
                                    md={4}
                                    lg={2}
                                  >
                                    <Trophy className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center  mt-4">
                                      {tag.tagName}
                                    </p>
                                  </Col>
                                );
                              case "1-1 Guide":
                                return (
                                  <Col
                                    className="text-center"
                                    key={i}
                                    xs={6}
                                    md={4}
                                    lg={2}
                                  >
                                    <People className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center  mt-4">
                                      {tag.tagName}
                                    </p>
                                  </Col>
                                );
                              case "Lodging":
                                return (
                                  <Col
                                    className="text-center"
                                    key={i}
                                    xs={6}
                                    md={4}
                                    lg={2}
                                  >
                                    <Lodging className="icon-Included" />
                                    <p className="font-size-15 font-weight-bold text-center  mt-4">
                                      {tag.tagName}
                                    </p>
                                  </Col>
                                );

                              default:
                                return (
                                  <Col
                                    className="text-center"
                                    key={i}
                                    xs={6}
                                    md={4}
                                    lg={2}
                                  ></Col>
                                );
                            }
                          case "Duration":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <Calendar className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagName}
                                </p>
                              </Col>
                            );
                          case "Exertion":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <Difficulty className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagName}
                                </p>
                              </Col>
                            );

                          default:
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              ></Col>
                            );
                        }
                      } else {
                        switch (tag.tagTypeName) {
                          case "Bait":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                {/* <SvgContainer route="../icons/rifle.svg" /> */}
                                <Bait className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Fishing License":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <GuideLicensed className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Drinks":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <Drinks className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Food (snacks)":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <Apple size={"30px"} />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Cleaning & Filleting":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <Razor className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Child Friendly":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <People className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Rod, Reels & Tackle":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <RodReels className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Keep Your Catch":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <KeepCatch className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                            case "Marine Head":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <TbToiletPaper size={"30px"} />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );

                            case "Group Charter":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <GroupCharter className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );

                            case "Private Charter":
                              return (
                                <Col
                                  className="text-center"
                                  key={i}
                                  xs={6}
                                  md={4}
                                  lg={2}
                                >
                                  <Person className="icon-Included" />
                                  <p className="font-size-15 font-weight-bold text-center  mt-4">
                                    {tag.tagTypeName}
                                  </p>
                                </Col>
                              );

                          case "Ice Box":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <IceBox className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );
                          case "Exertion":
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              >
                                <Difficulty className="icon-Included" />
                                <p className="font-size-15 font-weight-bold text-center  mt-4">
                                  {tag.tagTypeName}
                                </p>
                              </Col>
                            );

                          default:
                            return (
                              <Col
                                className="text-center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2}
                              ></Col>
                            );
                        }
                      }
                    })}
                  </Row>
                );
              })}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default PopularFeatures;
