import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Image } from "react-bootstrap";
import RejectionModal from "./RejectionModal";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import Loader from "react-loader-spinner";
import { showToast } from "../../../utils/Misc";
import { request } from "../../../utils/HttpHelper";
import DataTable from "react-data-table-component";
import ArrowDown from "../../../assets/icons/arrow-ios-down.svg";
import ArrowUp from "../../../assets/icons/arrow-ios-up.svg";
import "./ApproveSingleGuide.css";
import { useMediaQuery } from "react-responsive";

const ApproveSingleGuide = props => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { selectedGuide, onSubmitGuide } = props;
  const { guideType, guideId } = selectedGuide;
  const iconDown = <Image src={ArrowDown} />;
  const iconUp = <Image src={ArrowUp} />;
  const [showSwitch, setShowSwitch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [guideData, setGuideData] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const changeModal = () => {
    setShowSwitch(true);
  };

  const handleClose = () => {
    setShowSwitch(false);
  };

  const customStyles = {
    expanderButton: {
      style: {
        display: "block",
        position: "absolute",
        left: "61vw",
        zIndex: 1
      }
    },

    rows: {
      style: {
        minHeight: "72px",
        fontSize: "17px",
        fontFamily: "Roboto" // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "1px",
        fontSize: "17px",
        fontFamily: "Roboto",
        fontWeight: "bold" // override the row height
      }
    },
    headRow: {
      style: {
        minHeight: "56px",
        borderBottomWidth: "0px",
        borderBottomColor: "white",
        borderBottomStyle: "none"
      }
    },
    cells: {
      style: {
        paddingLeft: "1px", // override the cell padding for data cells
        paddingRight: "1px"
      }
    }
  };
  const columns = [
    {
      name: "License Number",
      selector: "licenseNumber",
      sortable: true
    }
  ];
  let licencesPage = async (state, guideType) =>{
    // This method will get the specific link to verify if the licence number is real
    let trailingURL = ""
        switch (guideType) {
          case "FISHERMAN":
            trailingURL = `/license-verification-url?state=${state}&entityTypeID=2`;
            break;
          default:
            trailingURL = `/license-verification-url?state=${state}&entityTypeID=1`;
        }
    return new Promise( (resolve)=>{
      request({
        url: "guidex-api",
        trailingURL,
      })
      .then(link =>{
        resolve(link.data.url)
      })
    })
  }
  const getGuideData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let trailingURL = "";

        switch (guideType) {
          case "FISHERMAN":
            trailingURL = `/guide-fisher?id=${guideId}`;
            break;
          default:
            trailingURL = `/guide-hunter?id=${guideId}`;
        }

        let guideResult = await request({
          url: "guidex-api",
          trailingURL,
          requiresAuth: true
        });
        console.dir(guideResult.data);
        
        const { guide, licenses } = guideResult.data;
        let mapedLicenses = licenses.map( l => {
          let licenseInfo = {
            licenseNumber: l.licenseNumber,
            details: {
              GuideName: guide.businessName,
              ContactName: guide.fullName,
              Phone: guide.phoneNumber,
              Email: guide.email,
              Website: guide.website,
              City: guide.mailingAddress,
              State: l.licenseState,
              linkPageGov : licencesPage(l.licenseState, guideType)
            }
          };
          console.dir(licenseInfo.details)
          return licenseInfo;
        });
        resolve(setGuideData(mapedLicenses), setIsLoading(false));
      } catch (error) {
        showToast(
          <>
            <AiFillWarning /> There is an error trying to get the guide data.
          </>,
          "error"
        );
        setIsLoading(false);
        reject(error);
      }
    });
  };

  const approveGuide = async () => {
    try {
      setDisabled(true);
      await request({
        method: "PUT",
        url: "guidex-api",
        trailingURL: `/approve-guide?guide_type=${guideType}&guide_id=${guideId}`,
        requiresAuth: true
      });
      showToast(
        <>
          <AiFillCheckCircle /> Selected guide has beed approved!
        </>,
        "success"
      );
      setDisabled(false);
      onSubmitGuide();
    } catch (error) {
      showToast(
        <>
          <AiFillWarning /> There was a problen trying to approve this guide.
        </>,
        "error"
      );
      setDisabled(false);
    }
  };

  const rejectGuide = async (reason, message) => {
    try {
      setShowSwitch(false);
      setDisabled(true);
      await request({
        method: "DELETE",
        url: "guidex-api",
        trailingURL: `/approve-guide?guide_type=${guideType}&guide_id=${guideId}&reason=${reason}&message=${message}`,
        requiresAuth: true
      });
      showToast(
        <>
          <AiFillCheckCircle /> Selected guide has beed rejected.
        </>,
        "success"
      );
      setDisabled(false);
      onSubmitGuide();
    } catch (error) {
      showToast(
        <>
          <AiFillWarning /> There was a problen trying to reject this guide.
        </>,
        "error"
      );
      setDisabled(false);
    }
  };

  useEffect(() => {
    setIsLoading(true)
    getGuideData();
    // eslint-disable-next-line
    setIsLoading(false)
  }, []);
  const ExpandableComponent = ({ data }) => (
    <Container fluid className="gray-background ml-0 mr-0">
      <Row>
        <Col md="1" className="ml-4 pt-5">
          <p className="font-weight-bold">Guide Name</p>
        </Col>
        <Col md="3 " className="pt-5">
          <span>{data.details.GuideName}</span>
        </Col>
        <Col md="7" className="d-flex justify-content-end  pt-5">
          <a
            href={data.details.linkPageGov}
            target="_blank"
            className="link-underline"
            rel="noopener noreferrer"
          >
            Look Up License Number
          </a>
        </Col>
      </Row>
      <Row>
        <Col md="1" className="ml-4">
          <p className="font-weight-bold">Contact Name</p>
        </Col>
        <Col md="4">
          <span>{data.details.ContactName}</span>
        </Col>
      </Row>
      <Row>
        <Col md="1" className="ml-4">
          <p className="font-weight-bold">Phone</p>
        </Col>
        <Col md="4">
          <span>{data.details.Phone}</span>
        </Col>
      </Row>
      <Row>
        <Col md="1" className="ml-4">
          <p className="font-weight-bold">Email</p>
        </Col>
        <Col md="4">
          <span>{data.details.Email}</span>
        </Col>
      </Row>
      <Row>
        <Col md="1" className="ml-4">
          <p className="font-weight-bold">Website</p>
        </Col>
        <Col md="4">
          <span>{data.details.Website}</span>
        </Col>
      </Row>
      <Row>
        <Col md="1" className="ml-4">
          <p className="font-weight-bold">City</p>
        </Col>
        <Col md="4">
          <span>{data.details.City}</span>
        </Col>
      </Row>
      <Row>
        <Col md="1" className="ml-4">
          <p className="font-weight-bold">State</p>
        </Col>
        <Col md="3">
          <span>{data.details.State}</span>
        </Col>
        <Col md="7" className="d-flex justify-content-end mb-5  ">
          <Button
            variant="guidex-light"
            className="mr-3"
            onClick={changeModal}
            disabled={disabled}
          >
            Reject
          </Button>
          <Button
            variant="approve-guide-approved"
            onClick={approveGuide}
            disabled={disabled}
          >
            Approved
          </Button>
        </Col>
      </Row>
    </Container>
  );
  return isLoading ? (
    <center className="mt-5">
      <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
    </center>
  ) : (
    <>
      {isTabletOrMobile ? (
        <>
          {guideData.map(data => (
            <Container
              fluid
              className="bg-white mt-3 shadow"
              key={data.details.Website}
            >
              <div className="pt-5">
                <a
                  href="https://www.guidex.com"
                  target="_blank"
                  className="link-underline  ml-4"
                  rel="noopener noreferrer"
                >
                  Look Up License Number
                </a>
              </div>
              <p className="label ml-4 mt-5">license Number</p>
              <p className="ml-4 license">{data.licenseNumber}</p>
              <p className="label ml-4 mt-5">Guide Name</p>
              <p className="ml-4">{data.details.GuideName}</p>
              <p className="label ml-4 mt-5">Phone</p>
              <p className="ml-4">{data.details.Phone}</p>
              <p className="label ml-4 mt-5">Website</p>
              <p className="ml-4">{data.details.Website}</p>
              <p className="label ml-4 mt-5">Guide Contact Name</p>
              <p className="ml-4">{data.details.ContactName}</p>
              <p className="label ml-4 mt-5">Email</p>
              <p className="ml-4">{data.details.Email}</p>
              <p className="label ml-4 mt-5">City</p>
              <p className="ml-4">{data.details.City}</p>
              <p className="label ml-4 mt-5">State</p>
              <p className="ml-4">{data.details.State}</p>
              <div className="d-flex  flex-row justify-content-center pb-5 mt-4">
                <Button
                  className="mr-3"
                  variant="guidex-light"
                  onClick={changeModal}
                  disabled={disabled}
                >
                  Reject
                </Button>
                <Button
                  variant="guidex"
                  onClick={approveGuide}
                  disabled={disabled}
                >
                  Approved
                </Button>
              </div>
            </Container>
          ))}
        </>
      ) : (
        <Container fluid className="approve-guide-container2 mb-5">
          <Row>
            <Col>
              <DataTable
                title="orders"
                expandableIcon={{ collapsed: iconDown, expanded: iconUp }}
                customStyles={customStyles}
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                highlightOnHover
                data={guideData}
                columns={columns}
                expandableRows
                expandableRowsComponent={<ExpandableComponent />}
              />
            </Col>
          </Row>
        </Container>
      )}

      <Modal show={showSwitch} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <RejectionModal
            submitRejection={rejectGuide}
            cancelRejection={() => {
              setShowSwitch(false);
            }}
          ></RejectionModal>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ApproveSingleGuide;
