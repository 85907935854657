import React from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";

import alertIcon from "../../../assets/icons/Warning Icon.png";
const ModalWarning = (props) => {

  const { closeModal } = props;

  return (
    <Container fluid className="rejection-modal">
      <Row>
        <Col className="d-flex justify-content-center">
          <Image src={alertIcon} width="72px " />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="d-flex justify-content-center">
          <p className="font-weight-bold font-size-22">Oops!</p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="d-flex justify-content-center">
          <p className="text-center">
            A desktop exprience is recommended to view your account. the
            exprience is not formatted for movile and tables devices at this
            time
          </p>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col className="d-flex justify-content-center button-procced">
          <Button variant="procced" onClick={() => { closeModal(); }}>Procced</Button>
        </Col>
      </Row>
    </Container>
  );
};
export default ModalWarning;
