import qs from "query-string";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { request } from "../../utils/HttpHelper";
import ContactUsForm from "../ContactUs/ContactUsForm";
import "./ContactGuide.css";

export default function ContactGuide() {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const history = useHistory();
  const [tripId, setTripId] = useState();
  const [guideType, setGuideType] = useState();
  const [guideId, setGuideId] = useState();
  const [guideData, setGuideData] = useState();
  const [guideName, setGuideName] = useState();
  const [reviewData, setReviewData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getGuideData = (guide_type, guide_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { guide_type, guide_id, trip_id } = qs.parse(history.location.search);
        console.table({ guide_type, guide_id, trip_id });
        setGuideType(guide_type);
        setGuideId(Number(guide_id));
        setTripId(trip_id);
        switch (guide_type) {
          case "guide_fisherman":
            let guideResult = await request({
              url: "guidex-api",
              trailingURL: `/guide-fisher?id=${guide_id}`,
            });
            setGuideData(guideResult.data);
            setGuideName(guideResult.data.guidePublic.fullName);
            break;
          case "guide_hunter":
            let guideResultHunting = await request({
              url: "guidex-api",
              trailingURL: `/guide-hunter?id=${guide_id}`,
            });
            console.dir(guideResultHunting.data);
            setGuideData(guideResultHunting.data);
            setGuideName(guideResultHunting.data.guidePublic.fullName);
            break;
          default:
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const getGuideReviews = (guide_type, guide_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let guideTypeName = "";
        switch (guide_type) {
          case "guide_fisherman":
            guideTypeName = "Guide Fisherman";
            break;
          default:
            guideTypeName = "Guide Hunter";
        }
        let reviewsResult = await request({
          url: "guidex-api",
          trailingURL: `/public-reviews?entity_type=${guideTypeName}&entity_id=${guide_id}`,
        });
        console.dir(reviewsResult.data);
        const { reviews } = reviewsResult.data;
        if (reviews && reviews.length > 0) {
          setReviewData(reviews[0]);
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const getInfo = async () => {
    try {
      setIsLoading(true);
      await getGuideData();
      await getGuideReviews();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line
  }, []);
  console.log(guideData)
  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-center"
        id="imageBackgroundContacUs"
      >
        {<Row className="guidex-card2">
          <Col className={!isMobile ? "" : "pr-0 pl-0"}>
            <div className={!isMobile ? " " : ""}>
              <ContactUsForm
                targetMessage={{ guideType, guideId, guideName, tripId }}
              />
            </div>
          </Col>
        </Row>}
      </Container>
    </>
  );
}
