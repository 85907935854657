import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  FormControl,
  Form,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { request } from "../../../utils/HttpHelper";
import { showToast } from "../../../utils/Misc";
import { AiFillWarning, AiFillCheckCircle } from "react-icons/ai";
import Loader from "react-loader-spinner";
import StarRatings from "react-star-ratings";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import copyIcon from "../../../assets/icons/checkmark-circle.svg";
import archiveIcon from "../../../assets/icons/archive.svg";
import emailIcon from "../../../assets/icons/email.svg";

const ApproveReviews = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [refresh, setRefresh] = useState(false)

  const getPendingReviews = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let reviewsResults = await request({
          url: "guidex-api",
          trailingURL: `/staff/reviews`,
          requiresAuth: true,
        });
        resolve(setReviews(reviewsResults.data));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        reject(error);
      }
    });
  };

  const changeReviewStatus = (id, entityTypeID, reviewStatus) => {
    let body = {
      reviewId: id,
      reviewEntityTypeId: entityTypeID,
      state: `${reviewStatus ? reviewStatus : "live"}`,
    };
    return new Promise(async (resolve, reject) => {
      try {
        request({
          url: "guidex-api",
          method: "PUT",
          body: body,
          trailingURL: `/reviews?staff=true`,
          requiresAuth: true,
        })
        resolve(
          showToast(
            <>
              <AiFillCheckCircle /> {reviewStatus ? "Review archived":"Review approved"}
            </>,
            "success"
          ), setRefresh(!refresh)
        );
      } catch (error) {
        console.log("This is unauthorized");
      }
    });
  };

  useEffect(() => {
    getPendingReviews();
  }, [refresh]);
  return (
    <>
      <h2 className="mb-5 approve-guides-title portal-section-title mt-5">
        Approve Reviews
      </h2>
      {isLoading && (
        <center>
          <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
        </center>
      )}
      {!isLoading && (
        <>
          <Container fluid className="approve-guides-container mb-5">
            <Table>
              <thead>
                <tr>
                  <th> Rating</th>
                  <th>Author</th>
                  <th>Guide</th>
                  <th>Was your trip successful?</th>
                  <th>Was your experience as advertised?</th>
                  
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((e) => (
                  <>
                    <tr key={e.id + "review"}>
                      <td>
                        <StarRatings
                          rating={e.rating}
                          starDimension="20px"
                          starSpacing="0px"
                          starRatedColor="#EC7228"
                        />
                      </td>
                      <td>{e.requesteeName}</td>
                      <td>{e.requesterName}</td>
                      <td>{e.wasSuccessful}</td>
                      <td>{e.wasAsAdvertised}</td>                     
                      <td style={{ maxWidth: "525px" }}>
                        {e.requesteeMessage}
                      </td>
                      <td>
                        <Dropdown alignRight className="pb-0 mb-0">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            variant="no-border"
                          >
                            <BiDotsHorizontalRounded color="orange" size="30" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="favorite-trips-menu-width">
                            <Dropdown.Item
                              eventKey="option-1 "
                              className="favorite-trips-bigger-cell"
                              onClick={() => {
                                changeReviewStatus(e.id, e.entityTypeID);
                              }}
                            >
                              <span className="list-center-element">
                                <Image src={copyIcon} className="mr-4" />
                                Approve Review
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              eventKey="option-2"
                              className="favorite-trips-bigger-cell"
                              onClick={()=> window.location.href=`mailto:${e.requesteeEmail}`}
                            >
                              <span className="list-center-element">
                                <Image src={emailIcon} className="mr-4" />
                                Contact Reviewer

                              </span>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              className="favorite-trips-bigger-cell"
                              eventKey="option-3"
                              onClick={() => {
                                changeReviewStatus(
                                  e.id,
                                  e.entityTypeID,
                                  "archived"
                                );
                              }}
                            >
                              <span className="list-center-element">
                                <Image src={archiveIcon} className="mr-4" />
                                Archive
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </Container>
        </>
      )}
    </>
  );
};

export default ApproveReviews;
