import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import Loader from "react-loader-spinner";
import { User } from "../utils/UserProvider";
import { request } from "../utils/HttpHelper";
import { useParams } from "react-router-dom";
import { findAllInRenderedTree } from "react-dom/test-utils";

const Favorite = props => {
  const id = parseInt(useParams("id").id)
  const { entityType, entityId } = props;
  const { user, setuser } = useContext(User);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentEntityArray, setCurrentEntityArray] = useState([]);
  const history = useHistory();

  
  const matchFavortes = () => {
    const { favorites } = user;
    const { huntingTripFavorites } = favorites;

    switch (entityType) {
      /**
       * TODO: Guide hunter & guide fisherman case
       */
      case "HUNT_TRIP":
        setCurrentEntityArray([...huntingTripFavorites]);
        let favElement = huntingTripFavorites.find(ht => ht === (id ? id : entityId));
        console.log(favElement);  
        if (favElement) {
          setIsFavorite(true);
        }
        break;
      default:
    }
  };

  const setLocalFavorites = () => {
    const { favorites } = user;
    switch (entityType) {
      case "HUNT_TRIP":
        let i = currentEntityArray.findIndex(id => id === entityId);
        if (i >= 0) {
          currentEntityArray.splice(i, 1);
        } else {
          currentEntityArray.push(entityId);
        }
        break;
      default:
    }
    setuser(prevData => {
      let newfavorites = {
        ...favorites,
        huntingTripFavorites: currentEntityArray
      };
      return { ...prevData, favorites: newfavorites };
    });
  };

  const setFavoriteStatus = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let body = {
          entityID: id ? id : entityId,
          entityName: entityType
        };
        await request({
          method: "PUT",
          url: "guidex-api",
          trailingURL: "/favorites",
          body,
          requiresAuth: true
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const toggle = () => {
    if (user == null) history.push("/login")
    if (user && user.favorites) {
      setIsLoading(true);
      setFavoriteStatus()
        .then(() => {
          setIsFavorite(!isFavorite);
          setLocalFavorites();
          setIsLoading(false);
        })
        .catch(err => {
          console.error(err);
          setIsLoading(false);
        });
    };
  };

  useEffect(() => {
    if (user && user.favorites) {
      matchFavortes();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading ? (
        isFavorite ? (
          <FaHeart
            className="primary-color ml-auto clickable te"
            size={20}
            onClick={() => {
              toggle();
            }}
          ></FaHeart>
        ) : (
          <FaRegHeart
            className="primary-color ml-auto clickable"
            size={20}
            onClick={() => {
              toggle();
            }}
          ></FaRegHeart>
        )
      ) : (
        <Loader type="ThreeDots" color="#ec7228" height={16} width={16} />
      )}
    </>
  );
};

export default Favorite;
