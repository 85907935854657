import React, { useState, useEffect } from "react";
import AdvertisingPerArea from "./AdvertisingPerArea";
import AdvertisingShares from "./AdvertisingShares";

const AdvertisementFisherman = () => {
  const [step, setStep] = useState("");
  const [aquireSharesData, setAquireSharesData] = useState();

  const apply = (selectedCity) => {
    setAquireSharesData({
      cityName: selectedCity.label,
      mapboxID: selectedCity.key
    })
    setStep("acquiredShares");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <div className="mb-5">
      {(() => {
        switch (step) {
          case "acquiredShares":
            return <AdvertisingShares onReturn={() => {
              setStep("");
            }} aquireSharesData={aquireSharesData} />
          default:
            return (
              <>
                <AdvertisingPerArea
                  onApply={apply}
                />=
              </>
            );
        }
      })()}
    </div>
  );
};
export default AdvertisementFisherman;
