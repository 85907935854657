import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import ReactDOMServer from "react-dom/server";
import location1 from "../../assets/icons/location1.svg";
import location2 from "../../assets/icons/location2.svg";
import { useHistory } from "react-router-dom";
import "./Mapbox.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWlrZWZyYW5jbzM5MzkiLCJhIjoiY2tpM3U2emQ4M2VjZTJ5bjN6NjEybnNmcyJ9.Tsm309LXDKV3Mw2MtgJrRw";

const Map = (props) => {
  const { target, results, currentLocation } = props;
  

  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  const setUserLocation = ()=>{
    if(currentLocation){
      setLng(currentLocation.coords.longitude)
      setLat(currentLocation.coords.latitude)
      setZoom(3)
    }
    return
  }

  // eslint-disable-next-line
  const [lng, setLng] = useState(-93.185195);
  // eslint-disable-next-line
  const [lat, setLat] = useState(44.460938);
  // eslint-disable-next-line
  const [zoom, setZoom] = useState(2.5);

  const history = useHistory();

  const popUpMarker = ({
    tripId,
    category,
    title,
    guideCompany,
    price,
    persons,
  }) => {
    const popUpContent = (
      <>
        <div className="map-popup-content d-flex flex-column justify-content-between">
          {category == "hunt" ? (
            <div className="d-flex flex-column">
              <div className="d-flex">
                <Image src={location1} className="mr-2" width="25px" />
                <h1>{title}</h1>
              </div>
              <p className="guide-company font-weight-bold">{guideCompany}</p>
              <div className="d-flex justify-content-between">
                <p className="price mb-0 map-popup-price">{price}</p>
                <a href={`/hunting-trip/${tripId}`} className="btn-guidex-guides">Learn More</a>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <Image src={location2} className="mr-2" width="25px" />
                <p className="guide-company font-weight-bold mb-0">{title}</p>
              </div>
              <h1>{guideCompany}</h1>
              <p className="text-dark">{persons} person</p>
              <div className="d-flex justify-content-between">
                <p className="price mb-0 map-popup-price">
                  <span className="font-weight-light map-popup-price-from mr-1">
                    From:
                  </span>
                  ${price[0]} - ${price[1]}
                </p>
                <a href={`/guide-fisherman/${tripId}`} className="btn-guidex-guides">Contact Guide</a>
              </div>
            </div>
          )}
        </div>
      </>
    );
    return ReactDOMServer.renderToStaticMarkup(popUpContent);
  };

  const getLocationsFishing = () => {
    results
      .filter((r) => {
        return r.geolocation != null;
      })
      .forEach((r) => {
        let markerElFish = document.createElement("div");
        markerElFish.className = "marker marker-fish-trip";
        let popUpFish = new mapboxgl.Popup().setHTML(
          popUpMarker({
            tripId: r.guideFisherID,
            category: "fish",
            title: r.guideFullName,
            guideCompany: r.guideBusinessName,
            price: [r.guidePriceMin, r.guidePriceMax],
            persons: r.guidePartySize,
          })
        );
        new mapboxgl.Marker(markerElFish)
          .setLngLat([r.geolocation.lng, r.geolocation.lat])
          .setPopup(popUpFish)
          .addTo(map);
      });
  };

  const getLocationsHunting = () => {
    results
      .filter((r) => {
        return r.geolocation != null;
      })
      .forEach((r) => {
        let markerElHunt = document.createElement("div");
        markerElHunt.className = "marker marker-hunt-trip";
        let popUpHunt = new mapboxgl.Popup().setHTML(
          popUpMarker({
            tripId: r.huntingTripID,
            category: "hunt",
            title: r.huntingTripName,
            guideCompany: r.guideBusinessName,
            price: `$${r.pricePerPerson}`,
          })
        );
        new mapboxgl.Marker(markerElHunt)
          .setLngLat([r.geolocation.lng, r.geolocation.lat])
          .setPopup(popUpHunt)
          .addTo(map);
      });
  };

  useEffect( () => {
    const auxMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [lng, lat],
      zoom: zoom,
      pitch: 0,
      doubleClickZoom: false,
    });

    setMap(auxMap);
  }, [mapContainerRef, lat, results]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map) {
      switch (target) {
        case "fishing":
          getLocationsFishing();
          break;
        default:
          getLocationsHunting();
      }
    }
    setUserLocation()
  }, [map, results, currentLocation]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="mapbox-outter-layer">
      <div className="map-container" ref={mapContainerRef} />
    </div>
  );
};

export default Map;
