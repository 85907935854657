import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Row, Tab, Nav } from "react-bootstrap";
import { Formik } from "formik";
import { showToast } from "../../../../utils/Misc";
import Loader from 'react-loader-spinner';
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { request } from "../../../../utils/HttpHelper";
import MapGeolocation from "../../Common/Map/MapGeolocation";
import DataListInput from "react-datalist-input";
import { DateRangePicker } from "react-dates";
import "./NewTrip.css";
import * as $ from "jquery";
import * as moment from "moment";

const UpdateTrip = (props) => {

  const { onUploadTrip, tripId, handlePage } = props;

  // eslint-disable-next-line no-unused-vars
  const [showSwitch, setShowSwitch] = useState(false);
  const [tagsCatalogue, setTagsCatalogue] = useState([]);
  const [galleryImagesList, ] = useState([]);
  const [galleryId, setGalleryId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDataTrip, setCurrentDataTrip] = useState();
  const [, updateState] = useState();
  const [species, setSpecies] = useState([]);
  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [dateRange, setdateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [focus, setFocus] = useState(null);
  const handleOnDateChange = (startDate, endDate) =>  {
    console.log(startDate)
    console.log(endDate)
    setdateRange(startDate, endDate);
  }
  // eslint-disable-next-line no-unused-vars
  const handleClose = () => {
    setShowSwitch(false);
  };

  const getHuntTripsCatalogue = () => {
    return new Promise(async(resolve, reject) => {
      try {
        let result = await request({
          url: 'guidex-api',
          trailingURL: `/hunt-trip-tags`
        });
        resolve(result.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const getTripInfo = () => {
    return new Promise( async(resolve, reject) => {
      try {
        let result = await request({
          url: 'guidex-api',
          trailingURL: `/hunt-trip?id=${tripId}`
        });
        console.log(result.data)
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  const getTripGallery = () => {
    return new Promise(async(resolve, reject) => {
      try {
        let result = await request({
          url: 'guidex-api',
          trailingURL: `/gallery?entity_id=${tripId}&entity_type=Hunt%20Trip`
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    })
  }

  const checkIfselected = (values, tagId) => {
    return (values.tags.indexOf(tagId) >= 0);
  }
  
  const toggleTag = (values, tagId) => {
    let index = values.tags.indexOf(tagId);
    if(index >= 0){
      values.tags.splice(index, 1);
    } else {
      values.tags.push(tagId);
    }
    updateState({});
  };

  const tagHastErrors = (tagsCat = [], selTags = []) => {
    let hasError = false;
    tagsCat.forEach ( tt => {
      let { tags } = tt;
      let matchedTag = tags.find( t => {
        return selTags.includes(t.tagId);
      });
      if(!matchedTag){
        hasError = true;
      }
    })
    return hasError;
  }

  const setGalleryPictures = () => {
    $('#gallery-pictures').trigger("click");
  }

  const handleGalleryChange = () => {
    let input = document.getElementById('gallery-pictures');
    //setGalleryImagesList([]);
    galleryImagesList.splice(0, galleryImagesList.length);
    for(let i=0; i<11; i++){
      let currentSlot = '#gallery-pictures-slot-'+i;
      $(currentSlot).attr('src', '');
    }
    if(input.files){
      for(let i=0; i<input.files.length; i++){
        var reader = new FileReader();
        reader.onload = function(e) {
          let currentSlot = '#gallery-pictures-slot-'+i;
          $(currentSlot).attr('src', e.target.result);
        }
        reader.readAsDataURL(input.files[i]); 
        galleryImagesList.push(input.files[i]);
      }
      updateState({});
    }
  }

  const checkFilesSize = (galleryList) => {
    let maxSizeFile = false;
    let maxSize = 314572800;
    for(var i=0; i<galleryList.length; i++){
      let { size } = galleryList[i];
      if(size > maxSize){
        maxSizeFile = true;
        break;
      }
    }
    return maxSizeFile;
  }

  const uploadTripData = (tripData) => {
    
    return new Promise(async(resolve, reject) => {
      try {
        let body = {huntingTripID: tripId, ...tripData};
        console.log(body)
        let result = await request({
          method: 'POST',
          url: 'guidex-api',
          trailingURL: `/hunt-trip`,
          body: body,
          requiresAuth: true
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    })
  }

  const deleteCurrentGallery = (id) => {
    return new Promise( async (resolve, reject) => {
      try {
        await request({
          method: 'DELETE',
          url: 'guidex-api',
          trailingURL: `/gallery?id=${id}`,
          requiresAuth: true
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  
  const uploadTripGallery = (formData) => {
    return new Promise(async(resolve, reject) => {
      try {
        let result = await request({
          method: 'POST',
          url: 'guidex-api',
          trailingURL: `/gallery-create`,
          body: formData,
          config: {
            headers: {
              "Content-Type": 'multipart/form-data'
            }
          },
          requiresAuth: true
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    })
  }

  const loadGalleryImages =(imagesList) => {
    imagesList.sort((a,b) => {
      return a.imageOrder - b.imageOrder;
    }).forEach( (img, i) => {
      galleryImagesList.push({existingElement: true});
      updateState({});
      let currentSlot = '#gallery-pictures-slot-'+i;
      $(currentSlot).attr('src', img.imageUrl);
    });
  }

  const loadData = async () =>{
    try {
      setIsLoading(true);
      let tagsCat = await getHuntTripsCatalogue();
      let tripInfo = await getTripInfo();
      let galleryInfo = await getTripGallery();
      setTagsCatalogue(tagsCat['tagsCatalogue']);
      setCurrentDataTrip(tripInfo);
      
      setdateRange({
        startDate: moment(tripInfo.trip.tripTimeFrame.substring(0, 24)),
        endDate: moment(tripInfo.trip.tripTimeFrame.substring(27))
      })

      initSpiciesList();
      setIsLoading(false);
      console.log(galleryInfo);
      setGalleryId(galleryInfo['galleryID']);
      loadGalleryImages(galleryInfo['images']);
    } catch (error) {
      showToast(
        <>
          <AiFillWarning /> Data of selected trip could not be fetched.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  }

  const loadInitValues = () => {
    if(currentDataTrip){
      const { trip, tags } = currentDataTrip;
      let initValues = {
        tripName: trip.tripName,
        pricePerPerson: trip.tripPricePerPerson,
        discountPerPerson: trip.tripDiscountPerPerson,
        timeFrame: trip.tripTimeFrame,
        regionLocation: trip.tripLocation,
        tripSpecies: (trip.tripSpecies),
        shortDescription: trip.tripDescription,
        huntingStyle: trip.tripHuntingStyle,
        priceBreakdown: trip.tripPriceBreakdown,
        huntingTransport: trip.tripTransport,
        otherInfo: trip.tripOtherInfo,
        huntingTerms: trip.tripTerms,
        aboutTheGuide: trip.tripAboutTheGuide,
        tags: tags.map( t => t.tagID),
        lat: trip.lat,
        lng: trip.lng
      };
      console.dir(initValues)
      return initValues;
    }
    return {};
  }

  useEffect(() => {
    loadData();
  
  // eslint-disable-next-line
  }, []);


  const initSpiciesList = async () => {
    let speciesList = await getSpeciesCatalogue();
    let regionsList = await getRegionsCatalogue();

    setSpecies(speciesList.data.map( species => {
      let item = {
        label: species.advName,
        key: species.advID
      };
      return item;
    }));

    setRegions(regionsList.data.map( region => {
      let item = {
        label: region.advName,
        key: region.advID
      };
      return item;
    }));

  }

  const getSpeciesCatalogue = () => {
    return new Promise( async (resolve, reject) => {
      try {
        let speciesResult = await request({
          url: 'guidex-api',
          trailingURL: '/adv-apply?advType=SPECIES',
          requiresAuth: true
        });
        resolve(speciesResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const getRegionsCatalogue = () => {
    return new Promise( async (resolve, reject) => {
      try {
        let regionsResult = await request({
          url: 'guidex-api',
          trailingURL: '/adv-apply?advType=REGIONS',
          requiresAuth: true
        });
        resolve(regionsResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const match = (currentInput, item) => {
    return item.label.toLowerCase().includes(currentInput.toLowerCase());
  }

  console.log(galleryImagesList)

  return (
    <>
      {
        isLoading ?
          <center className="mt-5">
            <Loader
              type="TailSpin"
              color="#ec7228"
              height={150}
              width={150}
            />
          </center>
        :
        <div className="mt-5 mb-5">
          <Formik
            initialValues={loadInitValues()}
            validate={(values) => {
              const errors = {};
              if (!values.tripName) {
                errors.tripName = "Required";
              }
              if (!values.pricePerPerson) {
                errors.pricePerPerson = "Required";
              }
              if (!selectedRegion) {
                errors.regionLocation = "Required";
              }
              if (!selectedSpecies) {
                errors.tripSpecies = "Required";
              }
              if (!values.shortDescription) {
                errors.shortDescription = "Required";
              }
              if(tagHastErrors(tagsCatalogue, values.tags)){
                errors.tags = "You should select at least one option per category."
              }
              if(galleryImagesList.length === 0){
                errors.gallery = "You should upload at least one picture.";
              } else if(checkFilesSize(galleryImagesList)){
                errors.gallery = "One or more pictures are 300 MB in size or heavier. Please upload images within the size limit.";
              }
              return errors;
            }}
            onSubmit={ async (values, { setSubmitting }) => {
              try {
                setSubmitting(true);
                if(galleryImagesList.length === 0){
                  showToast(
                    <>
                      <AiFillWarning /> You should upload at least one picture of the trip.
                    </>,
                    "warning"
                  );
                  setSubmitting(false);
                  return;
                } else if(!galleryImagesList[0].existingElement){

                  console.warn("Gallery update not implemented");

                  if(galleryId && galleryId !== 0){
                    await deleteCurrentGallery(galleryId);
                  }

                  const formData = new FormData();
                  let input = document.getElementById('gallery-pictures');
                  if(input.files){
                    for(let i=0; i<input.files.length; i++){
                      formData.append('file'+i, input.files[i]);
                    }
                  }
                  formData.append('galleryTypeID', 1);
                  formData.append('galleryEntityID', tripId);

                  await uploadTripGallery(formData);

                }

                if(selectedRegion != null) {
                  values.regionLocation = selectedRegion;              
                }
                if(selectedSpecies != null) {
                  values.tripSpecies = [(selectedSpecies)];
                }
                values.timeFrame = dateRange.startDate.toISOString()+ " - " + dateRange.endDate.toISOString();
                await uploadTripData(values);
                
                showToast(
                  <>
                    <AiFillCheckCircle /> Hunt Trip uploaded succesfully!
                  </>,
                  "success"
                );

                setSubmitting(false);

                onUploadTrip();

              } catch (error) {
                console.error(error);
                showToast(
                  <>
                    <AiFillWarning /> There was a problem trying to update the selected trip.
                  </>,
                  "warning"
                );
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit} onReset={handleReset}>
                <div className="d-flex justify-content-between">
                  <p className="trips-title mb-5">Update Trip</p>
                  <div className="d-flex flex-column mb-2">
                    <Button 
                      variant="save" 
                      type="submit"
                      disabled={isSubmitting}
                      //onClick={() => console.log("touched")}
                    >Save &amp; Exit</Button>
                    <Button
                      variant="secondary-guidex"
                      className="mt-2"
                      onClick={()=> {handlePage("")}}
                    >
                      Cancel
                    </Button>
                    
                    {/* <Button variant="continue ml-4 mr-3">Continue</Button> */}
                  </div>
                </div>
                <Container fluid className="NewTrip pl-1 gray-background">
                  <Row className="gray-background">
                    <Col>
                      <p className="primary-color font-weight-bold font-size-20">
                        General Details
                      </p>
                    </Col>
                  </Row>
                  <Row className="gray-background mb-5">
                    <Col className="mr-5">
                      <Row>
                        <Col>
                          {
                            galleryImagesList[0] ?
                              <>
                                <Button 
                                  variant="new-trip-add-photo"
                                  onClick={setGalleryPictures}
                                >+</Button>
                                {/* eslint-disable-next-line */}
                                <img id="gallery-pictures-slot-0" className="img-gallery-slot" />
                              </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares  mb-2">
                              <Button 
                                variant="new-trip-add-photo"
                                onClick={setGalleryPictures}
                              >+</Button>
                              <div className="new-trip-text-photo">
                                {/* eslint-disable-next-line */}
                                <p className="font-weight-bold font-size-20 photo-text-title">
                                  Add Trips Images
                                </p>
                                <p className="mb-0">
                                  Up to 10 Images with in JPEG Format not
                                </p>
                                <p>exceeeding 300mb. no watermarks on images</p>
                              </div>
                            </div>
                          }
                          <input 
                            type="file" 
                            name="gallery-pictures" 
                            id="gallery-pictures"
                            className="invisible-trigger" 
                            multiple={true}
                            onChange={handleGalleryChange}
                            accept="image/x-png,image/gif,image/jpeg,image/png"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          {
                            galleryImagesList[1] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <div>
                              <img id="gallery-pictures-slot-1" className="img-gallery-slot" />
                              </div>
                              
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[2] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-2" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[3] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-3" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[4] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-4" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[5] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-5" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          {
                            galleryImagesList[6] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-6" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[7] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-7" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[8] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-8" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[9] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-9" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                        <Col>
                          {
                            galleryImagesList[10] ?
                            <>
                              {/* eslint-disable-next-line */}
                              <img id="gallery-pictures-slot-10" className="img-gallery-slot" />
                            </>
                            :
                            <div className="embed-responsive embed-responsive-1by1 squares mr-1 mb-2">
                            </div>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <div
                          className={`${
                            errors.gallery ? 'd-block' : 'd-none'
                          } invalid-feedback`}
                        >
                          {errors.gallery}
                        </div>
                      </Row>
                    </Col>

                    <Col className="ml-5">
                      <FormGroup controlId="controlTripName">
                        <Form.Label>Trip Name</Form.Label>
                        <Form.Control 
                          placeholder="Enter Trip Name" 
                          value={values.tripName}
                          name="tripName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.tripName}
                          isValid={
                            touched.tripName && !errors.tripName
                          }
                        />
                        <Form.Control.Feedback
                          className="mt-2"
                          type="invalid"
                        >
                          {errors.tripName}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup controlId="controlPricePerPerson">
                        <Form.Label className="mt-4">Price</Form.Label>
                        <Form.Control 
                          placeholder="Enter Price Per Person" 
                          value={values.pricePerPerson}
                          name="pricePerPerson"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.pricePerPerson}
                          isValid={
                            touched.pricePerPerson && !errors.pricePerPerson
                          }
                          min={0}
                        />
                        <Form.Control.Feedback
                          className="mt-2"
                          type="invalid"
                        >
                          {errors.pricePerPerson}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup controlId="controlDiscountPerPerson">
                      <Form.Label className="mt-4 d-flex justify-content-between">Discounted Price<span>Optional</span></Form.Label>
                        <Form.Control 
                          placeholder="Enter Discount Per Person" 
                          value={values.discountPerPerson}
                          name="discountPerPerson"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.discountPerPerson}
                          isValid={
                            touched.discountPerPerson && !errors.discountPerPerson
                          }
                          min={0}
                        />
                        <Form.Control.Feedback
                          className="mt-2"
                          type="invalid"
                        >
                          {errors.discountPerPerson}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup controlId="controlTimeFrame">
                        <Form.Label className="mt-4">Date Range</Form.Label>
                        <div>
                          <DateRangePicker
                            startDatePlaceholderText="Start"
                            startDate={dateRange.startDate}
                            onDatesChange={handleOnDateChange}
                            endDatePlaceholderText="End"
                            endDate={dateRange.endDate}
                            numberOfMonths={1}
                            displayFormat="MMM D"
                            showClearDates={true}
                            focusedInput={focus}
                            onFocusChange={focus => setFocus(focus)}
                            startDateId="startDateMookh"
                            endDateId="endDateMookh"
                            minimumNights={0}
                            hideKeyboardShortcutsPanel={true}
                          />
                        </div>

                        <Form.Control.Feedback
                          className="mt-2"
                          type="invalid"
                        >
                          {errors.timeFrame}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup controlId="controlTripName">
                        <Form.Label className="mt-4">Region</Form.Label>

                        <DataListInput
                          //placeholder={values.regionLocation}
                          inputClassName="background-guidex form-control"
                          dropDownLength={17}
                          items={regions}
                          onSelect={(selectedItem) => {
                             setSelectedRegion(selectedItem.key);
                          }}
                         match={match}
                        />

                        <Form.Control.Feedback
                          className="mt-2"
                          type="invalid"
                        >
                          {errors.regionLocation}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup controlId="controlTripName">
                        <Form.Label className="mt-4">Species</Form.Label>
                        <DataListInput                        
                          //placeholder={values.tripSpecies}
                          inputClassName="background-guidex form-control"
                          dropDownLength={7}
                          onChange={handleChange}     
                          onBlur={handleBlur}                     
                          items={species}
                          isInvalid={!!errors.tripSpecies}
                          isValid={
                            touched.tripSpecies && !errors.tripSpecies
                          }
                          onSelect={(selectedItem) => {                          
                            setSelectedSpecies(selectedItem.key);                                                                          
                          }}
                          match={match}
                       />
                        <Form.Control.Feedback
                          className="mt-2"
                          type="invalid"
                        >
                          {errors.tripSpecies}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup>
                        <Form.Label className="mt-4">Drag the pin on the map to get a more accurate location.</Form.Label>
                        <MapGeolocation storedGeolocation={{ lat: values.lat, lng: values.lng}} onChangeGeoloction={(coords) => {
                          const [ lat, lng ] = coords;
                          values.lat = lat;
                          values.lng = lng;
                        }}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Form.Label className="d-flex justify-content-between">
                        Short Description <span>Max of 1500 characters</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter short description about this hunt"
                        rows={10}
                        maxLength={1500}
                        value={values.shortDescription}
                        name="shortDescription"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.shortDescription}
                        isValid={
                          touched.shortDescription && !errors.shortDescription
                        }
                      ></Form.Control>
                      <Form.Control.Feedback
                        className="mt-2"
                        type="invalid"
                      >
                        {errors.shortDescription}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      <h5 className="primary-color font-weight-bold">Add Tags</h5>
                      <p className="mb-0">
                        This will help get you infront of the right people. Please choose
                        tags
                      </p>
                      <p>
                        that best descrbies what is{" "}
                        <span className="font-weight-bold"> included</span> in this trip
                      </p>
                      <FormGroup controlId="controlTags">
                        {
                          tagsCatalogue.map( tt => 
                            <Row key={"tt_"+tt.tagTypeId} className="mt-3">
                              <Col>
                                <p className="font-weight-bold d-inline mr-4">{tt.tagTypeName}</p>
                                {
                                  tt.tags.map( t => 
                                    <span key={"t_"+t.tagId} onClick={(e) => {toggleTag(values, t.tagId)}} className={ (checkIfselected(values, t.tagId) ? "tags-guidex-select" : "tags-guidex-no-select") +  " clickable badge badge-pill mr-2"}>
                                      {t.tagName}
                                    </span>
                                  )
                                }
                              </Col>
                            </Row>
                          )
                        }
                        <div
                          className={`${
                            errors.tags ? 'd-block' : 'd-none'
                          } invalid-feedback`}
                        >
                          {errors.tags}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="mt-5 addition-info-section">
                    <Tab.Container defaultActiveKey="about_the_guide">
                      <Row>
                        <Col md={3}>
                          <Nav className="flex-column list-group-item-section">
                            <Nav.Item>
                              <Nav.Link eventKey="about_the_guide">About the Guide</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="hunting_style">Hunting Style</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="price_breakdown">Price Breakdown</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="transportation">Transportation</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="other_info">Other Information</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="terms">Terms</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col md={9}>
                          <Tab.Content>
                            <Tab.Pane eventKey="about_the_guide">
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      About the guide
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about yourself..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.aboutTheGuide}
                                      name="aboutTheGuide"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Container>  
                            </Tab.Pane>
                            <Tab.Pane eventKey="hunting_style">
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Hunting Style
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about your hunting style..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.huntingStyle}
                                      name="huntingStyle"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="price_breakdown">
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Price Breakdown
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about your pricing..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.priceBreakdown}
                                      name="priceBreakdown"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="transportation">
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Transportation
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about your means of transportation..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.huntingTransport}
                                      name="huntingTransport"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="other_info">
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Other Information
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about additional information about this trip..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.otherInfo}
                                      name="otherInfo"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="terms">
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <h1 className="primary-color font-weight-bold font-size-30">
                                      Terms
                                    </h1>
                                    <Form.Label className="d-flex justify-content-between">
                                      Short Description <span>Max of 1500 characters</span>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Enter short description about the terms for this trip..."
                                      as="textarea"
                                      rows={10}
                                      maxLength={1500}
                                      value={values.huntingTerms}
                                      name="huntingTerms"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    ></Form.Control>
                                  </Col>
                                </Row>
                              </Container>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                  <Row className="mt-5 mb-5 new-trip-buttons">
                    <Col className="d-flex justify-content-end">
                      <Button 
                        variant="save" 
                        type="submit"
                        disabled={isSubmitting}
                      >Save &amp; Exit</Button>
                      {/* <Button variant="continue ml-4 mr-3">Continue</Button> */}
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </div>
      }
    </>
  );
};
export default UpdateTrip;
