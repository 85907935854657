import React from "react";
import { Image } from "react-bootstrap";
import Statement from "../../assets/pngs/MissionStatement.png";
import MissionStatementMobile from "../../assets/pngs/MissionStatementMobile.png";
import "./Quote.css";
const Quote = () => {
  return (
    <>
      <div className="container-text-about-page">
        <Image
          src={MissionStatementMobile}
          width="100%"
          className="d-block d-sm-none img-fluid image-max-size mb-0"
        />
        <Image
          src={Statement}
          className="d-none d-md-block img-fluid image-max-size mb-5"
          width="100%"
          height="100%"
        />
        <p className="content-text-about-page">
          “Your Guide to Guides”
        </p>
      </div>


    </>
  );
};
export default Quote;
