import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useLocation } from 'react-router-dom'
import Loader from "react-loader-spinner";
import { AiFillWarning } from "react-icons/ai";
import AddImage from "./AddImage";
import EditImage from "./EditImage";
import CardImage from "./CardImage";
import { showToast } from "../../../../utils/Misc";
import { request } from "../../../../utils/HttpHelper";
import "./Gallery.css";

const Gallery = (props) => {

  const { user } = props;
  const [showAddImages, setShowAddImages] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);
  const [selectedImage, setSelectedImage ] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [entityTypeID, setEntityTypeID] = useState(0);
  const [entityID, setEntityID] = useState(0);
  const [galleryID, setGalleryID] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryImagesDroppable, setGalleryImagesDroppable] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [disableAdd, setDisableAdd] = useState(false);
  const location = useLocation()

  const handleClose = () => {
    setShowAddImages(false);
  };

  const handleCloseEdit = () => {
    setShowEditImage(false);
  }

  const changeModal = () => {
    setShowAddImages(true);
  };

  const reorderGallery = (payload) => {
    return new Promise( async (resolve, reject) => {
      try {
        let resultReorder = await request({
          method: "PUT",
          url: 'guidex-api',
          trailingURL: `/gallery-transact`,
          body: payload,
          requiresAuth: true
        });
        resolve(resultReorder.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  const  handleOnDragEnd = (result) => {
    const {source, destination } = result;
    if(source.index === destination.index) return;
    setDisableSave(false);
    setDisableAdd(true);
    const tempGallery = [...galleryImagesDroppable];
    const [itemToMove] = tempGallery.splice(source.index, 1);
    tempGallery.splice(destination.index, 0, itemToMove);
    setGalleryImagesDroppable([...tempGallery]);
  }

  const handleSubmit = async () => {
    try {
      const imageArrange = [];
      galleryImages.forEach(( gi, i) =>{
        const currentIndex = galleryImagesDroppable[i].imageOrder;
        const newIndex = gi.imageOrder;
        if(gi.imageGalleryID !== galleryImagesDroppable[i].imageGalleryID){
          imageArrange.push({
            galleryID,
            galleryImageID: galleryImagesDroppable[i].imageGalleryID,
            currentIndex,
            newIndex
          });
        }
      });
      await reorderGallery({imageArrange});
      setDisableSave(true);
      setDisableAdd(false);
      await initGallery();
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to update your gallery.
        </>,
        "error"
      );
      setIsLoading(false);
    }
    

  }

  const queryParams = new URLSearchParams("?" + location.hash.split('?')[1]);
  const guideType = queryParams.get("guide-type");
  const loginAsId = queryParams.get("id");

  const getEntityData = () => {
    return new Promise((resolve, reject) => {
      try {
        const { selectedRole } =  user;
        const selRoleData = user.otherRoles.find(
          (r) => r.roleName === selectedRole
        );
        const { userId } = selRoleData;
        let entityType = ""; 
        switch(guideType ? guideType : selectedRole){
          case "HUNTER":
            entityType = "Guide Hunter";
            setEntityTypeID(2);
            break;
          case "FISHERMAN":
          case "FISHER":
            entityType = "Guide Fisherman";
            setEntityTypeID(3);
            break;
          default:
        }
        resolve({
          entityType,
          userId
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  const getGallery = (entityType, entityId) => {
    return new Promise( async (resolve, reject) => {
      try {
        let galleryResult = await request({
          url: 'guidex-api',
          trailingURL: `/gallery?entity_id=${entityId}&entity_type=${entityType}`,
          requiresAuth: true
        });
        resolve(galleryResult.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  

  const initGallery = async () => {
    try {
      setIsLoading(true);
      const { entityType, userId } = await getEntityData();
      setEntityID(userId);
      let galleryImages = await getGallery(entityType, (loginAsId ? loginAsId : userId));
      console.log(`this is the info ${entityID, userId}`)
      setGalleryID(galleryImages.galleryID);
      setGalleryImages([...galleryImages.images]);
      setGalleryImagesDroppable([...galleryImages.images]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to load your gallery.
        </>,
        "error"
      );
      setIsLoading(false);
    }
  }

  const deleteImage = (id) => {
    return new Promise( async (resolve, reject) => {
      try {
        let result = await request({
          method: 'DELETE',
          url: 'guidex-api',
          trailingURL: `/gallery-add?id=${galleryID}&image_id=${id}`,
          requiresAuth: true
        });
        resolve(result.data)
        initGallery();
      } catch (error) {
        console.error(error);
        reject(error)
      }
    })
  }

  const handleActionImage = async (action, id) => {
    try {
      switch(action){
        case "edit":
          setSelectedImage(id);
          setShowEditImage(true);
          break;
        case "delete":
          await deleteImage(id);
          break;
        default:
      }
    } catch (error) {
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to apply your changes.
        </>,
        "error"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    initGallery();
  // eslint-disable-next-line
  }, [])

  return (
    !isLoading ?
    <div className="mt-5 mb-5">
      <div className="acc-details-container">
        <div className="d-flex justify-content-between align-items-center">
          <p className="trips-title mb-0">Gallery</p>
          <div className="d-flex flex-column">
            <Button 
              variant="secondary-guidex" 
              className="mb-2 " 
              onClick={changeModal} 
              disabled={disableAdd}>
              Add New Image +
            </Button>
            <Button variant="guidex" onClick={handleSubmit} disabled={disableSave}>
              Save changes
            </Button>
          </div>
        </div>
        <p className="mb-5">
          Order will be reflected on the guide profile page Gallery
        </p>
      </div>
      <Row>
        <Col className="list-components">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="dorian">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef} className="list-unstyled">
                  {
                    galleryImagesDroppable.map(( img, i ) => {
                      const id = img.imageGalleryID;
                      const component = <CardImage imageData={img} handleAction={handleActionImage}></CardImage>;
                      return (
                        <Draggable key={id} draggableId={id.toString()} index={i}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {component}
                            </li>
                          )}
                        </Draggable>
                      );
                    })
                  }
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      {/* <Modal show={showSwitch} onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="ml-5 mr-5">
          <DeleteImage></DeleteImage>
        </Modal.Body>
      </Modal> */}
      <Modal show={showAddImages} onHide={handleClose} size="xl">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="ml-5 mr-5">
          <AddImage galleryId={galleryID} entityId={entityID} entityTypeId={entityTypeID} onCancel={handleClose} onSubmit={() => {
            handleClose();
            initGallery();
          }}></AddImage>
        </Modal.Body>
      </Modal>
      <Modal show={showEditImage} onHide={handleCloseEdit} size="xl">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="ml-5 mr-5">
          <EditImage galleryId={galleryID} selectedImage={selectedImage} onCancel={handleCloseEdit} onSubmit={() => {
            handleCloseEdit();
            initGallery();
          }}></EditImage>
        </Modal.Body>
      </Modal>
    </div> : 
    <center>
      <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
    </center>
  );
};
export default Gallery;
