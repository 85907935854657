import React, { useState, useEffect } from "react";

import { Col, Row, Container, Table, Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "./Orders.css";
import ArrowDown from "../../../../assets/icons/arrow-ios-down.svg";
import ArrowUp from "../../../../assets/icons/arrow-ios-up.svg";
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";
import Loader from 'react-loader-spinner'
import { AiFillWarning } from "react-icons/ai";

const Orders = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getorders = () => {
    return new Promise( async (resolve, reject) => {
      try {
        let result = await request({
          url: "guidex-api",
          trailingURL: `/stripe-orders`,
          requiresAuth: true
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  }
  const typeOfOrder = (type) => {
    switch (type) {
      case "pi":
        return 1;
      case "in":
        return 2;
      default:
        return 1;
    }
  }
  const initOrders = async ()  => {
    try {
      setIsLoading(true);
      let ordersList = await getorders();
      console.log(ordersList)
      let { orders } = ordersList;
      let mappedOrders = orders.map( o => {
        let orderEntry = {
          type: typeOfOrder(o.paymentIntentID.substring(0, 2)),
          orderNumber: o.paymentIntentID,
          dailyRate: `$${o.dailyRate}`,
          date: new Date(o.transactionDate).toUTCString(),
          tripName: o.tripName,
          subtotalCost: `$${o.amountSubtotal/100}`,
          totalCost: `$${o.amountTotal/100}`,
          startDate: o.startDate.split(" ")[0],
          endDate: o.endDate.split(" ")[0],
        }
        return orderEntry;
      });
      setData(mappedOrders);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch required data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    initOrders();
  // eslint-disable-next-line
  }, []);
  const iconDown = <Image src={ArrowDown} />;
  const iconUp = <Image src={ArrowUp} />;
  const customStyles = {
    expanderButton: {
      style: {
        display: "block",
        position: "absolute",
        left: "74vw",
        zIndex: 1
      }
    },

    rows: {
      style: {
        minHeight: "72px",
        fontSize: "17px",
        fontFamily: "Roboto" // override the row height
      }
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "17px",
        fontFamily: "Roboto",
        fontWeight: "bold" // override the row height
      }
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px"
      }
    }
  };
  const columns = [
    {
      name: "Order Number",
      selector: "orderNumber",
      sortable: true
    },
    {
      name: "Date",
      selector: "date",
      sortable: true
    },
    {
      name: "Start Date",
      selector: "startDate",
      sortable: true
    },
    {
      name: "End Date",
      selector: "endDate",
      sortable: true
    },
    {
      name: "Total Cost",
      selector: "totalCost",
      sortable: true
    }
  ];
  const tableData = {
    columns,
    data
  };
  console.log(data)
  const ExpandableComponent = ({ data }) => (
    <Container fluid className="gray-background">
      <Row className="pt-5 ml-5">
        <Col>
          <p className="primary-color font-weight-bold">Order Details</p>
        </Col>
      </Row>

      <Row>
        <Col className="col-order-table">
          <Table borderless className="">
            <thead>
              <tr className="order-table-border">
                <th className="col-4">{data.type === 1 ? `Trip Name` : "Region & Species"}</th>
                <th className="col-4">{data.type === 1 ? `Daily Rate` : "Cost of Shares"}</th>
                <th className="col-4 px-1">Date Range</th>
              </tr>
            </thead>
            <tbody>
              <tr className="order-table-border">
                <td>{data.tripName}</td>
                <td>{data.dailyRate}</td>
                <td>{data.startDate} to {data.endDate}</td>
              </tr>
            </tbody>
          </Table>

          <Row className="d-flex justify-content-end pb-4">
            <Col className="col-2 px-0">
              <p className="font-weight-bold">SubTotal</p>
              <p className="font-weight-bold">Tax</p>
              <p className="font-weight-bolder font-size-20">Total</p>
            </Col>
            <Col className="col-2">
              {" "}
              <p>{data.subtotalCost}</p>
              <p>N/A</p>
              <p>{data.totalCost}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
  return (
    !isLoading ?
      <>
        <Container fluid className="Orders">
          <Row>
            <Col>
              <DataTableExtensions
                {...tableData}
                export={false}
                print={false}
                filterPlaceholder="Search Orders"
              >
                <DataTable
                  title="orders"
                  expandableIcon={{ collapsed: iconDown, expanded: iconUp }}
                  customStyles={customStyles}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                  data={data}
                  columns={columns}
                  expandableRows
                  expandableRowsComponent={<ExpandableComponent />}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All"
                  }}
                />
              </DataTableExtensions>
            </Col>
          </Row>
        </Container>
      </> :
      <center>
        <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
      </center>
  );
};
export default Orders;
