import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Owner from "../../assets/pngs/owner.png";
import "./OwnerInfo.css";

const OwnerInfo = () => {
  return (
    <div className="bg-green">
      <Container className="bg-green mt-n5 max-widht-card pb-5" fluid>
        <Row className="owner-info">
          <Col md="5" className="d-block d-md-none mb-4">
            <Image src={Owner} className="img-fluid" />
          </Col>
          <Col md="7">
            <h5 className="primary-color font-size-13 font-weight-bold font-family-Roboto">
              Owner & CEO
            </h5>
            <h1 className="font-size-32 font-weight-bold font-family-Montserrat secondary-color">
              Tim Clark
            </h1>
            <p className="font-size-13 font-family-Roboto my-3 secondary-color">
              Tim is a lifelong outdoorsman. He spent his young adult life as an Army Officer after graduating from West Point in 2011. After serving for 8 years to include a combat deployment, he finished his Army career after commanding an Artillery Battery at Fort Wainwright, Alaska. Following that, he and his wife settled in as Alaskan Residents. Since then, Tim has pursued his outdoor passion in Alaska and across the United States. It was during these adventures that it became clear there was a significant gap in the guided outdoor world. Prior to GuideX there were no online marketplaces where a perspective client find objective reviews and receive unbiased advice as well as assistance in their lifelong outdoor experience. That is why he started GuideX!
            </p>
            <ul>
              <li>Guides located around the United States</li>
              <li>All hunt details pre-planned</li>
              <li>Licensed official guides</li>
              <li>Veteran Owned and Operated</li>
            </ul>
            <Row>
              <Col sm={12} lg={4}>
                <Link to="/contact-us">
                  <Button variant="owner" type="submit" className="mt-2">
                    Contact Us
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md="5" className="d-none d-md-block ">
            <Image src={Owner} className=" about-guidex-img" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default OwnerInfo;
