import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Row, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import * as firebase from "firebase/app";
import { AiFillWarning } from "react-icons/ai";
import { showToast } from "../../../../utils/Misc";
import Separator from "../../../../utils/Separator";
import { Formik } from "formik";

function SettingsDeleteAccount(props) {
  
  const history = useHistory();
  const { onCancel, onSubmit, isCustomer } = props;
  

  const deleteAcc = async (values) => {
    
    const user = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, values.password)
    
    user.reauthenticateWithCredential(credential)
    .then((result) => {
      console.log('result', result)
      // ... delete here if password is ok check if its needed to delete from data base
      user.delete().then((result) => {
        history.push("/");
        showToast(
          <>
            <AiFillWarning /> User Deleted
          </>,
          "warning"
        );
        }
      );
    })
    .catch((error) => {
      console.log('error', error)
      console.log('code', error.code)
      showToast(
        <>
          <AiFillWarning /> Wrong Password
        </>,
        "warning"
      );
    })

  }


  return (
    <Container fluid>
      <Row className="mx-0 mb-2 align-items-center">
        <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
          Yikes
        </h2>
        <Separator size="401px" color="#EC7228" />
        
        { (isCustomer == null) ? // need to fix this because i had no time to check why it is not working
        <p className="card-text font-size-15 mt-2">
          Are you sure you want to delete your account? This is a permanent action and cannot be reversed.
        </p>
          : <p className="card-text font-size-15 mt-2"> 
            <b>Warning:</b> Any paid advertising will be run till the end of the month and will be cancelled at the end of the month. No refunds will be issued. 
              
          </p>
        }
        
      </Row>
        <Formik
          initialValues={{ password: "" }}
          validate={values => {
            const errors = {};
            if (!values.password) {
              errors.password = "Required";
            }
            return errors;
          }}
          onSubmit={(values) => {
            deleteAcc(values);
          }}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
             }) => (
            <>
            
              <Form noValidate onSubmit={handleSubmit}>
              <Form.Label>Password</Form.Label>
                  <Form.Control                 
                    onBlur={handleBlur}                
                    isInvalid={!!errors.password}
                    isValid={touched.password && !errors.password}
                    onChange={handleChange}
                    value={values.password}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <Col className="d-flex justify-content-end mt-5 mb-5">
                    <Button variant="guidex-light" className="mr-4" type="submit">
                    Delete Account
                    </Button>
                    <Button variant="guidex" onClick={onCancel}>Back to Safety</Button>
                  </Col>
                  </Form>  
                  </>


        )}
          </Formik>
          
      <Row>
        
      </Row>
    </Container>
  );
}
export default SettingsDeleteAccount;
