import React, { useState, useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { FaFacebookF, FaGoogle, FaTwitter } from "react-icons/fa";
import * as firebase from "firebase/app";
import { app } from "../../FirebaseConfig";
import { User } from "../../utils/UserProvider";
import { Formik } from "formik";
import Separator from "../../utils/Separator";
import { Link, useHistory } from "react-router-dom";
import { request } from "../../utils/HttpHelper.jsx";
import { useMediaQuery } from "react-responsive";
import FetchFavorites from "./FetchFavorites";

export default function Login() {
  window.scrollTo(0, 0);
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const history = useHistory();
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
  const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { user, setuser } = useContext(User);

  const signInWithEmailAndPasswordHandler = async (values, setSubmitting) => {
    try {
      await app
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);
      let resultUserData = await request({
        method: "POST",
        url: "guidex-api",
        trailingURL: "/login",
        requiresAuth: true
      });
      setUserData(resultUserData.data);
      setSubmitting(false);
      let roles = resultUserData.data.roles;
      if (roles.length > 1) {
        history.push("/multiple-accounts", resultUserData.data);
      } else {
        let userData = {
          email: resultUserData.data.email,
          userId: resultUserData.data.userId,
          fullName: roles[0].fullName,
          selectedRole: roles[0].roleName,
          profilePic: roles[0].profilePic || null,
          otherRoles: roles
        };
        if(roles[0].roleName === "CUSTOMER"){
          let favorites = await FetchFavorites();
          userData = { ...userData, favorites };
        }
        setuser(userData);
        //window.localStorage.setItem("userRolesData", JSON.stringify(userData));
        history.push("/");
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
      setSubmitting(false);
    }
  };

  const socialLogin = async provider => {
    try {
      let dt = new Date();
      let resultSocialLogin = await app.auth().signInWithPopup(provider);
      const body = {
        fullName:
          resultSocialLogin.user.displayName ??
          resultSocialLogin.additionalUserInfo.username,
        email: resultSocialLogin.user.email,
        phone: resultSocialLogin.user.phoneNumber,
        acceptedTACOn: dt.toISOString()
      };
      if (resultSocialLogin.additionalUserInfo.isNewUser) {
        await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: "/register",
          body,
          requiresAuth: true
        });
        const userDataResp = await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: "/login",
          requiresAuth: true
        });
        setUserData(userDataResp.data);
        let roles = userDataResp.data.roles;
        if (roles.length > 1) {
          history.push("/multiple-accounts", userDataResp.data);
        } else {
          let userData = {
            email: userDataResp.data.email,
            userId: userDataResp.data.userId,
            fullName: roles[0].fullName,
            selectedRole: roles[0].roleName,
            profilePic: roles[0].profilePic || null,
            otherRoles: roles
          };
          let favorites = await FetchFavorites();
          userData = { ...userData, favorites };
          setuser(userData);
          // window.localStorage.setItem(
          //   "userRolesData",
          //   JSON.stringify(userData)
          // );
          history.push("/");
        }
      } else {
        let resultUserData = await request({
          method: "POST",
          url: "guidex-api",
          trailingURL: "/login",
          requiresAuth: true
        });
        setUserData(resultUserData.data);
        let roles = resultUserData.data.roles;
        if (roles.length > 1) {
          history.push("/multiple-accounts", resultUserData.data);
        } else {
          let userData = {
            email: resultUserData.data.email,
            userId: resultUserData.data.userId,
            fullName: roles[0].fullName,
            selectedRole: roles[0].roleName,
            profilePic: roles[0].profilePic || null,
            otherRoles: roles
          };
          let favorites = await FetchFavorites();
          userData = { ...userData, favorites };
          setuser(userData);
          // window.localStorage.setItem(
          //   "userRolesData",
          //   JSON.stringify(userData)
          // );
          history.push("/");
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div id="imageBackgroundSignin">
      <Container className="d-flex justify-content-center">
        <Col md="auto" className="guidex-card ">
          <div className="mb-3">
            <Row className="mx-0 mb-3 align-items-center">
              <h2 className="primary-color font-size-30 mr-1 font-weight-bold ">
                Log In
              </h2>
              <Separator size={!isMobile ? "351px" : "190px"} color="#EC7228" />
            </Row>
            <p className="mb-0 font-size-15">
              To log in please enter your email and password.
            </p>
            <p className="mb-0 font-size-15">
              Not a member?
              <Link to="/register" className="ml-1 guidex-link primary-color">
                Sign Up
              </Link>
            </p>
          </div>

          <Formik
            initialValues={{ email: "", password: "" }}
            validate={values => {
              const errors = {};
              if (!values.password) {
                errors.password = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              signInWithEmailAndPasswordHandler(values, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    bsCustomPrefix="asdasda"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Email address"
                    isInvalid={!!errors.email}
                    isValid={touched.email && !errors.email}
                  />
                  <Form.Control.Feedback className="mt-2" type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={!!errors.password}
                    isValid={touched.password && !errors.password}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <div className="mx-0 mt-2 d-flex align-items-end justify-content-between">
                    <div>
                      <div
                        className={`${
                          errors.password ? "d-block" : "d-none"
                        } invalid-feedback`}
                      >
                        {errors.password}
                      </div>
                      <div
                        className={`${
                          error ? "d-block" : "d-none"
                        } invalid-feedback`}
                      >
                        {error}
                      </div>
                    </div>
                    <a
                      href="/forgot-password"
                      className="ml-4 guidex-link font-weight-bold primary-color font-size-10"
                    >
                      Forgot Password?
                    </a>
                  </div>
                </Form.Group>

                <div className="d-flex justify-content-end mt-4 mb-5 ">
                  <Button
                    variant="guidex"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Sign In
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <p className="font-weight-bold font-size-13 text-center">
            Sign up or log in with a social network
          </p>
          <Row className="justify-content-center">
            <Button
              onClick={() => socialLogin(googleAuthProvider)}
              variant="guidex-social-media"
              className="mr-3"
            >
              <FaGoogle />
            </Button>
            <Button
              onClick={() => socialLogin(facebookAuthProvider)}
              variant="guidex-social-media"
              className="mr-3"
            >
              <FaFacebookF />
            </Button>

            <Button
              onClick={() => socialLogin(twitterAuthProvider)}
              variant="guidex-social-media"
              className="mr-3"
            >
              <FaTwitter />
            </Button>
          </Row>
        </Col>
      </Container>
    </div>
  );
}
