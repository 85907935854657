import React, { useState } from "react";

export const Staff = React.createContext();

export const StaffProvider = ({ children }) => {
    const [signInAs, setSignInAs] = useState(false);
    const [guideType, setGuideType] = useState();
    const data = { signInAs, setSignInAs, setGuideType, guideType };

    return (
        <Staff.Provider value={data}>{children}</Staff.Provider>
    )
}
