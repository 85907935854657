import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import algoliasearch from "algoliasearch";
import TripCard from "../../../common/TripCard";
import { Col, Container, Row, Button } from "react-bootstrap";
import { User } from "../../../utils/UserProvider";
import "./FavoriteItemsCustomer.css";

const FavotireItemsCustomer = () => {

  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const { user } = useContext(User);
  const { favorites } = user;
  const { guideFisherManFavorites, guideHunterFavorites, huntingTripFavorites } = favorites;
  const [huntingTripsResults, setHuntingTripResults] = useState([]);
  const index = searchClient.initIndex("HUNTING_TRIP");

  const search = (id) => {
    index
      .search("",{
        "facetFilters": `objectID:ALG-HUNT-TRIP-${id}`
      })
      .then(({ hits }) => {
        const [ huntTrip ] = hits;
        if(huntTrip){
          const huntTripData = {
            title: huntTrip.huntingTripName,
            guideCompany: huntTrip.guideBusinessName,
            category: "hunting",
            priceTotal: `$${huntTrip.pricePerPerson - huntTrip.discountPerPerson}`,
            priceOriginal: `$${huntTrip.pricePerPerson}`,
            timeFrame: huntTrip.timeFrame.join(", "),
            location: huntTrip.location.join(", "),
            description: huntTrip.description,
            ratting: huntTrip.rating.reviewsAverage,
            id: huntTrip.huntingTripID,
            mainImage: {
              imageUrl: huntTrip.galleryFirst
            }
          }
          setHuntingTripResults( prevData => {
            return [...prevData, huntTripData];
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if(guideFisherManFavorites.length){

    }
    if(guideHunterFavorites.length){

    }
    if(huntingTripFavorites.length){
      huntingTripFavorites.forEach( id => {
        search(id);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div className="mb-5">
      {huntingTripsResults.length > 0 ? (
        huntingTripsResults.map(trip => {
          return (
            <TripCard key={trip.id} align={"left"} tripData={trip}></TripCard>
          );
        })
      ) : (
        <Container className={"shadow case-0"} fluid>
          <Row noGutters className=" ">
            <Col>
              <p className="case-0-text pt-5">
                You haven’t added any favorite trips yet.{" "}
              </p>
              <Link to="/search">
                <Button variant="guidex mt-3 mb-5">Browse Trips</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default FavotireItemsCustomer;
