import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import ApproveSingleGuide from "./ApproveSingleGuide";
import { request } from "../../../utils/HttpHelper";
import { showToast } from "../../../utils/Misc";
import { AiFillWarning } from "react-icons/ai";
import Loader from "react-loader-spinner";
import { useMediaQuery } from "react-responsive";
import { Staff } from "../../../utils/staffProvider";

const ApproveGuidesStaff = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [guidesList, setGuideList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { signInAs } = useContext(Staff)

  const openGuideDetails = guide => {
    setSelectedGuide(guide);
  };

  const getPendingGuides = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let getPendingGuidesResult = await request({
          url: "guidex-api",
          trailingURL: "/approve-guide",
          requiresAuth: true
        });
        const { pendingGuideList } = getPendingGuidesResult.data;
        setGuideList(pendingGuideList);
        setIsLoading(false);
        resolve();
      } catch (error) {
        showToast(
          <>
            <AiFillWarning /> There is an error trying to get the guides list.
          </>,
          "error"
        );
        setIsLoading(false);
        reject(error);
      }
    });
  };

  const onSubmitGuide = () => {
    setSelectedGuide(null);
    getPendingGuides();
  };

  useEffect(() => {
    getPendingGuides();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!selectedGuide &&
        (isLoading ? (
          <center className="mt-5">
            <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
          </center>
        ) : (
          <>
            <h2
              className={
                isTabletOrMobile
                  ? "mb-4 approve-guides-title-responsive portal-section-title mt-5"
                  : "mb-5 approve-guides-title portal-section-title mt-5"
              }
            >
              Approve Guides
            </h2>
            <Container
              fluid
              className={
                isTabletOrMobile
                  ? "approve-guides-container-responsive mb-5"
                  : "approve-guides-container mb-5"
              }
            >
              {isTabletOrMobile ? (
                <>
                  {guidesList.map(g => {
                    let l = 20;
                    let { guideName, dateOfApplication } = g;
                    let name =
                      guideName.trim().length > l
                        ? guideName.trim().substring(0, l) + "..."
                        : guideName;
                    let dt = new Date(dateOfApplication);
                    // eslint-disable-next-line
                    let dateStr = dt
                      .toUTCString()
                      .replace(
                        /( (?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d) GMT)/,
                        ""
                      );
                    return (
                      <div key={g.guideType + "_" + g.guideId}>
                        <div className="border-bottom mt-5">
                          <div className="d-flex justify-content-between  ">
                            <p className="font-weight-bold font-size-18 ">
                              Name{" "}
                            </p>
                            <span className="font-weight-normal font-size-18 text-truncate">
                              {name}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="font-weight-bold font-size-18 ">
                              Email{" "}
                            </p>
                            <span className="font-weight-normal font-size-18 text-truncate ">
                              {g.email}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="font-weight-bold font-size-18 ">
                              ID{" "}
                            </p>
                            <span className="font-weight-normal font-size-18 text-truncate">
                              {g.guideUID}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="font-weight-bold font-size-18">
                              Status{" "}
                            </p>
                            <span className="font-weight-normal font-size-18 text-truncate">
                              {g.guideStatus}
                            </span>
                          </div>
                          <div className="d-flex flex-row-reverse mb-5 mt-3">
                            <Button
                              variant="login-responsive "
                              onClick={() => {
                                openGuideDetails(g);
                              }}
                            >
                              {" "}
                              Details
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <Row>
                  <Col>
                    <Table
                      responsive
                      borderless
                      className="approve-guides-table mt-5"
                    >
                      <thead>
                        <tr>
                          <th> Name</th>
                          <th>Email</th>
                          <th>
                            Date of
                            <br />
                            Susbmision
                          </th>
                          <th>ID</th>
                          <th className="text-right">Status</th>
                          <th className="ml-5"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {guidesList.map(g => {
                          let l = 20;
                          let { guideName, dateOfApplication } = g;
                          let name =
                            guideName.trim().length > l
                              ? guideName.trim().substring(0, l) + "..."
                              : guideName;
                          let dt = new Date(dateOfApplication);
                          // eslint-disable-next-line
                          let dateStr = dt
                            .toUTCString()
                            .replace(
                              /( (?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d) GMT)/,
                              ""
                            );
                          return (
                            <tr key={g.guideType + "_" + g.guideId}>
                              <td>{name}</td>
                              <td>{g.email}</td>
                              <td>{dateStr}</td>
                              <td>{g.guideUID}</td>
                              <td className="text-right">{g.guideStatus}</td>
                              <td className="text-right">
                                <Button
                                  onClick={() => {
                                    openGuideDetails(g);
                                  }}
                                  variant="details"
                                >
                                  View Details
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </Container>
          </>
        ))}
      {selectedGuide && (
        <>
          {isTabletOrMobile ? (
            <>
              {" "}
              <Row className=" mt-5">
                <Col>
                  <h5
                    className="back-text clickable"
                    onClick={() => {
                      setSelectedGuide(null);
                    }}
                  >
                    {" "}
                    &lt;&nbsp; Back to Results
                  </h5>
                </Col>
              </Row>
              <h2 className="mb-2 approve-guides-title-responsive portal-section-title mt-3">
                Approve Guide
              </h2>
            </>
          ) : (
            <>
              {" "}
              <Row className="staff-back mt-5">
                <Col>
                  <h5
                    className="back-text clickable"
                    onClick={() => {
                      setSelectedGuide(null);
                    }}
                  >
                    {" "}
                    &lt;&nbsp; Back to Results
                  </h5>
                </Col>
              </Row>
              <h2 className="mb-5 approve-guides-title portal-section-title mt-5">
                Approve Guide
              </h2>
            </>
          )}

          <ApproveSingleGuide
            selectedGuide={selectedGuide}
            onSubmitGuide={onSubmitGuide}
          />
        </>
      )}
    </>
  );
};
export default ApproveGuidesStaff;
