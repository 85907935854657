import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import { IoIosSend } from "react-icons/io";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { AiFillWarning } from "react-icons/ai";
import * as moment from "moment";
import { app } from "../../../FirebaseConfig";
import { request } from "../../../utils/HttpHelper";
import { showToast, getInitials } from "../../../utils/Misc";
import "./Messages.css";
import { useMediaQuery } from "react-responsive";
import { BsFillCaretLeftFill } from "react-icons/bs";
const MessagesCustomer = props => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { user } = props;
  const [tab, setTab] = useState(true);
  const [showMessages, setShowMessages] = useState(false);
  const getEntityData = () => {
    const { selectedRole } = user;
    const selRoleData = user.otherRoles.find(r => r.roleName === selectedRole);
    const { userId } = selRoleData;
    let roleTypeId = 0;
    switch (selectedRole) {
      case "HUNTER":
        roleTypeId = 1;
        break;
      case "FISHERMAN":
        roleTypeId = 2;
        break;
      case "STAFF":
        roleTypeId = 3;
        break;
      default:
        roleTypeId = 4;
    }
    return [roleTypeId, userId];
  };
  const [senderRoleId, senderUserId] = getEntityData();
  const [conversationsList, setConversationsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState();
  const [newMessage, setnewMessage] = useState("");
  const [, updateState] = useState();
  const database = app.database();

  let senderTemp;

  const getRoleId = roleName => {
    switch (roleName) {
      case "HUNTER":
        return 1;
      case "FISHERMAN":
        return 2;
      case "STAFF":
        return 3;
      default:
        return 4;
    }
  };

  const getUserInfoByEntity = (entityTypeId, entityId) => {
    return new Promise(async resolve => {
      let addInfo = {
        initials: "",
        urlPic: "",
        name: "",
        company: ""
      };
      try {
        switch (entityTypeId) {
          case 1:
            let guideHunterResult = await request({
              url: "guidex-api",
              trailingURL: `/guide-hunter?id=${entityId}`
            });
            const guideHunterPublic = guideHunterResult.data.guidePublic;
            addInfo["initials"] = getInitials(guideHunterPublic.fullName);
            addInfo["urlPic"] = guideHunterPublic.profilePictureURL;
            addInfo["name"] = guideHunterPublic.fullName;
            addInfo["company"] = guideHunterPublic.businessName;
            break;
          case 2:
            let guideFishermanResult = await request({
              url: "guidex-api",
              trailingURL: `/guide-fisher?id=${entityId}`
            });
            const guideFishermanPublic = guideFishermanResult.data.guidePublic;
            addInfo["initials"] = getInitials(guideFishermanPublic.fullName);
            addInfo["urlPic"] = guideFishermanPublic.profilePictureURL;
            addInfo["name"] = guideFishermanPublic.fullName;
            addInfo["company"] = guideFishermanPublic.businessName;
            break;
          default:
            let customerResult = await request({
              url: "guidex-api",
              trailingURL: `/customer?user_id=${entityId}`,
              requiresAuth: true
            });
            const { data } = customerResult.data;
            if (data && data.length > 0) {
              addInfo["initials"] = getInitials(data[0].fullName);
              addInfo["name"] = data[0].fullName;
            }
        }
        resolve(addInfo);
      } catch (error) {
        console.error(error);
        resolve(addInfo);
      }
    });
  };

  const getSenderData = async participants => {
    const { otherRoles, selectedRole } = user;
    const selRoleData = otherRoles.find(r => r.roleName === selectedRole);
    const { userId } = selRoleData;
    // eslint-disable-next-line
    let senderIndex = participants.findIndex(p => {
      if (p.roleId === userId && p.roleType === getRoleId(selectedRole)) {
        return p;
      }
    });
    let sender = participants[senderIndex];
    //const senderAdditionalInfo = await getUserInfoByEntity(sender.roleType, sender.roleId);
    sender = { ...sender, addInfo: senderTemp };
    return sender;
  };

  const getRecipientData = async participants => {
    const { otherRoles, selectedRole } = user;
    const selRoleData = otherRoles.find(r => r.roleName === selectedRole);
    const { userId } = selRoleData;
    const roleId = getRoleId(selectedRole);

    // eslint-disable-next-line
    let recipient = participants.find(p => {
      if (!(p.roleId === userId && p.roleType === roleId)) {
        return p;
      }
    });
    const recipientAdditionalInfo = await getUserInfoByEntity(
      recipient.roleType,
      recipient.roleId
    );
    recipient = { ...recipient, addInfo: recipientAdditionalInfo };
    return recipient;
  };

  const getLastMessageText = conversationItem => {
    let lastMessage =
      conversationItem.messages[conversationItem.messages.length - 1];
    let { content } = lastMessage;
    return content;
  };

  const getLastMessageDatetime = conversationItem => {
    let lastMessage =
      conversationItem.messages[conversationItem.messages.length - 1];
    let { datetime } = lastMessage;
    return getTimeLabel(datetime);
  };

  const getChatList = (entityId, entityTypeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        let chatList = await request({
          url: "guidex-api",
          trailingURL: `/conversation?entitytypeid=${entityTypeId}`,
          requiresAuth: true
        });
        const { conversations } = chatList.data;
        const rawConversations = [];

        for (let c of conversations) {
          const { conversationUUID } = c;
          await database
            .ref(conversationUUID)
            .orderByChild("lastTimeUpdated")
            .once("value", v => {
              const { participants, messages, lastTimeUpdated } = v.toJSON();
              const participantsArray = Object.values(participants);
              let messagesArray = Object.values(messages);

              const conv = {
                uuid: conversationUUID,
                lastTimeUpdated,
                messages: messagesArray,
                participants: participantsArray,
                fetchContactData: function () {
                  return new Promise(async (resolve, reject) => {
                    try {
                      let { participants, messages } = this;
                      const sender = await getSenderData(participants);
                      const recipient = await getRecipientData(participants);

                      const mappedMessages = messages.map(m => {
                        if (m.from === sender.id) {
                          return { ...m, fromType: "sender" };
                        } else {
                          return { ...m, fromType: "recipient" };
                        }
                      });
                      this.messages = mappedMessages;
                      this.sender = sender;
                      this.recipient = recipient;
                      resolve(this);
                    } catch (error) {
                      reject(error);
                    }
                  });
                }
              };
              rawConversations.push(conv);
            });
        }
        getTripName()
        resolve(rawConversations);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  const lauchListener = chatList => {
    chatList.forEach(chat => {
      database.ref(chat.uuid).on("value", v => {
        const { messages, participants } = v.toJSON();
        let messagesArray = Object.values(messages);
        let particpantsArray = Object.values(participants);
        setConversationsList(prevConvList => {
          let convIndex = prevConvList.findIndex(c => c.uuid === chat.uuid);
          prevConvList[convIndex].lastTimeUpdated = new Date().toISOString();
          prevConvList[convIndex].messages = messagesArray.map(m => {
            if (m.from === prevConvList[convIndex].sender.id) {
              return { ...m, fromType: "sender" };
            } else {
              return { ...m, fromType: "recipient" };
            }
          });
          let sender = particpantsArray.find(
            p => p.roleId === senderUserId && p.roleType === senderRoleId
          );
          prevConvList[convIndex].sender.unread = sender.unread;
          return [...prevConvList];
        });
        updateState({});
      });
    });
  };

  const initializeChat = async () => {
    try {
      setIsLoading(true);
      senderTemp = await getUserInfoByEntity(senderRoleId, senderUserId);
      let rawChat = await getChatList(senderUserId, senderRoleId);
      let fetchDataResult = [];
      for await (let rc of rawChat) {
        let result = await rc.fetchContactData();
        fetchDataResult.push(result);
      }
      /* Old method to retrieve info. Discontinued for backend performance issues */
      //let fetchDataList = rawChat.map( rc => rc.fetchContactData() );
      //let fetchDataResult = await Promise.all(fetchDataList);
      console.log(fetchDataResult)
      setSelectedChat(fetchDataResult.sort(
        (a, b) =>
          new Date(b.lastTimeUpdated) -
          new Date(a.lastTimeUpdated)
      )[0])
      setConversationsList(fetchDataResult);
      lauchListener(fetchDataResult);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to load your messages.
        </>,
        "error"
      );
      setIsLoading(false);
    }
  };

  const chatboxScroll = () => {
    let chatBox = document.getElementById("TopographyBackground");
    chatBox.scrollTo({
      top: 100000,
      behavior: "smooth"
    });
  };

  const sendMessage = async () => {
    if (newMessage.trim().length > 0) {
      let dt = new Date();
      let currentConversation = conversationsList.find(
        c => c.uuid === selectedChat.uuid
      );
      let { participants } = currentConversation;
      let recipientIndexes = participants
        .filter(p => p.roleId !== senderUserId || p.roleType !== senderRoleId)
        .map(p => Number(p.id) - 1);
      await database
        .ref(selectedChat.uuid)
        .child("lastTimeUpdated")
        .set(dt.toISOString());
      for (let rIx in recipientIndexes) {
        let currentUnreadRef = await database
          .ref(
            `${selectedChat.uuid}/participants/${recipientIndexes[rIx]}/unread`
          )
          .once("value");
        let unread = currentUnreadRef ? currentUnreadRef.val() : 0;
        await database
          .ref(
            `${selectedChat.uuid}/participants/${recipientIndexes[rIx]}/unread`
          )
          .set(unread + 1);
      }
      database
        .ref(selectedChat.uuid)
        .child("messages")
        .push(
          {
            from: selectedChat.sender.id,
            content: newMessage,
            datetime: dt.toISOString()
          },
          () => {
            setnewMessage("");
            chatboxScroll();
            updateState({});
          }
        );
    }
  };

  const getTimeLabel = dateString => {
    const then = new Date(dateString);
    const now = new Date();
    let ms = moment(now).diff(then);
    let d = moment.duration(ms);
    let message = "";
    if (d.years() > 0) {
      message = `${d.years()} years ago`;
    } else if (d.months() > 0) {
      message = `${d.months()} months ago`;
    } else if (d.days() > 0) {
      message = `${d.days()} days ago`;
    } else if (d.hours() > 0) {
      message = `${d.hours()} hours ago`;
    } else if (d.minutes() > 0) {
      message = `${d.minutes()} minutes ago`;
    } else {
      message = `a few seconds ago`;
    }
    return message;
  };

  const setUnreadToZero = () => {
    if (selectedChat) {
      let currentConversation = conversationsList.find(
        c => c.uuid === selectedChat.uuid
      );
      let { participants } = currentConversation;
      let senderIndex = participants.findIndex(
        p => p.roleId === senderUserId && p.roleType === senderRoleId
      );
      if (selectedChat.sender.unread > 0) {
        database
          .ref(`${selectedChat.uuid}/participants/${senderIndex}/unread`)
          .set(0);
        setConversationsList(prevConvList => {
          let convIndex = prevConvList.findIndex(
            c => c.uuid === selectedChat.uuid
          );
          prevConvList[convIndex].sender.unread = 0;
          return [...prevConvList];
        });
      }
    }
  };

  // useEffect(() => {
  //   setUnreadToZero();
  // // eslint-disable-next-line
  // }, [selectedChat])

  useEffect(() => {
    initializeChat();
    let interval = setInterval(() => {
      updateState({});
    }, 20000);
    return function cleanup() {
      clearInterval(interval);
      conversationsList.forEach(c => {
        database.ref(c.uuid).off("value");
      });
    };
    // eslint-disable-next-line
  }, []);

  const [tripName, setTripName] = useState()

  const getTripName = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "GET",
          url: "guidex-api",
          trailingURL: `/hunt-trip?id=${selectedChat.recipient.tripId}`,
        });
        setTripName(result.data)
      } catch (error) {
        console.error(error);
      }
    });
  }
  //http://localhost:8080/hunt-trip?id=69
  console.log(conversationsList)

  return (
    <>
      {!isLoading ? (
        <>
          {isTabletOrMobile && (
            <>
              {!showMessages ? (
                <div className="ml-3 mt-3 mb-2 d-flex flex-row">
                  <p
                    className={
                      tab ? "mb-0 tab-selected mr-5 ml-3" : "mb-0 mr-5 ml-3"
                    }
                    onClick={() => setTab(true)}
                  >
                    All
                  </p>
                  <p
                    className={tab ? "mb-0" : "mb-0 tab-selected  "}
                    onClick={() => setTab(false)}
                  >
                    Unread{" "}
                  </p>
                </div>
              ) : (
                <div className="ml-2 mt-4 mb-2">
                  <p className="back-responsive">
                    {" "}
                    <BsFillCaretLeftFill
                      color="grey"
                      className="mr-3"
                      onClick={() => {
                        setShowMessages(false);
                      }}
                    ></BsFillCaretLeftFill>
                    Back to Messages
                  </p>
                </div>
              )}
            </>
          )}
          {
            <>
              {conversationsList.length > 0 ? (
                <>
                  {isTabletOrMobile ? (
                    <Container fluid className=" shadow mb-5 bg-grey">
                      <Row>
                        {!showMessages && (
                          <Col md="12" className=" px-0">
                            <Row>
                              <Col>
                                {tab ? (
                                  <>
                                    <ListGroup
                                      variant="flush"
                                      className="customer-list"
                                    >
                                      {conversationsList
                                        .sort(
                                          (a, b) =>
                                            new Date(b.lastTimeUpdated) -
                                            new Date(a.lastTimeUpdated)
                                        )
                                        .map(conversationItem => {
                                          return (
                                            <ListGroup.Item
                                              className="item-messages mt-1 bg-grey"
                                              key={conversationItem.uuid}
                                              onClick={() => {
                                                setSelectedChat(
                                                  conversationItem
                                                );
                                                setUnreadToZero();
                                                setShowMessages(true);
                                                setTimeout(() => {
                                                  chatboxScroll();
                                                }, 100);
                                              }}
                                            >
                                              <Row noGutters>
                                                <Col>
                                                  {conversationItem.recipient
                                                    .addInfo != null ? (
                                                    <>
                                                      {conversationItem
                                                        .recipient.addInfo
                                                        .urlPic ? (
                                                        <img
                                                          src={
                                                            conversationItem
                                                              .recipient.addInfo
                                                              .urlPic
                                                          }
                                                          className="circle-profile float-left mr-1"
                                                          width="50"
                                                          height="50"
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <div className="circle-profile float-left mr-1">
                                                          <span className="initials">
                                                            {
                                                              conversationItem
                                                                .recipient
                                                                .addInfo
                                                                .initials
                                                            }
                                                          </span>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div className="circle-profile float-left mr-1">
                                                      <span className="initials"></span>
                                                    </div>
                                                  )}

                                                  <p className="pl-2 card-text font-size-18 font-weight-bold user mt-1 d-flex justify-content-between">
                                                    {conversationItem.recipient
                                                      .name.length > 20
                                                      ? conversationItem.recipient.name.substring(
                                                        0,
                                                        20
                                                      ) + "..."
                                                      : conversationItem
                                                        .recipient.name}
                                                    <span className="font-weight-normal font-size-13">
                                                      {getLastMessageDatetime(
                                                        conversationItem
                                                      )}
                                                    </span>
                                                  </p>

                                                  <div className="pl-2 d-flex justify-content-between mt-2">
                                                    <p className="message font-size-13">
                                                      {getLastMessageText(
                                                        conversationItem
                                                      )}
                                                    </p>
                                                    {conversationItem.sender
                                                      .unread > 0 ? (
                                                      <span className="square messages-square">
                                                        {
                                                          conversationItem
                                                            .sender.unread
                                                        }
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </Col>
                                              </Row>
                                            </ListGroup.Item>
                                          );
                                        })}
                                    </ListGroup>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <ListGroup
                                      variant="flush"
                                      className="customer-list"
                                    >
                                      {conversationsList
                                        .filter(
                                          conversationItem =>
                                            conversationItem.sender.unread > 0
                                        )
                                        .sort(
                                          (a, b) =>
                                            new Date(b.lastTimeUpdated) -
                                            new Date(a.lastTimeUpdated)
                                        )
                                        .map(conversationItem => {
                                          return (
                                            <ListGroup.Item
                                              className="item-messages mt-1 bg-grey"
                                              key={conversationItem.uuid}
                                              onClick={() => {
                                                setSelectedChat(
                                                  conversationItem
                                                );
                                                setUnreadToZero();
                                                setShowMessages(true);
                                                setTimeout(() => {
                                                  chatboxScroll();
                                                }, 100);
                                              }}
                                            >
                                              <Row noGutters>
                                                <Col>
                                                  {conversationItem.recipient
                                                    .addInfo != null ? (
                                                    <>
                                                      {conversationItem
                                                        .recipient.addInfo
                                                        .urlPic ? (
                                                        <img
                                                          src={
                                                            conversationItem
                                                              .recipient.addInfo
                                                              .urlPic
                                                          }
                                                          className="circle-profile float-left mr-1"
                                                          width="50"
                                                          height="50"
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <div className="circle-profile float-left mr-1">
                                                          <span className="initials">
                                                            {
                                                              conversationItem
                                                                .recipient
                                                                .addInfo
                                                                .initials
                                                            }
                                                          </span>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div className="circle-profile float-left mr-1">
                                                      <span className="initials"></span>
                                                    </div>
                                                  )}

                                                  <p className="pl-2 card-text font-size-18 font-weight-bold user mt-1 d-flex justify-content-between">
                                                    {conversationItem.recipient
                                                      .name.length > 20
                                                      ? conversationItem.recipient.name.substring(
                                                        0,
                                                        20
                                                      ) + "..."
                                                      : conversationItem
                                                        .recipient.name}
                                                    <span className="font-weight-normal font-size-13">
                                                      {getLastMessageDatetime(
                                                        conversationItem
                                                      )}
                                                    </span>
                                                  </p>

                                                  <div className="pl-2 d-flex justify-content-between mt-2">
                                                    <p className="message font-size-13">
                                                      {getLastMessageText(
                                                        conversationItem
                                                      )}
                                                    </p>
                                                    {conversationItem.sender
                                                      .unread > 0 ? (
                                                      <span className="square messages-square">
                                                        {
                                                          conversationItem
                                                            .sender.unread
                                                        }
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </Col>
                                              </Row>
                                            </ListGroup.Item>
                                          );
                                        })}
                                    </ListGroup>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        )}

                        <Col className="px-0">
                          {selectedChat && showMessages && (
                            <>
                              <Col sm={12} className="border-bottom bg-white">
                                <Row>
                                  <Col className="message-title">
                                    <Row>
                                      <Col>
                                        <p className="card-text font-size-18 font-weight-bold user mt-1 d-flex justify-content-between">
                                          {selectedChat.recipient.addInfo
                                            ? selectedChat.recipient.addInfo
                                              .name
                                            : ""}
                                          <Link
                                            // This will dinamicaly redirect to the trip or charter where the chat were started
                                            to={`/${selectedChat.recipient.guideType == 'guide_hunter' ? 'hunting-trip' : 'guide-fisherman'}/${selectedChat.recipient.tripId}`}
                                            className="primary-color font-size-18 pr-3 "
                                          >
                                            {selectedChat.recipient.addInfo
                                              ? selectedChat.recipient.addInfo
                                                .company
                                              : ""}
                                          </Link>
                                        </p>
                                        {/* This part will be uncommented when the guide profiles page its ready 
                                        <p className="primary-color font-size-10 font-weight-bold mb-2">
                                          view profile
                                        </p> */}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={12} className="px-0">
                                <Row
                                  noGutters
                                  id="TopographyBackground"
                                  className="messages-body bg-light  py-3"
                                >
                                  <Col className="h-100 px-3">
                                    {selectedChat.messages.map(m => {
                                      if (m.fromType === "recipient") {
                                        return (
                                          <Row
                                            noGutters
                                            className="my-1"
                                            key={
                                              selectedChat.uuid +
                                              "_" +
                                              m.datetime
                                            }
                                          >
                                            <Col className="d-flex flex-row-reverse">
                                              {selectedChat.recipient.addInfo !=
                                                null ? (
                                                <>
                                                  {selectedChat.recipient
                                                    .addInfo.urlPic ? (
                                                    <img
                                                      src={
                                                        selectedChat.recipient
                                                          .addInfo.urlPic
                                                      }
                                                      className="circle-profile align-self-end ml-3"
                                                      width="50"
                                                      height="50"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <div className="circle-profile align-self-end ml-3">
                                                      <span className="initials">
                                                        {
                                                          selectedChat.recipient
                                                            .addInfo.initials
                                                        }
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="circle-profile align-self-end ml-3">
                                                  <span className="initials"></span>
                                                </div>
                                              )}
                                              <div className="message-user mt-4">
                                                <p className="mb-0">
                                                  {m.content}
                                                </p>
                                                <p className="mb-0 font-size-10  d-flex justify-content-end">
                                                  {getTimeLabel(m.datetime)}
                                                </p>
                                              </div>
                                            </Col>
                                          </Row>
                                        );
                                      } else {
                                        return (
                                          <Row
                                            noGutters
                                            className="my-1"
                                            key={
                                              selectedChat.uuid +
                                              "_" +
                                              m.datetime
                                            }
                                          >
                                            <Col className="d-flex flex-row">
                                              {selectedChat.sender.addInfo !=
                                                null ? (
                                                <>
                                                  {selectedChat.sender.addInfo
                                                    .urlPic ? (
                                                    <img
                                                      src={
                                                        selectedChat.sender
                                                          .addInfo.urlPic
                                                      }
                                                      className="circle-profile align-self-end ml-3 mr-3"
                                                      width="50"
                                                      height="50"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <div className="circle-profile align-self-end ml-3 mr-3">
                                                      <span className="initials">
                                                        {
                                                          selectedChat.sender
                                                            .addInfo.initials
                                                        }
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="circle-profile align-self-end ml-3 mr-3">
                                                  <span className="initials"></span>
                                                </div>
                                              )}
                                              <div className="message-lenght">
                                                <div className="message-other mt-4">
                                                  <p className="mb-0">
                                                    {m.content}
                                                  </p>
                                                  <p className="mb-0 font-size-10  d-flex justify-content-end">
                                                    {getTimeLabel(m.datetime)}
                                                  </p>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    })}
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={12} className="p-4 border-top">
                                <Row noGutters>
                                  <Col>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Form.Row>
                                        <Col sm="6" md="9" xl="10">
                                          <Form.Control
                                            type="text"
                                            name="newMessage"
                                            placeholder="Type a message..."
                                            value={newMessage}
                                            onFocus={setUnreadToZero}
                                            onChange={e => {
                                              setnewMessage(e.target.value);
                                            }}
                                          />
                                        </Col>
                                        <Col
                                          sm="6"
                                          md="auto"
                                          lg="7"
                                          xl="auto"
                                          className="ml-auto d-flex d-flex flex-row-reverse"
                                        >
                                          <Button
                                            variant="messages-send "
                                            onClick={sendMessage}
                                            className="mt-3 "
                                          >
                                            Send
                                            <IoIosSend
                                              className="ml-1"
                                              size="25px"
                                            ></IoIosSend>
                                          </Button>
                                        </Col>
                                      </Form.Row>
                                    </Form>
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  ) : (
                    //////////////////////////////////////
                    ///////////////////////////////////

                    <Container fluid className="bg-white shadow mb-5">
                      <Row noGutters>
                        <Col md="4" className="border-right px-0">
                          <Row className="mx-2">
                            <Col>
                              <Tabs defaultActiveKey="all" id="messages">
                                <Tab eventKey="all" title="All">
                                  <ListGroup
                                    variant="flush"
                                    className="customer-list"
                                  >
                                    {
                                    conversationsList
                                      .sort(
                                        (a, b) =>
                                          new Date(b.lastTimeUpdated) -
                                          new Date(a.lastTimeUpdated)
                                      )
                                      .map((conversationItem, i) => {
                                        return (
                                          <ListGroup.Item
                                            className="item-messages mt-1"
                                            key={conversationItem.uuid}
                                            onClick={() => {
                                              setSelectedChat(conversationItem);
                                              setUnreadToZero();
                                              setTimeout(() => {
                                                chatboxScroll();
                                              }, 100);
                                            }}
                                          >
                                            <Row noGutters>
                                              <Col>
                                                {conversationItem.recipient
                                                  .addInfo != null ? (
                                                  <>
                                                    {conversationItem.recipient
                                                      .addInfo.urlPic ? (
                                                      <img
                                                        src={
                                                          conversationItem
                                                            .recipient.addInfo
                                                            .urlPic
                                                        }
                                                        className="circle-profile float-left mr-1"
                                                        width="50"
                                                        height="50"
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <div className="circle-profile float-left mr-1">
                                                        <span className="initials">
                                                          {
                                                            conversationItem
                                                              .recipient.addInfo
                                                              .initials
                                                          }
                                                        </span>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <div className="circle-profile float-left mr-1">
                                                    <span className="initials"></span>
                                                  </div>
                                                )}

                                                <p className="pl-2 card-text font-size-18 font-weight-bold user mt-1 d-flex justify-content-between">
                                                  {conversationItem.recipient
                                                    .name.length > 20
                                                    ? conversationItem.recipient.name.substring(
                                                      0,
                                                      20
                                                    ) + "..."
                                                    : conversationItem.recipient
                                                      .name}
                                                  <span className="font-weight-normal font-size-13">
                                                    {getLastMessageDatetime(
                                                      conversationItem
                                                    )}
                                                  </span>
                                                </p>

                                                <div className="pl-2 d-flex justify-content-between mt-2">
                                                  <p className="message font-size-13">
                                                    {getLastMessageText(
                                                      conversationItem
                                                    )}
                                                  </p>
                                                  {conversationItem.sender
                                                    .unread > 0 ? (
                                                    <span className="square messages-square">
                                                      {
                                                        conversationItem.sender
                                                          .unread
                                                      }
                                                    </span>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>
                                        );
                                      })}
                                  </ListGroup>
                                </Tab>
                                <Tab eventKey="unread" title="Unread">
                                  <ListGroup
                                    variant="flush"
                                    className="customer-list"
                                  >
                                    {conversationsList
                                      .filter(
                                        conversationItem =>
                                          conversationItem.sender.unread > 0
                                      )
                                      .sort(
                                        (a, b) =>
                                          new Date(b.lastTimeUpdated) -
                                          new Date(a.lastTimeUpdated)
                                      )
                                      .map(conversationItem => {
                                        return (
                                          <ListGroup.Item
                                            className="item-messages mt-1"
                                            key={conversationItem.uuid}
                                            onClick={() => {
                                              setSelectedChat(conversationItem);
                                              setUnreadToZero();
                                              setTimeout(() => {
                                                chatboxScroll();
                                              }, 100);
                                            }}
                                          >
                                            <Row noGutters>
                                              <Col>
                                                {conversationItem.recipient
                                                  .addInfo != null ? (
                                                  <>
                                                    {conversationItem.recipient
                                                      .addInfo.urlPic ? (
                                                      <img
                                                        src={
                                                          conversationItem
                                                            .recipient.addInfo
                                                            .urlPic
                                                        }
                                                        className="circle-profile float-left mr-1"
                                                        width="50"
                                                        height="50"
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <div className="circle-profile float-left mr-1">
                                                        <span className="initials">
                                                          {
                                                            conversationItem
                                                              .recipient.addInfo
                                                              .initials
                                                          }
                                                        </span>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <div className="circle-profile float-left mr-1">
                                                    <span className="initials"></span>
                                                  </div>
                                                )}

                                                <p className="pl-2 card-text font-size-18 font-weight-bold user mt-1 d-flex justify-content-between">
                                                  {conversationItem.recipient
                                                    .name.length > 20
                                                    ? conversationItem.recipient.name.substring(
                                                      0,
                                                      20
                                                    ) + "..."
                                                    : conversationItem.recipient
                                                      .name}
                                                  <span className="font-weight-normal font-size-13">
                                                    {getLastMessageDatetime(
                                                      conversationItem
                                                    )}
                                                  </span>
                                                </p>

                                                <div className="pl-2 d-flex justify-content-between mt-2">
                                                  <p className="message font-size-13">
                                                    {getLastMessageText(
                                                      conversationItem
                                                    )}
                                                  </p>
                                                  {conversationItem.sender
                                                    .unread > 0 ? (
                                                    <span className="square messages-square">
                                                      {
                                                        conversationItem.sender
                                                          .unread
                                                      }
                                                    </span>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>
                                        );
                                      })}
                                  </ListGroup>
                                </Tab>
                              </Tabs>
                            </Col>
                          </Row>
                        </Col>

                        <Col className="px-0">
                          {selectedChat && (
                            <>
                              <Col sm={12} className="border-bottom">
                                <Row>
                                  <Col className="message-title">
                                    <Row className="pl-3 align-items-center py-2">
                                      {selectedChat.recipient.addInfo !=
                                        null ? (
                                        <>
                                          {selectedChat.recipient.addInfo
                                            .urlPic ? (
                                            <img
                                              src={
                                                selectedChat.recipient.addInfo
                                                  .urlPic
                                              }
                                              className="circle-profile float-left"
                                              width="50"
                                              height="50"
                                              alt=""
                                            />
                                          ) : (
                                            <div className="circle-profile float-left">
                                              <span className="initials">
                                                {
                                                  selectedChat.recipient.addInfo
                                                    .initials
                                                }
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <div className="circle-profile float-left">
                                          <span className="initials"></span>
                                        </div>
                                      )}
                                      <Col> 
                                        <p className="card-text font-size-18 font-weight-bold user mt-1 d-flex justify-content-between text-capitalize">
                                          {selectedChat.recipient.addInfo
                                            ? selectedChat.recipient.addInfo
                                              .name
                                            : ""}
                                          <Link
                                            // This will dinamicaly redirect to the trip or charter where the chat were started
                                            to={`/${selectedChat.recipient.guideType == 'guide_hunter' ? 'hunting-trip' : 'guide-fisherman'}/${selectedChat.recipient.tripId}`}
                                            className="primary-color font-size-18 pr-3 "
                                          >
                                            {selectedChat.recipient.addInfo
                                              ? selectedChat.recipient.addInfo
                                                .company
                                              : ""}
                                          </Link>
                                        </p>
                                        {/* This part will be uncommented when the guide profiles page its ready 
                                        <p className="primary-color font-size-10 font-weight-bold mb-2">
                                          view profile
                                        </p> */}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={12} className="px-0">
                                <Row
                                  noGutters
                                  id="TopographyBackground"
                                  className="messages-body bg-light  py-3"
                                >
                                  <Col className="h-100 px-3">
                                    {selectedChat.messages.map(m => {
                                      if (m.fromType === "recipient") {
                                        return (
                                          <Row
                                            noGutters
                                            className="my-1"
                                            key={
                                              selectedChat.uuid +
                                              "_" +
                                              m.datetime
                                            }
                                          >
                                            <Col className="d-flex flex-row-reverse">
                                              {selectedChat.recipient.addInfo !=
                                                null ? (
                                                <>
                                                  {selectedChat.recipient
                                                    .addInfo.urlPic ? (
                                                    <img
                                                      src={
                                                        selectedChat.recipient
                                                          .addInfo.urlPic
                                                      }
                                                      className="circle-profile align-self-end ml-3"
                                                      width="50"
                                                      height="50"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <div className="circle-profile align-self-end ml-3">
                                                      <span className="initials">
                                                        {
                                                          selectedChat.recipient
                                                            .addInfo.initials
                                                        }
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="circle-profile align-self-end ml-3">
                                                  <span className="initials"></span>
                                                </div>
                                              )}
                                              <div className="message-user">
                                                <p className="mb-0">
                                                  {m.content}
                                                </p>
                                                <p className="mb-0 font-size-10  d-flex justify-content-end">
                                                  {getTimeLabel(m.datetime)}
                                                </p>
                                              </div>
                                            </Col>
                                          </Row>
                                        );
                                      } else {
                                        return (
                                          <Row
                                            noGutters
                                            className="my-1"
                                            key={
                                              selectedChat.uuid +
                                              "_" +
                                              m.datetime
                                            }
                                          >
                                            <Col className="d-flex flex-row">
                                              {selectedChat.sender.addInfo !=
                                                null ? (
                                                <>
                                                  {selectedChat.sender.addInfo
                                                    .urlPic ? (
                                                    <img
                                                      src={
                                                        selectedChat.sender
                                                          .addInfo.urlPic
                                                      }
                                                      className="circle-profile align-self-end ml-3 mr-3"
                                                      width="50"
                                                      height="50"
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <div className="circle-profile align-self-end ml-3 mr-3">
                                                      <span className="initials">
                                                        {
                                                          selectedChat.sender
                                                            .addInfo.initials
                                                        }
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="circle-profile align-self-end ml-3 mr-3">
                                                  <span className="initials"></span>
                                                </div>
                                              )}
                                              <div className="message-lenght">
                                                <div className="message-other ">
                                                  <p className="mb-0">
                                                    {m.content}
                                                  </p>
                                                  <p className="mb-0 font-size-10  d-flex justify-content-end">
                                                    {getTimeLabel(m.datetime)}
                                                  </p>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    })}
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={12} className="p-4 border-top">
                                <Row noGutters>
                                  <Col>
                                    <Form
                                      onSubmit={e => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Form.Row>
                                        <Col>
                                          <Form.Control
                                            type="text"
                                            name="newMessage"
                                            placeholder="Type a message..."
                                            value={newMessage}
                                            onFocus={setUnreadToZero}
                                            onChange={e => {
                                              setnewMessage(e.target.value);
                                            }}
                                          />
                                        </Col>
                                        <Col
                                          sm="6"
                                          md="auto"
                                          xl="auto"
                                          className="ml-auto d-flex"
                                        >
                                          <Button
                                            variant="messages-send "
                                            onClick={sendMessage}
                                          >
                                            Send
                                            <IoIosSend
                                              className="ml-1"
                                              size="25px"
                                            ></IoIosSend>
                                          </Button>
                                        </Col>
                                      </Form.Row>
                                    </Form>
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  )}
                </>
              ) : (
                <Container className="shadow case-0" fluid>
                  <Row noGutters className=" ">
                    <Col>
                      <p className="case-0-text pt-5 pb-5">
                        You have no messages.{" "}
                      </p>
                    </Col>
                  </Row>
                </Container>
              )}
            </>
          }
        </>
      ) : (
        <center>
          <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
        </center>
      )}
    </>
  );
};
export default MessagesCustomer;
