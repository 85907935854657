import React, { useEffect, useState } from "react";
import { Col, Container, Image, ListGroup, Row } from "react-bootstrap";
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import AdsActiveIcon from "../../../assets/icons/Ads Active.svg";
import AdsIcon from "../../../assets/icons/Ads.svg";
import GallaryActiveIcon from "../../../assets/icons/Gallary Active.svg";
import GallaryIcon from "../../../assets/icons/Gallary.svg";
import LogoutActiveIcon from "../../../assets/icons/Logout Active.svg";
import LogoutIcon from "../../../assets/icons/Logout.svg";
import MessagesActiveIcon from "../../../assets/icons/Messages Active.svg";
import MessagesIcon from "../../../assets/icons/Messages.svg";
import OrdersActiveIcon from "../../../assets/icons/Orders Active.svg";
import OrdersIcon from "../../../assets/icons/Orders.svg";
import profileActiveIcon from "../../../assets/icons/Profile Active.svg";
import profileIcon from "../../../assets/icons/Profile.svg";
import ReviewsActiveIcon from "../../../assets/icons/Reviews Active.svg";
import ReviewsIcon from "../../../assets/icons/Reviews.svg";
import SettingsActiveIcon from "../../../assets/icons/Settings Active.svg";
import SettingsIcon from "../../../assets/icons/settings.svg";
import SwitchActiveIcon from "../../../assets/icons/Switch Account Active.svg";
import SwitchIcon from "../../../assets/icons/Switch Account.svg";
import TripsActiveIcon from "../../../assets/icons/Trips Active.svg";
import TripsIcon from "../../../assets/icons/Trips.svg";
import { app } from "../../../FirebaseConfig";
import { getInitials } from "../../../utils/Misc";

export default function MenuGuideHunter({
  fullName,
  selectedRole,
  profilePic,
  selectedTab,
  onChangeItem,
  triggerChangeModal,
  staffLoged
}) {
  const [selectedPage, setSelectedPage] = useState(selectedTab ?? "profile");

  const history = useHistory();
  const location = useLocation()

  useEffect(() => {
    setSelectedPage(selectedTab);
  }, [selectedTab]);

  const logout = async () => {
    await app
      .auth()
      .signOut()
      .then((result) => {
        history.push("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const queryParams = new URLSearchParams("?" + location.hash.split('?')[1]);
  const guideType = queryParams.get("guide-type");
  const loginAsId = queryParams.get("id");

  const loginAsParams = (page) => loginAsId ? `${page}?id=${loginAsId}&guide-type=${guideType}` : page
  return (
    <>
      <Router>
        <ListGroup variant="flush" className="lateral-menu">
          <ListGroup.Item className="mt-3 lateral-menu-header">
            <Container fluid>
              <Row>
                <Col>
                  <Row>
                    {profilePic ? (
                      <img
                        className="d-none d-sm-block circle-profile "
                        src={profilePic}
                        alt=""
                      />
                    ) : (
                      <div className="circle-profile mt-1">
                        <span className="initials">
                          {getInitials(fullName)}
                        </span>
                      </div>
                    )}
                    <Col>
                      <p className="card-text font-size-18 font-weight-bold user mt-1">
                        {fullName}
                      </p>
                      <p className="font-size-12 role-name type">
                        {selectedRole.toLowerCase()} portal
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedPage === "profile"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("profile");
              onChangeItem(loginAsParams("profile"));
            }}
          >
            <Image className="selected mr-3" src={profileActiveIcon} />
            <Image className="no-selected mr-3 " src={profileIcon} /> Profile
          </ListGroup.Item>
          {!staffLoged && (
            <ListGroup.Item
              className={
                selectedPage === "messages"
                  ? "lateral-menu-item-selected"
                  : "lateral-menu-item"
              }
              onClick={() => {
                setSelectedPage("messages");
                onChangeItem("messages");
              }}
            >
              <Image className="selected mr-3" src={MessagesActiveIcon} />
              <Image className="no-selected mr-3 " src={MessagesIcon} />
              Messages
            </ListGroup.Item>
          )}
          <ListGroup.Item
            className={
              selectedPage === "advertisement"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("advertisement");
              onChangeItem(loginAsParams("advertisement"));
            }}
          >
            <Image className="selected mr-3" src={AdsActiveIcon} />
            <Image className="no-selected mr-3 " src={AdsIcon} />
            Advertisement
          </ListGroup.Item>

          <ListGroup.Item
            className={
              selectedPage === "trips"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("trips");
              onChangeItem(loginAsParams("trips"));
            }}
          >
            <Image className="selected mr-3" src={TripsActiveIcon} />
            <Image className="no-selected mr-3 " src={TripsIcon} /> Trips
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedPage === "gallery"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("gallery");
              onChangeItem(loginAsParams("gallery"));
            }}
          >
            <Image className="selected mr-3" src={GallaryActiveIcon} />
            <Image className="no-selected mr-3 " src={GallaryIcon} /> Gallery
          </ListGroup.Item>
          <ListGroup.Item
            className={
              selectedPage === "orders"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("orders");
              onChangeItem("orders");
            }}
          >
            <Image className="selected mr-3" src={OrdersActiveIcon} />
            <Image className="no-selected mr-3 " src={OrdersIcon} /> Orders
          </ListGroup.Item>
          {!staffLoged &&<ListGroup.Item
            className={
              selectedPage === "reviews"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("reviews");
              onChangeItem("reviews");
            }}
          >
            <Image className="selected mr-3" src={ReviewsActiveIcon} />
            <Image className="no-selected mr-3 " src={ReviewsIcon} /> Reviews
          </ListGroup.Item>}
          <ListGroup.Item
            className={
              selectedPage === "settings"
                ? "lateral-menu-item-selected"
                : "lateral-menu-item"
            }
            onClick={() => {
              setSelectedPage("settings");
              onChangeItem(loginAsParams("settings"));
            }}
          >
            <Image className="selected mr-3" src={SettingsActiveIcon} />
            <Image className="no-selected mr-3 " src={SettingsIcon} /> Settings
          </ListGroup.Item>

          <ListGroup.Item
            className="lateral-menu-item"
            onClick={() => {
              triggerChangeModal();
            }}
          >
            <Image className="selected mr-3" src={SwitchActiveIcon} />
            <Image className="no-selected mr-3 " src={SwitchIcon} />
            Switch Account
          </ListGroup.Item>
          <ListGroup.Item
            className="lateral-menu-item"
            onClick={() => logout()}
          >
            <Image className="selected mr-3" src={LogoutActiveIcon} />
            <Image className="no-selected mr-3 " src={LogoutIcon} />
            Log Out
          </ListGroup.Item>
        </ListGroup>
      </Router>
    </>
  );
}
