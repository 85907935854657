import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import Loader from 'react-loader-spinner'
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { User } from "../../../../utils/UserProvider";
import "./AdvertisingShares.css";
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";

const AdvertisingShares = props => {

  const { onReturn, aquireSharesData } = props;
  const { user } = useContext(User);

  const [guideId, setGuideId] = useState(0);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [minimum, setMinimum] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sharesData, setSharesData] = useState({
    shareHolders: []
  })
  const [maxShares, setMaxShares] = useState(100);

  const handleChange = e => {
    let newValue = e.target.value
    const currentUser = sharesData.shareHolders.findIndex(u =>  u.guideFishermanID === guideId);
    if(currentUser >= 0 && newValue <= maxShares){
      if(newValue < minimum){
        sharesData.shareHolders[currentUser].guideFishermanAquiredShares = Number(minimum);
      } else{
        sharesData.shareHolders[currentUser].guideFishermanAquiredShares = Number(newValue);
      }
      setSharesData({...sharesData});
      setValue(newValue < minimum ? minimum : newValue);
    }
  };

  const getSharesByMapboxId = () => {
    return new Promise( async (resolve, reject) => {
      try {
        const { cityName, mapboxID } = aquireSharesData;
        let sharesResult = await request({
          url: 'guidex-api',
          trailingURL: `/ranking-fisherman?city_name=${cityName}&city_mapbox_id=${mapboxID}`,
          requiresAuth: true
        });
        resolve(sharesResult.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  const initShares = async () => {
    try {
      if(user){
        setIsLoading(true);
        let selRoleI = user.otherRoles.find(
          (r) => r.roleName === "FISHERMAN"
        );
        setGuideId(selRoleI.userId);
        let shares = await getSharesByMapboxId();
        console.log(shares);
        let shareHolderData = shares.shareHolders.find( s => {
          return s.guideFishermanID === selRoleI.userId;
        });
        let max = shareHolderData.guideFishermanAquiredShares + shares.availableSharesPerGuide;
        setMaxShares(max);
        setMinimum(shareHolderData.guideFishermanAquiredShares)
        setValue(shareHolderData.guideFishermanAquiredShares);
        setSharesData({...shares});
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch the advertisement data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  }

  useEffect(() => {
    initShares();
  // eslint-disable-next-line
  }, []) ;

  const setAdvertisement = async () => {
    try {
      setIsSubmitting(true);
      const { cityName, mapboxID } = aquireSharesData;
      let shareHolderData = sharesData.shareHolders.find( s => {
        return s.guideFishermanID === guideId;
      });
      let body = {
        "cityName": cityName,
        "mapboxID": mapboxID,
        "amountShares": shareHolderData.guideFishermanAquiredShares - minimum
      };
      await request({
        method: "POST", 
        url: "guidex-api",
        trailingURL: "/ranking-fisherman",
        body,
        requiresAuth: true
      });
      showToast(
        <>
          <AiFillCheckCircle /> Shares acquired correctly!
        </>,
        "success"
      );
      setIsSubmitting(false);
      onReturn();
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to update your shares.
        </>,
        "warning"
      );
      setIsSubmitting(false);
    }
  }

  return (
    <>
      {
        !isLoading ?
          <Container
            fluid
            className="advertising-area-container mt-2 pr-5 pl-5 mb-5"
          >
            <Row className=" mb-3 mt-3">
              <Col>
                <p className="font-weight-bold font-size-22 mb-1">
                  Advertising for{" "}
                  <span className="primary-color">{sharesData.cityName}</span>
                </p>
                <p className="font-weight-bold">Available Shares: {sharesData.availableSharesPerGuide}%</p>
              </Col>
            </Row>

            <p className="font-weight-bold font-size-20">Your Budget</p>
            <Row>
              <Col md={2} sm={2}>
                <div className="input-box d-flex justify-content-between align-self-center">
                  <span className="pl-3 text-gray">$ </span>
                  {/* Todo: change the value 0 to the actual value of the shares */}
                  <span className="font-weight-bold pr-4">0</span>{" "}
                  <span className="pr-3 text-gray">/month </span>
                </div>
              </Col>
              <Col className="align-self-end" md="10" sm={2}>
                <Form.Control
                  type="range"
                  min={0}
                  max={100}
                  className="adv-featured-slider mb-2 "
                  value={value}
                  //   onChange={value => changeInput(value.target.value)}
                  onChange={handleChange}
                  style={{
                    background:
                      "linear-gradient(to right, #ec7228 0%,  #ec7228 " +
                      value +
                      "%,  #E9EAEB " +
                      value +
                      "%,  #E9EAEB 100%)"
                  }}
                />
                <div className="d-flex justify-content-between  font-weight-bold">
                  <p className="mb-0">0%</p>
                  <p className="mb-0">{maxShares}%</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="font-weight-bold mt-5 ml-5">
                  Advertising in this area
                </p>

                {sharesData.shareHolders
                  .sort((a, b) => {
                    return b.guideFishermanAquiredShares - a.guideFishermanAquiredShares;
                  })
                  .map((user, i) => {
                    return (
                      <Row className="mt-5 d-flex align-items-center" key={user.guideFishermanID}>
                        <Col className="first-col" md={1}>
                          <span
                            key={user.guideFishermanBusinessName}
                            className={user.guideFishermanID === guideId ? "font-weight-bold" : "none"}
                          >
                            {user.guideFishermanAquiredShares + "%"}
                          </span>
                        </Col>
                        <Col md={1} className="second-col">
                          <div className="wrapper d-inline"></div>
                          <img
                            src={user.guideFishermanLogoURL}
                            className="advertising-circle"
                            alt="profile-img"
                          />
                        </Col>
                        <Col md={5} className="third-col">
                          <span
                            key={i}
                            className={
                              user.guideFishermanID === guideId
                                ? "font-weight-bold primary-color"
                                : "none"
                            }
                          >
                            {user.guideFishermanBusinessName}
                          </span>
                          <div className="progress mt-2">
                            <div
                              className={
                                user.guideFishermanID === guideId ? "progress-bar-active" : "progress-bar"
                              }
                              role="progressbar"
                              style={{
                                width: user.guideFishermanAquiredShares + "%"
                              }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end mt-3">
                <Row>
                  <Col md={6}>
                    <Button
                      variant="cancel"
                      disabled={isSubmitting}
                      onClick={() => {
                        onReturn();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      variant="guidex"
                      disabled={isSubmitting}
                      onClick={() => {
                        setAdvertisement();
                      }}
                    >
                      Review
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        :
          <center>
            <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
          </center>
      }
    </>
  );
};
export default AdvertisingShares;
