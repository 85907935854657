import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import Trips from "../../common/Trips";
import { request } from "../../utils/HttpHelper";
import Carrousel from "../Home/Carrousel";
import Gallary from "../Home/Gallary";
import Captain from "../HuntingTrip/Capitan/Captain";
import PopularFeatures from "../HuntingTrip/PopularFeatures";
import "./GuideProfile.css";
export default function GuideProfileFisherman() {
  const history = useHistory();

  const [guide, setGuide] = useState();
  const [rating, setRating] = useState();
  const [gallery, setGallery] = useState();
  const [tags, setTags] = useState();
  const { id } = useParams();

  console.log(history.location)

  const processGuide = async () => {
    getHuntingTripById().then((res) => {
      console.dir(res);
      setTags([...res.tagTypes]);
      setGuide({ ...res.guidePublic });
      setRating({ ...res.rating });
      setGallery({ ...res.guideGallery });
    });
  };

  const getHuntingTripById = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await request({
          method: "GET",
          url: "guidex-api",
          trailingURL: `/guide-fisher?id=${id}`,
        });
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (id) processGuide();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Container fluid className="pdp px-0" id="imageBackgroundTopo">
        <div className="bg-green-light">
          <Container>
            <Row className="">
              <Col>
                <h5
                  onClick={() => {
                    history.goBack();
                  }}
                  className="back d-inline-block"
                >
                  <MdKeyboardArrowLeft size="20" /> Back to Results
                </h5>
                {!!guide && !!rating && !!gallery && (
                  <Trips
                    guide={guide}
                    rating={rating}
                    gallery={gallery}
                    page="guideProfile"
                    target="fishing"
                  ></Trips>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {!!tags && (
          <div className="bg-green-light py-5">
            <PopularFeatures target="fisherman" tags={tags}></PopularFeatures>
          </div>
        )}
        {!!guide && (
          <div className="bg-grey py-5">
            <Captain guide={guide}></Captain>
          </div>
        )}
        <Row className="noBackground">
          <Col>
            <Carrousel></Carrousel>
          </Col>
        </Row>

        {!!guide && !!rating && (
          <Row className="noBackground">
            <Col>
              <Carrousel guide={guide} rating={rating} page="guide" site="fishing"></Carrousel>
            </Col>
          </Row>
        )}
        <Row className="noBackground">
          <Col>
            <Gallary site="fishing"></Gallary>
          </Col>
        </Row>
      </Container>
    </>
  );
}
