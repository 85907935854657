import algoliasearch from "algoliasearch";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FeaturedExpeditions from "../Home/FeaturedExpeditions";
import "./FeaturedExpeditions.css";
export default function FeaturedExpeditionsPage() {
  const [results, setResults] = useState([]);
  window.scrollTo(0, 0);
  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    { protocol: "https:" }
  );

  const index = searchClient.initIndex("HUNTING_TRIP");

  const search = () => {
    index
      .search("", {
        filters: "featured:true",
      })
      .then(({ hits }) => {
        console.log(hits);
        setResults(hits);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Container fluid className="customer" id="imageBackgroundTopo">
        <Row className="background-color-page">
          <Col className="mt-5">
            <FeaturedExpeditions
              results={results}
              page="featuredExpeditions"
            ></FeaturedExpeditions>
          </Col>
        </Row>
      </Container>
    </>
  );
}
