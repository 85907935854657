import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { FaFacebookF, FaGoogle, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/icons/Logo-Alt.svg';
import './Footer.css';
export default function Footer() {
  return (
    <Container fluid className='footer-guidex d-flex align-items-center'>
      <Container fluid className='align-items-center'>
        <Row>
          <Col md={3} className='mt-2 d-flex justify-content-center pr-0 footer-logo'>
            <Link to='/'>
              <Image src={logo} width='136' height='37' className='' />
            </Link>
          </Col>
          <Col md='auto' className='font-size-15 pl-0'>
            <Row>
              <Col xs={6} md='auto'>
                <Col>
                  <Button variant='guidex-link' className='font-weight-bold'>
                    Guidex
                  </Button>
                </Col>
                <Col>
                  <Link to='/about'>
                    <Button variant='guidex-link' className="footer-btn">About Us</Button>
                  </Link>
                </Col>
                <Col>
                  <Link to='/contact-us'>
                    <Button variant='guidex-link' className="footer-btn">Contact</Button>
                  </Link>
                </Col>
              </Col>

              <Col xs={6} md='auto'>
                <Col>
                  <Button variant='guidex-link ' className='font-weight-bold'>
                    Site Info
                  </Button>
                </Col>

                <Col>
                  <Link to="/featured-expeditions">
                    <Button variant='guidex-link' className="footer-btn">Featured Trips</Button>
                  </Link>
                </Col>

                <Col>
                  <Link to="/terms-conditions">
                    <Button variant="guidex-link" className="footer-btn">Terms &amp; Conditions</Button>
                  </Link>
                </Col>
              </Col>

              <Col md='auto'>
                <Col>
                  <Button variant='guidex-link ' className='font-weight-bold'>
                    Join Us
                  </Button>
                </Col>

                <Col>
                  {/* <Button className="font-italic" variant="guidex-link ">
                    Become a Guide
                  </Button> */}
                  <Link to='/guide-info'>
                    <div className='ring-container d-inline-block align-top'>
                      <div className="ringring"></div>
                      <div className="circle"></div>
                    </div>
                    <span className='font-italic white-font footer-btn pl-3'>
                      Become a Guide
                    </span>
                  </Link>
                </Col>

                <Col>
                  <Link to='/guide-registration'>
                    <Button variant='guidex-link' className="footer-btn">Guide Registration</Button>
                  </Link>
                </Col>
              </Col>
            </Row>
          </Col>
          <Col className='d-flex justify-content-end align-start'>
            <Row className='justify-content-start'>
              <Col className='col-3'>
                <Button
                  href='https://www.facebook.com/'
                  variant='guidex-link'
                  target='_blank'
                  size='lg'
                >
                  <FaFacebookF />
                </Button>
              </Col>
              <Col className='col-3'>
                <Button
                  href='https://www.google.com/'
                  variant='guidex-link'
                  target='_blank'
                  size='lg'
                >
                  <FaGoogle />
                </Button>
              </Col>
              <Col className='col-3'>
                <Button
                  href='https://www.twitter.com/'
                  variant='guidex-link'
                  target='_blank'
                  size='lg'
                >
                  <FaTwitter />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
