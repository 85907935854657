import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import Favorite from "../../common/Favorite";
import "./FeaturedExpeditions.css";
const FeaturedExpeditions = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const history = useHistory();
  const { results } = props;

  const getCleanDates = (s) => {
    try {
      const dateString = s.toString();
      const startDate = dateString.substring(0, 24);
      const endDate = dateString.substring(27);

      const dt1 = new Date(startDate);
      const dt2 = new Date(endDate);

      if (isNaN(dt2) || isNaN(dt2)) {//to check if the dates are valid dates
        return s;
      }

      return `${dt1.getMonth() + 1}/${dt1.getDate()}/${dt1.getFullYear()} - ${dt2.getMonth() + 1}/${dt2.getDate()}/${dt2.getFullYear()}`;

    } catch (e) {
      //if the date cannot be converted just return the previous string
      console.error(e);
      return s;
    }

  }

  return (
    <>
      <div id={props.background} className="font-family-Roboto">
        <Container fluid className="pb-3 ml-0 mr-0 pr-0 pl-0">
          {props.page === "featured" ? (
            <Row className="max-width-content">
              <Col className="featured-expeditions-title pl-0">
                <p className="mt-5">Check out our</p>
                <h1 className="font-weight-bold featured-home-title">
                  Featured Expeditions
                </h1>
              </Col>
            </Row>
          ) : props.page === "PDP" ? (
            <Row className="max-width-content">
              <Col className="other-expeditions-title pl-0">
                <h1 className="font-weight-bold ml-3">You Might Also Like </h1>
              </Col>
            </Row>
          ) : (props.page === "Guide" && results.length > 0) ? (
            <Row className="max-width-content">
              <Col className="other-expeditions-title pl-0">
                <h1 className="font-weight-bold  ml-3">Other trips we offer</h1>
              </Col>
            </Row>
          ) : props.page === "featuredExpeditions" ? (
            <Row className="max-width-content">
              <Col className="other-expeditions-title pl-0">
                <h1 className="featured-expeditions-page-title text-center font-weight-bold">
                  Featured Expeditions
                </h1>
              </Col>
            </Row>
          ) : props.page === "noTitle" ? (
            <></>
          ) : (
            <Row className="mx-auto px-0" style={{maxWidth:"1140px"}}>
              <Col className="search-expeditions-title mt-5 px-0">
                <h3 className="search-expeditions-h3 font-weight-bold">
                  Expeditions Search Results
                </h3>
              </Col>
            </Row>
          )}

          <Row className="mt-0 justify-content-between" style={{ maxWidth: "1140px", margin: "0 auto" }}>
            {results.map((trip, index) =>
              isTabletOrMobile ? (
                index < 2 ? (
                  <Col key={trip.huntingTripID} xs={12} md={6} className="">
                    <div className="ml-0 my-4 mb-0 bg-white rounded featured-card">
                      <div className="w-100">
                        <img
                          src={trip.galleryFirst}
                          alt=""
                          className="img-featured rounded"
                        />
                      </div>
                      <div className="expeditions-cards-content">
                        <Row>
                          <Col>
                            <p className="primary-color font-size-12 mb-1 font-weight-bold">
                              {trip.guideBusinessName}
                            </p>
                          </Col>
                          <Col xs="auto">
                            {" "}
                            <Favorite
                              entityType="HUNT_TRIP"
                              entityId={trip.huntingTripID}
                            />
                          </Col>
                        </Row>
                        <p className="font-size-18 font-weight-bold mb-4">
                          {trip.huntingTripName}
                        </p>
                        <p className="mb-1 font-size-13">
                          Dates:{" "}
                          <span className="font-weight-bold">
                            {getCleanDates(trip.timeFrame)}
                          </span>
                        </p>
                        <p className="font-size-13">
                          Location:{" "}
                          <span className="font-weight-bold">
                            {trip.location}
                          </span>
                        </p>

                        <Row className="justify-content-between">
                          <Col xs="12" md="auto" className="d-flex mb-3">
                            <Row className="my-auto justify-content-xs-start">
                              <Col xs="5" md="auto" className="d-flex">
                                <p className="primary-color font-weight-bold d-flex my-auto font-size-15 ">
                                  <CurrencyFormat
                                    value={
                                      trip.discountPerPerson
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => value}
                                  />
                                </p>
                              </Col>
                              <Col xs="5" md="auto" className="d-flex ">
                                {trip.discountPerPerson > 0 && (
                                  <p className="price-original d-flex my-auto">
                                    <CurrencyFormat
                                      value={trip.pricePerPerson}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      renderText={(value) => value}
                                    />
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" md="auto" className="mb-3">
                            <Button
                              onClick={() => {
                                history.push(
                                  `/hunting-trip/${trip.huntingTripID}`
                                );
                              }}
                              variant="guidex"
                              type="submit"
                              className="featured-btn font-weight-bold d-flex pl-3 pr-3 pt-2 pb-2"
                            >
                              Learn More
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ) : props.page === "featuredExpeditions" ? (
                  <Col key={trip.huntingTripID} xs={12} md={6} className="">
                    <div className="ml-0 my-4 mb-0 bg-white rounded featured-card">
                      <div className="w-100">
                        <img
                          src={trip.galleryFirst}
                          alt=""
                          className="img-featured rounded"
                        />
                      </div>
                      <div className="expeditions-cards-content">
                        <Row>
                          <Col>
                            <p className="primary-color font-size-12 mb-1 font-weight-bold">
                              {trip.guideBusinessName}
                            </p>
                          </Col>
                          <Col xs="auto">
                            {" "}
                            <Favorite
                              entityType="HUNT_TRIP"
                              entityId={trip.huntingTripID}
                            />
                          </Col>
                        </Row>
                        <p className="font-size-18 font-weight-bold mb-4">
                          {trip.huntingTripName}
                        </p>
                        <p className="mb-1 font-size-13">
                          Dates:{" "}
                          <span className="font-weight-bold">
                            {getCleanDates(trip.timeFrame)}
                          </span>
                        </p>
                        <p className="font-size-13">
                          Location:{" "}
                          <span className="font-weight-bold">
                            {trip.location}
                          </span>
                        </p>

                        <Row className="justify-content-between">
                          <Col xs="12" md="auto" className="d-flex mb-3">
                            <Row className="my-auto justify-content-xs-start">
                              <Col xs="5" md="auto" className="d-flex">
                                <p className="primary-color font-weight-bold d-flex my-auto font-size-15 ">
                                  <CurrencyFormat
                                    value={trip.discountPerPerson > 0 ? trip.discountPerPerson : trip.pricePerPerson}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => value}
                                  />
                                </p>
                              </Col>
                              <Col xs="5" md="auto" className="d-flex ">
                                {trip.discountPerPerson > 0 && (
                                  <p className="price-original d-flex my-auto">
                                    <CurrencyFormat
                                      value={trip.pricePerPerson}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      renderText={(value) => value}
                                    />
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" md="auto" className="mb-3">
                            <Button
                              onClick={() => {
                                history.push(
                                  `/hunting-trip/${trip.huntingTripID}`
                                );
                              }}
                              variant="guidex"
                              type="submit"
                              className="featured-btn font-weight-bold d-flex pl-3 pr-3 pt-2 pb-2"
                            >
                              Learn More
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <div key={trip.huntingTripID}></div>
                )
              ) : (index < 9 &&
                <Col key={trip.huntingTripID} xs={12} md={4} className="px-0" style={{ maxWidth: '360px' }}>
                  <div className="ml-0 my-4 mb-0 bg-white rounded featured-card">
                    <div className="w-100">
                      <img
                        src={trip.galleryFirst}
                        alt=""
                        className="img-featured"
                      />
                    </div>
                    <div className="expeditions-cards-content">
                      <Row>
                        <Col>
                          <p className="primary-color font-size-12 mb-1 font-weight-bold text-capitalize">
                            {trip.guideBusinessName}
                          </p>
                        </Col>
                        <Col xs="auto">
                          {" "}
                          <Favorite
                            entityType="HUNT_TRIP"
                            entityId={trip.huntingTripID}
                          />
                        </Col>
                      </Row>
                      <p className="font-size-18 font-weight-bold mb-2 long-title">
                        {trip.huntingTripName}
                      </p>
                      <p className="mb-1 font-size-13">
                        Dates:{" "}
                        <span className="font-weight-bold">
                          {getCleanDates(trip.timeFrame)}
                        </span>
                      </p>
                      <p className="font-size-13">
                        Location:{" "}
                        <span className="font-weight-bold">
                          {trip.location}
                        </span>
                      </p>

                      <Row className="justify-content-between">
                        <Col xs="12" md="auto" className="d-flex mb-3">
                          <Row className="my-auto justify-content-xs-start">
                            <Col xs="5" md="auto" className="d-flex">
                              <p className="primary-color font-weight-bold d-flex my-auto font-size-15 ">
                                <CurrencyFormat
                                  value={trip.discountPerPerson > 0 ? trip.discountPerPerson : trip.pricePerPerson}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  renderText={(value) => value}
                                />
                              </p>
                            </Col>
                            <Col xs="5" md="auto" className="d-flex px-0">
                              {trip.discountPerPerson > 0 && (
                                <p className="price-original d-flex my-auto font-size-15">
                                  <CurrencyFormat
                                    value={trip.pricePerPerson}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => value}
                                  />
                                </p>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" md="auto" className="mb-3">
                          <Button
                            onClick={() => {
                              history.push(
                                `/hunting-trip/${trip.huntingTripID}`
                              );
                            }}
                            variant="guidex"
                            type="submit"
                            className="featured-btn font-weight-bold d-flex pl-3 pr-3 pt-2 pb-2"
                          >
                            Learn More
                          </Button>
                        </Col>
                      </Row>

                    </div>
                  </div>
                </Col>
              )
            )}
          </Row>

          {props.page === "featured" ? (
            <Row className="justify-content-center">
              <Col xs="auto">
                <Link to="/featured-expeditions">
                  <Button
                    variant="guidex-outline"
                    size="guidex-lg"
                    className="see-more mb-5 mt-4"
                  >
                    See More
                  </Button>
                </Link>
              </Col>
            </Row>
          ) : props.page === "PDP" ? (
            <Row></Row>
          ) : (
            <div></div>
          )}
        </Container>
      </div>
    </>
  );
};
export default FeaturedExpeditions;
