import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Captain.css";
import MenuCaptain from "./MenuCaptain";

const Capitain = (props) => {
  const { trip, guide } = props;
  return (
    <>
      <Container className="captain">
        <Row>
          <Col md={12} className="captain-menu">
            <MenuCaptain trip={trip} guide={guide} />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Capitain;
