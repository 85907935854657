import { toast } from "react-toastify";

const getInitials = (fullName = "") => {
  let arrayName = fullName.split(" ");
  let arrayInitials = [];
  arrayName.slice(0, 2).forEach((str) => {
    if (str.length) {
      arrayInitials.push(str[0]);
    }
  });
  return arrayInitials.join("");
};

const showToast = (message, type) => {
  return toast(message, {
    type: type,
    position: "bottom-right",
  });
};

export { getInitials, showToast };
