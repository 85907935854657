import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import { app } from "../../../FirebaseConfig";
import { showToast } from "../../../utils/Misc";
import { User } from "../../../utils/UserProvider";
import { request } from "../../../utils/HttpHelper";
import SettingsChangePassword from "../Common/Settings/SettingsChangePassword";
import SettingsDeleteAccount from "../Common/Settings/SettingsDeleteAccount";
import "./AccoutDetailsCustomer.css";

const AccoutDetailsCustomer = ({ email, userId }) => {
  const [fullNameCustomer, setFullNameCustomer] = useState("");
  const [phoneNumberCustomer, setPhoneNumberCustomer] = useState("");
  const [error, setError] = useState(null);
  const { user, setuser } = useContext(User);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [isSubmittingNewPass, setIsSubmittingNewPass] = useState(false)

  const getUserData = async () => {
    setIsLoading(true);
    request({
      url: 'guidex-api',
      trailingURL: `/customer`,
      requiresAuth: true
    })
    .then((result) => {
      setIsLoading(false);
      const customersList = result.data["data"];
      if (customersList.length > 0) {
        setFullNameCustomer(customersList[0]["fullName"]);
        if (customersList[0]["phoneNumber"])
          setPhoneNumberCustomer(customersList[0]["phoneNumber"]);
      } else {
        showToast(
          <>
            <AiFillWarning /> There was a problem trying to get this user
            data.
          </>,
          "warning"
        );
      }
    })
    .catch((err) => {
      console.error(error);
      setIsLoading(false);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to get this user data.
        </>,
        "error"
      );
    });
  };

  const updateCustomerData = async (values, setSubmitting) => {
    try {
      
      await request({
        method: 'POST',
        url: 'guidex-api',
        trailingURL: `/customer`,
        body: {
          userID: userId,
          fullName: values.fullName,
          phoneNumber: values.phoneNumber,
        },
        requiresAuth: true
      });

      /** Updating name in local storage */
      let userD = user;
      userD.fullName = values.fullName;
      let selRoleI = userD.otherRoles.findIndex(
        (r) => r.roleName === "CUSTOMER"
      );
      if (selRoleI >= 0) {
        let selRole = userD.otherRoles[selRoleI];
        selRole.fullName = values.fullName;
        userD.otherRoles[selRoleI] = selRole;
      }
      setuser(userD);
      //window.localStorage.setItem("userRolesData", JSON.stringify(userD));
      showToast(
        <>
          <AiFillCheckCircle /> Data saved correctly!
        </>,
        "success"
      );
      setSubmitting(false);
    } catch (error) {
      setError(error.message);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to update this user data.
        </>,
        "warning"
      );
      setSubmitting(false);
    }
  };

  const handleClosePassword = () => {
    setShowPassword(false);
  }

  const handleCloseDeleteAccount = () => {
    setShowDeleteAccount(false)
  }

  const changeModalPassword = () => {
    setShowPassword(true);
  }

  const changeModalDeleteAccount = () => {
    setShowDeleteAccount(true);
  }

  const changePassword = () => {
    setIsSubmittingNewPass(true);
    app.auth().sendPasswordResetEmail(email)
    .then( val => {
      changeModalPassword();
      setIsSubmittingNewPass(false);
    })
    .catch( err => {
      console.error(error);
      setIsSubmittingNewPass(false);
    })
  }

  useState(() => {
    const initialState = getUserData();
    return initialState;
  });

  return (
    <>
      {isLoading ? (
        <center>
          <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
        </center>
      ) : (
        <Container fluid className="acc-details-form-container mb-5">
          <Row>
            <Col>
              <p className="acc-details-info"> Your Information</p>
            </Col>
          </Row>
          <Formik
            enableReinitialize={true}
            initialValues={{
              fullName: fullNameCustomer,
              email: email,
              phoneNumber: phoneNumberCustomer,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.fullName) {
                errors.fullName = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              updateCustomerData(values, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      placeholder="Full Name"
                      className="acc-details-form-input"
                      name="fullName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                      isInvalid={!!errors.fullName}
                      isValid={touched.fullName && !errors.fullName}
                    />
                    <Form.Control.Feedback className="mt-2" type="invalid">
                      {errors.fullName}
                    </Form.Control.Feedback>
                    <Form.Label className="mt-4">Phone Number</Form.Label>
                    <Form.Control
                      placeholder="Phone Number"
                      className="acc-details-form-input"
                      name="phoneNumber"
                      onChange={handleChange}
                      value={values.phoneNumber}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      placeholder="Email Address"
                      className="acc-details-form-input disabled-input"
                      name="email"
                      disabled={true}
                      onChange={handleChange}
                      value={values.email}
                    />
                    <Form.Label className="mt-4">Password</Form.Label>
                    <div className="position-relative">
                    <Button variant="guidex" onClick={changePassword} disabled={isSubmittingNewPass}>
                        Change Password
                      </Button>
                    </div>
                  </Col>
                </Form.Row>
                <Row>
                  <Col>
                    <p className="mt-5 mb-4 adv-settings">Advance Settings</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="acc-details-delete" onClick={changeModalDeleteAccount}>
                      Delete Account <BsFillTrashFill className="ml-2" />
                    </Button>
                  </Col>
                </Row>
                <Row className="d-flex flex-row-reverse">
                  <Button
                    variant="guidex"
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-save-portal"
                  >
                    Save Edits
                  </Button>
                  <div
                    className={`${
                      error ? "d-block" : "d-none"
                    } invalid-feedback`}
                  >
                    {error}
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
          <Modal show={showPassword} onHide={handleClosePassword} size="lg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="ml-5 mr-5">
              <SettingsChangePassword onReturn={handleClosePassword} />
            </Modal.Body>
          </Modal>
          <Modal show={showDeleteAccount} onHide={handleCloseDeleteAccount} size="lg">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="ml-5 mr-5">
              <SettingsDeleteAccount onCancel={handleCloseDeleteAccount} onSubmit={handleCloseDeleteAccount} isCustumer={true}/>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </>
  );
};
export default AccoutDetailsCustomer;
