import React, { useState, useEffect, useContext } from "react";
import AdvertisingPerArea from "./AdvertisingPerArea";
import AdvertisingRanking from "./AdvertisingRanking";
import AdvertisingFeaturedTrips from "./AdvertisingFeaturedTrips";
import AdvertisingConfirmation from "./AdvertisingConfirmation";
import AdvertisingShares from "./AdvertisingShares";
import AdvertisingCheckout from "./AdvertisingCheckout";
import { AiFillWarning } from "react-icons/ai";
import { request } from "../../../../utils/HttpHelper";
import { showToast } from "../../../../utils/Misc";
import Loader from 'react-loader-spinner';
import { useLocation } from "react-router-dom";
import { Staff, StaffProvider } from "../../../../utils/staffProvider";
import { set } from "date-fns/esm";
import AdvertisingPerAreaCheckout from "./AdvertisingPerAreaCheckout";
const Advertisement = () => {

  const [step, setStep] = useState("");
  const [aquireSharesData, setAquireSharesData] = useState();
  const [featuredTripData, setFeaturedTripData] = useState();
  const [session, setSession] = useState();
  
  
  const [regions, setRegions] = useState([]);
  const [species, setSpecies] = useState([]);
  const [activeRegionSpecies, setActiveRegionSpecies] = useState([]);
  const [featuredTrips, setFeaturedTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRS, setIsLoadingRS] = useState(false);
  const [sessionId, setSessionId] = useState();
  const location = useLocation();
  const [selectedTripData, setSelectedTripData] = useState();
  const { signInAs } = useContext(Staff)
  const getRegionsCatalogue = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let regionsResult = await request({
          url: 'guidex-api',
          trailingURL: `/adv-apply?advType=REGIONS`,//
          requiresAuth: true
        });
        resolve(regionsResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const getSpeciesCatalogue = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let speciesResult = await request({
          url: 'guidex-api',
          trailingURL: '/adv-apply?advType=SPECIES',
          requiresAuth: true
        });
        resolve(speciesResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const getActiveAreasSpecies = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let avtiveASResult = await request({
          url: 'guidex-api',
          trailingURL: '/shares-hunter',
          requiresAuth: true
        });
        resolve(avtiveASResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const getMyFeaturedTrips = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let avtiveASResult = await request({
          url: 'guidex-api',
          trailingURL: '/shares-trip',
          requiresAuth: true
        });
        resolve(avtiveASResult.data)
      } catch (error) {
        reject(error);
      }
    })
  }

  const reloadAreaSpecies = async () => {
    try {
      setIsLoadingRS(true);
      let activeRS = await getActiveAreasSpecies();
      setActiveRegionSpecies(activeRS.shares);
      setIsLoadingRS(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch the advertisement data.
        </>,
        "warning"
      );
      setIsLoadingRS(false);
    }
  }

  const initAdvertising = async () => {
    try {
      setIsLoading(true);
      let regionsList = await getRegionsCatalogue();
      let speciesList = await getSpeciesCatalogue();
      setRegions(regionsList.data.map(region => {
        let item = {
          label: region.advName,
          key: region.advID
        };
        return item;
      }));
      setSpecies(speciesList.data.map(region => {
        let item = {
          label: region.advName,
          key: region.advID
        };
        return item;
      }));
      let activeRS = await getActiveAreasSpecies();
      setActiveRegionSpecies(activeRS.shares);
      let featured = await getMyFeaturedTrips();
      setFeaturedTrips(featured.trips);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      showToast(
        <>
          <AiFillWarning /> There was a problem trying to fetch the advertisement data.
        </>,
        "warning"
      );
      setIsLoading(false);
    }
  }

  const addNewFeatured = () => {
    setStep("rankingFeatured");
  };

  const selectFeatured = (tripIData => {
    setSelectedTripData(tripIData)
    setStep("rankingFeatured");
  })

  const reviewPayment = (session, tripIData) => {
    console.dir(session)
    setSession(session);
    setSelectedTripData(tripIData)
    setStep("rankingFeaturedCheckout");
  };

  const cancelFeaturedReview = () => {
    setSelectedTripData()
    setStep("");
  }

  const apply = (regionId, speciesId) => {
    setAquireSharesData({
      regionId,
      speciesId
    })
    setStep("acquiredShares");
  }

   const confirmCardPayment = (advertisementData) =>{
    setStep("rankingFeaturedConfirmation");
    setSelectedTripData(advertisementData)
   }

   const onReturn = async (sharesData) => {
    {
      setAquireSharesData(sharesData);
      setStep("")
      //sharesData.dailyRate === 0 ?
     // setStep("rankingFeaturedConfirmation") : // we need to make sure after the next phase to charge for it with stripe
      //setStep("acquiredSharesCheckout")
    }
   
    await reloadAreaSpecies();
  }

  useEffect(() => {
    const locHash = location.hash;
    if (locHash.includes("?")) {
      const query = locHash.split("?")[1];
      const queryParams = query.split("&")
      let queryResult = {};
      queryParams.forEach(qp => {
        let [key, val] = qp.split("=");
        let obj = {
          [key]: val
        }
        queryResult = { ...queryResult, ...obj }
      });
      console.log(queryResult);
      if (queryResult.session_id) {
        console.log(queryResult.session_id)
        setSessionId(queryResult.session_id);
      }
      setStep("rankingFeaturedConfirmation");
    } else {
      initAdvertising();
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    !isLoading ?
      <div className="mb-5">
        {(() => {
          switch (step) {
            case "rankingFeatured":
              return <AdvertisingFeaturedTrips
                onReview={reviewPayment}
                onCancel={cancelFeaturedReview}
                confirmCardPayment={confirmCardPayment}
                selectedTripData={selectedTripData}
              />;
            case "rankingFeaturedCheckout":
              return <AdvertisingCheckout
              session={session}
              advertisementData={selectedTripData}
              confirmCardPayment={confirmCardPayment}
              
               />;
            case "rankingFeaturedConfirmation":
              return <AdvertisingConfirmation sessionId={sessionId} advertisementData={selectedTripData} />;
            case "acquiredSharesCheckout":
              return <AdvertisingPerAreaCheckout 
              confirmCardPayment={confirmCardPayment}
              aquireSharesData={aquireSharesData}  
              />
            case "acquiredShares":
              return (
                 !isLoadingRS ?
                  <AdvertisingShares onReturn={onReturn} aquireSharesData={aquireSharesData}                     
                  /> :
                  <center>
                    <Loader
                      type="ThreeDots"
                      color="#ec7228"
                      height={25}
                      width={50}
                    />
                  </center>
              )
            default:
              return (
                <>
                  <AdvertisingPerArea
                    onApply={apply}
                    regions={regions}
                    species={species}
                    activeRegionSpecies={activeRegionSpecies}
                  />
                  <AdvertisingRanking
                    onAddNew={addNewFeatured}
                    onSelect={selectFeatured}
                    featuredTrips={featuredTrips}
                  />
                </>
              );
          }
        })()}
      </div>
      :
      <center>
        <Loader type="TailSpin" color="#ec7228" height={150} width={150} />
      </center>
  );
};
export default Advertisement;
