import React, { useState } from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import keypad from "../../../../assets/icons/keypad-fill.svg";
import "./CardImage.css";
import DeleteImage from "./DeleteImage";

const CardImage = (props) => {
  const { imageData, handleAction } = props;
  const [showSwitch, setShowSwitch] = useState(false);
  const handleClose = () => {
    setShowSwitch(false);
  };

  const changeModal = () => {
    setShowSwitch(true);
  };
  return (
    <>
      <Container
        className="shadow bg-white card-image-container favorite-card  p-0 mb-4 "
        fluid
      >
        <Row noGutters className="card-fav-trips ">
          <Col lg={1} sm={1} md={1} className="d-flex justify-content-center">
            <Image src={keypad} width={30} />
          </Col>
          <Col lg={3} sm={3} md={3}>
            <Image
              src={imageData.imageUrl}
              className="card-image-size"
              fluid
              style={{maxHeight: "140px"}}
            />
          </Col>
          <Col lg={6} md={4} sm={2}></Col>
          <Col lg={2} md={4} sm={6} className="d-flex justify-content-center pr-5">
            {/* <Button variant="transparent" onClick={() => handleAction("edit", imageData.imageGalleryID)}>
              <span className="card-button card-button-edit align-self-center">
                Edit{" "}
              </span>
            </Button>
            <span className="card-separator align-self-center"></span> */}
            <Button variant="transparent">
              <span
                className="card-button align-self-center "
                onClick={changeModal}
              >
                Delete{" "}
              </span>
            </Button>
          </Col>
        </Row>
        <Modal show={showSwitch} onHide={handleClose} size="xl">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="ml-5 mr-5">
            <DeleteImage handleAction={handleAction} onCancel={() => {
              handleClose();
            }} imageId={imageData.imageGalleryID}></DeleteImage>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};
export default CardImage;
